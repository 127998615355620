import { all } from 'redux-saga/effects';
import ContactSaga from '../screens/coach/components/contact/contact.saga';
import CoachSaga from '../screens/coach/coach.saga';
import LoginSaga from '../screens/login/login.saga';
import HomeSaga from '../screens/home/home.saga';
import BlogSaga from '../screens/blog/blog.saga';
import CoachesList from '../screens/coaches-list/coaches-list.saga';
import StepForms from '../screens/steps-forms/step-forms.saga';

export default function* rootSaga() {
    yield all([
        ContactSaga(),
        CoachSaga(),
        LoginSaga(),
        HomeSaga(),
        BlogSaga(),
        CoachesList(),
        StepForms(),
    ]);
}