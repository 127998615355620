import {put, call, all, takeEvery, select} from 'redux-saga/effects';

import {actions, APIroutes, basepath4,} from '../../utils/constants';
import {axiosGet} from '../../utils/common-functions';
import {treatError} from '../../utils/error-utils';
import {setIsPostsLoading, setPosts, setPostsCategories, setPostsPageContent, setPostSuggestions} from './blog.actions';
import { history } from '../../utils/store';

function* getPosts({payload}) {
    try {
        const {category, offset, search, isFetchMore} = payload;
        const {data: postCategories} = yield call(axiosGet, `${basepath4}${APIroutes.blogPostsCategories}`);

        if(postCategories) {
            yield put(setPostsCategories(postCategories.items));
        }

        if (!isFetchMore) {
            yield put(setIsPostsLoading(true));
        }

        const hasCategories = postCategories.items && postCategories.items.length > 0;

        const selectedCategory = (category && hasCategories) ? postCategories.items.find(i => i.name === category) || {} : {};

        if (category && !selectedCategory.id) {
            return history.push('/not_found');
        }

        const categoryString = selectedCategory.id || null;
        const searchQuery = search ? `&search=${search}`: '';
        const categoryQuery = !search && category ? `&categories=${categoryString}` : '';
        const offsetQuery = offset ? `&offset=${offset}` : '';
        const queryString = `&limit=10${categoryQuery}${offsetQuery}${searchQuery}`;

        const {data} = yield call(axiosGet, `${basepath4}${APIroutes.blogPosts}${queryString}`);

        if (!isFetchMore) {
            yield put(setPosts({
                total: data.meta.total_count,
                posts: data.items,
            }));
        } else {
            const currentPosts = yield select(({Blog}) => Blog.postsData);

            yield put(setPosts({
                total: data.meta.total_count,
                posts: [...currentPosts.posts,  ...data.items],
            }));
        }
    } catch (error) {
        treatError(error);
    } finally {
        yield put(setIsPostsLoading(false));
    }
}

function* getPostsPageContent({payload}) {
    try {
        yield put(setIsPostsLoading(true));
        const {data: pageContent} = yield call(axiosGet, `${basepath4}${APIroutes.blogPosts}&slug=${payload}`);
        const {data: postCategories} = yield call(axiosGet, `${basepath4}${APIroutes.blogPostsCategories}`);

        if(postCategories) {
            yield put(setPostsCategories(postCategories.items));
        }

        if (pageContent.items.length < 1) {
            history.push('/not_found');
        } else {
            const {data: postSuggestions} = yield call(axiosGet, `${basepath4}${APIroutes.blogPosts}&limit=3`);
            const suggestionsFiltered = postSuggestions.items.filter(i => i.meta.slug !== payload).slice(0, 2);
            yield put(setPostSuggestions(suggestionsFiltered));
            yield put(setPostsPageContent(pageContent.items[0]));
        }
       
    } catch (error) {
        treatError(error);
    } finally {
        yield put(setIsPostsLoading(false));
    }

}

export default function* HomeSaga() {
    yield all([
        yield takeEvery(actions.ASYNC_GET_POSTS, getPosts),
        yield takeEvery(actions.ASYNC_GET_POSTS_PAGE_CONTENT, getPostsPageContent),
    ]);
} 