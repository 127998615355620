import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Header, Resume, Footer, Gallery, Videos, Testimonies } from '../../index';

import './oldTemplate.scss';
import { shallowEqual, useSelector } from 'react-redux';
import SessionRequestForm from '../components/SessionRequestForm/SessionRequestForm';
import ContactForm from '../components/contact-form';
import TitleSection from '../../../../components/TitleSection/TitleSection';

function OldTemplate({ themeSettingSelected, themeColor }) {
    const templateRef = useRef(null);
    const {t} = useTranslation();

    const username = useSelector(({Coach}) => Coach.username);
    const theme = useSelector(({Coach}) => Coach.user.themeColor || '#3588db');

    useEffect(() => {
        templateRef.current.style.setProperty('--c-primary', themeColor);
    }, [themeColor]);

    const coachDetails = useSelector(
        ({Coach}) => Coach,
        shallowEqual,
    );

    return (
        <div className='page-coach-content' ref={templateRef}>
            <Header />
            <Resume />
            <Videos videosQtd={themeSettingSelected.videosQtd} />
            <Testimonies />
            <Gallery imgsQtd={themeSettingSelected.galerryQtd} />
            {coachDetails.user.enableSiteExperimentalSession
                ? <SessionRequestForm />
                : (
                    <section
                        className='old-template-contact-section'
                        style={{backgroundColor: theme}}
                        id="contact"
                    >
                        <TitleSection
                            text1={t('ENTER_IN')}
                            text2={t('CONTACT')}
                            style={{color: '#ffffff'}}
                            text2Style={{color: '#ffffff'}}
                            colorIcon='#ffffff'
                            iconView={true}
                            iconName='icon-text-effect'
                            iconWidth='39px'
                        />
                        <ContactForm username={username} />
                    </section>
                )
            }
            <Footer />
        </div>
    );
}

export default OldTemplate;