import React from 'react';
import {Link} from 'react-router-dom';

import Carousel from '../../../../components/SlickCarousel/slickCarousel';
import LazyImage from '../../../../components/lazyImage';

import './postSlider.scss';

const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
};

function PostSlider({posts}) {
    return (
        <Carousel settings={settings} className='post-slider'>
            {posts.map(post => <PostSlide post={post} key={post.id} />)}
        </Carousel>
    );
}

function PostSlide({post}) {
    return (
        <Link to={`/blog/post/${post.meta.slug}`}>
            <article className='post-slide'>
                <LazyImage
                    src={post.thumbnail}
                    alt='Thumbnail da postagem'
                    placeholderClassName='lazy-placeholder'
                />
                <div className='post-info'>
                    <h4>{post.title}</h4>
                    <p>{post.description}</p>
                </div>
            </article>
        </Link>
    );
}

export default PostSlider;
