import React from 'react';
import NavBar from './nav-bar/nav-bar';
import EffectMessage from './effect-message/effect-message';
import { useWidth } from '../../../template.hooks';
import IconSvg from '../../../../../../utils/svg';
import SocialMedias from '../../../components/social-medias/social-medias';

import './banner.css';

function Banner({
    enableBiography,
    enableResume,
    enableVideos,
    enableTestimonies,
    enableGallery,
    onClick,
    facebook,
    linkedin,
    instagram,
    twitter,
    whatsapp,
    youtube,
    gallery,
    testimonies,
    videos,
    backgroundImg,
    mainPhrase,
    mainPhraseAuthor,
    tel,
    firstName,
    location,
    themeColor
}) {
    const [width] = useWidth();

    return (
        <section
            id='home'
            onClick={() => onClick('home')}
            className='template--7--banner'>
            <NavBar
                enableBiography={enableBiography}
                enableResume={enableResume}
                enableVideos={enableVideos}
                enableTestimonies={enableTestimonies}
                enableGallery={enableGallery}
                firstName={firstName}
                tel={tel}
                facebook={facebook}
                linkedin={linkedin}
                instagram={instagram}
                twitter={twitter}
                whatsapp={whatsapp}
                youtube={youtube}
                gallery={gallery}
                testimonies={testimonies}
                videos={videos}
                themeColor={themeColor}
            />

            <section className='background-image'>
                <EffectMessage
                    mainPhrase={mainPhrase}
                    mainPhraseAuthor={mainPhraseAuthor} />

                <div className='background-image-content'>

                    <img src={backgroundImg.desktop} alt='banner' />

                    {width >= 850 &&
                        <div className='background-informations'>
                            <div className='background-location'>
                                <IconSvg name='icon-pin' width='18px' color={'#262D52'} />
                                <span> {location} </span>
                            </div>

                            <SocialMedias
                                facebook={facebook}
                                linkedin={linkedin}
                                instagram={instagram}
                                twitter={twitter}
                                whatsapp={whatsapp}
                                youtube={youtube}
                                sizeIcons={25}
                                direction='row'
                                color='#262D52'
                            />
                        </div>
                    }
                </div>
            </section>

        </section>
    );
}

export default Banner;