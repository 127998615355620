import {actions} from '../../../../utils/constants';

export const addPicture = (picture, gallery = [], caption = '') => ({
    type: actions.ASYNC_ADD_GALLERY_PICTURE,
    payload: {
        picture,
        gallery,
        caption
    },
});

export const deletePicture = (picture, gallery = []) => ({
    type: actions.ASYNC_DELETE_GALLERY_PICTURE,
    payload: {
        picture,
        gallery,
    },
});

export const editPictureCaption = (picture, gallery = []) => ({
    type: actions.ASYNC_EDIT_GALLERY_PICTURE,
    payload: {
        picture,
        gallery,
    },
});
