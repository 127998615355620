import React from 'react';
import {useDispatch} from 'react-redux';

import {asyncGetPosts, setSearch} from '../../blog.actions';
import filesImg from '../../../../img/files.svg';
import {history} from '../../../../utils/store';

import './no-posts-found.scss';

function NoPostsFound({hasSearch, hasCategory}) {
    const dispatch = useDispatch();

    const handleClearSearch = () => {
        history.push('/blog');
        dispatch(setSearch(''));
        dispatch(asyncGetPosts({}));
    };

    return (
        <div className='no-posts-found'>
            <p>Nenhuma postagem encontrada</p>
            <img src={filesImg} alt='Busca não encontrada' />
            {(hasSearch || hasCategory) && (
                <button onClick={handleClearSearch}>
                    Voltar para o início
                </button>
            )}
        </div>
    );
}

export default NoPostsFound;
