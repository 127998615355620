import React from 'react';
import NewHeader from '../../../../components/newHeader';
import ContainerList from '../container-list';
import Search from '../search';

import './container-main.css';

const ContainerMain = () => {
    return (
        <div className='coaches-list-container-main' >
            <NewHeader showLogo={false} />
            <Search isDouble isResponsive />
            <ContainerList />
        </div>
    );
};

export default ContainerMain;