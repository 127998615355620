import React, { Component } from 'react';
import { CustomModal, ImageCard, Loader, Collapse } from '../../../commons/index';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setBackgroundImg, imagesBackgroundLoad } from '../../coach.actions';


import './background-selector.css';

class BackgroundSelector extends Component {
    constructor(props) {
        super(props);
        this.setImage = this.setImage.bind(this);
    }

    componentDidMount() {
        this.props.imagesBackgroundLoad();
    }

    setImage(urls) {
        this.props.setBackgroundImg(urls);
        this.props.close();
    }

    createCategoriesCollapses(arr) {
        return arr.map(({ name, images })=> {
            return this.createCategoryCollapse({name, images});
        });
    }

    createCategoryCollapse({ name, images}) {
        return (
            <Collapse id={name} title={ name } key={name}>
                <div className='images-container'>
                    {
                        images.map((obj, i)=> {
                            return <ImageCard onclick={ ()=> this.setImage( { mobile: obj['url-mobile'], desktop: obj['url-desktop'] } ) } key={ i } imgUrl={obj['url-mobile']}  />;
                        })
                    }
                </div>
            </Collapse>
        );
    }   

    render() {
        const { show, close, className } = this.props;
        return (
            <CustomModal className={ className } show={ show } close={ close } title={'Selecionar imagem'}>   
                { this.props.imgsLoading && <Loader height={'50vh'} color={'#F17127'} /> }
                    
                
                { !this.props.imgsLoading && 
                <div className="list-select-modal">
                    { this.createCategoriesCollapses( this.props.backImgs ) }
                </div>
                    
                }
            </CustomModal>
        );
    }
}

const mapStateToProps = state => ({ backImgs: state.Coach.backgroundImgs, imgsLoading: state.Coach.backImgsLoading });
const mapDispatchToProps = dispatch => bindActionCreators( {setBackgroundImg, imagesBackgroundLoad}, dispatch );

export default connect(mapStateToProps, mapDispatchToProps)(BackgroundSelector);