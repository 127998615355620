import { combineReducers } from 'redux';

import TestimoniesReducer from '../screens/coach/components/testimonies/testimonies.reducer';
import GalleryReducer from '../screens/coach/components/gallery/gallery.reducer';
import VideosReducer from '../screens/coach/components/videos/videos.reducer';
import ContactReducer from '../screens/coach/components/contact/contact.reducer';
import CoachReducer from '../screens/coach/coach.reducer';
import Login from '../screens/login/login.reducer';
import HomeReducer from '../screens/home/home.reducer';
import Blog from '../screens/blog/blog.reducer';
import StepForms from '../screens/steps-forms/step-forms.reducer';
import { connectRouter } from 'connected-react-router';

const rootReducer = history => combineReducers({
    Testimonies: TestimoniesReducer,
    Gallery: GalleryReducer,
    Videos: VideosReducer,
    Contact: ContactReducer,
    Coach: CoachReducer,
    Login: Login,
    Home: HomeReducer,
    StepForms,
    Blog,
    router: connectRouter(history),
});

export default rootReducer;
