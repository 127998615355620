import React from 'react';
import IconSvg from '../../../../../../../utils/svg';

import './video-card.css';

function VideoCard({
    caption,
    thumbnail,
    onClick
}) {
    return (
        <div
            onClick={onClick}
            className="template--5-video-card"
            style={{ backgroundImage: `url(${thumbnail})` }}
        >
            <div className="background-effect" />
            <div className="background-hover-effect" />
            <p className="video-subtitle">{caption}</p>
            <IconSvg name="icon-circle-play" classDiv="icon-play-float" width={40} height={40} color="#fff" />
        </div>
    )
};

export default VideoCard;