import { put, all, takeEvery, call } from 'redux-saga/effects';

import { postLogin, axiosGet } from '../../utils/common-functions';
import { history } from '../../utils/store';
import { settingsAuth, actions, basepath, APIroutes, externalUlrs, basepathV2 } from '../../utils/constants';
import CredentialsHelper from '../../utils/credentials-helper';

export function* sendServer({ payload }) {
    const KASP_MAX_ATTEMPT = 20;
    let kaspAttempt = 0;

    while (true) {
        let kaspError = false;
        kaspAttempt++;

        if (kaspAttempt > 1) {
            // eslint-disable-next-line no-console
            console.log(`attempt ${kaspAttempt} with kaspersky injected script`);
        }

        try {
            const { data: loginInfo } = yield call(postLogin, {
                grant_type: 'authorization_code',
                code: payload,
                redirect_uri: settingsAuth.redirect_uri,
            });

            CredentialsHelper.update(loginInfo.access_token, loginInfo.refresh_token);
            yield put({ type: actions.SAVE_TOKENS, payload: { token: 'token', value: loginInfo.access_token } });
            yield put({ type: actions.SAVE_TOKENS, payload: { token: 'refreshToken', value: loginInfo.refresh_token } });

            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${loginInfo.access_token}`
            };

            const { data } = yield call(axiosGet, `${basepath}${APIroutes.getUsername}`, { headers });
            yield put({ type: actions.SAVE_USERNAME, payload: data.username });

            const {status, data: profileData} = yield call(axiosGet, `${basepathV2}${APIroutes.profile}`, {headers});
            if (profileData.profile.alreadySiteAccess) {
                history.push(`${data.username}/edit`);
            } else {
                yield put({type: actions.SET_USER, payload: status === 404 ? {} : profileData.profile});
                history.push('/customize');
            }

            // success
            return;
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(`sendServer error: ${e.message}`);

            if (e.message === 'ns.GetCommandSrc is not a function') {
                kaspError = true;
            }
        }

        if (kaspError === true && kaspAttempt < KASP_MAX_ATTEMPT) {
            // eslint-disable-next-line no-console
            console.log('wait for 1 seconds for kaspersky injected script has been loaded');
            yield new Promise(resolve => setTimeout(resolve, 1000));
        } else {
            CredentialsHelper.clear();
            window.location.replace(externalUlrs.LOGOUT);

            // failed
            return;
        }
    }
}

function* changeAsyncValue(action) {
    const { id, value, keyCode } = action.payload;
    if (id === 'password' && keyCode === 13) {
        yield call(sendServer);
    } else {
        yield put({ type: 'changeValue', payload: { id, value } });
    }
}

export function* watchSendServer() {
    yield takeEvery('sendToServer', sendServer);
}

export function* watchChangeValue() {
    yield takeEvery('changeAsyncValue', changeAsyncValue);
}

function* keyPressListener(action) {
    if (action.payload === 13) {
        yield call(sendServer);
    }
}

export function* watchKeyPress() { yield takeEvery('keyPressInput', keyPressListener); }

export default function* loginSaga() {
    yield all([
        watchSendServer(),
        watchChangeValue(),
        watchKeyPress()
    ]);
}
