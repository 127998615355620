import React from 'react';
import IconSvg from '../../utils/svg';

import './Loader.css';

export default function SpinnerLoad({width='20px', height='20px', color='#ffffff'}) {
    return (
        <div className='loader-spin-container'>
            <IconSvg name='spinner-load' width={width} height={height} color={color} />
        </div>
    );
}
