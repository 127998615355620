import Axios from 'axios';
import { basepath, APIroutes, settingsAuth, urlAuth, templatesSocialMedia, regexEmail, constantItens, channel } from './constants';
import React from 'react';
import { transform, isEqual, isObject, capitalize } from 'lodash';
import {history} from './store';

export const goesTo = id => {
    window.location.href = `${id}`;
};

export const userRequest = (headers) => {
    return axiosGet(`${basepath}${APIroutes.profile}`, headers);
};

export const axiosGet = async (url, headers) => {
    return await Axios.get(url, headers).then(data => data);
};

export const axiosWitEtag = (url, etagName) => {
    const header = () => {
        let etag = getInfo(etagName);
        if (etag !== '' && etag !== null) {
            return { 'Accept': 'application/json', 'Content-Type': 'application/json', 'If-None-Match': etag };
        } else {
            return { 'Accept': 'application/json', 'Content-Type': 'application/json' };
        }
    };
    return Axios.get(url, header()).then(data => data);
};


export const CheckIfHasEmpty = (obj, keys = []) => {
    let ret = false;
    if (obj === {}) {
        return true;
    } else {
        if (keys.length > 0) {
            keys.forEach(e => {
                if (obj[e] === {} || obj[e] === '' || obj[e] === undefined || obj[e] === null || obj[e].length === 0) {
                    ret = true;
                }
            });
        } else {
            for (let key in obj) {
                if (obj[key] === {} || obj[key] === '' || obj[key] === undefined || obj[key] === null || obj[key].length === 0) {
                    ret = true;
                }
            }
        }

    }
    return ret;
};

export const isEmpty = (obj) => {
    const hasOwnProperty = Object.prototype.hasOwnProperty;
    if (obj == null) {
        return true;
    }
    if (obj.length === 0) {
        return true;
    }
    if (obj.length > 0) {
        return false;
    }

    if (typeof obj !== 'object') {
        return true;
    }
    for (var key in obj) {
        if (hasOwnProperty.call(obj, key)) {
            return false;
        }
    }

    return true;
};

export const axiosPost = (url, data, headers) => {
    return Axios.post(`${url}`, data, headers).then(res => res);
};

export const axiosPut = async (url, data, headers) => {
    return await Axios.put(`${url}`, data, headers).then(res => res);
};

export const postLogin = (data) => {
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };

    const body = {
        client_id: settingsAuth.client_id,
        machine: window.navigator.userAgent,
        scope: 'coach',
    };

    const tokenUrl = `${urlAuth}${APIroutes.token}`;

    return Axios.post(tokenUrl, { ...body, ...data }, { headers });
};


export const difference = (object, base) => {
    function changes(object, base) {
        return transform(object, (result, value, key) => {
            if (!isEqual(value, base[key])) {
                result[key] = (isObject(value) && isObject(base[key])) ? changes(value, base[key]) : value;
            }
        });
    }
    return changes(object, base);
};

export const userEditRequest = (data, headers) => {
    return axiosPost(`${basepath}${APIroutes.profile}`, data, headers)
        .then(data => data)
        .catch(err => { throw err; });
};

export const uploadImage = (data, headers, fileName = 'filename') => {
    return Axios.put(
        `${basepath}${APIroutes.upload}`,
        data,
        {
            headers
        }
    ).then(response => response)
        .catch(error => { throw error; });
};

export const encrypt = str => {
    let ret = window.btoa(window.btoa(str));
    ret = ret.replace('=', '');
    return ret;
};

export const decrypt = str => {
    str += '=';
    return window.atob(window.atob(str));
};

export const saveInfo = (key, value) => localStorage.setItem(key, value);

export const getInfo = key => localStorage.getItem(key);

export const clearStorageItem = key => localStorage.removeItem(key);

export const getViewportWidth = () => Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

export const splitIntoChunks = ([...objs], chunkSize = 2) => {
    const chunks = [];
    while (objs.length) {
        chunks.push(objs.splice(0, chunkSize));
    }
    return chunks;
};


export const createIndexKey = array => {
    return array.map((obj, key) => {
        obj.key = key;
        return obj;
    });
};

export const sortArray = (array, key, dec) => {
    if (array) {
        let newArray = [...array];
        if (dec) {
            return newArray.sort((a, b) => (a[key] < b[key]) ? 1 : ((b[key] < a[key]) ? -1 : 0));
        } else {
            return newArray.sort((a, b) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0));
        }
    } else {
        return [];
    }
};

export const takeOffIndexKey = collection => {
    let array = [...collection];
    if (array.length > 0) {
        let keys = Object.keys(array[0]).filter(e => e !== 'key');
        return array.map(e => {
            let obj = {};
            for (let i = 0; i < keys.length; i++) {
                obj[keys[i]] = e[keys[i]];
            }
            return obj;
        });
    } else {
        return array;
    }
};

export const returnStrWithLineBreak = (str = '', divider = '\n') => {
    if (!str) {
        return '';
    }
    let retArray = [];
    let strToArray = str.split(divider);
    for (let x = 0; x < strToArray.length; x++) {
        if (x < strToArray.length - 1) {
            retArray.push(strToArray[x]);
            retArray.push(<br key={x} />);
        }
        else {
            retArray.push(strToArray[x]);
        }
    }
    return retArray;
};

export function validateSocialMediaUrl(type, data) {
    const template = templatesSocialMedia[type];
    const splitted = data.split('/');
    const splittedFilter = splitted.filter(item => item.length > 1);
    const user = splittedFilter[splittedFilter.length - 1];
    const userNewType  = user.includes('@');
   
    const fixedData = template.base === 'youtube.com/' &&  !userNewType ? `${template.prefix}.${template.base}${channel}${user}` : `${template.prefix}.${template.base}${user}`;

    return fixedData;
}

export function normalizeName(name) {
    if (typeof name !== 'string') {
        return name;
    }

    return capitalize(name.toLowerCase());
}

export function formatNumber(num) {
    num = isNaN(num) ? 0 : num;
    return Number(num).toLocaleString('pt', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).split(',')[0];
}

export function validEmail(email) {
    if (!email) {
        return false;
    }
    return (regexEmail).test(email);
}

export function paramsToQueryString(params) {
    let queryString = [];

    for (const param in params) {
        const isPropValid = params[param] && params[param].length > 0;

        if (isPropValid) {
            queryString.push(`${param}=${params[param]}`);
        }
    }

    return queryString.join('&');
}

function paramsToObject(entries) {
    const result = {};

    for(const [key, value] of entries) {
        result[key] = value;
    }

    return result;
}

export function getQueryParams() {
    const queryString = history.location.search;
    const urlParams = new URLSearchParams(queryString);
    const entries = urlParams.entries();
    const params = paramsToObject(entries);

    return params;
}

export function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        return parts.pop().split(';').shift();
    }
}

export function removeConstatsFromExpertises(expertiseAreas) {
    const expertisesClear = expertiseAreas.filter(item => !constantItens.includes(item));
    return expertisesClear;
}