import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ContentLoader from 'react-content-loader';
import { toLower } from 'lodash';

import Carousel from '../../../../components/SlickCarousel/slickCarousel';
import IconSvg from '../../../../utils/svg';
import { useWidth } from '../../../../hooks/useWidth';
import { setSearch } from '../../blog.actions';

import './categories.scss';

export const allCategoriesOpt = {id: 'todos', name: 'Todos', path: '/blog'};

export function Categories({selectedCategory}) {
    const postCategories = useSelector(({Blog}) => Blog.postCategories);
    const isLoading = useSelector(({Blog}) => Blog.isLoading);
    const {pathname} = useSelector(({router}) => router.location);
    const dispatch = useDispatch();

    const categoryOptions = useMemo(() => {
        const fetchedCategories = postCategories || [];
        const categoriesWithPath = fetchedCategories.map(category => ({...category, path: `/blog/${category.name}`}));
        return [...[allCategoriesOpt], ...categoriesWithPath];
    }, [postCategories]);

    const responsive = [
        {
            breakpoint: 1500,
            settings: {
                slidesToShow: 5,
            }
        },
        {
            breakpoint: 1100,
            settings: {
                slidesToShow: 4,
            }
        },
        {
            breakpoint: 880,
            settings: {
                slidesToShow: 4,
            }
        },
        {
            breakpoint: 750,
            settings: {
                slidesToShow: 4.5,
                arrows: false,
                
            }
        },
        {
            breakpoint: 550,
            settings: {
                slidesToShow: 3.5,
            }
        },
        {
            breakpoint: 400,
            settings: {
                slidesToShow: 2.5,
            }
        },
    ];

    const settings = {
        dots: false,
        centerMode: false,
        arrows: true,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        infinite: false,
        autoplay: false,
        slidesToShow: categoryOptions && categoryOptions.length >= 6 ? 6 : categoryOptions && categoryOptions.length,
        slidesToScroll: 1,
        responsive: responsive,
    };

    function handleCategoryClick() {
        dispatch(setSearch(''));
    }

    return (
        <div className='categories'>
            {isLoading && !postCategories
                ? <LoadingContent />
                : (
                    <Carousel settings={settings} className='category' >
                        {categoryOptions.map(category => (
                            <Link
                                onClick={handleCategoryClick}
                                key={category.id} 
                                to={category.path} 
                                className={`${pathname === '/blog' && category.name === allCategoriesOpt.name ? 
                                    'category-style' : 
                                    toLower(category.name) === toLower(selectedCategory) && 'category-style'}`}
                            >
                                {category.name}
                            </Link>
                        ))}
                    </Carousel>
                )
            }
        </div>
    );
}

function NextArrow(props) {
    const { className, onClick } = props;

    const removeCustonClassName = className.replace('slick-next', 'icon-blog-next-arrow');
    return (
        <div 
            onClick={onClick} 
            className={removeCustonClassName}
        >
            {<IconSvg name='icon-blog-next-arrow' width={40} heigth={40} />}
        </div>
    );
}

function PrevArrow(props) {
    const { className, onClick } = props;

    const removeCustonClassName = className.replace('slick-prev', 'icon-blog-prev-arrow ');
    return (
        <div 
            onClick={onClick} 
            className={removeCustonClassName}
        >
            {<IconSvg name='icon-blog-prev-arrow' width={40} heigth={40} />}
        </div>
    );
}

function LoadingContent(props) {
    const [width] = useWidth();

    return (
        <ContentLoader
            style={{
                paddingLeft: '20px',
                height: '50%',
                minHeight: '32px',
                zIndex: 3,
            }}
            speed={2}
            width='100%'
            backgroundColor='#e6e6e6'
            foregroundColor='#d8d8d8'
            {...props}
        >
            {(width < 560)
                ? (
                    <>
                        <rect x='0' rx='18px' ry='18px' width='130px' height='100%' />
                        <rect x='150px' rx='18px' ry='18px' width='130px' height='100%' />
                        <rect x='300px' rx='18px' ry='18px' width='130px' height='100%' />
                        <rect x='450px' rx='18px' ry='18px' width='130px' height='100%' />
                    </>
                ) : (
                    <>
                        <rect x='0' rx='25px' ry='25px' width='170px' height='100%' />
                        <rect x='180px' rx='25px' ry='25px' width='170px' height='100%' />
                        <rect x='360px' rx='25px' ry='25px' width='170px' height='100%' />
                        <rect x='540px' rx='25px' ry='25px' width='170px' height='100%' />
                        <rect x='720px' rx='25px' ry='25px' width='170px' height='100%' />
                        <rect x='900px' rx='25px' ry='25px' width='170px' height='100%' />
                        <rect x='1080px' rx='25px' ry='25px' width='170px' height='100%' />
                        <rect x='1260px' rx='25px' ry='25px' width='170px' height='100%' />
                        <rect x='1440px' rx='25px' ry='25px' width='170px' height='100%' />
                    </>
                )
            }
        </ContentLoader>
    );
}

export default Categories;
