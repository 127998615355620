import React from 'react';

import SvgIcon from '../../utils/svg';

import './toast-body.css';

export const types = {
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'error',
    INFO: 'info',
};

function ToastBody({closeToast, message}) {
    return (
        <div className='toast-body'>
            <p className='toast-message'>{message}</p>
            <button onClick={closeToast}>
                <SvgIcon
                    name='icon-close'
                    classDiv='toast-close'
                    width='12px'
                    heigth='12px'
                />
            </button>
        </div>
    );
}

export default ToastBody;
