import React, { Component } from 'react';

import { Button } from '../../../../../commons/index';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { editProfile } from '../../../header/header.action';
import { setPublishProfile, saveConfig, cancelSaveConfig, asyncVerifyIfUsernameIsAvailable } from '../../../../coach.actions';

import './media-social.css';

class MediaSocial extends Component {
    constructor(props) {
        super(props);
        this.saveConfigs = this.saveConfigs.bind(this);
        this.cancelSaveConfigs = this.cancelSaveConfigs.bind(this);
    }

    saveConfigs() {
        this.props.saveConfig();
        this.props.saveFunction();
        this.props.close();
    }

    cancelSaveConfigs() {
        this.props.cancelSaveConfig();
        this.props.close();
    }
    
    render() { 
        const { user } = this.props;

        return ( 
            <section className='configurations-media-social'>
                <div className='social-net-container'>

                    <div className='sector-divider'>Redes Sociais</div>
                    
                    <div className='input-group'>
                        <label htmlFor='facebook'><i className='fa fa-facebook'/>Facebook</label>
                        <div className='input-group-box'>
                            <input type='text' value={ user.facebook || '' } onChange={ this.props.editProfile } id='facebook' />
                        </div>
                    </div>

                    <div className='input-group'>
                        <label htmlFor='Instagram'><i className='fa fa-instagram'/>Instagram</label>
                        <div className='input-group-box'>
                            <input type='text' value={ user.instagram || '' } onChange={ this.props.editProfile }  id='instagram' />
                        </div>
                    </div>

                    <div className='input-group'>
                        <label htmlFor='youtube'><i className='fa fa-youtube'/>youtube</label>
                        <div className='input-group-box'>
                            <input type='text' value={ user.youtube || '' } onChange={ this.props.editProfile } id='youtube'  />
                        </div>
                    </div>

                    <div className='input-group'>
                        <label htmlFor='twitter'><i className='fa fa-twitter'/>twitter</label>
                        <div className='input-group-box'>
                            <input type='text' value={ user.twitter || ''} onChange={ this.props.editProfile } id='twitter'  />
                        </div>
                    </div>

                    <div className='input-group'>
                        <label htmlFor='linkedin'> <i className='fa fa-linkedin'/>Linkedin</label>
                        <div className='input-group-box'>
                            <input type='text' value={ user.linkedin || '' } onChange={ this.props.editProfile } id='linkedin' />
                        </div>
                    </div>

                    <div className='input-group'>
                        <label htmlFor='whatsapp'> <i className='fa fa-whatsapp'/>WhatsApp</label>
                        <div className='input-group-box'>
                            <input type='text' value={ user.whatsapp || '' } onChange={ this.props.editProfile } id='whatsapp' />
                        </div>
                    </div>
                </div>

                <div className='configurations-buttons'>
                    <Button type='outline warning' text='Cancelar' click={ this.cancelSaveConfigs }  />
                    <Button type='warning' text='Salvar' disabled={ this.props.disableSaveButon } click={ this.saveConfigs } />
                </div>
            </section>
        );
    }
}
 
const mapStateToProps = state => ({
    user: state.Coach.user,
    username: state.Coach.username,
    disableSaveButon: state.Coach.disableSaveConfig,
    disableCheck: state.Coach.stateDisabled
});

const mapDispatchToProps = dispatch => bindActionCreators({ 
    editProfile, 
    setPublishProfile, 
    saveConfig, 
    cancelSaveConfig, 
    asyncVerifyIfUsernameIsAvailable
}, dispatch);

export default connect( mapStateToProps, mapDispatchToProps )( MediaSocial );
