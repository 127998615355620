import React from 'react';
import IconSvg from '../../../../../../../utils/svg';

import './photo-card.css';

function PhotoCard({ url, onClick }) {
    return (
        <div
            onClick={onClick}
            style={{ backgroundImage: `url(${url})` }}
            className="template--6-photo-card">
            <IconSvg name="icon-circle-zoom" classDiv="photo-icon-zoom" width={25} height={25} color="#fff" />
        </div>
    )
};

export default PhotoCard;