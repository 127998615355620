import React from 'react';

import Carousel from '../../../../components/SlickCarousel/slickCarousel';
import PostCard from '../postCard';

import './post-suggestions.scss';

const responsive = [
    {
        breakpoint: 630,
        settings: {
            autoplay: true,
            infinity: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
        }
    }
];

const settings = {
    dots: true,
    speed: 500,
    slidesToScroll: 0,
    slidesToShow: 2,
    responsive: responsive,
};

function PostSuggestions({posts}) {
    return (
        <section className='post-suggestions'>
            <h2 className='see-more-title'>Veja também</h2>
            <Carousel settings={settings} className='post-suggestions'>
                {posts.map(post => <PostCard post={post} key={post.id} />)}
            </Carousel>
        </section>
    );
}

export default PostSuggestions;
