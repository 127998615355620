import {actions} from '../../utils/constants';

export function asyncGetPosts(payload) {
    return {
        type: actions.ASYNC_GET_POSTS,
        payload,
    };
}

export function setPosts(payload) {
    return {
        type: actions.SET_POSTS,
        payload,
    };
}

export function setIsPostsLoading(payload) {
    return {
        type: actions.SET_IS_POSTS_LOADING,
        payload,
    };
}

export function setSearch(payload) {
    return {
        type: actions.SET_BLOG_SEARCH,
        payload,
    };
}

export function asyncGetPostsPageContent(payload) {
    return {
        type: actions.ASYNC_GET_POSTS_PAGE_CONTENT,
        payload,
    };
}

export function setPostsPageContent(payload) {
    return {
        type: actions.SET_POSTS_PAGE_CONTENT,
        payload,
    };
}

export function setPostSuggestions(payload) {
    return {
        type: actions.SET_POST_SUGGESTIONS,
        payload,
    };
}

export function setPostsCategories(payload) {
    return {
        type: actions.SET_POST_CATEGORIES,
        payload,
    };
}
