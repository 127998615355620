import React from 'react';
import {useTranslation} from 'react-i18next';

import coachImg from '../../../../img/select-man.min.webp';
import IconSvg from '../../../../utils/svg';

import './site-example.css';

function SiteExample() {
    return (
        <article className='site-example'>
            <BurgerMenu />
            <InfoOnTheLeft />
            <InfoOnTheRight />
            <img className='coach-img' alt='Coach' src={coachImg}></img>
        </article>
    );
}

function BurgerMenu() {
    return (
        <div className='burger-menu'>
            <div className='burger-layer'></div>
            <div className='burger-layer'></div>
            <div className='burger-layer'></div>
        </div>
    );
}

function InfoOnTheLeft() {
    const {t} = useTranslation();

    return (
        <section className='left-info'>
            <IconSvg name='icon-oseucoach' classDiv='site-logo' />
            <p className='welcome-paragraph'>
                {t('WELCOME_TO_MY_SPACE')}
            </p>
            <p className='transformation-paragraph'>
                {t('SECURE_YOUR_TRANSFORMATION')}
            </p>
        </section>
    );
}

function InfoOnTheRight() {
    return (
        <section className='right-info'>
            <p className='coach-name'>Vitor Sas</p>
            <div className='black-stripe'></div>
            <div className='fake-btn'></div>
        </section>
    );
}

export default SiteExample;
