import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import NewHeader from '../../components/newHeader';
import { confirmSessionRequest } from '../coach/coach.actions';
import { Loader } from '../commons';
import Footer from '../home/components/footer';

import './expSessionRequest.css';

const ExpSessionRequest = ({match}) => {
    const dispatch = useDispatch();
    const isLoading = useSelector(({ Coach }) => Coach.loadingSessionRequest);

    useEffect(() => {
        dispatch(confirmSessionRequest(match.params));
    }, [match.params, dispatch]);

    return (
        <main className='confirm-container'>
            <NewHeader className='new-home-header' isWhiteColor/>
            {isLoading
                ? <Loader className='confirm-loader' />
                : <Message />
            }
            <Footer />
        </main>
    );
};

export default ExpSessionRequest;

const Message = () => {
    const {t} = useTranslation();
    return (
        <div className='confirm-message-content'>
            <h1>{t('REQUESTED_SESSION')}</h1>
            <p>{t('WAIT_CONFIRMATION')}</p>
        </div>
    );
};
