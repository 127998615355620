import React from 'react';
import {useTranslation} from 'react-i18next';

import IconSvg from '../../../../utils/svg';

import './footer.css';

function Footer() {
    const {t} = useTranslation();

    return (
        <footer className='new-home-footer'>
            <CopyrightInfo t={t} />
            <IconSvg name='icon-febracis' classDiv='logo-febracis-footer' />
        </footer>
    );
}

function CopyrightInfo({t}) {
    return (
        <div className='copyright-info'>
            <p className='copyright-text'>
                <span>© Copyright 2022 FEBRACIS.</span>
                <span>{t('ALL_RIGHTS_RESERVED')}</span>
            </p>
        </div>
    );
}

export default Footer;
