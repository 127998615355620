import React from 'react';
import IconSvg from '../../../../../../../utils/svg';
import './biography-contact.css';

function BiographyContact({
    tel,
    email,
    location
}) {
    return (
        <ul className="biography-contact">
           
            <ContactItem iconName="icon-message" text={email} />
            

            {tel &&
                <ContactItem iconName="icon-phone" text={tel} />
            }

            {location &&
                <ContactItem iconName="icon-pin" text={location} />
            }
        </ul>
    );
};

const ContactItem = ({ iconName, text }) => (
    <li>
        <IconSvg name={iconName} width={26} height={26} color="#fff" />
        <p>{text || 'Não informado'}</p>
    </li>
)

export default BiographyContact;