import React from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import './start-section.css';

function StartSection() {
    const {t} = useTranslation();
    const mainParagraph = t('START_SECTION_MAIN_PARAGRAPH');
    const [start, hightlight, end] = mainParagraph.split('*');

    return (
        <section className='start-section'>
            <p className='main-paragraph'>
                {start}
                <span>{hightlight}</span>
                {end}
            </p>
            <p className='sub-paragraph'>
                {t('START_SECTION_SUB_PARAGRAPH')}
            </p>
            <Link to='/customize/form'>
                <button className='btn-start'>
                    {t('START')}
                </button>
            </Link>
        </section>
    );
}

export default StartSection;
