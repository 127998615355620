import React from 'react';
import { useTranslation } from 'react-i18next';
import TitleSection from '../../../../../../components/TitleSection/TitleSection';
import CurriculumInfo from './curriculum-info/curriculum-info';

import './curriculum.css';

function Curriculum({
    resumeFeed,
    onClick,
    themeColor
}) {
    const {t} = useTranslation();

    return (
        <section
            onClick={() => onClick('resume')}
            className="template--7--curriculum"
            id="resume">
            <TitleSection
                text1={t('CURRICULUM')}
                colorIcon={themeColor}
                iconView={true}
                iconName='icon-text-effect'
                iconWidth='39px'
            />
            <div className="curriculum-content">
                <CurriculumInfo
                    resumeFeed={resumeFeed}
                />
            </div>
        </section>
    );
}

export default Curriculum;