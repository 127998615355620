import React from 'react';
import IconSvg from '../../../../../../utils/svg';
import { goesTo } from '../../../../../../utils/common-functions';

import './footer.css';

function Footer({ firstName }) {
    return (
        <footer className='template--5--footer'>
            <div className='template--5--footer-content'>
                <p> {firstName} </p>

                <button className='btn-scroll-top' onClick={() => goesTo('#home')}>
                    <IconSvg name='icon-arrow' width={'20px'} color={'#fff'} />
                </button>
            </div>
        </footer>
    );
};

export default Footer;