import React, { useState, Fragment, useEffect } from 'react';
import { ITEMS_NAV, SESSION_ITEM_NAV } from '../../../../../../../utils/constants';
import { goesTo } from '../../../../../../../utils/common-functions';
import { useWidth } from '../../../../template.hooks';
import NavBarMobile from '../nav-bar-mobile/nav-bar-mobile';

import './nav-bar.css';
import SelectLanguage from '../../../../../../../components/SelectLanguage';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { initSearchingOptions } from '../../../../../../home/home.actions';
import { useTranslation } from 'react-i18next';

function navBar({
    enableBiography,
    enableResume,
    enableVideos,
    enableTestimonies,
    enableGallery,
    gallery,
    testimonies,
    videos,
    firstName,
    tel,
    facebook,
    linkedin,
    instagram,
    twitter,
    whatsapp,
    youtube,
    themeColor
}) {
    const [width] = useWidth();
    const [addClass, setAddClass] = useState(false);
    const [itemsNav, setItemsNav] = useState(ITEMS_NAV);
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const coachDetails = useSelector(
        ({Coach}) => Coach.user,
        shallowEqual
    );

    useEffect(() => {
        const newitems = ITEMS_NAV.filter(({ url }) => {
            switch (url) {
            case '#biography':
                return enableBiography;
            case '#resume':
                return enableResume;
            case '#videos':
                return enableVideos && videos && videos.length;
            case '#testimonies':
                return enableTestimonies && testimonies && testimonies.length;
            case '#gallery':
                return enableGallery && gallery && gallery.length;
            default:
                return true;
            }
        });
        setItemsNav(newitems);
    }, [enableBiography, enableResume, enableVideos, enableTestimonies, enableGallery]);

    useEffect(() => {
        if(coachDetails && coachDetails.enableSiteExperimentalSession) {
            const list = itemsNav.map((item) => {
                if (coachDetails && coachDetails.enableSiteExperimentalSession) {
                    if (item.key === 'CONTACT') {
                        return SESSION_ITEM_NAV;
                    }
                }
                return item;
            });

            setItemsNav(list);
        }
    }, [coachDetails]);

    function toggle() {
        setAddClass(!addClass);
    }

    let boxClass = ['template--7--navbar'];
    if (addClass) {
        boxClass.push(' menu-active');
    }

    function handleLanguageChange(option) {
        dispatch(initSearchingOptions(option.value));
    }

    function createList(list) {
        const firstArray = list.splice(0, 4);
        let listRender = [];

        listRender[0] = <ul key="first_ul">{firstArray.map(({key, url}) => (
            <li key={url} onClick={() => goesTo(url)} >
                <p> {t(key)} </p>
            </li>
        ))} </ul>;

        listRender[1] = <h2 key="name" className="logo-name-coach"> {firstName} </h2>;
        
        listRender[2] =
            <ul key="second_ul">
                {list.map(({key, url}) => (
                    <li key={url} onClick={() => goesTo(url)} >
                        <p> {t(key)} </p>
                    </li>
                ))}
                <li className='select-language'>
                    <SelectLanguage
                        onChange={handleLanguageChange}
                        className={'template-7'}
                    />
                </li>
            </ul>;

        listRender[3] = tel ? <span key="tel" className='header-tel'> {tel} </span> : null;

        return listRender;
    }

    return (
        <nav className={`${boxClass.join('')}`}>
            {width <= 990 ?
                <Fragment>
                    <h2 className="logo-name-coach">{firstName}</h2>

                    <div className='left-header'>
                        <div className='select-language-hamburguer-template-7'>
                            <SelectLanguage
                                onChange={handleLanguageChange}
                                className={'template-7'}
                            />
                        </div>
                        <div className="header--menu-burguer" onClick={toggle}>
                            <span className="line" style={{visibility: `${addClass ? 'hidden': 'visible'}`}}></span>
                            <span className="line" style={{visibility: `${addClass ? 'hidden': 'visible'}`}}></span>
                            <span className="line" style={{visibility: `${addClass ? 'hidden': 'visible'}`}}></span>
                        </div>
                    </div>

                    {addClass &&
                        <NavBarMobile
                            itemsNav={itemsNav}
                            onClose={() => setAddClass(false)}
                            firstName={firstName}
                            tel={tel}
                            facebook={facebook}
                            linkedin={linkedin}
                            instagram={instagram}
                            twitter={twitter}
                            whatsapp={whatsapp}
                            youtube={youtube}
                            themeColor={themeColor}
                            hasSessionExperimetal={coachDetails.enableSiteExperimentalSession}
                        />
                    }
                </Fragment>

                :

                <Fragment>
                    <div className="items--nav">
                        {createList([...itemsNav])}
                    </div>
                </Fragment>

            }

        </nav>
    );
}

export default navBar;