import React from 'react';

import './modal.css';
import ReactDOM from 'react-dom';

export default class Modal extends React.Component {

    constructor() {
        super();

        this.listenKeyboard = this.listenKeyboard.bind(this);
        this.onOverlayClick = this.onOverlayClick.bind(this);
    }
    
    componentDidMount() {
        if (this.props.onClose) {
            window.addEventListener('keydown', this.listenKeyboard, true);
            document.body.classList.remove('modal-active');
        }
        
        if (this.props.show) {
            document.body.classList.add('modal-active');
        }
    }

    componentWillUnmount() {
        if (this.props.onClose) {
            window.removeEventListener('keydown', this.listenKeyboard, true);
            document.body.classList.remove('modal-active');
        }
    }

    listenKeyboard(event) {
        if (event.key === 'Escape' || event.keyCode === 27) {
            this.props.onClose();
        }
    }

    onOverlayClick(event) {
        this.props.onClose();
        event.stopPropagation();
    }

    static onDialogClick(event, wrapClose, onClose) {
        event.stopPropagation();
        if (wrapClose) {
            onClose();
        }
    }

    render() {
        const {
            show = false,
            overlayStyle = {},
            contentStyle = {},
            dialogStyle = {},
            children,
            contentClassName = '',
            dialogClassName = '',
            wrapClose, 
            onClose,
        } = this.props;

        if (!show) {
            return null;
        }

        return ReactDOM.createPortal(
            (<div>
                <div className="modal-overlay-div" style={overlayStyle}/>
                <div className={`modal-content-div ${contentClassName}`} style={contentStyle} onClick={this.onOverlayClick}>
                    <div className={`modal-dialog-div ${dialogClassName}`} style={dialogStyle} onClick={(event) => Modal.onDialogClick(event, wrapClose, onClose)}>
                        {children}
                    </div>
                </div>
            </div>),
            document.getElementById('root')
        );
    }
}