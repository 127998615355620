import React, { useEffect } from 'react';
import { externalUlrs } from '../../utils/constants';
import { connect } from 'react-redux';
import { sendToServer } from './login.action';

const Login = ({ sendToServer, location }) => {

    useEffect(() => {
        const code = new URLSearchParams(location.search).get('code');
        if (code) {
            sendToServer(code);
        } else {
            window.location.href = externalUlrs.LOGIN;
        }
    }, []);

    return <p>Redirecionando...</p>;
};

export default connect(null, { sendToServer })(Login);
