import React from 'react';
import IconSvg from '../../../../../../../utils/svg';

import './photo-card.css';

function PhotoCard({ url, caption, onClick }) {
    return (
        <div className="template--7-photo-card" onClick={onClick}>
            <figure>
                <img src={url} alt='metodo cis' />
                <figcaption>
                    <span>{caption}</span>
                </figcaption>
                <IconSvg name="icon-circle-zoom" classDiv="photo-icon-zoom" width={25} height={25} color="#fff" />
            </figure>
        </div>
    );
}

export default PhotoCard;