import i18n from '../assets/i18n/i18n';

import notification from './notification';

export const treatError = ({response}) => {
    const responseData = response && response.Data;
    const description = responseData && responseData.error_description;

    if (description) {
        notification('error', description);
    } else {
        notification('error', i18n.t('UNKNOWN_ERROR'));
    }
};
