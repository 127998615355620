import React from 'react';
import {useTranslation} from 'react-i18next';

import Carousel from '../../../../components/SlickCarousel/slickCarousel';
import selectManImg from '../../../../img/select-man.min.webp';
import coachManImg from '../../../../img/coach-man.min.webp';
import trainerWomanImg from '../../../../img/trainer-woman.min.webp';

import './category-section.css';

function CategorySection() {
    const {t} = useTranslation();
    const [title, highlightedText] = t('CATEGORY_TITLE').split('*');

    return (
        <section className='category-section'>
            <p className='category-section-title'>
                {title}
                <span>{highlightedText}</span>
            </p>
            <CategoryCarousel categoryItems={categoryItems} t={t} />
        </section>
    );
}

function CategoryCarousel({categoryItems, t}) {
    const categoryCards = categoryItems.map(item => {
        return <CategoryCard item={item} key={item.name} t={t} />;
    });
    const settings = {
        dots: true,
        infinite: false,
        arrows: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <Carousel settings={settings} className='category-carousel'>
            {categoryCards}
        </Carousel>
    );
}

function CategoryCard({item, t}) {
    return (
        <div className='category-card' key={item.id}>
            <div className='category-text-container'>
                <p className='category-name'>{item.name}</p>
                <p className='category-text'>{t(item.text)}</p>
            </div>
            <div className='category-img-container'>
                <img src={item.img} alt={item.name} className='category-img' />
            </div>
        </div>
    );
}

const categoryItems = [
    {
        id: 'coach',
        name: 'Coach',
        text: 'CATEGORY_COACH_TEXT',
        img: coachManImg,
    },
    {
        id: 'select',
        name: 'Select',
        text: 'CATEGORY_SELECT_TEXT',
        img: selectManImg,
    },
    {
        id: 'trainer',
        name: 'Trainer',
        text: 'CATEGORY_TRAINER_TEXT',
        img: trainerWomanImg,
    },
];

export default CategorySection;
