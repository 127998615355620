/* eslint-disable no-undef */
import i18n from '../assets/i18n/i18n';
import ACTIONS from './actions-constants';
import { ptBR, enUS, es } from 'date-fns/locale';

export const ITEMS_NAV = [
    { name: 'Sobre Mim', url: '#biography', key: 'ABOUT_ME' },
    { name: 'Currículo', url: '#resume', key: 'CURRICULUM' },
    { name: 'Vídeos', url: '#videos', key: 'VIDEOS' },
    { name: 'Depoimentos', url: '#testimonies', key: 'TESTIMONY' },
    { name: 'Galeria', url: '#gallery', key: 'GALLERY' },
    { name: 'Contato', url: '#contact', key: 'CONTACT' }
];

export const SESSION_ITEM_NAV = { 
    name: 'Solicitação de sessão',
    url: '#session_request',
    key: 'REQUEST_YOUR_EXPERIMENTAL_SESSION'
};

export const actions = ACTIONS;


const authUrls = {
    dev: 'https://dev.login.scisapp.com/v2/oauth2',
    alpha: 'https://login.febracis.com.br.staging.fortalsistemas.com.br/v2/oauth2',
    beta: '',
    prod: 'https://login.febracis.com.br/v2/oauth2',
};

export const urlAuth = authUrls[process.env.REACT_APP_STAGE || 'dev'];

const basepaths = {
    dev: 'https://api.scisapp.com.dev.fortalsistemas.com.br/v1/dev',
    alpha: 'https://api.scisapp.com.staging.fortalsistemas.com.br/v1/alpha',
    beta: '',
    prod: 'https://api.scisapp.com/v1/prod',
};

export const basepath = basepaths[process.env.REACT_APP_STAGE || 'dev'];

const basepathsV2 = {
    dev: 'https://dev.api.scisapp.com.dev.fortalsistemas.com.br/v2',
    alpha: 'https://alpha.api.scisapp.com.staging.fortalsistemas.com.br/v2',
    beta: '',
    prod: 'https://prod.api.scisapp.com/v2',
};

export const basepathV2 = basepathsV2[process.env.REACT_APP_STAGE || 'dev'];

const basepaths3 = {
    dev: 'https://api.scisapp.com.dev.fortalsistemas.com.br/v2/dev',
    alpha: 'https://api.scisapp.com.staging.fortalsistemas.com.br/v2/alpha',
    beta: '',
    prod: 'https://api.scisapp.com/v2/prod'
};

export const basepath3 = basepaths3[process.env.REACT_APP_STAGE || 'dev'];

const basepaths4 = {
    dev: 'https://blog.com.dev.fortalsistemas.com.br/api/v2',
    alpha: 'https://blog.staging.fortalsistemas.com.br/api/v2',
    beta: '',
    prod: 'https://blog.prod.fortalsistemas.com.br/api/v2'
};

export const basepath4 = basepaths4[process.env.REACT_APP_STAGE || 'dev'];

export const APIroutes = {
    profile: '/profiles/me',
    publish: '/profiles/me/publish',
    unpublish: '/profiles/me/unpublish',
    upload: '/upload',
    expertises: '/expertises',
    countries: '/countries',
    regenerate_token: '/profiles/regenerate_token',
    token: '/token',
    getUsername: '/profiles/me/username',
    contact: '/profiles/{username}/contact',
    backgrounds: '/types/background-images',
    searchProfiles: '/profiles',
    searchV2: '/coaches/public/profiles/v2',
    statistics: '/admin/public/dashboard',
    sendEmail: '/coaches/public/profiles/contact/:id',
    getCoachExperimentalSessionData: '/coaches/public/profiles/:id',
    experimentalSession: '/coaches/public/profiles/request-experimental-session',
    confirmExperimentalSession: '/coaches/public/profiles/confirm-exp-session/:id',
    alreadyAccessedSite: '/profiles/me/first-login',
    coach: '/coaches/public/coach/:name',
    blogPosts:'/pages/?type=blog.BlogPage&fields=*&order=-last_published_at',
    blogPostsCategories:'/categories',
};

const clientIdStages = {
    dev: 'dhtqUwpmHRNxUL9q',
    alpha: 'Yt6ccjKJTGDdxj3B',
    beta: '',
    prod: 'C47fCT9qjRyKWXN4',
};

export const settingsAuth = {
    client_id: clientIdStages[process.env.REACT_APP_STAGE || 'dev'],
    redirect_uri: `${window.location.origin}/login`,
    redirect_uri_logout: `${window.location.origin}/logout`,
};


export const externalUlrs = {
    LOGIN: `${urlAuth}/authorize?client_id=${settingsAuth.client_id}&redirect_uri=${settingsAuth.redirect_uri}&response_type=code&scope=coach`,
    LOGOUT: `${urlAuth}/logout?client_id=${settingsAuth.client_id}&redirect_uri=${settingsAuth.redirect_uri_logout}`

};

export const themeSetting = {
    'template-1': { name: 'template-1', videosQtd: 4, galerryQtd: 8, articlesQtd: 3, eventsQtd: 4 },
    'template-2': { name: 'template-2', videosQtd: 1, galerryQtd: 8, articlesQtd: 3, eventsQtd: 4 },
    'template-3': { name: 'template-3', videosQtd: 3, galerryQtd: 4, articlesQtd: 3, eventsQtd: 4 },
    'template-4': { name: 'template-4', videosQtd: 4, galerryQtd: 6, articlesQtd: 3, eventsQtd: 4 }
};
export const socialNetArray = ['instagram', 'facebook', 'twitter', 'linkedin', 'youtube', 'whatsapp'];

export const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const phoneNumberRegex = /^[0-9()-]+$/;

export const templatesSocialMedia = {
    'facebook': { prefix: 'www', base: 'facebook.com/' },
    'linkedin': { prefix: 'www', base: 'linkedin.com/in/' },
    'twitter': { prefix: 'www', base: 'twitter.com/' },
    'youtube': { prefix: 'www', base: 'youtube.com/' },
    'instagram': { prefix: 'www', base: 'instagram.com/' },
    'whatsapp': { prefix: 'wa', base: 'me/' },
};

export const channel = 'channel/';

export const expertiseFebracisSelect = {
    name: 'Febracis Select',
    flag: 'Febracis Select',
    id:'3f1c151b-d51f-47fb-8efb-49f3e98f2604',
    iconName: 'icon-select-flag',
    minifyName: 'Select',
};

export const expertiseFebracisTrainer = {
    name: 'Master Trainer',
    flag: 'trainer',
    id:'3f1c151b-d51f-47fb-8efb-49f3e98f2605',
    iconName: 'icon-trainer-flag',
    minifyName: 'Master Trainer',
};

export const Categories = [
    expertiseFebracisSelect,
    expertiseFebracisTrainer
];

export const weekDays = () => [
    {label: i18n.t('SUNDAY'), value: 'SUNDAY'},
    {label: i18n.t('MONDAY'), value: 'MONDAY'},
    {label: i18n.t('TUESDAY'), value: 'TUESDAY'},
    {label: i18n.t('WEDNESDAY'), value: 'WEDNESDAY'},
    {label: i18n.t('THURSDAY'), value: 'THURSDAY'},
    {label: i18n.t('FRIDAY'), value: 'FRIDAY'},
    {label: i18n.t('SATURDAY'), value: 'SATURDAY'},
];

export const turnTypes = () => [
    {label: i18n.t('MORNING'), value: 'MORNING', key: 'Manhã'},
    {label: i18n.t('AFTERNOON'), value: 'AFTERNOON', key: 'Tarde'},
    {label: i18n.t('EVENING'), value: 'NIGHT', key: 'Noite'},
];

export const sessionTypes = () => [
    { label: i18n.t('PRESENTIAL'), value: 'PRESENTIAL' },
    { label: i18n.t('ONLINE'), value: 'ONLINE' },
];

export const phoneCountries = {
    defaultCountry: 'br',
    preferredCountries: ['br', 'us', 'pt', 'ao', 'jp']
};

export const categoryOptions = () => [
    {
        label: 'Febracis Select',
        name: 'Febracis Select',
        id: 'Febracis Select',
        value: 'Febracis Select',
    },
    {
        label: 'Master Trainer',
        name:  'trainer',
        id:  'trainer',
        value:  'trainer',
    },
    {
        label: i18n.t('EXPERIMENTAL_SESSION'),
        name:  'enableSiteExperimentalSession',
        id:  'enableSiteExperimentalSession',
        value:  'enableSiteExperimentalSession',
    },
];

export const constantItens = ['Febracis Select', 'trainer'];

export const locales = {
    'pt': ptBR,
    'en': enUS,
    'es': es,
};
