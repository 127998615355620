import React, { useEffect, useRef, Fragment } from 'react';
import { Banner } from './components/index';

import Biography from './components/biography/biography';
import Curriculum from './components/curriculum/curriculum';
import Videos from './components/videos/videos';

import Testimonial from './components/testimonial/testimonial';
import Gallery from './components/gallery/gallery';
import Contact from './components/contact/contact';
import Footer from './components/footer/footer';
import CoachNumbers from './components/coach-numbers/coach-numbers';
import SessionRequestForm from '../components/SessionRequestForm/SessionRequestForm';

import './template-7.css';

function Template7({ user, changeCurrentSection, username, isExperimentalSession }) {
    const templateRef = useRef(null);

    useEffect(() => {
        templateRef.current.style.setProperty('--c-primary', user.themeColor);
    }, [user.themeColor]);

    function getUserLocation({ country, city, state }) {
        return `
            ${city ? city + `${state ? ' / ' : ' - '}` : ''} 
            ${state ? state + ' - ' : ''} 
            ${country} 
        `;
    }

    return (
        <main className='template--7' ref={templateRef}>
            <Banner
                enableBiography={user.enableBiography}
                enableResume={user.enableResume}
                enableVideos={user.enableVideos}
                enableTestimonies={user.enableTestimonies}
                enableGallery={user.enableGallery}
                onClick={changeCurrentSection}
                facebook={user.facebook}
                linkedin={user.linkedin}
                instagram={user.instagram}
                twitter={user.twitter}
                whatsapp={user.whatsapp}
                youtube={user.youtube}
                gallery={user.gallery}
                testimonies={user.testimonies}
                videos={user.videos}
                backgroundImg={user.backgroundImg}
                mainPhrase={user.mainPhrase}
                mainPhraseAuthor={user.mainPhraseAuthor}
                tel={user.tel}
                firstName={user.firstName}
                location={getUserLocation(user)}
                themeColor={user.themeColor}
            />

            {user.enableResume && 
                <Fragment>
                    <Curriculum
                        onClick={changeCurrentSection}
                        resumeFeed={user.resumeFeed}
                        themeColor={user.themeColor}
                    />

                    { (user.enableCoachingHours || user.enableFebracisCourses || user.enableLecturesHours) === true &&
                        <CoachNumbers
                            onClick={changeCurrentSection}
                            coachingHours={user.coachingHours}
                            enableCoachingHours={user.enableCoachingHours}
                            febracisCourses={user.febracisCourses}
                            enableFebracisCourses={user.enableFebracisCourses}
                            lecturesHours={user.lecturesHours}
                            enableLecturesHours={user.enableLecturesHours}
                        />
                    }
                </Fragment> 
            }

            {user.enableBiography &&
                <Biography
                    onClick={changeCurrentSection}
                    aboutMe={user.aboutMe}
                    expertises={user.expertises}
                    photoUrl={user.photoUrl}
                    themeColor={user.themeColor}
                />
            }
            {user.enableGallery &&
                <Gallery
                    onClick={changeCurrentSection}
                    photos={user.gallery}
                    themeColor={user.themeColor}
                />
            }
            {user.enableVideos &&
                <Videos
                    onClick={changeCurrentSection}
                    videos={user.videos}
                    themeColor={user.themeColor}
                />
            }

            {user.enableTestimonies &&
                <Testimonial
                    onClick={changeCurrentSection}
                    testimonies={user.testimonies}
                    themeColor={user.themeColor}
                />
            }
            {/* <Blog /> */}
            {isExperimentalSession
                ? (
                    <SessionRequestForm />
                )
                : (
                    <Contact
                        onClick={changeCurrentSection}
                        username={username}
                        themeColor={user.themeColor}
                    />
                )
            }

            <Footer firstName={user.firstName} />
        </main>
    );

}

export default Template7;