import {actions} from '../../../../utils/constants';

export default (state = videos, action) => {
    switch (action.type) {

    case actions.SET_USER:
        return action.payload.videos || [];

    case actions.ADD_VIDEO:
        return  [...state, action.payload];
        // return [action.payload].concat(state);

    case actions.DELETE_VIDEO:
        return state
            .filter(v => v.key !== action.payload.key);

    case actions.EDIT_VIDEO:
        return state
            .map(v => v.key !== action.payload.key ? v : action.payload);

    default:
        return state;
    }
};

const videos = [];


// const videos = [
//     {
//         id: 'iOvnzXEgJV8',
//         host: 'YouTube',
//         thumbnail: 'https://img.youtube.com/vi/iOvnzXEgJV8/maxresdefault.jpg',
//         caption: 'Confiança é tudo',
//     },
//     {
//         id: '21303658',
//         host: 'Vimeo',
//         thumbnail: 'https://i.vimeocdn.com/video/136964591_640.webp',
//         caption: 'Inteligência emocional',
//     },
//     {
//         id: '83289179',
//         host: 'Vimeo',
//         thumbnail: 'https://i.vimeocdn.com/video/459763385_640.jpg',
//         caption: 'Lute pelos seus sonhos',
//     },
//     {
//         id: 'ca5Se6EIMug',
//         host: 'YouTube',
//         thumbnail: 'https://img.youtube.com/vi/ca5Se6EIMug/maxresdefault.jpg',
//         caption: 'Comece agora a mudar!',
//     },
// ];
