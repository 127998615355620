import { put, call, all, takeEvery, select } from 'redux-saga/effects';

import { actions, basepath, APIroutes, basepathV2, basepath3, categoryOptions, basepath4 } from '../../utils/constants';
import { axiosGet, axiosPost, getQueryParams } from '../../utils/common-functions';
import { trackEvent } from '../../utils/analytics';
import config from '../../utils/config';
import { setAllOptionsByLang, setAllOptionsMixed, setOptions } from './home.actions';
import {treatError} from '../../utils/error-utils';

function* resetSearch() {
    yield put({ type: actions.SET_SEARCH_RESULT, payload: [] });
    yield put({ type: actions.SET_TOTAL_PROFILES, payload: 0 });
    yield put({ type: actions.SET_NEXT_PAGE, payload: 0 });
    yield put({ type: actions.RESET_ARRAY_PAGES });
    yield put({ type: actions.SET_TOTAL_PAGES, payload: 0 });
}

export function* doSearch() {
    yield call(resetSearch);

    yield put({ type: actions.DO_SEARCH });
    yield put({ type: actions.SET_LOADING, payload: true });

    const {allOptionsByLang, allOptionsMixed} = yield select(({Home}) => ({
        allOptionsByLang: Home.allOptionsByLang,
        allOptionsMixed: Home.allOptionsMixed,
    }));

    const searchFields = yield select(state => state.Home.search);
    const translatedOptions = allOptionsByLang['pt'];
    const currentCountry = allOptionsMixed.countries.find(c => c.name === searchFields.country) || '';
    const translatedCountry = translatedOptions.countries.find(c => c.id === currentCountry.id) || '';

    const translatedExpertises = searchFields.expertises.map(e => translatedOptions.expertises.find(te => e.id === te.id));

    const translatedSearchFields = {...searchFields, country: translatedCountry.name, expertises: translatedExpertises};

    const {name, country, state, city, category, expertises} = translatedSearchFields;

    const baseQuery = `${(name && name !== '-1')
        ? `name=${name}&` : ''}${(state && (state !== '-1'))
        ? `state=${state}&` : ''}${(city && (city !== '-1'))
        ? `city=${city}&` : ''}${(country && country !== '-1')
        ? `country=${country}&` : ''}${(category && category !== '-1')
        ? `expertise=${category}&` : ''}${expertises && expertises.length > 0
        ? expertises.map(e => `expertise=${e.name}&`).join('') : ''}`;

    const expertiseCategories = (searchFields.categories || []).filter((item) => (
        item.id !== 'enableSiteExperimentalSession')
    ).map(e => `expertise=${e.name}&`).join('');

    const booleanParams = (searchFields.categories || []).filter((item) => (
        item.id === 'enableSiteExperimentalSession'
    )).map(e => `${e.name}=true&`).join('');

    const queryString = `${baseQuery}${expertiseCategories}${booleanParams}order=coachingHours&qtd=10`;
    const url = `${basepathV2}${APIroutes.searchV2}?${queryString}`;

    trackEvent('user action', 'searching for coaches', 'search coach');
    try {
        const { data, status } = yield call(axiosGet, url);
        if (status === 200) {
            yield put({ type: actions.SET_SEARCH_RESULT, payload: data.profiles });
            yield put({ type: actions.SET_TOTAL_PROFILES, payload: data.totalProfiles });
            yield put({ type: actions.SET_NEXT_PAGE, payload: data.nextPage });
            yield put({ type: actions.SET_ARRAY_PAGES, payload: { idx: data.nextPage - 1, value: data.profiles } });
            yield put({ type: actions.SET_TOTAL_PAGES, payload: Math.ceil(data.totalProfiles / 10) });

            const NumberOfPages = yield select(state => state.Home.totalPages);
            if (data.nextPage + 1 <= NumberOfPages) {
                const nextPage = yield select(state => state.Home.nextPage);
                const queryString = `${baseQuery}${expertiseCategories}${booleanParams}${data.nextPage ? `page=${data.nextPage}&` : ''}order=coachingHours&qtd=10`;
                const url = `${basepathV2}${APIroutes.searchV2}?${queryString}`;
                try {
                    const { data } = yield call(axiosGet, url);
                    yield put({ type: actions.SET_ARRAY_PAGES, payload: { idx: nextPage, value: data.profiles } });
                } catch (error) {
                    treatError(error);
                }
            }
        }

        yield put({type: actions.SET_RESULTS_QUERY, payload: {...searchFields}});
    } catch (error) {
        treatError(error);
    }
    yield put({ type: actions.SET_LOADING, payload: false });
}

function* initialCoaches() {
    try {
        const { data } = yield call(axiosGet, `${basepathV2}${APIroutes.searchV2}?order=coachingHours&qtd=10`);
        yield put({ type: actions.ADD_INITIAL_COACHES, payload: data.profiles });
    } catch ({ response }) {
        treatError(response);
    }
}

function* setCountry(name) {
    yield put({
        type: actions.SET_OPTIONS, payload: {
            id: 'cities',
            value: []
        }
    });
    yield put({
        type: actions.SET_OPTIONS, payload: {
            id: 'states',
            value: []
        }
    });
    
    yield put({ type: actions.CHANGE_SEARCH_INPUT, payload: { id: 'state', value: undefined } });
    yield put({ type: actions.CHANGE_SEARCH_INPUT, payload: { id: 'city', value: undefined } });

    const countries = yield select(state => state.Home.searchingOptions.countries);
    for (let x = 0; x < countries.length; x++) {
        if (countries[x].name === name) {
            try {
                const currentCountry = countries[x];
                const { data } = yield call(axiosGet, `${basepath}${countries[x].url}`);

                const parsedData = data.map(optionsParserMapper);

                if (currentCountry.model === 'states-cities') {
                    yield put({ type: actions.TOOGLE_STATE, payload: true });
                    yield put({
                        type: actions.SET_OPTIONS, payload: {
                            id: 'states',
                            value: parsedData
                        }
                    });
                } else {
                    yield put({ type: actions.TOOGLE_STATE, payload: false });
                    yield put({
                        type: actions.SET_OPTIONS, payload: {
                            id: 'cities',
                            value: parsedData
                        }
                    });
                }
            } catch (error) {
                throw error;
            }
            break;
        }
    }
}

function* setState(name) {
    yield put({
        type: actions.SET_OPTIONS, payload: {
            id: 'cities',
            value: []
        }
    });

    yield put({ type: actions.CHANGE_SEARCH_INPUT, payload: { id: 'city', value: '' } });

    const states = yield select(state => state.Home.searchingOptions.states);

    const currentState = states.filter((el, idx) => el.name === name);

    yield put({
        type: actions.SET_OPTIONS, payload: {
            id: 'cities',
            value: currentState[0] ? currentState[0].cities.map(optionsParserMapper) : []
        }
    });
}

export function* changeSearchFields({payload}) {
    if (payload.id === 'country') {
        yield call(setCountry, payload.value);
    }

    if (payload.id === 'state') {
        yield call(setState, payload.value);
    }

    yield put({ type: actions.CHANGE_SEARCH_INPUT, payload: {id: payload.id, value: payload.value} });

    if (payload.doSearch) {
        const search = yield select(({Home}) => (Home.search));
        payload.doSearch(search);
    }
}

function optionsParserMapper(option) {
    return {...option, label: option.name, value: option.id};
}

export function* handleQueryParams() {
    try {
        yield put({ type: actions.SET_LOADING, payload: true });

        const queryParams = getQueryParams();
        const hasParams = Object.keys(queryParams).length > 0;
        const currentLang = localStorage.getItem(config.currentLang);

        yield call(initOptions, {payload: currentLang});

        if (hasParams) {
            yield call(setSearchValuesFromQueryParams, queryParams);
        } else {
            yield call(doSearch);
        }
    } catch (error) {
        treatError(error);
    }
}

export function* fetchAllOptionsLanguages({payload: isCoachList}) {
    try {
        const pt = yield fetchOptions('pt');
        const en = yield fetchOptions('en');
        const es = yield fetchOptions('es');

        const allOptionsLanguages = {pt, en, es};

        const allCountries = [];
        const allExpertises = [];

        for (const lang in allOptionsLanguages) {
            allCountries.push(allOptionsLanguages[lang].countries);
            allExpertises.push(allOptionsLanguages[lang].expertises);
        }

        const flatCountries = allCountries.flatMap(e => e);
        const flatExpertises = allExpertises.flatMap(e => e);

        const allOptionsMixed = {countries: flatCountries, expertises: flatExpertises};

        yield put(setAllOptionsByLang(allOptionsLanguages));
        yield put(setAllOptionsMixed(allOptionsMixed));

        const currentLang = localStorage.getItem(config.currentLang);

        if (isCoachList) {
            yield call(handleQueryParams);
        } else {
            yield call(initOptions, {payload: currentLang});
        }

    } catch (error) {
        treatError(error);
    }
}

function* fetchOptions(language) {
    try {
        const typesPathV2 = `${basepath3}/types/${language}`;
        const countriesURL = `${typesPathV2}${APIroutes.countries}`;
        const expertisesURL = `${typesPathV2}${APIroutes.expertises}`;
        const urls = [countriesURL, expertisesURL];

        const [countries, expertises] = yield Promise.all(urls.map(url => {
            return axiosGet(url, true);
        }));

        let parsedCountries = [];
        let parsedExpertises = [];

        if (countries) {
            parsedCountries = countries.data.map(optionsParserMapper);
        }

        if (expertises) {
            parsedExpertises = expertises.data.map(optionsParserMapper);
        }

        return {countries: parsedCountries, expertises: parsedExpertises};
    } catch (error) {
        treatError(error);
    }
}

function* changeSearchValuesLang(lang) {
    const {allOptionsByLang, allOptionsMixed} = yield select(({Home}) => ({
        allOptionsByLang: Home.allOptionsByLang,
        allOptionsMixed: Home.allOptionsMixed,
    }));

    const {country, expertises} = yield select(({Home}) => (Home.search));

    const translatedOptions = allOptionsByLang[lang];
    const currentCountry = allOptionsMixed.countries.find(c => c.name === country) || '';
    const translatedCountry = translatedOptions.countries.find(c => c.id === currentCountry.id) || '';

    const translatedExpertises = expertises.map(e => translatedOptions.expertises.find(te => e.id === te.id));

    yield put({type: actions.CHANGE_SEARCH_INPUT, payload: {id: 'country', value: translatedCountry.name}});
    yield put({type: actions.CHANGE_SEARCH_INPUT, payload: {id: 'expertises', value: translatedExpertises}});
}

export function* initOptions({payload: lang}) {
    try {
        const {allOptionsByLang} = yield select(({Home}) => ({
            allOptionsByLang: Home.allOptionsByLang,
        }));

        const selectedLang = lang || localStorage.getItem(config.currentLang);
        const selectedOptions = allOptionsByLang[selectedLang];

        yield put(setOptions('countries', selectedOptions.countries.sort(function(a,b) {
            return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
        })));
        yield put(setOptions('expertises', selectedOptions.expertises));

        yield call(changeSearchValuesLang, selectedLang);

        const searchFields = yield select(({Home}) => Home.search);
        yield put({type: actions.SET_RESULTS_QUERY, payload: {...searchFields}});
    } catch (error) {
        treatError(error);
    }
}

function categoriesQueryToReduxFormat(query) {
    const categoriesLabels = query.split(',');
    const parsedCategories = categoriesLabels.map(categoriesLabelMapper).filter(category => category);

    function categoriesLabelMapper(label) {
        const matchedCategory = categoryOptions().find(category => category.name === label);

        if (matchedCategory) {
            const parsedCategory = {...matchedCategory, value: matchedCategory.id};
            return parsedCategory;
        } else {
            return undefined;
        }
    }

    return parsedCategories;
}

function expertiseQueryToReduxFormat(query, expertises) {
    const expertiseLabels = query.split(',');
    const parsedExpertises = expertiseLabels.map(expertiseLabelMapper).filter(expertise => expertise);

    function expertiseLabelMapper(label) {
        const matchedExpertise = expertises.find(expertise => expertise.label === label);
    
        if (matchedExpertise) {
            const parsedExpertise = {...matchedExpertise, value: matchedExpertise.id};
            return parsedExpertise;
        } else {
            return undefined;
        }
    }

    return parsedExpertises;
}

function* setSearchValuesFromQueryParams(queryParams) {
    const {allOptionsByLang, allOptionsMixed} = yield select(({Home}) => ({
        allOptionsByLang: Home.allOptionsByLang,
        allOptionsMixed: Home.allOptionsMixed,
    }));

    const parsedExpertises = expertiseQueryToReduxFormat(queryParams.expertises || '', allOptionsMixed.expertises);
    const parsedCategories = categoriesQueryToReduxFormat(queryParams.categories || '');
    const searchFields = {
        ...queryParams,
        expertises: parsedExpertises,
        categories: parsedCategories,
    };
    const currentLang = localStorage.getItem(config.currentLang);
    const translatedOptions = allOptionsByLang[currentLang];
    const currentCountry = allOptionsMixed.countries.find(c => c.name === searchFields.country) || '';
    const translatedCountry = translatedOptions.countries.find(c => c.id === currentCountry.id) || '';

    const translatedExpertises = searchFields.expertises.map(e => translatedOptions.expertises.find(te => e.id === te.id));

    const translatedSearchFields = {...searchFields, country: translatedCountry.name, expertises: translatedExpertises};

    for (const [id, value] of Object.entries(translatedSearchFields)) {
        yield call(changeSearchFields, {payload: {id, value}});
    }

    yield call(doSearch);
}

export function* nextPageSearch() {
    yield put({ type: actions.SET_DISABLE_NEXT, payload: true });
    const nextPage = yield select(state => state.Home.nextPage);
    const arrayOfpages = yield select(state => state.Home.pages);
    const NumberOfPages = yield select(state => state.Home.totalPages);
    const searchFields = yield select(state => state.Home.search);

    const {allOptionsByLang, allOptionsMixed} = yield select(({Home}) => ({
        allOptionsByLang: Home.allOptionsByLang,
        allOptionsMixed: Home.allOptionsMixed,
    }));

    const translatedOptions = allOptionsByLang['pt'];
    const currentCountry = allOptionsMixed.countries.find(c => c.name === searchFields.country) || '';
    const translatedCountry = translatedOptions.countries.find(c => c.id === currentCountry.id) || '';

    const translatedExpertises = searchFields.expertises.map(e => translatedOptions.expertises.find(te => e.id === te.id));

    const translatedSearchFields = {...searchFields, country: translatedCountry.name, expertises: translatedExpertises};

    const {name, country, state, city, category, expertises} = translatedSearchFields;

    if (arrayOfpages[nextPage] !== null && arrayOfpages[nextPage] !== [] && arrayOfpages[nextPage] !== 'undefined') {
        yield put({ type: actions.SET_SEARCH_RESULT, payload: arrayOfpages[nextPage] });
        yield put({ type: actions.SET_NEXT_PAGE, payload: nextPage + 1 });
        if (nextPage + 1 < NumberOfPages) {
            if (arrayOfpages[nextPage + 1] === undefined) {
                const queryString = `${(name && name !== '-1')
                    ? `name=${name}&` : ''}${(state && state !== '-1')
                    ? `state=${state}&` : ''}${(city && city !== '-1')
                    ? `city=${city}&` : ''}${(country && country !== '-1')
                    ? `country=${country}&` : ''}${(category && category !== '-1')
                    ? `expertise=${category}&` : ''}${expertises && expertises.length > 0
                    ? expertises.map(e => `expertise=${e.name}&`).join('') : ''}${nextPage + 1
                    ? `page=${nextPage + 1}&` : ''}`;
                const expertiseCategories = (searchFields.categories || []).filter((item) => (
                    item.id !== 'enableSiteExperimentalSession')
                ).map(e => `expertise=${e.name}&`).join('');
            
                const booleanParams = (searchFields.categories || []).filter((item) => (
                    item.id === 'enableSiteExperimentalSession'
                )).map(e => `${e.name}=true&`).join('');
                let url = `${basepathV2}${APIroutes.searchV2}?${queryString}${expertiseCategories}${booleanParams}order=coachingHours&qtd=10`;
                try {
                    const { data, status } = yield call(axiosGet, url);
                    if (status === 200) {
                        yield put({ type: actions.SET_ARRAY_PAGES, payload: { idx: nextPage + 1, value: data.profiles } });
                    }
                } catch (error) {
                    treatError(error);
                }
            }
        }
    } else {
        const queryString = `${(name && name !== '-1')
            ? `name=${name}&` : ''}${(state && state !== '-1')
            ? `state=${state}&` : ''}${(city && city !== '-1')
            ? `city=${city}&` : ''}${(country && country !== '-1')
            ? `country=${country}&` : ''}${(category && category !== '-1')
            ? `expertise=${category}&` : ''}${expertises && expertises.length > 0
            ? expertises.map(e => `expertise=${e.name}&`).join('') : ''}${nextPage
            ? `page=${nextPage}&` : ''}`;
        const expertiseCategories = (searchFields.categories || []).filter((item) => (
            item.id !== 'enableSiteExperimentalSession')
        ).map(e => `expertise=${e.name}&`).join('');
    
        const booleanParams = (searchFields.categories || []).filter((item) => (
            item.id === 'enableSiteExperimentalSession'
        )).map(e => `${e.name}=true&`).join('');
        let url = `${basepathV2}${APIroutes.searchV2}?${queryString}${expertiseCategories}${booleanParams}order=coachingHours&qtd=10`;
        try {
            const { data, status } = yield call(axiosGet, url);
            if (status === 200) {
                yield put({ type: actions.SET_SEARCH_RESULT, payload: data.profiles });
                yield put({ type: actions.SET_NEXT_PAGE, payload: data.nextPage });
                yield put({ type: actions.SET_ARRAY_PAGES, payload: { idx: data.nextPage - 1, value: data.profiles } });
                if (data.nextPage + 1 <= NumberOfPages) {
                    const queryString = `${(name && name !== '-1')
                        ? `name=${name}&` : ''}${(state && state !== '-1')
                        ? `state=${state}&` : ''}${(city && city !== '-1')
                        ? `city=${city}&` : ''}${(country && country !== '-1')
                        ? `country=${country}&` : ''}${(category && category !== '-1')
                        ? `expertise=${category}&` : ''}${nextPage
                        ? `page=${nextPage + 1}&` : ''}order=coachingHours&qtd=10`;
                    let url = `${basepathV2}${APIroutes.searchV2}?${queryString}`;
                    try {
                        const { data, status } = yield call(axiosGet, url);
                        if (status === 200) {
                            yield put({ type: actions.SET_ARRAY_PAGES, payload: { idx: data.nextPage, value: data.profiles } });
                        }
                    } catch (error) {
                        treatError(error);
                    }
                }
            }
        } catch (error) {
            treatError(error);
        }
    }

    yield put({ type: actions.SET_RESULTS_QUERY, payload: {...searchFields}});
    yield put({ type: actions.SET_DISABLE_NEXT, payload: false });
}

export function* previousPageSearch() {
    yield put({ type: actions.SET_DISABLE_PREVIOUS, payload: true });
    yield put({ type: actions.SET_LOADING, payload: true });
    const nextPage = yield select(state => state.Home.nextPage);
    const arrayOfpages = yield select(state => state.Home.pages);

    yield put({ type: actions.SET_SEARCH_RESULT, payload: arrayOfpages[nextPage - 2] });
    yield put({ type: actions.SET_NEXT_PAGE, payload: nextPage - 1 });
    yield put({ type: actions.SET_LOADING, payload: false });

    yield put({ type: actions.SET_DISABLE_PREVIOUS, payload: false });
}

export function* getStatistics() {
    const infos = ['horas', 'sessoes', 'coachees'];
    const [hours, sessions, coachees] = yield Promise.all(infos.map(info => {
        return axiosGet(`${basepathV2}${APIroutes.statistics}?type=${info}`);
    }));

    const stats = {hours: hours.data.value, sessions: sessions.data.value, coachees: coachees.data.value};

    yield put({type: actions.SET_STATS_INFO, payload: stats});
}

export function* saveCookieConsent() {
    try {
        yield axiosPost(`${window.location.origin}/v2/febracis-cookies/cookies/`,undefined, {headers: {Cookie: document.cookie}});
    }
    catch (error) {
        console.log(error);
    }
}

function* fetchBlogPosts() {
    try {
        const postsData = yield call(axiosGet, `${basepath4}${APIroutes.blogPosts}&limit=4`);
        yield put({ type: actions.ASYNC_SET_BLOG_POSTS, payload: postsData.data.items });
    } catch ({ response }) {
        treatError(response);
    }
}

export function* watchDoSearch() { yield takeEvery(actions.ASYNC_DO_SEARCH, doSearch); }

export function* watchChangeSearchField() { yield takeEvery(actions.ASYNC_CHANGE_SEARCH_INPUT, changeSearchFields); }

export function* watchInitOptions() { yield takeEvery(actions.SET_SEARCH_OPTIONS, initOptions); }

export function* watchInitialCoaches() { yield takeEvery(actions.ASYNC_INITIAL_COACHES, initialCoaches); }

export function* watchNextPage() { yield takeEvery(actions.ASYNC_NEXT_PAGE_SEARCH, nextPageSearch); }

export function* watchPreviousPage() { yield takeEvery(actions.ASYNC_PREVIOUS_PAGE_SEARCH, previousPageSearch); }

export function* watchStatistics() { yield takeEvery(actions.ASYNC_GET_STATISTICS, getStatistics); }

export function* watchHandleQueryParams() { yield takeEvery(actions.ASYNC_HANDLE_QUERY_PARAMS, handleQueryParams); }

export function* watchFetchAllOptionsLanguages() { yield takeEvery(actions.ASYNC_FETCH_ALL_OPTIONS_LANGUAGES, fetchAllOptionsLanguages); }

export function* watchAsyncSetCookieConsent() { yield takeEvery(actions.ASYNC_SET_COOKIE_CONSENT, saveCookieConsent); }

export function* watchFetchBlogPosts() { yield takeEvery(actions.ASYNC_GET_BLOG_POSTS, fetchBlogPosts); }

export default function* HomeSaga() {
    yield all([
        watchDoSearch(),
        watchChangeSearchField(),
        watchInitOptions(),
        watchInitialCoaches(),
        watchNextPage(),
        watchPreviousPage(),
        watchStatistics(),
        watchHandleQueryParams(),
        watchFetchAllOptionsLanguages(),
        watchAsyncSetCookieConsent(),
        watchFetchBlogPosts(),
    ]);
} 