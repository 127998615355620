import React, { Component, Children } from 'react';

import './collapse.css';

class Collapse extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        };
        this.changeChecked = this.changeChecked.bind(this);
    }

    changeChecked() {
        this.setState({ show: !this.state.show });
    }
    

    render() {
        const { id, title, children } = this.props;
        return (
            <div className='collapse-wrapper'>
                <div onClick={ this.changeChecked } className='collapse-header'>
                    <span >{ title }</span>
                    <i className={`fa fa-chevron-right ${ this.state.show ? 'fa-rotate-90': ''}`} />
                </div>
                <div className={`content-wrapper ${ this.state.show ? 'open': 'close'}`} id={`content-${id}`}>
                    {   
                        Children.toArray(children).map((child, i) => {
                            return child;
                        })
                    }
                </div>
            </div>
        );
    }
}

export default Collapse;