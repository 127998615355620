import React, {useCallback} from 'react';
import { useTranslation } from 'react-i18next';
import Input from '../../../../../components/Input';

import './personal.css';
import PhoneInput from 'react-phone-input-2';
import { phoneCountries } from '../../../../../utils/constants';

function PersonalData({data, setData, errors, invalidEmail}) {
    const {t} = useTranslation();

    const changeValue = useCallback((key) => {
        return useCallback(({target}) => {
            let item = {...data};
            item = {...item, [key]: target.value};
            setData(item);
        }, [data]);
    }, [data]);

    const changePhone = (value) => {
        setData((data) => ({ ...data, phone: value.valueOf() }));
    };

    return (
        <div className='personal-data'>
            <div className='title'>{t('PERSONAL_DATA')}:</div>
            <div className="form-item">
                <Input
                    optional={false}
                    label={t('NAME')}
                    value={data.name}
                    type="text"
                    onChange={changeValue('name')}
                    placeholder={t('FULL_NAME')}
                    className={`input-contact ${errors && errors[0] ? 'error' : ''}`}
                    error={errors && errors[0]}
                    errorMessage={`*${t('FIELD_NULL')}`}
                />
            </div>
            <div className="form-item">
                <Input
                    optional={false}
                    label="Email"
                    value={data.email}
                    type="email"
                    onChange={changeValue('email')}
                    placeholder={t('WORK_EMAIL')}
                    className={`input-contact ${(errors && errors[1]) ? 'error' : ''}`}
                    error={!data.email ? errors && errors[1] : invalidEmail}
                    errorMessage={`${data.email ? `*${t('INVALID_MAIL')}` : `*${t('FIELD_NULL')}`}`}
                />
            </div>
            <div className="form-item-phone">
                <label>{t('TELEPHONE')}</label>
                <PhoneInput
                    country={phoneCountries.defaultCountry}
                    preferredCountries={phoneCountries.preferredCountries}
                    countryCodeEditable={false}
                    autoFormat={true}
                    placeholder={'+55 (DDD)'}
                    value={data.phone}
                    onChange={changePhone}
                    className={'input-contact'}
                />
            </div>
        </div>
    );
}

export default PersonalData;