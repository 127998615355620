import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';

import './testimonies.css';
import {Button, Carousel, Modal, DisableBlock, Placeholder} from '../../../commons';
import ConfirmModal from '../confirm-modal/confirm-modal';
import {addTestimony, deleteTestimony, editTestimony} from './testimonies.action';
import { changeVisibility, changeCurrentSection } from '../../coach.actions';
import { withTranslation } from 'react-i18next';

class Testimonies extends Component {

    constructor() {
        super();

        this.state = {
            show: false
        };

    }

    handleAddTestimony(testimony) {
        this.props.addTestimony(testimony, this.props.testimonies);
    }

    handleEditTestimony(testimony) {
        this.props.editTestimony(testimony, this.props.testimonies);
    }

    handleDeleteTestimony(testimony) {
        this.props.deleteTestimony(testimony, this.props.testimonies);
    }

    render() {
        const {testimonies, edit, user, t} = this.props;

        if (!testimonies || !testimonies.length) {
            return null;
        }

        let items = testimonies.map((testimony, i) => (
            <Testimony
                testimony={testimony}
                edit={edit}
                onDelete={this.handleDeleteTestimony.bind(this)}
                onEdit={this.handleEditTestimony.bind(this)}
                key={i}
                user={user}/>
        ));
        if (items.length < 6 && edit) {
            const placeholder = 
            <TestimonyPlaceholder 
                key='placeholder' 
                addTestimony={this.handleAddTestimony.bind(this)}
                show={this.state.show} 
                close={ () => this.setState({show: false})} 
                open={ () => this.setState({show: true})} 
                onSave={ this.handleSave }
                user={user}
            />;
            items = [placeholder, ...items];
        }

        if(!this.props.user.enableTestimonies && !this.props.edit) {
            return null;
        } else {
            return (
                <section 
                    id="testimonies"
                    onClick={() => changeCurrentSection('testimonies')}
                    className='testimonies-container'>
                    <div className="home-container">

                        <div className='title-wrapper'>
                            <h1 className='title'>{t('TESTIMONY')}</h1>
                        </div>

                        <DisableBlock id={'enableTestimonies'} show={ this.props.edit } click={ this.props.changeVisibility } enable={ this.props.user.enableTestimonies }/>
                            
                        <div className='testimonies-slider-all' style={{ width: '100%'}}>
                            { ((items.length >= 1 && !this.props.edit) || (items.length >= 2 && this.props.edit)) &&
                                    <Carousel className='carousel-container'>
                                        {items}
                                    </Carousel>
                            }
                            { (items.length === 0 && !this.props.edit) &&
                                    <Placeholder text='Não há depoimentos adicionados.' />
                            }
                            { (items.length === 1 && this.props.edit) &&
                                    <div className='no--content'>
                                        <TestimonyPlaceholder
                                            user={ user }
                                            key='placeholder' 
                                            addTestimony={this.handleAddTestimony.bind(this)}
                                            show={this.state.show} 
                                            close={ () => this.setState({show: false})} 
                                            open={ () => this.setState({show: true})} 
                                            onSave={ this.handleSave }
                                        />
                                    </div>
                            }
                        </div>
                    </div>
                </section>
            );
        }
    }
}

class Testimony extends React.Component {

    constructor(props) {
        super(props);

        const {
            text = '',
            author=  '',
            occupation = '',
        } = props.testimony || {};

        this.state = {
            editingTestimony: false,
            confirmDelete: false,
            text,
            author,
            occupation,
            showModal: false
        };
        this.handleUpdate = this.handleUpdate.bind(this);
    }


    handleUpdate({target}) {
        this.setState({[target.name]: target.value});
    }

    handleSave(testimony) {
        this.props.onEdit({...this.props.testimony, ...testimony});
        this.setState({editingTestimony: false});
    }

    handleDelete(testimony) {
        this.props.onDelete(testimony);
        this.setState({confirmDelete: false});
    }

    render() {
        const {testimony, edit, user} = this.props;
        const { editingTestimony, confirmDelete, text, author, occupation } = this.state;

        return (
            <div style={{position: 'relative', padding: '2px'}}>
                {edit &&
                <div className='testimony-toolbar'>
                    { !editingTestimony && 
                    <a onClick={() => this.setState({editingTestimony: true})}> {/* eslint-disable-line */}
                        <i className='fa fa-pencil' title='Editar' style={{color: 'white'}}/>
                    </a>}
                    { editingTestimony && 
                    <a onClick={() => this.handleSave({text, author, occupation})}> {/* eslint-disable-line */}
                        <i className='fa fa-floppy-o' title='Salvar' style={{color: 'white'}}/>
                    </a>}
                    <a onClick={() => this.setState({confirmDelete: true})}> {/* eslint-disable-line */}
                        <i className='fa fa-trash' title='Remover' style={{color: 'white'}}/>
                    </a>
                </div>}
               
                <div className='testimony-content'>
                    <div className='testimony-text'>"{testimony.text}"</div>
                    <div className='testimony-author'>{testimony.author}</div>
                    <div className='testimony-author-job'>{testimony.occupation}</div>
                </div>
                
                { editingTestimony &&
                <AddOrEditTestimonyModal
                    testimony={testimony}
                    onCancel={() => this.setState({editingTestimony: false})}
                    onSave={this.handleSave.bind(this)}
                    user = {user}
                /> }
            
                {confirmDelete &&
                <ConfirmModal
                    onCancel={() => this.setState({confirmDelete: false})}
                    onConfirm={() => this.handleDelete(testimony)}
                    text='Confirma a exclusão do depoimento?'
                    user = {user}
                />}
            </div>
        );
    }
}

class TestimonyPlaceholder extends React.Component {

    constructor() {
        super();

        this.state = {
            addingTestimony: false,
            text: '',
            author: '',
            occupation: '',
            show: false
        };
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleAddTestimony = this.handleAddTestimony.bind(this);
    }

    handleAddTestimony(testimony) {
        const {author, text, occupation } = testimony;
        if(author !== ''&& occupation !== '' && text !== '' ) {
            this.props.addTestimony(testimony);
            this.setState({text: '', author: '', occupation: '', addingTestimony: false });    
        }
    }
    

    handleUpdate({target}) {
        this.setState({[target.name]: target.value});
    }

    render() {
        const { addingTestimony } = this.state;
        const { user } = this.props;
        return (
            <div style={{position: 'relative'}}>
        
                <div className='testimony-placeholder' title='Adicionar um novo depoimento'  onClick={() => this.setState({addingTestimony: true})} >
                    <i className='icon-add-testimony'/>
                    <span> Adicionar novo depoimento </span>
                </div>

                {addingTestimony &&
                    <AddOrEditTestimonyModal
                        onCancel={() => this.setState({addingTestimony: false})}
                        onSave={this.handleAddTestimony}
                        user= {user}
                    />
                }

            </div>
        );
    }
}

class AddOrEditTestimonyModal extends React.Component {

    constructor(props) {
        super(props);

        const {
            text = '',
            author=  '',
            occupation = '',
        } = props.testimony || {};

        this.state = {
            text,
            author,
            occupation,
        };

        this.handleUpdate = this.handleUpdate.bind(this);
    }

    handleUpdate({target}) {
        this.setState({[target.name]: target.value});
    }

    render() {
        const {onSave, onCancel, user, t} = this.props;
        const {text, author, occupation} = this.state;

        return (
            <Modal show={true} onClose={onCancel}>
                <div className={`home-container ${user.theme}`}>
                    <div className='modal-content modal-edit-testimonies'>

                        <div className='box-title-modal'>
                            <i className='icon-testimony'/>
                            <h4>{t('TESTIMONY')}</h4>
                        </div>

                        <div className="item-modal">
                            <label>Autor</label>
                            <input
                                className='wide-input'
                                value={author} name='author'
                                onChange={this.handleUpdate}
                            />
                        </div>
                        <div className="item-modal">
                            <label>Ocupação do autor</label>
                            <input
                                className='wide-input'
                                value={occupation} name='occupation'
                                onChange={this.handleUpdate}
                            />

                        </div>

                        <div className="item-modal">
                            <label>Texto</label>
                            <textarea
                                className='wide-input'  maxLength='300'
                                value={text} name='text'
                                onChange={this.handleUpdate}
                            />
                        </div>

                        <div className='box-alert-modal'> 
                            <div className='icon-alert'>
                                <svg viewBox="0 0 44 50">
                                    <g>
                                        <path className="st0" d="M39.3,37.1c-3.8-3.8-5.7-8.3-5.7-13.3v-7.4c0-5.6-3.7-9.3-7-10.8c-0.9-0.4-1.9-0.8-2.9-1V4.1
                                                c0-2.3-1.8-4.1-4.1-4.1s-4.1,1.8-4.1,4.1v0.5c-0.9,0.2-1.7,0.5-2.6,0.9c-3.6,1.7-7.2,5.6-7.2,10.8v7.4c0,5.2-1.8,9.7-5.5,13.3
                                                C0,37.3-0.1,37.6,0,37.9c0.1,0.3,0.3,0.5,0.6,0.6l7.8,1.3c1.4,0.2,2.8,0.5,4,0.6c1.4,2.8,4.2,4.6,7.3,4.6c3.1,0,5.9-1.8,7.3-4.6
                                                c1.3-0.2,2.6-0.4,4-0.6l7.8-1.3c0.3-0.1,0.5-0.3,0.6-0.6C39.6,37.6,39.5,37.3,39.3,37.1z M17.2,4.1c0-1.4,1.1-2.5,2.5-2.5
                                                s2.5,1.1,2.5,2.5v0.1c-1.6-0.2-3.3-0.2-4.9,0V4.1z M19.8,43.4c-2.1,0-4-1-5.3-2.7c1.8,0.2,3.5,0.3,5.2,0.3c1.7,0,3.4-0.1,5.3-0.3
                                                C23.8,42.3,21.9,43.4,19.8,43.4z M30.8,38.2c-1.6,0.3-3,0.5-4.4,0.7c0,0,0,0,0,0c-0.6,0.1-1.2,0.1-1.8,0.2c0,0,0,0,0,0
                                                c-0.5,0.1-1,0.1-1.5,0.1c-0.1,0-0.2,0-0.3,0c-0.4,0-0.9,0.1-1.3,0.1c-0.1,0-0.2,0-0.3,0c-1,0-2,0-3,0c-0.1,0-0.2,0-0.3,0
                                                c-0.4,0-0.9,0-1.3-0.1c-0.1,0-0.2,0-0.3,0c-0.5,0-1-0.1-1.5-0.1c0,0,0,0-0.1,0c-0.6-0.1-1.1-0.1-1.7-0.2c0,0,0,0,0,0
                                                c-1.4-0.2-2.8-0.4-4.4-0.7l-6.3-1.1c3.3-3.7,4.9-8.2,4.9-13.4v-7.4c0-4.4,3.2-7.9,6.3-9.3c0.9-0.4,1.9-0.7,2.9-1c0,0,0,0,0,0
                                                c2-0.5,4.2-0.5,6.2-0.1c0,0,0,0,0,0c1.1,0.2,2.1,0.6,3.1,1c3,1.4,6.1,4.8,6,9.3v7.4c0,4.9,1.8,9.5,5.2,13.4L30.8,38.2z"/>
                                        <path className="st0" d="M24.9,9.3c-3.2-1.4-7.1-1.5-10.2,0c-1.8,0.8-4.8,3.3-4.9,7.1c0,0.5,0.4,0.8,0.8,0.8c0,0,0,0,0,0
                                                c0.4,0,0.8-0.4,0.8-0.8c0-3,2.5-4.9,3.9-5.6c2.7-1.3,6.1-1.3,8.8,0c0.4,0.2,0.9,0,1.1-0.4C25.5,9.9,25.3,9.5,24.9,9.3z"/>
                                    </g>
                                </svg>
                            </div>
                            <p>Só comportamos um total de 6 depoimentos. </p>
                        </div>

                        <div className='modal-footer'>
                            <Button size='small' text={t('CANCEL')} type='warning' click={ onCancel }/>
                            <Button
                                size='small' text={t('SAVE')} type='warning'
                                click={() => onSave({text, author, occupation})}/>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = ({ Coach}) => ({
    testimonies: Coach.user.testimonies,
    edit: Coach.isEdit,
    user: Coach.user
});
const mapDispatchToProps = dispatch => bindActionCreators(
    {
        addTestimony,
        editTestimony,
        deleteTestimony,
        changeVisibility,
        changeCurrentSection
    },
    dispatch);
export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Testimonies);