import config from './config';

const CredentialsHelper = {
    update: function (accessToken, refreshToken) {
        localStorage.setItem(config.accessToken, accessToken);
        localStorage.setItem(config.refreshToken, refreshToken);
    },
    clear: function () {
        localStorage.removeItem(config.accessToken);
        localStorage.removeItem(config.refreshToken);
    },
    accessToken: () => localStorage.getItem(config.accessToken),
    refreshToken: () => localStorage.getItem(config.refreshToken),
    isLoggedIn: () => Boolean(CredentialsHelper.accessToken()),
    setItem: function (key, value) {
        localStorage.setItem(key, value);
    },
    getItem: function (key) {
        return localStorage.getItem(key);
    },
};

export default CredentialsHelper;
