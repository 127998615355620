import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import IconSvg from '../../utils/svg';
import {history} from '../../utils/store';
import SelectLanguage from '../SelectLanguage';
import {initSearchingOptions} from '../../screens/home/home.actions';

import './new-header.css';

const items = [
    {
        id: 'benefits',
        label: 'MENU_BENEFITS',
        path: '/home#benefits',
        icon:'icon-checkbox',
        toScroll: true,
    },
    {
        id: 'featured_coaches',
        label: 'MENU_COACHES',
        path: '/home#featured_coaches',
        icon:'icon-users',
        toScroll: true,
    },
    {
        id: 'transformation_areas',
        label: 'MENU_TRANSFORMATION_AREAS',
        path: '/home#transformation_areas',
        icon:'icon-target',
        toScroll: true
    },
    {
        id: 'contact_us',
        label: 'MENU_CONTACT',
        path: '/home#contact_us',
        icon:'icon-message-box',
        toScroll: true
    },
    {
        id: 'blog',
        label: 'BLOG',
        path: '/blog',
        icon:'icon-blog',
    },
];

const emptyItem = {
    id: '',
    path: '',
    label: '',
};

const NewHeader = ({
    showLogo = true,
    navs = items,
    className = '',
    isWhiteColor = false,
    showSelectLang = true,
    logoRedirectPath = '/home',
    onLogoClick,
}) => {
    const defaultActiveItem = navs.find(item => history.location.hash.includes(item.id)) || emptyItem;
    const [activeItem, setActiveItem] = useState({defaultActiveItem});
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {hash, pathname} = history.location;

    useEffect(() => {
        const selectedItem = navs.find(findSelectedItemInMenuByURL) || emptyItem;
        setActiveItem({...selectedItem});
        scrollToSection();
    }, [hash, pathname]);

    useEffect(() =>{
        scrollToSection();
    }, [activeItem]);
    
    function findSelectedItemInMenuByURL(menuItem) {
        return hash.includes(menuItem.id) || pathname.includes(menuItem.id);
    }

    function scrollToSection(){
        const {toScroll, id} = activeItem;
    
        if (toScroll) {
            const elementToScroll = document.getElementById(id);
            elementToScroll && elementToScroll.scrollIntoView({behavior: 'smooth'});
        }
    }

    const redirect = (item) => {
        setIsMobileMenuOpen(false);
        setActiveItem({...item});
        history.push(item.path);
    };

    function handleLanguageChange(option) {
        dispatch(initSearchingOptions(option.value));
    }

    function handleNavItemClick(item) { 
        redirect(item);

        if (item.onClick) {
            item.onClick();
        }
    }

    function handleLogoClick(onClick) {        
        redirect({...emptyItem, path: logoRedirectPath});

        if (onClick) {
            onClick();
        }
    }

    const fullPathName = `${window.location.pathname}${window.location.hash}`;

    return (
        <header className={`new-header ${className}`} id='new-header'>
            <div
                className={`new-header-container-image ${showLogo ? '' : 'logo-hidden'}`}
                onClick={() => handleLogoClick(onLogoClick)}
            >
                <IconSvg name='logo-oseu-coach' width={170} height={55} classDiv='logo-oseu-coach' />
            </div>

            <div className={`menu-container ${isMobileMenuOpen ? 'mobile-menu-open' : ''}`}>
                <nav className={`navs-group ${isMobileMenuOpen ? 'mobile-menu-open' : ''}`} >
                    <div className={`menu-hamburger ${isMobileMenuOpen ? 'mobile-menu-open' : ''}`} onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
                        <IconSvg name='icon-menu' width={26} classDiv='icon-burger' />
                    </div>

                    <ul className={`${isMobileMenuOpen ? 'mobile-menu-open' : ''}`}>
                        {navs.map(item => {
                            return (
                                <li
                                    key={item.path}
                                    className={`menu-item ${activeItem.path === item.path || fullPathName === item.path ? 'active' : ''}`}
                                    onClick={() => handleNavItemClick(item)}
                                >
                                    <IconSvg
                                        name={item.icon}
                                        classDiv='icon-menu-item'
                                        width={24}
                                        color='#000'
                                        color2='#000'
                                    />
                                    {t(item.label)}
                                </li>
                            );
                        })}
                        {showSelectLang && (
                            <li className='select-language-container'>
                                <SelectLanguage
                                    onChange={handleLanguageChange}
                                    isWhiteColor={isWhiteColor}
                                />
                            </li>
                        )}
                    </ul>
                </nav>
            </div>
        </header>
    );
};

export default NewHeader;