import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {format} from 'date-fns';

import {locales} from '../../../../../../utils/constants';
import IconSvg from '../../../../../../utils/svg';
import LazyImage from '../../../../../../components/lazyImage';

import './postCard.scss';

function PostCard({post}) {
    const {t} = useTranslation();

    return (
        <article className='post-card' key={post.id}>
            <div className='card-img'>
                <LazyImage
                    className='card-photo'
                    src={post.thumbnail}
                    alt={`Thumbnail post ${post.title}`}
                    placeholderClassName='lazy-placeholder'
                />
            </div>
            <h2 className='card-title'>{post.title}</h2>
            <div className='name-and-date'>
                <span className='card-coach-name'>{post.author}</span>
                <span className='card-date'>{format( new Date(post.last_published_at), 'PPP', {locale: locales['pt']})}</span>
            </div>
            <button className='card-button'>
                <Link to={`/blog/post/${post.meta.slug}`} className='link'>
                    <span>{t('READ_MORE')}</span>
                    <IconSvg name='arrow-riht-icon' width={35} height={10} color='#FBFBFB' classDiv='arrow-riht-icon' />
                </Link>
            </button>
        </article>
    );
}

export default PostCard;
