import {actions} from '../../../../utils/constants';

export default (state = testimonies, action) => {
    switch (action.type) {

    case actions.SET_USER:
        return action.payload.testimonies || [];

    case actions.ADD_TESTIMONY:
        return [action.payload].concat(state);

    case actions.DELETE_TESTIMONY:
        return state
            .filter(t => t.id !== action.payload.id);

    case actions.EDIT_TESTIMONY:
        return state
            .map(t => t.id === action.payload.id ? action.payload : t);

    default:
        return state;
    }
};

const testimonies = [
    {
        id: 1548795,
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
        author: 'Paulo Gentil',
        occupation: 'Administrador de empresa',
    },
];
