import { actions } from '../../utils/constants';

export const goToSearch = () => ({
    type: actions.ASYNC_DO_SEARCH
});

export const exitSearch = () => ({
    type: actions.EXIT_SEARCH
});

export const changeSearchInput = (event) => ({
    type: actions.ASYNC_CHANGE_SEARCH_INPUT, payload: {
        id: event.target.id,
        value: event.target.value
    }
});

export const setSearchInput = (payload) => ({
    type: actions.ASYNC_CHANGE_SEARCH_INPUT,
    payload,
});

export const initSearchingOptions = (lang) => ({
    type: actions.SET_SEARCH_OPTIONS,
    payload: lang,
});

export const initialCoaches = () => ({
    type: actions.ASYNC_INITIAL_COACHES
});

export const nextPageSearch =() => ({
    type: actions.ASYNC_NEXT_PAGE_SEARCH
});

export const previousPageSearch =() => ({
    type: actions.ASYNC_PREVIOUS_PAGE_SEARCH
});

export const asyncGetStatistics = () => ({
    type: actions.ASYNC_GET_STATISTICS
});

export const handleQueryParams = () => ({
    type: actions.ASYNC_HANDLE_QUERY_PARAMS,
});

export const fetchAllOptionsLanguages = (isCoachList) => ({
    type: actions.ASYNC_FETCH_ALL_OPTIONS_LANGUAGES,
    payload: isCoachList,
});

export const setAllOptionsByLang = (payload) => ({
    type: actions.SET_ALL_OPTIONS_BY_LANG,
    payload,
});

export const setAllOptionsMixed = (payload) => ({
    type: actions.SET_ALL_OPTIONS_MIXED,
    payload,
});

export const setOptions = (id, value) => ({
    type: actions.SET_OPTIONS,
    payload: {id, value},
});

export const asyncSetCookieConsent = () => ({
    type: actions.ASYNC_SET_COOKIE_CONSENT,
});

export const setOtherOptionsFilter = (payload) => ({
    type: actions.SET_OTHER_OPTIONS_FILTER,
    payload,
});

export const asyncGetBlogPosts = () => ({
    type: actions.ASYNC_GET_BLOG_POSTS
});

export const setGetBlogPosts = () => ({
    type: actions.ASYNC_SET_BLOG_POSTS
});
