import React, {useEffect} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import ContentLoader from 'react-content-loader';
import {useDispatch, useSelector} from 'react-redux';

import NewHeader from '../../../../components/newHeader';
import PostCard from '../postCard';
import PostSlider from '../postSlider';
import {Loader} from '../../../commons';
import {asyncGetPosts, setSearch} from '../../blog.actions';
import SearchBar from '../searchBar';
import NoPostsFound from '../noPostsFound';
import Categories from '../categories';

import './postList.scss';

function PostList({category}) {
    const postsData = useSelector(({Blog}) => Blog.postsData);
    const isLoading = useSelector(({Blog}) => Blog.isLoading);
    const search = useSelector(({Blog}) => Blog.search);
    const hasMore = postsData && postsData.posts.length < postsData.total;
    const dispath = useDispatch();
    
    const handleClearSearch = () => {
        dispath(setSearch(''));
    };
    
    const fetchMorePosts = () => {
        dispath(asyncGetPosts({
            search,
            category,
            isFetchMore: true,
            offset: postsData.posts.length
        }));
    };

    useEffect(() => {
        dispath(asyncGetPosts({search, category}));
        document.querySelector('.post-main-content').scrollIntoView({behavior: 'smooth'});
    }, [search, category]);

    return (
        <div className='post-main-content'>
            <NewHeader
                showSelectLang={false}
                className='blog-header'
                onLogoClick={handleClearSearch}
            />

            <SearchBar />

            <Categories selectedCategory={category} />

            <div className='post-list'>
                <ListContent
                    postsData={postsData}
                    fetchMorePosts={fetchMorePosts}
                    isLoading={isLoading}
                    hasMore={hasMore}
                    category={category}
                />
            </div>
        </div>
    );
}

function ListContent({postsData, fetchMorePosts, hasMore, isLoading, category}) {
    const search = useSelector(({Blog}) => Blog.search);

    if (isLoading) {
        return <LoadingContent />;
    } else if (!postsData || postsData.posts.length === 0) {
        return (
            <NoPostsFound
                hasSearch={Boolean(search)}
                hasCategory={Boolean(category)}
            />);
    } else {
        return (
            <>
                {!search && <PostSlider posts={postsData.posts.slice(0, 4)} />}
    
                <InfiniteScroll
                    dataLength={postsData.posts.length}
                    next={fetchMorePosts}
                    hasMore={hasMore}
                    loader={<Loader />}
                    scrollableTarget='my-body'
                >
                    {(search ? postsData.posts : postsData.posts).map(post => (
                        <PostCard post={post} key={post.id} />
                    ))}
                </InfiniteScroll>
            </>
        );
    }
}

function LoadingContent(props) {
    return (
        <ContentLoader
            style={{
                padding: '20px',
                height: '66vh',
                maxWidth: '1300px',
                alignSelf: 'center'
            }}
            speed={2}
            width='100%'
            backgroundColor='#e6e6e6'
            foregroundColor='#d8d8d8'
            className='post-list-content-loader'
            {...props}
        >
            <rect x='0' y='0' rx='23px' ry='23px' width='100%' height='50%' />
            <rect x='0%' y='58%' rx='6px' ry='6px' width='100%' height='42%' />
        </ContentLoader>
    );
}

export default PostList;
