import React from 'react';

import Footer from '../../screens/home/components/footer';
import Header from '../../components/newHeader';
import {Link} from 'react-router-dom';

import './409.css';
import { t } from 'i18next';

const page409 = () => (
    <div className='page-409'>
        <Header className='new-home-header' isWhiteColor/>

        <main className='page-409-main'>
            <h1 className='title-409'>{t('LINK_USED')}</h1>

            <h4 className='text-409'>{t('LINK_ALREADY_USED')}</h4>

            <button className='btn-back-to-home'>
                <Link to='/home'>
                    Voltar para a página inicial
                </Link>
            </button>
        </main>

        <Footer />
    </div>
);

export default page409;