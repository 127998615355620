import React from 'react';
import {useDispatch} from 'react-redux';

import SelectLanguage from '../../../../components/SelectLanguage';
import {initSearchingOptions} from '../../../home/home.actions';
import IconSvg from '../../../../utils/svg';

import './header.css';

const customFontStyle = {color: '#FFF', fontFamily: ''};

function Header({stepForms, handleBack}) {
    const dispatch = useDispatch();
    function handleLanguageChange(option) {
        dispatch(initSearchingOptions(option.value));
    }

    return (
        <header className='check-in-header' style={{position: `${stepForms ? 'relative' : 'absolute'}`}}>
            <button
                onClick={handleBack}
                type='button'
                className={stepForms ? 'btn-back-form' : 'btn-back-form-none'}
            >
                <IconSvg
                    name="arrow-left"
                    width={45}
                    height={37}
                    classDiv="btn-back"
                />
            </button>
            <SelectLanguage onChange={handleLanguageChange} customFontStyle={customFontStyle} />

            <IconSvg name='logo-oseu-coach' width={170} height={55} classDiv={stepForms ? 'logo-oseu-coach-margin' : 'logo-oseu-coach'}/>
        </header>
    );
}

export default Header;
