import React, { Component } from 'react';

import { Carousel } from 'react-responsive-carousel';

import './slider.css';

class SliderComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    createItems() {
        return this.props.items.map((e, idx)=> e.isVisible ? this.createItem(e, idx) : null) 
    }

    createItem(obj, key) {
        const { Component } = this.props;
        if ( Component ) {
            return <Component {...obj} key={key}/>; 
        } else {
            return <img src={ obj } alt=''  key={key} />;
        }
    }

    render() {


        return (
            <Carousel
                showThumbs={false}
                showStatus={false}
                showIndicators={true}
                infiniteLoop={true}
                useKeyboardArrows={true}
                emulateTouch={true}
                dynamicHeight={false}
            >
                {this.createItems()}
            </Carousel>
        );
    }
}

export default SliderComponent;
