import {Configuration, OpenAIApi} from 'openai';

class OpenAI {
    constructor(api) {
        this.api = api;
        this.defaultCompletionParams = {
            model: 'gpt-3.5-turbo',
            max_tokens: 1200,
            temperature: 0.3,
        };
    }

    static config(config) {
        const configuration = new Configuration(config);
        const openaiApi = new OpenAIApi(configuration);

        return new OpenAI(openaiApi);
    }

    async completion(prompt, options = {}) {
        const response = await this.api.createChatCompletion({
            ...this.defaultCompletionParams,
            ...options,
            messages: [{role: 'user', content: prompt}],
        });

        return response.data.choices[0].message.content.trim();
    }

    async chatCompletion(messages, options = {}) {
        const response = await this.api.createChatCompletion({
            ...this.defaultCompletionParams,
            ...options,
            messages,
        });

        return response.data.choices[0].message.content.trim();
    }
}

export default OpenAI;
