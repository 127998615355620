import { actions, APIroutes, basepath, regexEmail, socialNetArray, settingsAuth, urlAuth, expertiseFebracisSelect, basepathV2, basepath3, constantItens } from '../../utils/constants';
import {
    userRequest, saveInfo, getInfo, clearStorageItem, takeOffIndexKey, sortArray,
    userEditRequest, axiosPost, uploadImage, axiosGet, axiosWitEtag, createIndexKey
} from '../../utils/common-functions';
import { trackEvent } from '../../utils/analytics';
import { put, takeEvery, call, all, takeLatest, select } from 'redux-saga/effects';
import { history } from '../../utils/store';
import { setBackgroundImg, setCoachDetails } from './coach.actions';
import { treatError } from '../../utils/error-utils';
import CredentialsHelper from '../../utils/credentials-helper';

export function* returnHeader() {
    const token = yield select(state => state.Coach.token);
    return {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };
}

export function* returnHeaderImage() {
    const token = yield select(state => state.Coach.token);
    return {
        'Accept': 'application/json',
        'Content-Type': 'image/jpeg',
        'Authorization': `Bearer ${token}`,
        'x-scis-filename': 'filename',
    };
}

export function* handleError(error, callback, args) {
    const { status } = error;
    if (status === 401) {
        yield call(getNewToken);
        yield call(callback, ...args);
    }
    if (status === 404) {
        yield history.push('/not_found');
    }
}

export function* getUser() {
    const header = yield call(returnHeader);
    yield put({ type: actions.LOAD_COACH });
    try {
        const { status, data } = yield call(userRequest, header);

        if (data.gallery) {
            data.gallery = yield createIndexKey(data.gallery);
        }

        if (data.videos) {
            data.videos = yield createIndexKey(data.videos);
        }

        yield put({ type: actions.SET_USER, payload: status === 404 ? {} : data });
        const result = yield call(axiosGet, `${basepath}${APIroutes.getUsername}`, header);
        yield put({ type: actions.SAVE_USERNAME, payload: result.data.username });
        yield call(history.replace, `/${result.data.username}/edit`);
    }
    catch ({ response }) {
        if (response.status === 401) {
            yield call(handleError, response, getUser);
        }
    } finally {
        yield put({ type: actions.LOAD_COACH_FINISHED });
    }
}

export function* saveCreateEditField({ value, id }) {
    let fieldsToSave = yield JSON.parse(getInfo(actions.FIELDS_SAVED));
    if (fieldsToSave != null) {
        fieldsToSave[id] = value;
        yield saveInfo(actions.FIELDS_SAVED, JSON.stringify(fieldsToSave));
    } else {
        fieldsToSave = { [id]: value };
        yield saveInfo(actions.FIELDS_SAVED, JSON.stringify(fieldsToSave));
    }
}

export function* editUser(action) {
    if (action.payload.id === 'username') {
        const username = yield select(state => state.Coach.username);
        let value = action.payload.value.replace(/[^\w\d-]/g, '');
        if (username !== value) {
            yield put({ type: actions.SAVE_USERNAME, payload: value });
            yield put({ type: actions.CHECK_LAST_USERNAME, payload: value });
            yield put({ type: actions.SET_SEARCHING_USERNAME, payload: true });
        }
    } else {
        yield call(saveCreateEditField, action.payload);
        yield put({
            type: actions.EDIT_USER,
            payload: action.payload
        });
    }
    yield call(verifyNameAndEmail);
}

function* updateAvatar(action) {
    const header = yield call(returnHeader);
    const token = yield select(state => state.Coach.token);
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'image/jpeg',
        'Authorization': `Bearer ${token}`,
        'x-scis-filename': 'filename',
    };
    try {
        const response = yield uploadImage(action.payload, headers);
        if (response.status === 200) {
            yield userEditRequest({ photoUrl: response.data.uri }, header);
            yield put({ type: actions.AVATAR_UPDATED, payload: response.data.uri });
        }

    } catch (error) {
        if (error.response.status === 401) {
            yield call(handleError, error.response, updateAvatar, [action]);
        }
    }
}

function* addBannerPicture({ payload }) {
    const token = yield select(state => state.Coach.token);
    const header = yield call(returnHeader);
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'image/jpeg',
        'Authorization': `Bearer ${token}`,
        'x-scis-filename': 'filename',
    };

    let { picture } = payload;

    try {
        yield put({ type: actions.CHANGE_LOADING_EDIT, payload: true });
        const response = yield uploadImage(picture, headers);
        if (response.status === 200) {
            yield userEditRequest({ bannerImg: response.data.uri }, header);
            yield put(setBackgroundImg({desktop: response.data.uri, mobile: response.data.uri}));
        }
    } catch ({response}) {
        yield call(handleError, response, addBannerPicture, [{ payload }]);
    } finally {
        yield put({ type: actions.CHANGE_LOADING_EDIT, payload: false });
    }
}

function* addGalleryPicture({ payload }) {
    const token = yield select(state => state.Coach.token);
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'image/jpeg',
        'Authorization': `Bearer ${token}`,
        'x-scis-filename': 'filename',
    };

    const header = yield call(returnHeader);
    let { gallery, picture, caption } = payload;

    gallery = yield sortArray(gallery, 'key');
    let galleryArray = yield call(takeOffIndexKey, gallery);

    try {
        yield put({ type: actions.CHANGE_LOADING_EDIT, payload: true });
        const response = yield uploadImage(picture, headers);
        const newPicture = { caption, url: response.data.uri };
        galleryArray.push(newPicture);
        yield userEditRequest({ gallery: galleryArray }, header);
        yield put({
            type: actions.EDIT_USER,
            payload: { id: 'gallery', value: galleryArray },
        });
    } catch ({ response }) {
        yield call(handleError, response, addGalleryPicture, [{ payload }]);
    } finally {
        yield put({ type: actions.CHANGE_LOADING_EDIT, payload: false });
    }
}

function* editGalleryPicture({ payload }) {
    let { gallery, picture: updatedPicture } = payload;

    yield put({ type: actions.CHANGE_LOADING_EDIT, payload: true });
    const headers = yield call(returnHeader);
    const headersImg = yield call(returnHeaderImage);
    if (typeof updatedPicture.url !== 'string') {
        const { status, data } = yield uploadImage(updatedPicture.url, headersImg);
        if (status === 200) {
            updatedPicture.url = data.uri;
        }
    }

    gallery = sortArray(gallery, 'key');

    let newGallery = gallery.map((pic, index) => {
        if (index === updatedPicture.index) {
            return ({ caption: updatedPicture.caption, url: updatedPicture.url });
        }
        return pic;
    });

    newGallery = yield call(takeOffIndexKey, newGallery);

    yield userEditRequest({ gallery: newGallery }, headers);
    yield put({
        type: actions.EDIT_USER,
        payload: {
            id: 'gallery',
            value: newGallery
        },
    });
    yield put({ type: actions.CHANGE_LOADING_EDIT, payload: false });
}

function* deleteGalleryPicture({ payload }) {
    let { gallery, picture } = payload;
    const headers = yield call(returnHeader);


    gallery = sortArray(gallery, 'key');

    gallery = gallery.filter((pic, index) => index !== picture.index);

    let newGallery = yield call(takeOffIndexKey, gallery);

    try {
        yield put({ type: actions.CHANGE_LOADING_EDIT, payload: true });
        yield userEditRequest({ gallery: newGallery }, headers);
        yield put({
            type: actions.EDIT_USER,
            payload: {
                id: 'gallery',
                value: newGallery
            }
        });
    } catch ({ response }) {
        yield call(handleError, response, deleteGalleryPicture, [{ payload }]);
    } finally {
        yield put({ type: actions.CHANGE_LOADING_EDIT, payload: false });
    }
}


function* addVideo({ payload }) {
    const { video, videos } = payload;
    const headers = yield call(returnHeader);
    let videosArray = yield call(takeOffIndexKey, videos);
    videosArray.push(video);
    try {
        yield put({ type: actions.CHANGE_LOADING_EDIT, payload: true });
        const { status } = yield userEditRequest({ videos: videosArray }, headers);
        if (status >= 200 && status < 300) {
            yield put({ type: actions.EDIT_USER, payload: { id: 'videos', value: videosArray } });
        }
    } catch ({ response }) {
        yield call(handleError, response, addVideo, [{ payload }]);
    } finally {
        yield put({ type: actions.CHANGE_LOADING_EDIT, payload: false });
    }
}

function* editVideo({ payload }) {
    const { video, videos } = payload;

    const headers = yield call(returnHeader);
    let videosArray = videos.map(v => v.id === video.id ? video : v);
    videosArray = yield sortArray(videosArray, 'key');
    videosArray = yield call(takeOffIndexKey, videosArray);

    try {
        yield put({ type: actions.CHANGE_LOADING_EDIT, payload: true });
        yield userEditRequest({ videos: videosArray }, headers);
        yield put({
            type: actions.EDIT_USER,
            payload: { id: 'videos', value: videosArray },
        });
    } catch (error) {
        yield call(handleError, error.response, editVideo, [{ payload }]);
    } finally {
        yield put({ type: actions.CHANGE_LOADING_EDIT, payload: false });
    }

}

function* deleteVideo({ payload }) {
    const { video, videos } = payload;
    const headers = yield call(returnHeader);
    let videosArray = videos.filter(v => v.id !== video.id);
    videosArray = yield sortArray(videosArray, 'key');
    videosArray = yield call(takeOffIndexKey, videosArray);
    try {
        yield put({ type: actions.CHANGE_LOADING_EDIT, payload: true });
        yield userEditRequest({ videos: videosArray }, headers);
        yield put({
            type: actions.EDIT_USER,
            payload: { id: 'videos', value: videosArray },
        });
    } catch (error) {
        yield call(handleError, error.response, deleteVideo, [{ payload }]);
    } finally {
        yield put({ type: actions.CHANGE_LOADING_EDIT, payload: false });
    }

}

function* addTestimony({ payload }) {
    const { testimony, testimonies } = payload;
    const headers = yield call(returnHeader);
    testimony.id = Math.round(Math.random() * Number.MAX_SAFE_INTEGER);
    const newTestemonies = [testimony].concat(testimonies);
    try {
        yield put({ type: actions.CHANGE_LOADING_EDIT, payload: true });
        yield userEditRequest({ testimonies: newTestemonies }, headers);
        yield put({
            type: actions.EDIT_USER,
            payload: {
                id: 'testimonies', value: newTestemonies
            }
        });
    } catch (error) {
        yield call(handleError, error.response, addTestimony, [{ payload }]);
    } finally {
        yield put({ type: actions.CHANGE_LOADING_EDIT, payload: false });
    }
}

function* editTestimony({ payload }) {
    const { testimony, testimonies } = payload;
    const headers = yield call(returnHeader);
    const newTestemonies = testimonies.map(t => t.id === testimony.id ? testimony : t);
    try {
        yield put({ type: actions.CHANGE_LOADING_EDIT, payload: true });
        yield userEditRequest({ testimonies: newTestemonies }, headers);
        yield put({
            type: actions.EDIT_USER,
            payload: {
                id: 'testimonies', value: newTestemonies
            }
        });
    } catch (error) {
        yield call(handleError, error.response, editTestimony, [{ payload }]);
    } finally {
        yield put({ type: actions.CHANGE_LOADING_EDIT, payload: false });
    }
}

function* deleteTestimony({ payload }) {
    const { testimony, testimonies } = payload;
    const headers = yield call(returnHeader);
    const newTestemonies = testimonies.filter(t => t.id !== testimony.id);

    try {
        yield put({ type: actions.CHANGE_LOADING_EDIT, payload: true });
        yield userEditRequest({ testimonies: newTestemonies }, headers);
        yield put({
            type: actions.EDIT_USER,
            payload: {
                id: 'testimonies', value: newTestemonies
            }
        });
    } catch (error) {
        yield call(handleError, error.response, deleteTestimony, [{ payload }]);
    } finally {
        yield put({ type: actions.CHANGE_LOADING_EDIT, payload: false });
    }
}

export function* saveEdit(action) {
    const header = yield call(returnHeader);
    const data = yield select(state => state.Coach.user);
    trackEvent('coach options', 'save edition', 'change profile');
    yield put({ type: actions.CHANGE_LOADING_EDIT, payload: true });
    try {
        const newExpertises = data.expertises.filter(item => !constantItens.includes(item));
        yield put({type: actions.SET_USER_EXPERTISE, payload: Array.from(new Set(data.expertises))});
        yield call(userEditRequest, {...data, expertises: newExpertises}, header);
        yield call(saveNewUsername);
        yield put({ type: actions.EDIT_ACTION });
    } catch (error) {
        const { response } = error;
        yield handleError(response, saveEdit, [action]);
    } finally {
        yield put({ type: actions.CHANGE_LOADING_EDIT, payload: false });
    }
}

export function* getNewToken() {
    const refreshToken = yield select(state => state.Coach.refreshToken);
    try {
        const { status, data } = yield call(axiosPost, `${urlAuth}${APIroutes.token}`, {
            grant_type: 'refresh_token',
            refresh_token: refreshToken,
            client_id: settingsAuth.client_id,
            machine: window.navigator.userAgent
        }, true);
        if (status === 200) {
            CredentialsHelper.update(data.access_token, data.refresh_token);
            yield put({ type: actions.SAVE_TOKENS, payload: { token: 'token', value: data.access_token } });
            yield put({ type: actions.SAVE_TOKENS, payload: { token: 'refreshToken', value: data.refresh_token } });
        }
    } catch (error) {
        if (error.response.status === 404) {
            yield history.push('/not_found');
        }
        if (error.response.status === 401) {
            yield history.push('/login');
        }
    }
}

export function* getUserToken(code) {
    try {
        const data = yield call(axiosPost, `${urlAuth}${APIroutes.token}`, {
            grant_type: 'authorization_code',
            code: code,
            redirect_uri: 'none',
            client_id: settingsAuth.client_id,
            machine: window.navigator.userAgent,
            scope: 'coach',
        }, true);
        if (data.status === 200) {
            CredentialsHelper.update(data.data.access_token, data.data.refresh_token);
            yield put({ type: actions.SAVE_TOKENS, payload: { token: 'token', value: data.data.access_token } });
            yield put({ type: actions.SAVE_TOKENS, payload: { token: 'refreshToken', value: data.data.refresh_token } });
        }
    } catch ({ response }) {
        if (response.status === 404 || response.status === 401) {
            yield history.push('/not_found');
        }
    }
}

export function* initUser(action) {
    yield put({ type: actions.SAVE_USERNAME, payload: action.payload.name });
    if (action.payload.code || action.payload.refreshToken) {
        try {
            if (action.payload.code) {
                yield call(getUserToken, action.payload.code);
                yield call(handleCoachAccess);
            } else {
                yield call(getUser);
            }
            yield put({ type: actions.SET_CAN_EDIT, payload: true });
            yield call(loadExpertises, action);
        } catch (error) { console.debug('tryerror', error); }
    } else {
        yield put({ type: actions.SET_CAN_EDIT, payload: false });
        try {
            const { data } = yield call(axiosGet, `${basepath}/profiles/${action.payload.name}`, true);
            if (data) {
                if (data.gallery) {
                    data.gallery = yield createIndexKey(data.gallery);
                }

                if (data.videos) {
                    data.videos = yield createIndexKey(data.videos);
                }

                if (data.id) {
                    yield put({
                        type: actions.ASYNC_GET_COACH_DETAILS,
                        payload: data.id
                    });
                }

                yield put({ type: actions.SET_USER, payload: data });
                yield put({ type: actions.LOAD_COACH_FINISHED });
            }
        } catch (error) {
            const { status } = error.response;
            if (status === 404 || status === 401) {
                yield history.push('/not_found');
            }
        }
    }
}

export function* changeVisibility(action) {
    const header = yield call(returnHeader);
    const enableItem = yield select(state => state.Coach.user[action.payload]);
    try {
        yield userEditRequest({ [action.payload]: !enableItem }, header);
        yield put({ type: actions.CHANGE_VISIBILITY, payload: action.payload });
    } catch (error) {
        const { response } = error;
        yield handleError(response, changeVisibility, [action]);
    }
}

export function* publishProfile(action) {
    const header = yield call(returnHeader);
    if (action) {
        yield put({ type: actions.SET_PUBLISH_PROFILE, payload: action.payload });

        if (action.payload) {
            yield axiosPost(`${basepath}${APIroutes.publish}`, null, header);
        } else {
            yield axiosPost(`${basepath}${APIroutes.unpublish}`, null, header);
        }
    }
}

export function* saveNewUsername(cantSave) {
    const header = yield call(returnHeader);
    if (cantSave) {
        try {
            const { data } = yield call(axiosGet, `${basepath}${APIroutes.getUsername}`, header);
            if (data) {
                yield put({ type: actions.SAVE_USERNAME, payload: data.username });
            }
        } catch (error) {
            if (error.response.status === 401) {
                yield history.push('/not_found');
            }
        }
    } else {
        const username = yield select(state => state.Coach.username);
        try {
            yield call(axiosPost, `${basepath}${APIroutes.getUsername}`, { 'username': username }, header);
            yield call(history.replace, `/${username}/edit`);

        } catch (error) {
            if (error.response.status === 401) {
                yield history.push('/not_found');
            }
            if (error.response.status === 409) {
                console.debug('token já sendo usado');
            }
        }

    }
}

function verifyUrl(url, key) {
    if (url) {
        if (!(/(https?:)\/\//).test(url.toLocaleLowerCase())) {
            if (!(/\/\//).test(url.toLocaleLowerCase())) {
                url = ('https://').concat(url);
            } else {
                url = ('https:').concat(url);
            }
        }

        let newUrl = new URL(url);
        if (!newUrl.host.includes(key)) {
            newUrl.host = `${key}.com`;
        }
        if (newUrl.pathname === '' || newUrl.pathname === '/') {
            newUrl.pathname = `/${url.replace(/(https?:)\/\//, '')}`;
        }
        return newUrl.toString();
    } else {
        return '';
    }

}

export function* setSocialLinks(obj) {

    let keys = yield Object.keys(obj).filter(e => socialNetArray.includes(e.toLocaleLowerCase()));
    let newObj = {};
    yield keys.forEach(el => {
        if (obj[el] !== 'undefined' || obj[el] !== '') {
            newObj[el] = verifyUrl(obj[el], el);
        }
    });

    for (let x = 0; x < keys.length; x++) {
        yield put({
            type: actions.EDIT_USER,
            payload: {
                id: keys[x],
                value: newObj[keys[x]]
            }
        });
    }
    return newObj;
}

export function* saveConfiguration(action) {
    const header = yield call(returnHeader);
    const user = yield select(state => state.Coach.user);
    const social = yield call(setSocialLinks, JSON.parse(getInfo(actions.FIELDS_SAVED)) || {});
    if (social !== {}) {
        let newUser = { ...user, ...social };
        try {
            yield userEditRequest(newUser, header);
        } catch (error) {
            const { response } = error;
            yield handleError(response, saveConfiguration, [action]);
        }
    } else {
        try {
            yield userEditRequest(user, header);
        } catch (error) {
            const { response } = error;
            yield handleError(response, saveConfiguration, [action]);
        }
    }
    yield clearStorageItem(actions.FIELDS_SAVED);
    yield call(saveNewUsername, false);
}

export function* cancelSave(action) {
    if (action) {
        yield put({ type: actions.CHANGE_LOADING_EDIT, payload: true });
        yield call(getUser, action);
        yield put({ type: actions.SET_CAN_EDIT, payload: true });
        yield call(saveNewUsername, true);
        yield put({ type: actions.CHANGE_LOADING_EDIT, payload: false });
    }
}

export function* loadBackImgs(action) {
    const imgs = yield select(state => state.Coach.backgroundImgs);
    if (imgs.length === 0) {
        yield put({ type: actions.BACK_IMGS_LOADING });
        try {
            const { status, data } = yield call(axiosGet, `${basepath}${APIroutes.backgrounds}`);
            if (status === 200) {
                yield put({ type: actions.SET_BACK_IMGS, payload: data });
                yield put({ type: actions.BACK_IMGS_LOADED });
            }
        } catch (error) {
            console.debug(error.response);
        }
    }
}

export function* loadExpertises(action) {
    const expertises = yield select(state => state.Coach.expertisesArray);
    const expertise = yield select(state => state.Coach.user.expertises);
    if (expertises.length === 0) {
        let data = yield axiosGet(`${basepath3}/types/pt${APIroutes.expertises}`, true);
        if (data) {
            let exp = [...data.data, expertiseFebracisSelect].map(obj => {
                if (expertise) {
                    if (expertise.includes(obj.name)) {
                        obj.selected = true;
                    } else {
                        obj.selected = false;
                    }
                    return obj;
                } else {
                    obj.selected = false;
                    return obj;
                }

            });
            yield put({ type: actions.SET_EXPERTISES_ARRAY, payload: exp });
        }
    } else {
        let ret = expertises.map((obj) => {
            if (expertise) {
                if (expertise.includes(obj.name)) {
                    obj.selected = true;
                } else { obj.selected = false; }
                return obj;
            } else {
                obj.selected = false;
                return obj;
            }

        });
        yield put({ type: actions.SET_EXPERTISES_ARRAY, payload: ret });
    }
}

export function* selectExpertise(action) {
    let expertiseList = yield select(state => state.Coach.expertisesArray);
    let userExpertises = yield select(state => state.Coach.user.expertises);
    if (userExpertises.length === 3 && !userExpertises.includes(action.payload)) {
        return false;
    } else {
        if (userExpertises) {
            if (userExpertises.includes(action.payload)) {
                expertiseList = expertiseList.map((e) => {
                    if (e.name === action.payload) { e.selected = false; }
                    return e;
                });
                yield put({ type: actions.SET_EXPERTISES_ARRAY, payload: expertiseList });
                userExpertises = userExpertises.filter((e) => {
                    return e !== action.payload;
                });
                yield put({ type: actions.SET_USER_EXPERTISE, payload: userExpertises });
            } else {
                expertiseList = expertiseList.map((e) => {
                    if (e.name === action.payload) { e.selected = true; }
                    return e;
                });
                yield put({ type: actions.SET_EXPERTISES_ARRAY, payload: expertiseList });
                userExpertises.push(action.payload);
                yield put({ type: actions.SET_USER_EXPERTISE, payload: userExpertises });
            }
            yield call(saveCreateEditField, { value: userExpertises, id: 'expertises' });
        } else {
            expertiseList = expertiseList.map((e) => {
                if (e.name === action.payload) {
                    e.selected = true;
                }
                return e;
            });
            yield put({ type: actions.SET_EXPERTISES_ARRAY, payload: expertiseList });
            yield put({ type: actions.SET_USER_EXPERTISE, payload: [action.payload] });
            yield call(saveCreateEditField, { value: [action.payload], id: 'expertises' });
        }
    }
}

export function* verifyIfUsernameIsAvailable(action) {
    const header = yield call(returnHeader);
    if (action.payload !== '') {
        try {
            const username = yield call(axiosGet, `${basepath}${APIroutes.getUsername}`, header);
            if (username.data.username) {
                if (username.data.username !== action.payload) {
                    try {
                        const result = yield call(axiosGet, `${basepath}/profiles/${action.payload}/avaliability`, header);
                        if (!result.data.available) {
                            yield put({ type: actions.TOOGLE_CONFIG_SAVE, payload: true });
                            yield put({ type: actions.SET_SEARCHING_USERNAME, payload: false });
                        } else {
                            yield put({ type: actions.TOOGLE_CONFIG_SAVE, payload: false });
                            yield put({ type: actions.SET_SEARCHING_USERNAME, payload: false });
                        }
                    } catch (error) { throw error; }
                } else {
                    yield put({ type: actions.TOOGLE_CONFIG_SAVE, payload: false });
                    yield put({ type: actions.SET_SEARCHING_USERNAME, payload: false });
                }
            }
        } catch (error) {
            if (error.response.status === 401 || error.response.status === 404) {
                yield call(history.push, '/not_found');
            }
        }
    } else {
        yield put({ type: actions.TOOGLE_CONFIG_SAVE, payload: true });
        yield put({ type: actions.SET_SEARCHING_USERNAME, payload: false });
    }
}

export function* verifyNameAndEmail() {
    const user = yield select(state => state.Coach.user);
    if (user.firstName === '' || user.secondName === '' || !(regexEmail).test(user.email)) {
        yield put({ type: actions.CHANGE_SAVE_DISABLE, payload: true });
    } else {
        yield put({ type: actions.CHANGE_SAVE_DISABLE, payload: false });
    }
}

export function* loadCountries(action) {
    try {
        //
        // Baixando e salvando no localStorage

        let { data, status, headers } = yield call(axiosWitEtag, `${basepath3}/types/pt${APIroutes.countries}`, 'eTAGCountries');

        if (status === 200) {
            yield headers.valueOf()['etag'] ? put({ type: actions.SET_COUNTRY_LIST_AND_CITIES, payload: data }) : false;
        } else {
            data = yield select(state => state.Coach.countryListAndCities);
        }

        let countries = [];

        for (let country of data) {
            const { data } = yield call(axiosGet, `${basepath}${country.url}`);
            if (country.model === 'states-cities') {
                country.states = data;
            } else if (country.model === 'cities') {
                country.cities = data;
            } else {
                // OBS: se model diferente de 'states-cities' e 'cities', país será ignorado
                // Isso implica possivelmente num novo tipo de model que deve ser suportado pela página
                continue;
            }

            countries.push(country);
            
        }
        countries.sort(function(a,b) {
            return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
        });
        yield put({ type: actions.SET_COUNTRY_LIST, payload: countries });

        // Processando request

        const nameCountry = yield select(state => state.Coach.user.country);
        let country = countries.find(e => e.name === nameCountry);
        let cities = [];
        let states = [];

        if (country) {
            const nameState = yield select(state => state.Coach.user.state);

            if (country.model === 'states-cities') {
                states = country.states;
                let temp = states.find(e => e.name === nameState || e.abbrev === nameState);
                cities = temp ? temp.cities : [];
            } else if (country.model === 'cities') {
                cities = country.cities;
                yield put({ type: actions.DISABLE_CHECKBOX, payload: true });
            }
        }

        yield put({
            type: actions.SET_COUNTRY, payload: {
                name: nameCountry,
                states: states,
                cities: cities
            }
        });

    } catch (error) { throw error; }
}

export function* setCountry(action) {
    let countries = yield select(state => state.Coach.countryListAndCities);
    if (action.payload !== '-1') {
        countries = countries.filter((e) => {
            return e.name === action.payload;
        });
        yield call(setCheckboxStateCities, countries[0]);
        yield put({
            type: actions.EDIT_USER,
            payload: {
                id: 'country',
                value: action.payload
            }
        });

        yield put({ type: actions.EDIT_USER, payload: { id: 'state', value: '' } });
        yield put({ type: actions.EDIT_USER, payload: { id: 'city', value: '' } });
    }
}

export function* setDataToCountry(country, type, data) {
    let countries = yield select(state => state.Coach.countryListAndCities);
    countries = countries.map(e => {
        if (e.name === country) {
            e[type] = data;
        }
        return e;
    });
    yield put({ type: actions.SET_COUNTRY_LIST_AND_CITIES, payload: countries });
}

export function* setCheckboxStateCities(obj) {
    if (obj.model === 'states-cities') {
        if (obj.states && obj.states.length !== 0) {
            yield put({
                type: actions.SET_COUNTRY, payload: {
                    name: obj.name,
                    states: obj.states,
                    cities: []
                }
            });
            yield put({ type: actions.DISABLE_CHECKBOX, payload: false });
        } else {
            const { data } = yield call(axiosGet, `${basepath}${obj.url}`);
            yield put({
                type: actions.SET_COUNTRY, payload: {
                    name: obj.name,
                    states: data,
                    cities: []
                }
            });
            yield put({ type: actions.DISABLE_CHECKBOX, payload: false });
            yield call(setDataToCountry, obj.name, 'states', data);
        }
    } else {
        if (obj.cities && obj.cities.length !== 0) {
            yield put({
                type: actions.SET_COUNTRY, payload: {
                    name: obj.name,
                    states: [],
                    cities: obj.cities
                }
            });
            yield put({ type: actions.DISABLE_CHECKBOX, payload: true });
        } else {
            const { data } = yield call(axiosGet, `${basepath}${obj.url}`);
            yield put({
                type: actions.SET_COUNTRY, payload: {
                    name: obj.name,
                    states: [],
                    cities: data
                }
            });
            yield put({ type: actions.DISABLE_CHECKBOX, payload: true });
            yield call(setDataToCountry, obj.name, 'cities', data);
        }
        yield put({
            type: actions.EDIT_USER,
            payload: {
                id: 'state',
                value: null
            }
        });
    }
}

export function* setCities(action) {
    let country = yield select(state => state.Coach.country);
    let countries = yield JSON.parse(getInfo('CountriesList'));
    if (action.payload !== '-1' && action.payload !== null) {
        const cities = yield countries.filter((el) => {
            if (el.name === country.name) {
                el.states = el.states.filter(e => e.name === action.payload);
                return el;
            } else { return false; }
        })[0]['states'][0]['cities'];

        yield put({ type: actions.SET_COUNTRY, payload: { ...country, cities } });
        yield put({
            type: actions.EDIT_USER,
            payload: {
                id: 'state',
                value: action.payload
            }
        });
    } else {
        yield put({
            type: actions.EDIT_USER,
            payload: {
                id: 'state',
                value: ''
            }
        });
    }

    yield put({ type: actions.EDIT_USER, payload: { id: 'city', value: '' } });

}

export function* setCity(action) {
    if (action.payload !== '-1' && action.payload !== null) {
        yield put({
            type: actions.EDIT_USER,
            payload: {
                id: 'city',
                value: action.payload
            }
        });
    } else {
        yield put({
            type: actions.EDIT_USER,
            payload: {
                id: 'city',
                value: ''
            }
        });
    }
}

export function* checkAndDisableButton(action) {
    yield put({ type: actions.TOOGLE_CONFIG_SAVE, payload: true });
    if (action.payload.value !== '') {
        yield call(verifyIfUsernameIsAvailable, action);
    } else {
        yield put({ type: actions.SET_SEARCHING_USERNAME, payload: false });
        yield put({ type: actions.TOOGLE_CONFIG_SAVE, payload: true });
    }
}

export function* chooseTemplate(action) {
    let header = yield call(returnHeader);
    let data = { theme: action.payload };
    try {
        yield call(userEditRequest, data, header);
    } catch (error) {
        if (error.response.status === 401) {
            const { response } = error;
            yield handleError(response, chooseTemplate, [action]);
        }
    }
    yield put({ type: actions.SELECT_TEMPLATE, payload: action.payload });
}

export function* getCoachDetails({payload}) {
    try {
        const url = `${basepathV2}${APIroutes.getCoachExperimentalSessionData.replace(':id', payload)}`;
        const {data: {profile}} = yield call(axiosGet, url);
        yield put(setCoachDetails(profile.Profile));
    } catch (error) {
        treatError(error);
    }
}

export function* asyncSendSessionRequest({payload}) {
    const {
        callback,
        coachId,
        name,
        email,
        phone,
        day,
        turn,
        sessionType
    } = payload;
    try {
        const url = `${basepathV2}${APIroutes.experimentalSession}`;
        const { status } = yield call(axiosPost, url, {
            name,
            email,
            phone,
            day: day.value,
            turn: turn.value,
            sessionType: sessionType.value,
            coachId
        });
        if (callback) {
            callback(!(status >= 200));
        }
    } catch (error) {
        treatError(error);
        if (callback) {
            callback(true);
        }
    }
}

function* confirmExperimentalSession({payload}) {
    const {requestId} = payload;
    const url = `${basepathV2}${APIroutes.confirmExperimentalSession.replace(':id', requestId)}`;
    try {
        yield call(axiosGet, url);
        yield put({ type: actions.SET_LOADING_REQUEST, value: false });
    } catch (error) {
        const errorStatus = error.response.status;
        if (errorStatus === 404) {
            yield history.push('/not_found');
        }
        if (errorStatus === 409) {
            yield history.push('/already_exist');
            yield put({ type: actions.SET_LOADING_REQUEST, value: false });
        }
    }
}

function* handleCoachAccess() {
    const headers = yield call(returnHeader);

    const {status, data: profileData} = yield call(axiosGet, `${basepathV2}${APIroutes.profile}`, headers);
    if (!profileData.profile.alreadySiteAccess) {
        yield put({type: actions.SET_USER, payload: status === 404 ? {} : profileData.profile});
        history.push('/customize');
    } else {
        yield call(getUser);
    }
}

export function* watchGetMetaDecorator() {
    const name = window.location.pathname.replace('/', '');

    if (!name.includes('/edit')){
        const {data} = yield call(axiosGet, `${basepathV2}${APIroutes.coach.replace(':name', name)}`);
        [
            {key: 'meta-image', value: 'url'},
            {key: 'meta-title', value: 'name'},
            {key: 'meta-description', value: 'description'},
        ].map((e) => {
            const el = document.getElementById(e.key);
            return (el.content = data[e.value]);
        } );
    }
}

export function* watchGetMetaTagDecorator() { yield takeLatest(actions.META_TAGS_DECORATOR, watchGetMetaDecorator);}

export function* watchSaveConfiguration() { yield takeEvery(actions.ASYNC_SAVE_CONFIG, saveConfiguration); }

export function* watchPublishProfile() { yield takeEvery(actions.ASYNC_SET_PUBLISH_PROFILE, publishProfile); }

export function* watchChangeVisibility() { yield takeEvery(actions.ASYNC_CHANGE_VISIBILITY, changeVisibility); }

export function* watchInitUser() { yield takeEvery(actions.INIT_USER, initUser); }

export function* watchSaveEdit() { yield takeEvery(actions.ASYNC_EDIT_ACTION, saveEdit); }

export function* watchEditUserEdit() { yield takeLatest(actions.ASYNC_EDIT_USER, editUser); }

export function* watchGetUser() { yield takeLatest(actions.GET_USER, getUser); }

export function* watchCancelSave() { yield takeLatest(actions.CANCEL_SAVE, cancelSave); }

function* watchUpdateAvatar() { yield takeEvery(actions.UPDATE_AVATAR, updateAvatar); }

function* watchAddBannerPIcture() { yield takeEvery(actions.ADD_BANNER_IMAGE, addBannerPicture); }

function* watchAddGalleryPicture() { yield takeEvery(actions.ASYNC_ADD_GALLERY_PICTURE, addGalleryPicture); }

function* watchEditGalleryPicture() { yield takeEvery(actions.ASYNC_EDIT_GALLERY_PICTURE, editGalleryPicture); }

function* watchDeleteGalleryPicture() { yield takeEvery(actions.ASYNC_DELETE_GALLERY_PICTURE, deleteGalleryPicture); }

function* watchAddVideo() { yield takeEvery(actions.ASYNC_ADD_VIDEO, addVideo); }

function* watchEditVideo() { yield takeEvery(actions.ASYNC_EDIT_VIDEO, editVideo); }

function* watchDeleteVideo() { yield takeEvery(actions.ASYNC_DELETE_VIDEO, deleteVideo); }

export function* watchAddTestimony() { yield takeEvery(actions.ASYNC_ADD_TESTIMONY, addTestimony); }

export function* watchDeleteTestimony() { yield takeEvery(actions.ASYNC_DELETE_TESTIMONY, deleteTestimony); }

export function* watchEditTestimony() { yield takeEvery(actions.ASYNC_EDIT_TESTIMONY, editTestimony); }

export function* watchLoadBackImgs() { yield takeEvery(actions.BACKGROUND_IMG_LOAD, loadBackImgs); }

export function* watchLoadExpertises() { yield takeEvery(actions.LOAD_EXPERTISE, loadExpertises); }

export function* watchSelectExpertise() { yield takeEvery(actions.ASYNC_SELECT_EXPERTISE, selectExpertise); }

export function* watchVerifyUsername() { yield takeEvery(actions.ASYNC_CHECK_USERNAME, verifyIfUsernameIsAvailable); }

export function* watchAsyncUsernameValue() { yield takeLatest(actions.CHECK_LAST_USERNAME, checkAndDisableButton); }

export function* watchLoadCountries() { yield takeLatest(actions.LOAD_COUNTRY, loadCountries); }

export function* watchSelectCountry() { yield takeEvery(actions.SELECT_COUNTRY, setCountry); }

export function* watchSetCities() { yield takeEvery(actions.SELECT_STATE, setCities); }

export function* watchSetCity() { yield takeEvery(actions.SELECT_CITY, setCity); }

export function* watchChooseTemplate() { yield takeEvery(actions.SELECT_ASYNC_TEMPLATE, chooseTemplate); }

export function* watchGetExperimentalSessionData() {
    yield takeLatest(actions.ASYNC_GET_COACH_DETAILS, getCoachDetails);
}

export function* watchSendSessionRequest() {
    yield takeLatest(actions.ASYNC_SEND_SESSION_REQUEST, asyncSendSessionRequest);
}

export function* watchConfirmExperimentalSession() {
    yield takeLatest(actions.ASYNC_CONFIRM_SESSION_REQUEST, confirmExperimentalSession);
}

export function* watchHandleCoachAccess() { yield takeEvery(actions.ASYNC_HANDLE_COACH_ACCESS, handleCoachAccess); }

export default function* appSaga() {
    yield all([
        watchGetUser(),
        watchChangeVisibility(),
        watchEditUserEdit(),
        watchSaveEdit(),
        watchAddBannerPIcture(),
        watchUpdateAvatar(),
        watchInitUser(),
        watchAddGalleryPicture(),
        watchEditGalleryPicture(),
        watchDeleteGalleryPicture(),
        watchAddVideo(),
        watchEditVideo(),
        watchDeleteVideo(),
        watchPublishProfile(),
        watchSaveConfiguration(),
        watchAddTestimony(),
        watchDeleteTestimony(),
        watchEditTestimony(),
        watchCancelSave(),
        watchLoadBackImgs(),
        watchLoadExpertises(),
        watchSelectExpertise(),
        watchVerifyUsername(),
        watchLoadCountries(),
        watchSelectCountry(),
        watchSetCities(),
        watchSetCity(),
        watchAsyncUsernameValue(),
        watchChooseTemplate(),
        watchGetExperimentalSessionData(),
        watchSendSessionRequest(),
        watchConfirmExperimentalSession(),
        watchHandleCoachAccess(),
        watchGetMetaTagDecorator(),
    ]);
}
