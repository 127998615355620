import React from 'react';
import { useTranslation } from 'react-i18next';
import './disable-block.css';

const DisableBlock = ({ click, enable, style, id, show, className }) => {
    const {t} = useTranslation();

    if ( show ) {
        return (  
            <div title='Habilitar visualização ?' className={`main-container ${ className ? className:''} ${ enable? 'enable' : 'disable'}`} style={{ ...style }}>
                { enable &&
                    <div className='section-enable' id={id} onClick={ click }>
                        <i className='fa fa-check'/>
                        {t('DISPLAYED_SECTION')}
                    </div>
                }
                { !enable  &&
                    <div className='section-disable' id={id} onClick={ click }>
                        <i className='fa fa-times'/>
                        {t('HIDDEN_SECTION')}
                    </div>
                }
            </div>
        );
    } else{
        return null;
    } 
};
    

export default DisableBlock;
