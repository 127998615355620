import React from 'react';
import './effect-message.css';

function EffectMessage({ mainPhraseAuthor, mainPhrase }) {
    return (
        <article className="template--7--effect-message">
            <div className='template--7--em-content'>
                <p className="subtitle">{mainPhrase}</p>

                <span className="autor"> {mainPhraseAuthor} </span>
            </div>
        </article>
    );
}

export default EffectMessage;
