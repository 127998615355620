import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import { toogleMenu, editProfile } from '../../header.action';

import { Button, NavbarMenu, SocialLine } from '../../../../../commons/index';
import { getViewportWidth, returnStrWithLineBreak } from '../../../../../../utils/common-functions';
import { changeCurrentSection } from '../../../../coach.actions';

import './header-main.css';
import { withTranslation } from 'react-i18next';

class HeaderMain extends Component {

    goesTo(id) {
        window.location.href = `#${id}`;
    }

    render() {
        const { user, changeCurrentSection, t } = this.props;
        const { backgroundImg } = user;
        const vw = getViewportWidth();

        return (

            <section
                className='header-firsthalf'
                id="home"
                onClick={() => changeCurrentSection('home')}>
                <div className='content-container'>
                    <section className="header-content-main">
                        <SocialLine user={this.props.user}/>
                        <NavbarMenu user={this.props.user} isEdit={this.props.isEdit} menuOpen={this.props.menuOpen} setToogle={this.props.toogleMenu} />
                    </section>

                    <article className='main-phrase-container'>
                        <div className='home-container'>
                            <div className='main-phrase'>
                                {!this.props.isEdit && (user.mainPhrase && user.mainPhrase !== null && user.mainPhrase !== undefined) &&
                                    <blockquote>{[...returnStrWithLineBreak(user.mainPhrase, '\n')]}</blockquote>
                                }
                                {!this.props.isEdit && (!user.mainPhrase || user.mainPhrase === null || user.mainPhrase === undefined) &&
                                    <blockquote></blockquote>
                                }
                                {this.props.isEdit &&
                                    <textarea maxLength='107' placeholder='Digite uma frase de efeito' onChange={this.props.editProfile} value={(user.mainPhrase && user.mainPhrase != null) ? user.mainPhrase : ''} id={'mainPhrase'} />
                                }
                                {this.props.isEdit && <span className='character-count' style={{ color: 'white' }}>{`${107 - (user.mainPhrase ? user.mainPhrase.length : ('').length)}  caracteres restantes`}</span>}
                            </div>

                            <div className="main-phrase-author">
                                {!this.props.isEdit &&
                                    <small className='main-phrase-name'> {(user.mainPhraseAuthor && user.mainPhraseAuthor != null) ? user.mainPhraseAuthor : ''} </small>
                                }

                                {this.props.isEdit &&
                                    <input maxLength='35' onChange={this.props.editProfile} value={(user.mainPhraseAuthor && user.mainPhraseAuthor != null) ? user.mainPhraseAuthor : ''} id={'mainPhraseAuthor'} placeholder="Autor" />
                                }

                            </div>

                            <div className='button-block'>
                                <Button type='warning' text={t('MEET')} size='small' click={() => this.goesTo('biography')} />
                            </div>

                        </div>
                    </article>

                    <section className='header-image' style={{ background: `url(${backgroundImg !== null ? vw > 768 ? backgroundImg.desktop : backgroundImg.mobile : 'https://cdn.scisapp.com/downloads/images/1521851004245676fbc9cffd08e15.jpeg'})` }}></section>
                </div>
            </section>

        );
    }
}

const mapStateToProps = state => ({
    user: state.Coach.user,
    menuOpen: state.Coach.menuOpen,
    isEdit: state.Coach.isEdit
});

const mapDispatchToProps = dispatch => bindActionCreators({ toogleMenu, editProfile, changeCurrentSection }, dispatch);

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(HeaderMain);

