import React from 'react';
import {useTranslation} from 'react-i18next';

import SiteExample from '../site-example';

import './info-section.css';

function InfoSection() {
    const {t} = useTranslation();
    const mainParagraph = t('INFO_SECTION_MAIN_PARAGRAPH');
    const [start, hightlight, end] = mainParagraph.split('*');

    return (
        <section className='info-section'>
            <div className='sub-section'>
                <p className='main-paragraph'>
                    {start}
                    <span>{hightlight}</span>
                    {end}
                </p>
                <p className='sub-paragraph'>
                    {t('INFO_SECTION_SUB_PARAGRAPH')}
                </p>
            </div>

            <SiteExample />
        </section>
    );
}

export default InfoSection;
