import { actions } from '../../../../utils/constants';

export const toogleMenu = event => {
    return {
        type: actions.MENU_OPEN,
        payload: event.target.checked
    };
};

export const editProfile = (obj) => {
    return {
        type: actions.ASYNC_EDIT_USER,
        payload: obj
    };
};

export const updateAvatar = url => ({
    type: actions.UPDATE_AVATAR,
    payload: url,
});
