import React from 'react';
import ga from 'react-ga4';

const initAnalytics = (trackingId, opts = {}) =>
    ga.initialize(trackingId, opts);

const trackUser = (userId, opts = {}) =>
    ga.set({ userId, ...opts });

const trackEvent = (category, action, label, opts = {}) =>
    ga.event({ category, action, label, ...opts });

const WithAnalytics = Component =>
    class WithAnalytics extends React.Component {
  
        componentDidMount() {
            const page = this.props.location.pathname;
            this.trackPage(page);
        }
  
        componentWillReceiveProps(nextProps) {
            const currentPage = this.props.location.pathname;
            const nextPage = nextProps.location.pathname;
            if (currentPage !== nextPage) {this.trackPage(nextPage);}
        }
  
      trackPage = page => {
          ga.set({ page });
      };
  
      render() {
          return <Component {...this.props} />;
      }
    };

export { WithAnalytics as default , trackEvent, trackUser, initAnalytics };
  
  