import React, { useState } from 'react';
import SlickCarousel from '../../../../../../components/SlickCarousel/slickCarousel';
import PhotoCard from './photo-card/photo-card';
import InitialCard from './initial-card/initial-card';
import { ZoomedPictureModal } from '../../../../components/gallery/gallery';

import './gallery.css';

function Gallery({ photos, onClick }) {

    if (!photos || !photos.length) {
        return null;
    }

    const [zoomImage, setZoomImage] = useState(null);

    const settings = {
        dots: true,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,

        responsive: [
            {
                breakpoint: 1260,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            }, {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            }, {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ]
    };

    return (
        <section
            onClick={() => onClick('gallery')}
            id="gallery"
            className="template--6--galery">
            <div className='template--6--galery-container'>
                <SlickCarousel
                    className="generic--slider"
                    settings={settings}>
                    <div className="generic--slider-item">
                        <InitialCard />
                    </div>
                    {photos.map((photo, i) => (
                        <div className="generic--slider-item" key={i}>
                            <PhotoCard
                                onClick={() => setZoomImage(photo)}
                                {...photo}
                                key={photo.url} />
                        </div>
                    ))}
                </SlickCarousel>

                <ZoomedPictureModal
                    picture={zoomImage}
                    gallery={photos}
                    onClose={() => setZoomImage(null)}
                />

            </div>
        </section>
    )
};


export default Gallery;