import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import IconSvg from '../../../../utils/svg';
import {setSearch} from '../../blog.actions';
import {history} from '../../../../utils/store';

import './search-bar.scss';

function SearchBar() {
    const search = useSelector(({Blog}) => Blog.search);
    const [currentSearch, setCurrentSearch] = useState(search);
    const isLoading = useSelector(({Blog}) => Blog.isLoading);
    const dispatch = useDispatch();

    const handleChangeSearch = (e) => {
        setCurrentSearch(e.target.value);
    };

    const handleSearch = () => {
        if (currentSearch.trim() !== search) {
            dispatch(setSearch(currentSearch.trim()));
            setCurrentSearch(currentSearch.trim());
            history.push('/blog');
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    useEffect(() => {
        setCurrentSearch(search);
    }, [search]);

    return (
        <div className='blog-search-background'>
            <div className='blog-search-bar'>
                <input
                    type="text"
                    placeholder='Buscar'
                    value={currentSearch}
                    onChange={handleChangeSearch}
                    onKeyDown={handleKeyDown}
                    disabled={isLoading}
                />
                <button onClick={handleSearch} disabled={isLoading}>
                    <IconSvg name='icon-search-2' classDiv='icon-search' />
                </button>
            </div>
        </div>
    );
}

export default SearchBar;
