import React, { Component } from 'react';

import imgDefault from '../../../../img/image-coach.jpg';

import './posts.css';

class Posts extends Component {
    render() {
        return (
            <section id={'posts'} className="posts--home">
                <div className="home-container">
                    <div className='title-wrapper'>
                        <h1 className='title'>Postagens</h1>
                    </div>

                    <ul className='post--list'>
                        <li className='post--item'>
                            <div className='post--image'>
                                <img src={imgDefault} alt='Imagem Test' />
                            </div>

                            <article className='post---content'>
                                <h5> Assine o maior canal de coaching do youtube </h5>
                                <p> Conheça toda a nova estrutura do Centro Conceito da Febracis. </p>
                                <button> Saiba Mais </button>
                            </article>
                        </li>

                        <li className='post--item'>
                            <div className='post--image'>
                                <img src={imgDefault} alt='Imagem Test' />
                            </div>

                            <article className='post---content'>
                                <h5> Assine o maior canal de coaching do youtube </h5>
                                <p> Conheça toda a nova estrutura do Centro Conceito da Febracis. </p>
                                <button> Saiba Mais </button>
                            </article>
                        </li>

                        <li className='post--item'>
                            <div className='post--image'>
                                <img src={imgDefault} alt='Imagem Test' />
                            </div>

                            <article className='post---content'>
                                <h5> Assine o maior canal de coaching do youtube </h5>
                                <p> Conheça toda a nova estrutura do Centro Conceito da Febracis. </p>
                                <button> Saiba Mais </button>
                            </article>
                        </li>

                        <li className='post--item'>
                            <div className='post--image'>
                                <img src={imgDefault} alt='Imagem Test' />
                            </div>

                            <article className='post---content'>
                                <h5> Assine o maior canal de coaching do youtube </h5>
                                <p> Conheça toda a nova estrutura do Centro Conceito da Febracis. </p>
                                <button> Saiba Mais </button>
                            </article>
                        </li>
                    </ul>

                    <button className='btn-more'> Ver todos </button>
                </div>
            </section>
        );
    }
}

export default Posts;