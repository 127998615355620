import React from 'react';
import { useTranslation } from 'react-i18next';
import IconSvg from '../../../../../../utils/svg';
import './coach-numbers.css';

function CoachNumbers({
    onClick,
    coachingHours,
    enableCoachingHours,
    febracisCourses,
    enableFebracisCourses,
    lecturesHours,
    enableLecturesHours,
}) {
    const {t} = useTranslation();

    return (
        <section
            onClick={() => onClick('resume')}
            className="template--7--coach-numbers">
            { enableFebracisCourses &&
                    <CoachNumberItem
                        iconName={'icon-graduated'}
                        label={t('FEBRACIS_TRAINING_COURSES')}
                        value={febracisCourses}
                    />
            }

            { enableCoachingHours &&
                    <CoachNumberItem
                        iconName={'icon-clock'}
                        label={t('COACHING_HOURS_SCIS')}
                        value={coachingHours}
                    />
            }

            { enableLecturesHours && 
                    <CoachNumberItem
                        iconName={'icon-speaker'}
                        label={t('HOURS_OF_COURSE_AND_LECTURES')}
                        value={lecturesHours}
                    />
            }
        </section>
    );
}


const CoachNumberItem = ({ value, iconName, label }) => value ? (
    <div className="item-info">
        <div className='item-info-content'>
            <IconSvg
                classDiv="icon-item"
                name={iconName}
                width={80}
                height={80}
                color={'#fff'} />
            <div className="coach-infos">
                <p className="number-info">{value}</p>
                <p className="title-info">{label}</p>
            </div>

        </div>
    </div>
) : null;
export default CoachNumbers;