import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import './SessionRequestForm.css';
import PhoneInput from 'react-phone-input-2';
import SelectWrapper from '../../../../../components/Select';
import {
    sessionTypes,
    turnTypes,
    weekDays,
} from '../../../../../utils/constants';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import TitleSection from '../../../../../components/TitleSection/TitleSection';
import { asyncSendSessionRequest } from '../../../coach.actions';
import { validEmail } from '../../../../../utils/common-functions';
import SpinnerLoad from '../../../../../components/Loader/Loader';
import IconSvg from '../../../../../utils/svg';
import { Loader, Modal } from '../../../../commons';

const defaultCountry = 'br';
const preferredCountries = ['br', 'us', 'pt', 'ao', 'jp'];
const initialData = {
    name: '',
    email: '',
    phone: '+55',
    day: '',
    turn: '',
    sessionType: '',
};

export default function SessionRequestForm() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [showMessage, setShowMessage] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState(initialData);
    const [error] = useState(false);
    const invalidEmail = !validEmail(formData.email);

    const currentThemeColor = useSelector(
        ({ Coach }) => Coach.user.themeColor,
        shallowEqual
    );

    const coachSessionType = useSelector(
        ({ Coach }) => Coach.coachDetails.sessionType,
        shallowEqual
    );

    const availableHours = useSelector(
        ({ Coach }) => Coach.coachDetails.schedules_hours,
        shallowEqual
    ) || [];

    const coachId = useSelector(({ Coach }) => Coach.user.id);

    const turnsAvailable = availableHours
        .filter((item) => item.weekday === formData.day.value)
        .map((e) => ({
            label: t(`${e.turn}`),
            value: e.turn,
        }));

    const turnsOrder = turnTypes().map(e => turnsAvailable
        .find(ele => ele.value === e.value))
        .filter(ele => ele !== undefined);

    const availableDays =
    Array.from(new Set(availableHours.map((e) => e.weekday))) || [];

    const disableButton = useMemo(() => {
        return (
            !formData.name ||
            invalidEmail ||
            !formData.day ||
            !formData.turn ||
            !formData.sessionType
        );
    }, [formData]);

    function changePhone(value) {
        setFormData((data) => ({ ...data, phone: value.valueOf() }));
    }

    function changeForm({ target }) {
        setFormData({ ...formData, [target.name]: target.value });
    }

    function changeSelectForm(value, { name }) {
        if (name === 'day') {
            setFormData({ ...formData, [name]: value, turn: '' });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    }

    function handleSessionRequest(event) {
        event.preventDefault();
        setIsLoading(true);
        setFormData(initialData);
        dispatch(
            asyncSendSessionRequest({
                ...formData,
                coachId: coachId,
                callback: (displayMessage = false) => {
                    setFormData(initialData);
                    setShowMessage(displayMessage);
                    setIsLoading(false);
                    setShowModal(true);
                },
            })
        );
    }

    const closeConfirm = useCallback(() => {
        setShowModal(false);
    }, []);

    return (
        <section
            id="session_request"
            className="session-request-section"
            style={{ backgroundColor: currentThemeColor }}
        >
            <TitleSection
                iconView={true}
                iconWidth="39px"
                colorIcon="#ffffff"
                style={{ color: '#ffffff', textAlign: 'center' }}
                iconName="icon-text-effect"
                text1={t('REQUEST_EXPERIMENTAL_SESSION')}
            />

            <form className="session-request-form" onSubmit={handleSessionRequest}>
                <div className="session-request-form-container">
                    <div className="request-input-field">
                        <input
                            id="name"
                            type="text"
                            name="name"
                            autoComplete="off"
                            aria-label={t('NAME')}
                            onChange={changeForm}
                            value={formData.name}
                            placeholder={t('NAME')}
                        />
                    </div>
                    <div className="form-row-group">
                        <div
                            className={`request-input-field ${
                                !formData.email ? false : invalidEmail && 'request-input-error'
                            }`}
                        >
                            <input
                                id="email"
                                type="text"
                                name="email"
                                aria-label={t('EMAIL')}
                                value={formData.email}
                                onChange={changeForm}
                                placeholder={t('EMAIL')}
                            />
                            {!formData.email
                                ? false
                                : invalidEmail && (
                                    <span className="span-email-error">
                                        * {t('INVALID_MAIL')}
                                    </span>
                                )}
                        </div>

                        <div className="phone-row-container">
                            <PhoneInput
                                country={defaultCountry}
                                onChange={changePhone}
                                value={formData.phone}
                                autoFormat={true}
                                countryCodeEditable={false}
                                preferredCountries={preferredCountries}
                            />
                        </div>
                    </div>

                    <div className="select-row-group">
                        <SelectWrapper
                            name="day"
                            className="session-request-select-day"
                            isClearable={false}
                            value={formData.day.value ? {
                                label: t(formData.day.value),
                                value: formData.day.value
                            } : null}
                            onChange={changeSelectForm}
                            options={weekDays().filter((item) =>
                                availableDays.includes(item.value)
                            )}
                            placeholder={t('CHOOSE_A DAY')}
                            style={{
                                placeholder: (baseStyles) => ({
                                    ...baseStyles,
                                    fontWeight: 'bold',
                                    fontSize: '14px',
                                }),
                                menuList: (baseStyles) => ({
                                    ...baseStyles,
                                    maxHeight: '160px'
                                }),
                            }}
                        />

                        <SelectWrapper
                            name="turn"
                            className="session-request-select-turn"
                            isClearable={false}
                            value={formData.turn.value ? {
                                label: t(formData.turn.value),
                                value: formData.turn.value
                            } : null}
                            options={turnsOrder}
                            placeholder={t('CHOOSE_A TURN')}
                            onChange={changeSelectForm}
                            style={{
                                placeholder: (baseStyles) => ({
                                    ...baseStyles,
                                    fontWeight: 'bold',
                                    fontSize: '14px',
                                }),
                                menuList: (baseStyles) => ({
                                    ...baseStyles,
                                    maxHeight: '160px'
                                }),
                            }}
                        />

                        <SelectWrapper
                            name="sessionType"
                            className="session-request-select-type"
                            isClearable={false}
                            value={formData.sessionType.value ? {
                                label: t(formData.sessionType.value),
                                value: formData.sessionType.value
                            } : null}
                            options={sessionTypes().filter((item) => {
                                if (coachSessionType === 'BOTH') {
                                    return sessionTypes();
                                }
                                return item.value === coachSessionType;
                            })}
                            placeholder={t('CHOOSE_SESSION_TYPE')}
                            onChange={changeSelectForm}
                            style={{
                                placeholder: (baseStyles) => ({
                                    ...baseStyles,
                                    fontWeight: 'bold',
                                    fontSize: '14px',
                                }),
                                menuList: (baseStyles) => ({
                                    ...baseStyles,
                                    maxHeight: '160px'
                                }),
                            }}
                        />
                    </div>

                    {showMessage && (
                        <DisplayMessage type="error" message="Erro ao enviar mensagem" />
                    )}

                    <button
                        type="submit"
                        aria-label={t('REQUEST_YOUR_EXPERIMENTAL_SESSION')}
                        className="session-request-submit"
                        disabled={disableButton || isLoading}
                    >
                        <span>{t('REQUEST_YOUR_EXPERIMENTAL_SESSION')}</span>
                        {isLoading && <SpinnerLoad />}
                    </button>
                </div>
            </form>
            {isLoading ? <Loader className='loaderComponent' /> : 
                <ConfirmationMensage
                    error={error}
                    showModal={showModal}
                    setShowModal={setShowModal}
                    isLoading={isLoading}
                    onClose={closeConfirm}
                />}
        </section>
    );
}

function DisplayMessage({ type, message }) {
    return (
        <div className={`dialog-message message-${type}`}>
            <p>{message}</p>
        </div>
    );
}

function ConfirmationMensage({error, showModal, isLoading, onClose, setShowModal}) {
    const { t } = useTranslation();
    const prevent = (e) => {
        if (!isLoading) {
            onClose(e);
        }
    };
    
    return (
        <Modal  show={showModal} onClose={onClose} wrapClose={true} >
            <div onClick={prevent} className='confirmationMensageSuccses'>
                <span className='x-close'>X</span>
                <IconSvg
                    classDiv={`confirm-icon ${
                        error ? 'icon-error' : 'icon-success'
                    }`}
                    width={error ? 100 : 100}
                    height={error ? 40 : 120}
                    name={error ? 'dark-close' : 'confirm-icon'}
                />
                {error
                    ? <p>{t('MESSAGE_NOT_SENT_TRY_AGAIN')}</p>
                    : <p>{t('MESSAGE_SENT_SUCCESSFULLY')} <br/> {t('MESSAGE_SENT_SUCCESSFULLY_L2')}</p>                        
                }
            </div>
           
        </Modal>
    );
}
