import React from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import {Button, FileUploader, Modal} from '../../../../../commons';
import {canvasToArrayBuffer, canvasWithImage} from '../../../../../../utils/image-utils';

export default class ImageEditor extends React.Component {

    constructor() {
        super();

        this.state = {
            crop: null,
            dataURI: null,
        };

        this.handleCropChange = this.handleCropChange.bind(this);
        this.handleImageLoading = this.handleImageLoading.bind(this);
        this.handleImageLoaded = this.handleImageLoaded.bind(this);
    }

    handleCropChange(crop) {
        this.setState({crop});
    }

    handleImageLoading(dataURI) {
        this.setState({dataURI});
    }

    handleImageLoaded(image) {
        const initialSide = Math.min(image.width, image.height);
        this.setState({
            crop: {
                x: (100 - Math.round(100 * initialSide / image.width)) / 2,
                y: (100 - Math.round(100 * initialSide / image.height)) / 2,
                width: 100 * initialSide / image.width,
                height: 100 * initialSide / image.height,
                aspect: 1,
            },
        });
    }

    handleSave(image) {
        getCroppedImg(image, this.state.crop)
            .then(imageUrl => this.props.onSave(imageUrl, image));
    }

    render() {
        const {onCancel, srcOrBlob} = this.props;
        const {crop, dataURI} = this.state;
        const image = dataURI || srcOrBlob;

        return (
            <Modal show={true} onClose={onCancel}>
                <div className='home-container'>
                    <div className='modal-content modal-edit-avatar'>
                        <div className='modal-content'>

                            <div className='box-title-modal'>
                                <i className='icon-edit-avatar'/>
                                <h4>Editar Perfil</h4>
                            </div>

                            <ReactCrop
                                src={image} crop={crop}
                                style={{'maxWidth': '400px'}}
                                imageStyle={{'maxWidth': '100%', 'height': 'auto'}}
                                onChange={this.handleCropChange}
                                onImageLoaded={this.handleImageLoaded}
                                keepSelection/>
                            <div className='modal-footer button-block'>
                                <Button size='small' text='Cancelar' type='warning' click={onCancel}/>
                                <FileUploader text='Carregar foto' handleFileChange={this.handleImageLoading} setCurrentUri={this.handleImageLoaded}/>
                                <Button
                                    size='small' text='Salvar' type='warning'
                                    click={() => this.handleSave(image)}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

const MAX_AVATAR_SIZE = {width: 600, height: 600};

async function getCroppedImg(image, crop, fileName = 'avatar') {
    const canvas = await canvasWithImage(image, {crop, maxImgSize: MAX_AVATAR_SIZE});
    return canvasToArrayBuffer(canvas, fileName);
}
