import React from 'react';

import SlickSlider from '../../../../../../components/SlickCarousel/slickCarousel';
import TestimonialCard from './testimonial-card/testimonial-card';
import IconSvg from '../../../../../../utils/svg';
import TitleSection from '../../../../../../components/TitleSection/TitleSection';

import './testimonial.css';
import { useTranslation } from 'react-i18next';

function Testimonial({ onClick, testimonies, themeColor }) {
    const {t} = useTranslation();

    if (!testimonies || !testimonies.length) {
        return null;
    }

    const settings = {
        dots: false,
        infinite: false,
        arrows: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <IconSvg name='icon-arrow' width='24px' color={themeColor} classDiv='arrowPrev' />,
        nextArrow: <IconSvg name='icon-arrow' width='24px' color={themeColor} classDiv='arrowNext' />,
    };

    return (
        <article
            className="template--7-testimonial"
            id="testimonies"
            onClick={() => onClick('testimonies')}>
            <span className="testimonial-icon-1">
                <IconSvg name="icon-circle-quotation-marks" width={200} color="#D4D4D4" />
            </span>

            <div className='template--7-testimonial-container'>
                <TitleSection text1={t('TESTIMONY')} colorIcon={themeColor} iconView={true} iconName='icon-text-effect' iconWidth='39px' />


                <SlickSlider className="generic--slider" settings={settings}>
                    {testimonies.map((testimonial, i) => (
                        <div className="generic--slider-item" key={i}>
                            <TestimonialCard  {...testimonial} />
                        </div>
                    ))}
                </SlickSlider>

            </div>

            <span className="testimonial-icon-2">
                <IconSvg name="icon-circle-quotation-marks" width={200} color="#D4D4D4" />
            </span>
        </article>
    );
}

export default Testimonial;