import React from 'react';

import { InfoCard } from '../index';
import { CarouselComponent, DisableBlock } from '../../../../../commons/index';

import './resume-footer.css';
import { useTranslation } from 'react-i18next';

const createFooterInfoArray = ( coaching, lectures, courses, isEdit, onchange, coachingVisibility, lecturesVisibility, coursesVisibility ) => {
    const {t} = useTranslation();

    let arr = [
        { iconUrl: 'tie', qtd: courses ? courses: 0, isEdit, id: 'febracisCourses', title: t('COURSES'), info: t('FEBRACIS_TRAINING'), onchange, canEdit: true, isVisible: coursesVisibility },
        { iconUrl: 'clock', qtd: coaching, isEdit, id: 'coachingHours', title: 'h', info: t('COACHING_WITH_SCIS'), onchange, isVisible: coachingVisibility },
        { iconUrl: 'microphone', qtd: lectures ? lectures: 0, isEdit, id: 'lecturesHours', title: 'h', info: t('LECTURES_AND_COURSES'), onchange, canEdit: true, isVisible: lecturesVisibility}
    ];
    return arr;
};

const createFooterCards = (coaching, lectures, courses, isEdit, onchange, coachingVisibility, lecturesVisibility, coursesVisibility) => {
    return createFooterInfoArray( coaching, lectures, courses, isEdit, onchange, coachingVisibility, lecturesVisibility, coursesVisibility ).map((e, idx)=> createFooterCard(e, idx));
};

const createFooterCard = (obj, key) => {
    return (<InfoCard  key={key} {...obj } />);
};

const ResumeFooter = ({
    changeVisibility,
    enable,
    coaching,
    coachingVisibility,
    lectures,
    lecturesVisibility,
    courses,
    coursesVisibility,
    isEdit,
    onchange,
}) => {
    return (
        <section className='resume-footer'>
            <div className='resume-footer-content'>                
                <div className='home-container'>
                    <DisableBlock className='resume-disable' id={'enableResumeFooter'} show={ isEdit } click={ changeVisibility } enable={ enable }/>
                    <div className='resume-footer-desk'>
                        { createFooterCards( coaching, lectures, courses, isEdit, onchange, coachingVisibility, lecturesVisibility, coursesVisibility ) }
                    </div>
                    <div className='resume-footer-mobile'>
                        {<CarouselComponent Component={ InfoCard } items={ createFooterInfoArray( coaching, lectures, courses, isEdit, onchange, coachingVisibility, lecturesVisibility, coursesVisibility)} />}
                    </div>
                </div>
            </div>
        </section>
    );};

export default ResumeFooter;