import React from 'react';
import { useTranslation } from 'react-i18next';
import TitleSection from '../../../../../../components/TitleSection/TitleSection';
import { returnStrWithLineBreak } from '../../../../../../utils/common-functions';

import './curriculum.css';

function Curriculum({ resumeFeed, onClick, themeColor }) {
    const {t} = useTranslation();

    return (
        <article
            className="template--5--curriculum"
            onClick={() => onClick('resume')}
            id="resume" >
            <TitleSection text1={t('CURRICULUM')} iconView={true} colorIcon={themeColor} />
            <p className="description">
                {[...returnStrWithLineBreak(resumeFeed, '\n')]}
            </p>

        </article>
    );
}

export default Curriculum;