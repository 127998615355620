import { startCase } from 'lodash';
import React, { Fragment } from 'react';
import IconSvg from '../../../../../../../utils/svg';
import './biography-contact.css';

function BiographyContact({
    expertises,
    tel,
    email,
    location,
    themeColor
}) {
    return (
        <Fragment>
            <div className="biography-contact--6">
                <div className="expertises">
                    <h4>Áreas de atuação: </h4>
                    <p> {expertises.map((item, index) => (`${index ? index === expertises.length - 1 ? ' e' : ',' : ''} ${startCase(item)}`))} </p>
                </div>
                <ContactItem iconName="icon-message" text={email} themeColor={themeColor} />
                <ContactItem iconName="icon-phone" text={tel} themeColor={themeColor} />
                <ContactItem iconName="icon-pin" text={location} themeColor={themeColor} />
            </div>
        </Fragment>
    );
}

const ContactItem = ({ iconName, text, themeColor }) => text ? (
    <section className="item-contact">
        <IconSvg name={iconName} width={30} height={30} color={themeColor} />
        <p>{text}</p>
    </section>
) : null;

export default BiographyContact;