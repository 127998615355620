import React, { Component } from 'react';
import Fab from '../float-button/float-button';

import './floating-menu.css';

class FloatingMenu extends Component {
    constructor(props) {
        super(props);
        this.changeVisibilityContainer = this.changeVisibilityContainer.bind(this);
    }

    generateFabChildren(array) {
        return array.map(({icon, click, tip, disable, link , color}, i)=> {
            return <Fab link={ link } tooltip={tip} key={i} color={color || 'green' } icon={ icon ? icon : 'fa-plus'} click={ click } disable={disable} />;
        });
    }

    changeVisibilityContainer() {
        if ( this.props.defaultFunction ) { this.props.defaultFunction(); }
    }
    
    render() {
        const { color, items, toogleControl } = this.props;
        return (
            <nav className={`btn-container ${ toogleControl ? 'show': ''}`}>
                { this.generateFabChildren(items, color) }
                <Fab color={ color || 'green' } tooltip={`${ toogleControl ? 'Fechar':'Menu'}`} icon={ `${ toogleControl ? 'fa-close' : 'fa-bars' }` } click={ this.changeVisibilityContainer }  />
            </nav>
        );
    }
}

export default FloatingMenu;