import React, { useState } from 'react';
import TitleSection from '../../../../../../components/TitleSection/TitleSection';
import SlickCarousel from '../../../../../../components/SlickCarousel/slickCarousel';
import PhotoCard from './photo-card/photo-card';
import { ZoomedPictureModal } from '../../../../components/gallery/gallery';

import './gallery.css';
import { useTranslation } from 'react-i18next';

function Gallery({ photos, onClick,themeColor }) {

    const {t} = useTranslation();

    if (!photos || !photos.length) {
        return null;
    }

    const [zoomImage, setZoomImage] = useState(null);

    const settings = {
        dots: true,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1260,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            }, {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            }, {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ]
    };

    return (
        <section
            onClick={() => onClick('gallery')}
            className="template--5--gallery"
            id="gallery">

            <div className='template--5--gallery-container'>
                <TitleSection text1={t('GALLERY')} colorIcon={themeColor} iconView={true} />

                <SlickCarousel className="template--5--gallery-slider" settings={settings}>
                    {photos.map((photo, i) => (
                        <div className="generic--slider-item" key={i}>
                            <PhotoCard {...photo} onClick={() => setZoomImage(photo)} />
                        </div>
                    ))}
                </SlickCarousel>

                <ZoomedPictureModal
                    picture={zoomImage}
                    gallery={photos}
                    onClose={() => setZoomImage(null)}
                />
            </div>

        </section>
    );
}


export default Gallery;