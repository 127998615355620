import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Header from '../../components/newHeader';
import Footer from './components/footer';
import MainContent from './components/main-content';
import { asyncSetCookieConsent } from './home.actions';

import './home.css';

function Home({setAcceptedCookies}) {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const now = new Date();
    const expiresDate =  new Date(now.setFullYear(now.getFullYear() + 1));
    const enableCookies = true;

    const cookieData = (expiresDate) => {
        const cookieConsent = true;
        const hostName = window.location.hostname;
        let saveCookieData = {
            'consent': cookieConsent,
            'expires': expiresDate,
            'hostname': hostName,
        };

        return saveCookieData;
    };

    function handleVerifyCookie() {
        cookieData();

        if (document.cookie.includes('CookieConsent')) {
            dispatch(asyncSetCookieConsent());
        }
    }

    return (
        
        <div className='new-home-container'>
            <Header className='new-home-header'/>
            <MainContent />
            {
                enableCookies &&
                    <CookieConsent
                        buttonText={t('ACCEPT_BUTTON')}
                        buttonStyle={
                            {
                                fontSize: '18px',
                                backgroundColor: '#fe7800',
                                color: 'white',
                                padding: '10px 30px',
                            }
                        }
                        style={{padding: '10px'}}
                        setDeclineCookie={false}
                        cookieValue={cookieData(expiresDate.toISOString())}
                        expires={expiresDate}
                        onAccept={() => setAcceptedCookies(handleVerifyCookie())}
                    >
                        <span style={{fontSize: '18px'}}>
                            {t('COOKIES_TEXT')}
                        </span>
                        
                    </CookieConsent>
            }
            <Footer />
        </div>
    );
}

export default Home;
