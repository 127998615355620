export async function canvasWithImage(image, {crop = DEFAULT_CROP, maxImgSize}) {
    const canvas = document.createElement('canvas');

    return new Promise(resolve => {
        const img = new Image();
        if (!isDataURL(image)) {
            img.crossOrigin = 'Anonymous';
        }
        img.onload = function () {
            const pixelCrop = absoluteCrop(crop, img);
            let scale = 1;
            if (maxImgSize) {
                const xScale = pixelCrop.width / maxImgSize.width;
                const yScale = pixelCrop.height / maxImgSize.height;
                scale = Math.max(xScale, yScale, 1);
            }

            canvas.width = Math.floor(pixelCrop.width / scale);
            canvas.height = Math.floor(pixelCrop.height / scale);

            canvas.getContext('2d')
                .drawImage(
                    img,
                    pixelCrop.x,
                    pixelCrop.y,
                    pixelCrop.width,
                    pixelCrop.height,
                    0,
                    0,
                    canvas.width,
                    canvas.height
                );
            resolve(canvas);
        };
        if (!isDataURL(image)) {
            img.src = image + '?q=' + new Date().getTime();
        } else {
            img.src = image;
        }
    });
}

export async function canvasToBlob(canvas, fileName) {
    return new Promise((resolve) => {
        canvas.toBlob(file => {
            file.name = fileName;
            resolve(file);
        }, 'image/jpeg');
    });
}

export async function canvasToArrayBuffer(canvas, fileName) {
    const blob = await canvasToBlob(canvas, fileName);
    return blobToArrayBuffer(blob);
}

function blobToArrayBuffer(blob) {
    return new Promise((resolve) => {
        const fileReader = new FileReader();
        fileReader.onload = function({target}) {
            resolve(target.result);
        };
        fileReader.readAsArrayBuffer(blob);
    });
}

function isDataURL(s) {
    return !!s.match(isDataURL.regex);
}

function absoluteCrop(crop, {width, height}) {
    return {
        x: Math.round(crop.x * width / 100),
        y: Math.round(crop.y * height / 100),
        width: Math.round(crop.width * width / 100),
        height: Math.round(crop.height * height / 100),
    };
}

isDataURL.regex = /^\s*data:([a-z]+\/[a-z]+(;[a-z-]+=[a-z-]+)?)?(;base64)?,[a-z0-9!$&',()*+,;=\-._~:@/?%\s]*\s*$/i;

const DEFAULT_CROP = {
    x: 0,
    y: 0,
    width: 100,
    height: 100,
};
