import React from 'react';
import { useTranslation } from 'react-i18next';
import IconSvg from '../../../../../../utils/svg';
import './coach-numbers.css';

function CoachNumbers({
    coachingHours,
    enableCoachingHours,
    febracisCourses,
    enableFebracisCourses,
    lecturesHours,
    enableLecturesHours,
}) {
    const {t} = useTranslation();

    return (
        <section className="template--6--coach-numbers">
            <div className='template--6--coach-numbers-content'>
                { enableFebracisCourses &&
                    <CoachNumberItem
                        iconName={'icon-graduated'}
                        label={t('FEBRACIS_TRAINING_COURSES')}
                        value={febracisCourses}
                    />
                }

                { enableCoachingHours && 
                    <CoachNumberItem
                        iconName={'icon-clock'}
                        label={t('COACHING_HOURS_SCIS')}
                        value={coachingHours}
                    />
                }

                { enableLecturesHours && 
                    <CoachNumberItem
                        iconName={'icon-speaker'}
                        label={t('HOURS_OF_COURSE_AND_LECTURES')}
                        value={lecturesHours}
                    />
                }
            </div>
        </section>
    );
}


const CoachNumberItem = ({ value, iconName, label }) => value ? (
    <div className="item-info">
        <IconSvg
            classDiv="icon-item"
            name={iconName}
            width={50}
            height={50}
            color={'#9A9A9A'} />
        <div className="coach-infos">
            <p className="number-info">{value}</p>
            <p className="title-info">{label}</p>
        </div>
    </div>
) : null;

export default CoachNumbers;