import React, { useEffect, useState } from 'react';
import {Router, Route, Switch, Redirect, withRouter} from 'react-router-dom';

import {
    Home,
    Help,
    Blog,
    Coach,
    Login,
    page404,
    page409,
    CoachesList,
    CustomizePage,
    ExpSessionRequest,
    StepsForms
} from '../index';
import PrivateRoute from '../../components/PrivateRoute/PrivateRoute';
import withAnalytics, {initAnalytics} from '../../utils/analytics';
import {getCookie} from '../../utils/common-functions';
import PostContent from '../blog/components/postPage';

import './App.css';

if (
    (JSON.parse(decodeURIComponent(getCookie('CookieConsent') || '{}'))).consent &&
    new Date().toISOString() < JSON.parse(decodeURIComponent(getCookie('CookieConsent') || '{}')).expires
) {
    initAnalytics('G-R4VH6JF1KS');
}

const App = (props) => {
    const[acceptedCookies, setAcceptedCookies] = useState(false);
    
    useEffect(() => {
        if (
            (JSON.parse(decodeURIComponent(getCookie('CookieConsent') || '{}'))).consent &&
                new Date().toISOString() < JSON.parse(decodeURIComponent(getCookie('CookieConsent') || '{}')).expires
        ) {
            initAnalytics('G-R4VH6JF1KS');
        }
    },[acceptedCookies]);

    
    return (<div className='App'>
        <Router history={props.history}>
            <Switch>
                <Route exact path='/home' component={(props) => <Home setAcceptedCookies={setAcceptedCookies} {...props}/>} />
                <Route exact path='/coaches_list' component={CoachesList} />
                <Route exact path='/search' component={() => <Home isSearch={true} />} />
                <Route exact path='/login' component={Login} />
                <Route exact path='/confirm-exp-session/:requestId' component={ExpSessionRequest} />
                <Redirect from='/logout' to={'/login'} />
                <Route exact path='/not_found' component={page404} />
                <Route exact path='/already_exist' component={page409} />
                <PrivateRoute exact path='/customize' component={CustomizePage} />
                <PrivateRoute exact path='/customize/form' component={StepsForms} />
                <Route exact path='/help' component={Help} />
                <Route exact path='/blog' component={Blog} />
                <Route exact path='/blog/:category' component={Blog} />
                <Route exact path='/blog/post/:slug' component={PostContent} />
                <Route path='/:name/:refreshToken?' component={Coach} />
                <Redirect to={'/home'} />
            </Switch>
        </Router>
    </div>);
};

export default withRouter(withAnalytics(App));
