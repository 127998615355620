import {all, takeEvery, select, call, put} from 'redux-saga/effects';

import {APIroutes, actions, basepathV2} from '../../utils/constants';
import {treatError} from '../../utils/error-utils';
import {axiosPut, userEditRequest} from '../../utils/common-functions';
import {history} from '../../utils/store';
import {returnHeader} from '../coach/coach.saga';
import {endFormLoading, initFormLoading, setCoachDescriptions, setCurrentDescriptionIndex, setDescriptionLoading} from './step-forms.actions';
import OpenAI from '../../utils/openai';
import config from '../../utils/config';
import {coachBioSystemContent, generateBioPromptMaker, proofreadTextPrompMaker, regenerateBioPrompts, withLangPromptMaker} from '../../utils/prompt-utils';

const context = [
    {
        role: 'system',
        content: coachBioSystemContent,
    },
];

function* finishForm({payload}) {
    try {
        yield put(initFormLoading());

        const {description: aboutMe, acting: expertises, name: fullName, email, phone: tel} = payload;
        const parsedExpertises = expertises.map(expertisesMap);

        const headers = yield call(returnHeader);
        yield call(userEditRequest, {
            aboutMe,
            expertises: parsedExpertises,
            fullName,
            email,
            tel,
        }, headers);

        const url = `${basepathV2}${APIroutes.alreadyAccessedSite}`;
        yield call(axiosPut, url, undefined, headers);
        
        const username = yield select(({Coach}) => Coach);
        history.push(`/${username}/edit`);
    } catch (error) {
        treatError(error);
    } finally {
        yield put(endFormLoading());
    }
}

function expertisesMap(item) {
    return item.value;
}

function* generateDescription({payload}) {
    const formData = {...payload, expertises: payload.acting};
    delete formData.acting;
    delete formData.phone;
    delete formData.email;

    try {
        yield put(setDescriptionLoading({isLoading: true, type: 'GENERATING_BIOGRAPHY'}));

        const currentLang = localStorage.getItem(config.currentLang);
        context.push({role: 'user', content: generateBioPromptMaker(formData, currentLang)});

        const openaiConfig = {apiKey: config.openaiApiKey};
        const openaiApi = OpenAI.config(openaiConfig);
        const completion = yield openaiApi.chatCompletion(context);

        context.push({role: 'assistant', content: completion});

        yield put(setCoachDescriptions([completion]));
    } catch(err) {
        treatError(err);
    } finally {
        yield put(setDescriptionLoading({isLoading: false, type: 'GENERATING_BIOGRAPHY'}));      
    } 
}

function* proofreadText({payload}) {
    try {
        yield put(setDescriptionLoading({isLoading: true, type: 'PROOFREADING_BIOGRAPHY'}));

        const currentLang = localStorage.getItem(config.currentLang);
        const prompt = proofreadTextPrompMaker(payload, currentLang);

        const openaiConfig = {apiKey: config.openaiApiKey};
        const openaiApi = OpenAI.config(openaiConfig);
        const completion = yield openaiApi.completion(prompt, {temperature: 0});

        const descriptions = yield select(({StepForms}) => StepForms.descriptions);
        const currentDescriptionIndex = yield select(({StepForms}) => StepForms.currentDescriptionIndex);

        const updatedDescriptions = [...descriptions];
        updatedDescriptions[currentDescriptionIndex] = completion;

        yield put(setCoachDescriptions(updatedDescriptions));
    } catch(err) {
        treatError(err);
    } finally {
        yield put(setDescriptionLoading({isLoading: false, type: 'PROOFREADING_BIOGRAPHY'}));
    } 
}

function* regenerateText() {
    try {
        yield put(setDescriptionLoading({isLoading: true, type: 'REGENERATING_BIOGRAPHY'}));

        const descriptions = yield select(({StepForms}) => StepForms.descriptions);
        const regenerateAttempt = descriptions.length;
        const promptIndex = regenerateAttempt - 1;
        
        const openaiConfig = {apiKey: config.openaiApiKey};
        const openaiApi = OpenAI.config(openaiConfig);

        const currentLang = localStorage.getItem(config.currentLang);
        context.push({
            role: 'user',
            content: regenerateBioPrompts[promptIndex].concat(withLangPromptMaker(currentLang)),
        });
        
        const completion = yield openaiApi.chatCompletion(context, {temperature: 0.5});
        context.push({role: 'assistant', content: completion});

        const updatedDescriptions = [...descriptions, completion];

        yield put(setCoachDescriptions(updatedDescriptions));
        yield put(setCurrentDescriptionIndex(updatedDescriptions.length - 1));
    } catch(err) {
        treatError(err);
    } finally {
        yield put(setDescriptionLoading({isLoading: false, type: 'REGENERATING_BIOGRAPHY'}));
    } 
}

export default function* mySaga() {
    yield all([
        yield takeEvery(actions.ASYNC_FINISH_FORM, finishForm),
        yield takeEvery(actions.ASYNC_GENERATE_DESCRIPTION, generateDescription),
        yield takeEvery(actions.ASYNC_PROOFREAD_TEXT, proofreadText),
        yield takeEvery(actions.ASYNC_REGENERATE_TEXT, regenerateText),
    ]);
}
