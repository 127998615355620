import React, { useState } from 'react';

import VideoCard from './video-card/video-card';
import TitleSection from '../../../../../../components/TitleSection/TitleSection';
import SlickCarousel from '../../../../../../components/SlickCarousel/slickCarousel';
import { ZoomedVideoModal } from '../../../../components/videos/videos';

import './videos.css';
import { useTranslation } from 'react-i18next';

function Videos({ videos, onClick, themeColor }) {

    const {t} = useTranslation();

    if (!videos || !videos.length) {
        return null;
    }

    const [zoomVideo, setZoomVideo] = useState(null);

    function getQtdSlides(minLenght) {
        return videos.length < minLenght ? videos.length : minLenght;
    }

    const settings = {
        dots: true,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: getQtdSlides(4),
        slidesToScroll: 1,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1260,
                settings: {
                    slidesToShow: getQtdSlides(3),
                    slidesToScroll: 1,
                }
            }, {
                breakpoint: 1024,
                settings: {
                    slidesToShow: getQtdSlides(2),
                    slidesToScroll: 1,
                }
            }, {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ]
    };

    return (
        <section
            className="template--5--videos"
            id="videos"
            onClick={() => onClick('videos')}>

            <div className='template--5-videos-container'>
                <div className='template--5-videos-content'>
                    <TitleSection text1={t('VIDEOS')} colorIcon={themeColor} iconView={true}/>

                    <SlickCarousel className="generic--slider" settings={settings}>
                        {videos.map((video, i) => (
                            <div className="generic--slider-item" key={i}>
                                <VideoCard {...video} onClick={() => setZoomVideo(video)} />
                            </div>
                        ))}
                    </SlickCarousel>

                    <ZoomedVideoModal
                        video={zoomVideo}
                        onClose={() => setZoomVideo(null)}
                    />
                </div>
            </div>

        </section>
    );
}

export default Videos;