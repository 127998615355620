import React from 'react';
import './curriculum-info.css';

function CurriculumInfo({
    resumeFeed
}) {
    return (
        <article className="curriculum-info">
            <p>{resumeFeed}</p>
        </article>
    );
}

export default CurriculumInfo;