import React from 'react';
import { useTranslation } from 'react-i18next';
import { goesTo } from '../../../../../../../utils/common-functions';
import IconSvg from '../../../../../../../utils/svg';
import SocialMedias from '../../../../components/social-medias/social-medias';

import './nav-bar-mobile.css';

function navBarMobile({ tel, onClose, facebook, linkedin, instagram, twitter,whatsapp, youtube, itemsNav }) {

    const {t} = useTranslation();

    function onSelectSection(url) {
        goesTo(url);
        onClose();
    }

    return (
        <nav className={'header--menu-mobile'}>
            <div className='header--menu-mobile-container'>
                <div className='close-btn' onClick={() => onClose()}></div>
                <ul className='header--menu-list'>
                    {itemsNav.map(({ key, url }) => (
                        <li key={url} onClick={() => onSelectSection(url)}>
                            {t(key)}
                        </li>
                    ))}
                </ul>

                <h2 className="phone">
                    <IconSvg name="icon-whatsapp" width={20} height={20} color="#fff" />
                    {tel}
                </h2>

                <SocialMedias
                    facebook={facebook}
                    linkedin={linkedin}
                    instagram={instagram}
                    twitter={twitter}
                    whatsapp={whatsapp}
                    youtube={youtube}
                    direction="row" />
            </div>
        </nav>
    );
}

export default navBarMobile;