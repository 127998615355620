import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { selectTemplate, selectTemplateColor, editAction, addBannerImage, initUser, toogleConfig, toogleBackground, closeChooseBackgroundModal, closeConfigModal, ViewModify, toogleFab, loadExpertiseAreas, changeUser, changeCurrentSection, loadCountries, asyncVerifyIfUsernameIsAvailable, setPublishProfile, getUser } from '../../coach.actions';
import { themeSetting } from '../../../../utils/constants';
import Template5 from '../../templates/template-5/template-5';
// import EditCoachee from '../../edit-coachee/edit-coachee';
import { updateAvatar, editProfile } from '../header/header.action';
import { addVideo, editVideo, deleteVideo } from '../videos/videos.action';
import { addTestimony, deleteTestimony, editTestimony } from '../testimonies/testimonies.action';
import { editPictureCaption, addPicture, deletePicture } from '../gallery/gallery.action';
import LazyImport from '../../../../LazyImport';
import Template6 from '../../templates/template-6/template-6';
import Template7 from '../../templates/template-7/template-7';
import OldTemplate from '../../templates/oldTemplate';

const Edit = LazyImport({
    loader: () => import('../../edit-coachee/edit-coachee')
});

class CurrentTemplate extends Component {

    returnTemplate(user) {
        switch (user.theme) {
        case 'template-1':
        case 'template-2':
        case 'template-3':
        case 'template-4':
            return (
                <OldTemplate themeSettingSelected={themeSetting[user.theme]} themeColor={user.themeColor} />
            );

        case 'template-5':
            return (
                <Template5 {...this.props} />
            );

        case 'template-6':
            return (
                <Template6 {...this.props} />
            );

        case 'template-7':
            return (
                <Template7 {...this.props} />
            );

        default:
            return (
                <Template5 {...this.props} />
            );
        }
    }

    render() {
        const { user, canEdit } = this.props;

        return (
            <div
                style={{ paddingRight: canEdit ? 40 : 0 }}
                className={`page-coach-container ${user.theme ? user.theme : 'template-5'}`}>
                {this.returnTemplate(user, this.props)}
                {canEdit && <Edit {...this.props} />}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    canEdit: state.Coach.canEdit,
    isLoading: state.Coach.isLoading,
    showConfig: state.Coach.showConfig,
    showBackground: state.Coach.showChangeBackground,
    viewMode: state.Coach.viewMode,
    user: state.Coach.user,
    disableSave: state.Coach.disableSave,
    username: state.Coach.username,
    fabToogle: state.Coach.fabToogle,
    contact: state.Contact,
    currentSection: state.Coach.currentSection,
    countryList: state.Coach.countryList,
    country: state.Coach.country,
    disableSaveConfig: state.Coach.disableSaveConfig,
    searchingUsername: state.Coach.searchingUsername,
    isExperimentalSession: state.Coach.user.enableSiteExperimentalSession,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    editAction,
    initUser,
    toogleConfig,
    toogleBackground,
    closeChooseBackgroundModal,
    closeConfigModal,
    ViewModify,
    toogleFab,
    loadExpertiseAreas,
    selectTemplate,
    selectTemplateColor,
    changeUser,
    changeCurrentSection,
    updateAvatar,
    loadCountries,
    addVideo,
    editVideo,
    deleteVideo,
    addTestimony,
    deleteTestimony,
    editTestimony,
    addPicture,
    addBannerImage,
    editPictureCaption,
    deletePicture,
    asyncVerifyIfUsernameIsAvailable,
    editProfile,
    setPublishProfile,
    getUser

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CurrentTemplate);