import i18next from 'i18next';
import React, {useState} from 'react';
import config from '../../utils/config';

import SelectWrapper from '../Select';
import './index.css';

const languageOptions = (className) => [
    {label: `${className === 'template-6' ? 'PT-Br': 'Pt-br'}`, value: 'pt'},
    {label: `${className === 'template-6' ? 'EN' : 'En'}`, value: 'en'},
    {label: `${className === 'template-6' ? 'ES' : 'Es'}`, value: 'es'},
];

function SelectLanguage({onChange, className, customFontStyle, isWhiteColor}) {
    const currentPath = window.location.pathname;
    const [lang, setLang] = useState(initialLangValue(className));

    function handleChange(option) {
        i18next.changeLanguage(option.value);
        setLang(option);

        if (onChange) {
            onChange(option);
        }
    }

    return (
        <SelectWrapper
            isClearable={false}
            value={lang}
            options={languageOptions(className)}
            onChange={handleChange}
            style={style(currentPath, className, customFontStyle, isWhiteColor)}
            className={className}
            isSearchable={false}
        />
    );
}

const initialLangValue = (className) => {
    const saved = localStorage.getItem(config.currentLang);
    if (saved) {
        return languageOptions(className).find(lang => lang.value === saved);
    } else {
        return languageOptions(className)[0];
    }
};

const style = (currentPath, className, customFontStyle, isWhiteColor) => {
    const isHome = currentPath === '/home';
    const isListCoaches = currentPath === '/coaches_list';
    const themeColor = customFontStyle || styleColor.find((e) => e.key === className);

    const hasThemeColorFont = themeColor && themeColor.fontFamily;

    return {
        control: (styles) => ({
            ...styles,
            backgroundColor: 'transparent',
            color: 'white',
            border: 'none',
            boxShadow: 'none',
        }),
        singleValue: (styles) => (
            {
                ...styles,
                color: isHome || isWhiteColor ? 'white' : themeColor ? themeColor.color : 'black',
                fontFamily: `${isHome || isListCoaches ? 'Montserrat' : (hasThemeColorFont || '')}`,
                fontSize: `${className === 'template-7' ? '20px' : ''}`,
                overflow: `${className === 'template-6' ? 'unset' : ''}`
            }),
    };
};
const styleColor = [
    {key: 'template-1', color: '#FFF', fontFamily: ''},
    {key: 'template-2', color: '#636363', fontFamily: ''},
    {key: 'template-3', color: '#636363', fontFamily: ''},
    {key: 'template-4', color: '#636363', fontFamily: ''},
    {key: 'template-5', color: '#FFF', fontFamily: ''},
    {key: 'template-6', color: '#585858', fontFamily: ''},
    {key: 'template-7', color: '#262d52', fontFamily: 'Cabin'},
];

export default SelectLanguage;
