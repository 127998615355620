import React from 'react';

import BenefitsSection from '../benefits-section';
import FeaturedCoachesSection from '../featured-coaches-section';
import IntroSection from '../intro-section';
import SearchSection from '../search-section';
import TransformationAreasSection from '../transformation-areas-section';
import CategorySection from '../category-section';
import ContactSection from '../contact-us-section';

import './main-content.css';
import PublishedArticlesSection from '../published-articles';

function MainContent() {
    return (
        <main className='main-content'>
            <IntroSection />
            <SearchSection />
            <BenefitsSection />
            <FeaturedCoachesSection />
            <TransformationAreasSection />
            <CategorySection />
            <PublishedArticlesSection />
            <ContactSection />
        </main>
    );
}

export default MainContent;
