import React from 'react';
import { useTranslation } from 'react-i18next';
import TitleSection from '../../../../../../components/TitleSection/TitleSection';
import ContactForm from '../../../components/contact-form';
import './contact.css';

function Contact({ onClick, username, themeColor }) {
    const {t} = useTranslation();

    return (
        <section
            id="contact"
            className="template--7-contact"
            onClick={() => onClick('contact')}
            style={{backgroundColor: themeColor}}
        >
            <TitleSection
                text1={t('ENTER_IN')}
                text2={t('CONTACT')}
                style={{color: '#ffffff'}}
                text2Style={{color: '#ffffff'}}
                colorIcon='#ffffff'
                iconView={true}
                iconName='icon-text-effect'
                iconWidth='39px'
            />
            <ContactForm username={username} />
        </section>
    );
}

export default Contact;