import React from 'react';

import InputMask from 'react-input-mask';

const Input = ({ type, classname, id, onchange, value, minlength, maxlength }) => {
    switch( type ) {
    case 'text':
        return (
            <InputMask type='text' maxLength={ maxlength } minLength={ minlength }  className={ classname } id={ id } onChange={ onchange } value={ value } />
        );
    
    case 'tel':
        return (
            <InputMask maxLength={ 16 } mask={ '(99) 99999-9999' } className={ classname } id={ id } onChange={ onchange } value={ value } /> 
        );

    case 'number':
        return (
            <InputMask type='number' maxLength={ maxlength } minLength={ minlength } className={ classname } id={ id } onChange={ onchange } value={ value } /> 
        );

    case 'email':
        return (
            <div>
                <InputMask type='email'  className={ classname } id={ id } onChange={ onchange } value={ value }></InputMask>
                { value.length !== 0 && !(/[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*/g).test(value) &&
                        <div style={{ color: 'red', fontSize: '.6em'}}>O campo deve ser preenchido com um email válido</div>
                }
            </div>
        );
        
    default:
        return (
            <InputMask type='text' maxLength={ maxlength } minLength={ minlength }  className={ classname } id={ id } onChange={ onchange } value={ value } />
        );
    }
};

export default Input;