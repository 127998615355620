import React from 'react';
import './effect-message.css';
import { returnStrWithLineBreak } from '../../../../../../../utils/common-functions';

function EffectMessage({ mainPhraseAuthor, mainPhrase }) {
    return (
        <section className="template--5--effect-message">
            <p className="phrase"
                placeholder="Insira uma frase de efeito">
                {returnStrWithLineBreak(mainPhrase)}
            </p>
            
            <span className="autor">
                {mainPhraseAuthor}
            </span>
        </section>
    );
}

export default EffectMessage;
