import React, { Component } from 'react';
import 'react-responsive-modal/lib/react-responsive-modal.css';
import Modal from 'react-responsive-modal/lib/css';

import './custom-modal.css';

class CustomModal extends Component {

    render() {
        const { show, close, title, icon, children, className } = this.props;

        return (
            <Modal open={ show } showCloseIcon={ false }  onClose={ close }>
                <div className={`header-modal ${className}`}>
                    <span className='title'>{ icon ? <i className={`fa ${icon}`} />: null }  { title }</span>
                    <span className='closeIcon' onClick={ close }><i className='fa fa-close' /></span>
                </div>

                {   
                    React.Children.toArray(children).map((child, i) => {
                        if ( i === 0 ) {
                            return (
                                <div key={i} className={`body-modal ${className}`}>
                                    { child }
                                </div>
                            );
                        } else {
                            return (
                                <div key={i} className={`footer-modal ${ className }`}>
                                    { child }
                                </div>
                            );
                        }
                    })
                }
            </Modal>
        );
    }
    
}

export default CustomModal;