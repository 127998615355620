import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './screens/app/App';
import Store, { history } from './utils/store';
import { unregister } from './registerServiceWorker';
import { ConnectedRouter } from 'connected-react-router';
import {ToastContainer} from 'react-toastify';

import './index.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-responsive-carousel/lib/styles/carousel.css';
import './utils/polyfill-toblob';
import 'core-js/es6/';
import 'core-js/es7/';
import 'url-polyfill';
import './assets/i18n/i18n';
import 'react-toastify/dist/ReactToastify.css';

ReactDOM.render(
    <Provider store={ Store }>
        <ConnectedRouter history={history}>
            <React.Fragment>
                <App history={history} />
                <ToastContainer />
            </React.Fragment>
        </ConnectedRouter>
    </Provider>, document.getElementById('root'));
    
unregister();
