import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {useSelector} from 'react-redux';

import CredentialsHelper from '../../utils/credentials-helper';

export default function PrivateRoute(props) {
    const loggedIn = CredentialsHelper.isLoggedIn();
    const reduxAccessToken = useSelector(({Coach}) => Coach.token);

    if (!loggedIn || !reduxAccessToken) {
        return <Redirect to='/login' />;
    }

    return (
        <Route {...props} />
    );
}
