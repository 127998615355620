import React from 'react';
import NavBar from './nav-bar/nav-bar';
import EffectMessage from './effect-message/effect-message';

import './banner.css';

function Banner({
    enableBiography,
    enableResume,
    enableVideos,
    enableTestimonies,
    enableGallery,
    facebook,
    linkedin,
    instagram,
    twitter,
    whatsapp,
    youtube,
    gallery,
    testimonies,
    videos,
    backgroundImg,
    mainPhrase,
    mainPhraseAuthor,
    tel,
    firstName,
    onClick,
}) {
    return (
        <section
            onClick={() => onClick('home')}
            id={'home'}
            className="template--6--banner">

            <div className="content">
                <NavBar
                    enableBiography={enableBiography}
                    enableResume={enableResume}
                    enableVideos={enableVideos}
                    enableTestimonies={enableTestimonies}
                    enableGallery={enableGallery}
                    firstName={firstName}
                    tel={tel}
                    facebook={facebook}
                    linkedin={linkedin}
                    instagram={instagram}
                    twitter={twitter}
                    whatsapp={whatsapp}
                    youtube={youtube}
                    gallery={gallery}
                    testimonies={testimonies}
                    videos={videos}
                />
                <div className="background-image">
                    <section
                        className="background-image-blur"
                        style={{ backgroundImage: `url(${window.innerWidth > 500 ? backgroundImg.desktop : backgroundImg.desktop})` }} />
                    <EffectMessage
                        mainPhrase={mainPhrase}
                        mainPhraseAuthor={mainPhraseAuthor} />
                </div>

            </div>
        </section>
    );
}

export default Banner;