import React from 'react';
import TitleSection from '../../../../../../components/TitleSection/TitleSection';
import SlickSlider from '../../../../../../components/SlickCarousel/slickCarousel';
import TestimonialCard from './testimonial-card/testimonial-card';
import IconSvg from '../../../../../../utils/svg';

import './testimonial.css';
import { useTranslation } from 'react-i18next';

function Testimonial({ testimonies, onClick, themeColor }) {
    const {t} = useTranslation();

    if (!testimonies || !testimonies.length) {
        return null;
    }

    const settings = {
        dots: false,
        infinite: false,
        arrows: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <IconSvg name='icon-arrow' width='24px' color={themeColor} classDiv='arrowPrev' />,
        nextArrow: <IconSvg name='icon-arrow' width='24px' color={themeColor} classDiv='arrowNext' />,
    };

    return (
        <article
            onClick={() => onClick('testimonies')}
            id="testimonies"
            className="template--6-testimonial">
            <div className='template--6-testimonial-container'>
                <TitleSection text1={t('WHAT_DO_YOU_THINK_OF_ME')} text2={t('TESTIMONY')} />

                <span className="testimonial-icon">
                    <IconSvg name="icon-circle-quotation-marks" width={60} height={60} color={themeColor} />
                </span>

                <SlickSlider className="generic--slider" settings={settings}>
                    {testimonies.map((testimonial, i) => (
                        <div className="generic--slider-item" key={i}>
                            <TestimonialCard  {...testimonial} />
                        </div>
                    ))}
                </SlickSlider>
            </div>
        </article>
    );
}

export default Testimonial;