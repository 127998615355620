import React from 'react';

import IconSvg from '../../../../utils/svg';
import imgDefault from '../../../../img/bg-coaches.min.webp';
import {history} from '../../../../utils/store';

import './intro-section.css';
import { useTranslation } from 'react-i18next';

function IntroSection() {
    const {t} = useTranslation();

    return (
        <section className='home-intro'>
            <LeftContent t={t} />
            <RightContent t={t} />
        </section>
    );
}

function redirectToSearchSection() {
    history.push('home#search_coach');
    document.getElementById('search-coach').scrollIntoView({behavior: 'smooth'});
}

function LeftContent({t}) {
    const [mainParagraph, highlightedWord] = t('INTRO_MAIN_PARAGRAPH').split('*');

    return (
        <div className='left-content'>
            <IconSvg name='icon-oseucoach' classDiv='icon-oseucoach' />

            <p className='main-paragraph'>
                {mainParagraph}
                <span>{highlightedWord}</span>
            </p>
            <p className='sub-paragraph'>
                {t('INTRO_SUB_PARAGRAPH')}
            </p>
            <button type='button' className='btn-find-your-coach' onClick={redirectToSearchSection}>
                {t('INTRO_BTN_FIND_YOUR_COACH')}
            </button>
        </div>
    );
}

function RightContent() {
    return (
        <div className='right-content'>
            <img
                src={imgDefault}
                alt='coaches-banner' 
                className='people-img'/>
        </div>
    );
}

export default IntroSection;
