import React from 'react';

import './float-button.css';

const FloatingButton = (props) => (
    <button disabled={props.disable} title={props.tooltip} className={`fab-wrapper ${props.disable ? 'disable': ''} ${props.color || ''}`} onClick={ props.click }>
        { !props.link && 
            <i className={`fa ${props.icon}`} />
        }
        { props.link && 
            <a target='_blank' href={props.link} rel='noreferrer'>
                <i className={`fa ${props.icon}`} />
            </a>
        }

        { (props.link && props.download) &&
            <a href={props.link} download>
                <i className={`fa ${props.icon}`} />
            </a>
        }
        { props.tooltip && 
            <span className='fab-tooltip'>{ props.tooltip }</span>
        }
    </button>
);

export default FloatingButton;