import React, { Component } from 'react';
import { connect } from 'react-redux';

import MenuHeader from './components/menu-header/menu-header';

import { ITEMS_NAV, SESSION_ITEM_NAV } from '../../../utils/constants';
import { goesTo } from '../../../utils/common-functions';

import './navbarMenu.css';
import SelectLanguage from '../../../components/SelectLanguage';
import { initSearchingOptions } from '../../home/home.actions';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

class NavbarMenu extends Component {

    renderWithName = (array, user) => {
        let firstArray = this.renderItems(array.slice(0, Math.floor(array.length/2) ));
        let secondArray = this.renderItems(array.slice(Math.floor(array.length/2), array.length ));
        let ret = [firstArray].concat([<li key='name' className='header-logo item'><b>{user.firstName}</b><br/>{user.secondName}</li>]).concat([secondArray]);
        return ret;
    }

    renderItems = (array) => {
        const {
            enableBiography,
            enableResume,
            enableGallery,
            enableVideos,
            enableTestimonies,
            gallery,
            testimonies,
            videos
        } = this.props.user;
        const { coachDetails, t } = this.props;

        return array.map((e)=> {
            if (e.name === 'tel ') {
                return true;
            } 
            if (e.name === 'Sobre Mim'){
                if( enableBiography ) {
                    return (
                        <li className='header-item-menu' key={ e.name } >
                            <a href={e.url||'#'} onClick={()=> goesTo(e.url||'#')}>{t('ABOUT_ME') }</a>
                        </li>
                    );
                } else { return true ;}
            }
            if (e.name === 'Currículo'){
                if( enableResume ) {
                    return (
                        <li className='header-item-menu' key={ e.name } >
                            <a href={e.url||'#'} onClick={()=> goesTo(e.url||'#')}>{t('CURRICULUM') }</a>
                        </li>
                    );
                } else { return true ;}
            }
            if (e.name === 'Vídeos'){
                if( enableVideos && videos && videos.length ) {
                    return (
                        <li className='header-item-menu' key={ e.name } >
                            <a href={e.url||'#'} onClick={()=> goesTo(e.url||'#')}>{t('VIDEOS') }</a>
                        </li>
                    );
                } else { return true ;}
            }
            if (e.name === 'Depoimentos'){
                if( enableTestimonies && testimonies && testimonies.length ) {
                    return (
                        <li className='header-item-menu' key={ e.name } >
                            <a href={e.url||'#'} onClick={()=> goesTo(e.url||'#')}>{t('TESTIMONY') }</a>
                        </li>
                    );
                } else { return true ;}
            }
            if (e.name === 'Galeria'){
                if( enableGallery && gallery && gallery.length ) {
                    return (
                        <li className='header-item-menu' key={ e.name } >
                            <a href={e.url||'#'} onClick={()=> goesTo(e.url||'#')}>{t('GALLERY') }</a>
                        </li>
                    );
                } else { return true ;}
            }
            if (coachDetails.enableSiteExperimentalSession) {
                return (
                    <li className='header-item-menu' key={ e.name } >
                        <a href={SESSION_ITEM_NAV.url||'#'} onClick={()=> goesTo(SESSION_ITEM_NAV.url||'#')}>{t('REQUEST_YOUR_EXPERIMENTAL_SESSION') }</a>
                    </li>
                );
            } else {
                return (
                    <li className='header-item-menu' key={ e.name } >
                        <a href={e.url||'#'} onClick={()=> goesTo(e.url||'#')}>{t('CONTACT') }</a>
                    </li>
                );
            }
        });
    }

    handleLanguageChange = (option) => {
        this.props.initSearchingOptions(option.value);
    }

    render() {  
        
        const { user, setToogle, menuOpen, isEdit } = this.props;
        const { theme } = user;

        return (
            <nav className='navbar-menu'>
                <div className='home-container'>
                    <div className='header-logo'><b>{user.firstName ? user.firstName : ''}</b><br/>{user.secondName ? user.secondName: ''}</div>
                    <MenuHeader socialLine={ true } menuOpen={ menuOpen } elements={ ITEMS_NAV } isEdit={ isEdit } user={ user } toogleMenu={ setToogle } />
                    <ul className='header-menu'>
                        { (theme === 'default' || theme !== 'template-3' || !theme ) && this.renderItems( ITEMS_NAV )}
                        { theme === 'template-3' && this.renderWithName( ITEMS_NAV, user )}
                        <li className='select-language'>
                            <SelectLanguage
                                onChange={this.handleLanguageChange}
                                className={theme}
                            />
                        </li>
                    </ul>
                    { user.tel &&
                    <div className='header-whatsapp'><i></i><span><a href={`tel:${user.tel}`}>{user.tel}</a></span></div>
                    }
                    {theme === 'template-3' && <li className='select-language-hamburguer'>
                        <SelectLanguage
                            onChange={this.handleLanguageChange}
                            className={theme}
                        />
                    </li>}
                </div>
            </nav>
        );
    }
}

const mapStateToProps = state => ({
    user: state.Coach.user,
    coachDetails: state.Coach.user,
});

const mapDispatchToProps = {
    initSearchingOptions,
};

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(NavbarMenu);