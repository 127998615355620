import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { validateSocialMediaUrl } from '../../../utils/common-functions';

import './social-line.css';

class SocialLine extends Component {
    render() {
        const { user, t } = this.props;

        return (
            <div className='social-line'>
                <div className='home-container'>
                    { ((user.theme === 'template-3' || user.theme === 'template-4' )) 
                    }
                    {
                        (user.facebook || user.instagram || user.youtube || user.twitter || user.linkedin || user.whatsapp) &&
                        <p>{t('FOLLOW_ME')}:</p>
                    }
                    { user.facebook && <a href={ 'https://' + validateSocialMediaUrl('facebook', user.facebook) } target='_blank' rel='noreferrer'><i className='fa fa-facebook' /></a> }
                    { user.instagram && <a href={ 'https://' + validateSocialMediaUrl('instagram', user.instagram) } target='_blank' rel='noreferrer'><i className='fa fa-instagram' /></a> }
                    { user.youtube && <a href={ 'https://' + validateSocialMediaUrl('youtube', user.youtube) } target='_blank' rel='noreferrer'><i className='fa fa-youtube' /></a> }
                    { user.twitter && <a href={ 'https://' + validateSocialMediaUrl('twitter', user.twitter) } target='_blank' rel='noreferrer'><i className='fa fa-twitter' /></a> }
                    { user.linkedin && <a href={ 'https://' + validateSocialMediaUrl('linkedin', user.linkedin) } target='_blank' rel='noreferrer'><i className='fa fa-linkedin' /></a> }
                    { user.whatsapp && <a href={ 'https://' + validateSocialMediaUrl('whatsapp', user.whatsapp) } target='_blank' rel='noreferrer'><i className='fa fa-whatsapp' /></a> }
                </div>
            </div>
        );
    }
}

export default withTranslation()(SocialLine);