import React, {Component} from 'react';
import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';

import {addPicture, deletePicture, editPictureCaption} from './gallery.action';
import {changeVisibility, changeCurrentSection} from '../../coach.actions';
import {Button, FileUploader, Modal, Carousel, DisableBlock, Placeholder } from '../../../commons';
import ConfirmModal from '../confirm-modal/confirm-modal';
import {canvasToArrayBuffer, canvasWithImage} from '../../../../utils/image-utils';
import { sortArray } from '../../../../utils/common-functions';
import { trackEvent  } from '../../../../utils/analytics';

import './gallery.css';
import { withTranslation } from 'react-i18next';

class Gallery extends Component {

    constructor() {
        super();

        this.state = {
            picture: null,
            vw: viewportWidth(),
            showModal: false,
            caption: '',
            uri: '',
            currentUri: ''
        };

        this.handlePictureZoom = this.handlePictureZoom.bind(this);
        this.handleDeletion = this.handleDeletion.bind(this);
        this.handleEditing = this.handleEditing.bind(this);
        this.handleInclusion = this.handleInclusion.bind(this);
        this.listenResize = this.listenResize.bind(this);
        this.changeCaption = this.changeCaption.bind(this);
        this.savePicture = this.savePicture.bind(this);
        this.setCurrentUri = this.setCurrentUri.bind(this);
    }

    handlePictureZoom(picture) {
        trackEvent('user interaction', 'click in picture', 'choosing a picture');
        this.setState({picture});
    }

    handleDeletion(picture) {
        this.props.deletePicture(picture, this.props.gallery);
    }

    handleEditing(picture) {
        this.props.editPictureCaption(picture, this.props.gallery);
    }

    handleInclusion(dataURI) {
        this.setState({uri: dataURI});
    }

    listenResize() {
        this.setState({vw: viewportWidth()});
    }

    componentDidMount() {
        window.addEventListener('resize', this.listenResize, true);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.listenResize, true);
    }

    changeCaption(str) {
        this.setState({caption: str});
    }

    savePicture() {
        const { uri, caption } = this.state;
        this.props.addPicture(uri, this.props.gallery, caption);
        this.setState({showModal: !this.state.showModal, currentUri: '', caption: ''});
    }   

    setCurrentUri(str) {
        this.setState({currentUri: str});
    }

    render() {
        const {gallery, edit, user, imgsQtd, changeCurrentSection, t} = this.props;
        const {picture, vw} = this.state;

        if (!gallery || !gallery.length) {
            return null;
        }

        let children = sortArray(gallery, 'key');
    
        children = children.map((picture, key) => (
            <GalleryPicture
                edit={edit}
                picture={picture}
                onClick={this.handlePictureZoom}
                onRemove={this.handleDeletion}
                onEditCaption={this.handleEditing}
                key={key}
                user = { user }
            />
        ));

        if (children.length < 24 && edit) {
            children = [<PicturePlaceholder 
                onSaveFile={this.handleInclusion} 
                showModal={this.state.showModal} 
                close={ () => this.setState({showModal: false})}  
                open={ () => this.setState({showModal: true})}
                changeCaption={ this.changeCaption }
                caption={ this.state.caption }
                onSave={ this.savePicture }
                uri={this.state.currentUri}
                setCurrentUri={this.setCurrentUri}
                user={this.props.user}
                key='placeholder'/>, ...children];
        }
        const chunks = splitIntoChunks(children, vw > 768 ? imgsQtd : 1);
        const chunksAll = splitIntoChunks(children, vw <= 1 ? 1 : 1);


        if (!this.props.user.enableGallery && !this.props.edit) {
            return null;
        } else {
            return (
                <section id="gallery" onClick={() =>changeCurrentSection('gallery')} className='gallery-container'>
                    <div className="home-container">

                        <div className='title-wrapper'>
                            <h1 className='title'>{t('GALLERY')}</h1>
                        </div>

                        <DisableBlock
                            id={'enableGallery'} show={this.props.edit} click={this.props.changeVisibility}
                            enable={this.props.user.enableGallery}/>

                        <div className='carousel-container-wrapper'>

                            { ((children.length >= 1 && !this.props.edit) || (children.length >= 2 && this.props.edit))  &&
                                <div className='carrousel-gallery-all'>

                                
                                    {((children.length <= 4 && !this.props.edit) || (children.length <= 5 && this.props.edit)) && 
                                        <Carousel className='carousel-container all-slider-container '>
                                            {chunksAll.map((chunk, i) => (
                                                <div className='flex-carousel-container' key={i}>
                                                    { vw > 768 &&
                                                        <div className='gallery-carousel-container'>
                                                            {chunk}
                                                        </div>
                                                    } 
                                                    { vw < 768 &&
                                                        chunk
                                                    }
                                                </div>
                                            ))}
                                        </Carousel>
                                    }
                                    
                                    {((children.length >= 5 && !this.props.edit) || (children.length >= 6 && this.props.edit)) &&
                                        <Carousel className='carousel-container'>
                                            {chunks.map((chunk, i) => (
                                                <div className='flex-carousel-container' key={i}>
                                                    { vw > 768 &&
                                                    <div className='gallery-carousel-container'>
                                                        {chunk}
                                                    </div>
                                                    } 
                                                    { vw < 768 &&
                                                    chunk
                                                    }
                                                </div>
                                            ))}
                                        </Carousel>
                                    }

                                </div>
                            }
                            
                            { ((children.length === 0) && (!this.props.edit)) &&
                                <Placeholder text='Não há imagens adicionadas.' />
                            }

                            { ((children.length === 1) && (this.props.edit)) &&
                                <div className='no--content'>
                                    <PicturePlaceholder 
                                        onSaveFile={this.handleInclusion} 
                                        showModal={this.state.showModal} 
                                        close={ () => this.setState({showModal: false})}  
                                        open={ () => this.setState({showModal: true})}
                                        changeCaption={ this.changeCaption }
                                        caption={ this.state.caption }
                                        onSave={ this.savePicture }
                                        uri={this.state.currentUri}
                                        setCurrentUri={this.setCurrentUri}
                                        key='placeholder'
                                        user= {this.props.user} />
                                </div>
                            }
                            <ZoomedPictureModal
                                picture={picture} gallery={gallery}
                                onClose={() => this.setState({picture: null})}
                            />
                        </div>
                    </div>
                </section>
            );
        }
    }
}

const PicturePlaceholder = ({user, onSaveFile, showModal, open, url, caption, close, onSave, changeCaption, uri, setCurrentUri}) => {
    
    return (

        <div className='img-container-wrapper'>
            <Modal show= { showModal } onClose={ close }> 
                <div className={`home-container ${user.theme}`}>
                    <div className='modal-content modal-add-image'>

                        <div className='box-title-modal'>
                            <i className='icon-gallery'/>
                            <h4>Galeria</h4>
                        </div>

                        <div className='item-modal'>
                            <label> Título da imagem </label>
                            <textarea
                                className='img-caption-edit'
                                rows='3'
                                defaultValue={caption}
                                onChange={({target}) => changeCaption(target.value)}/>
                        </div>

                        <div className='item-modal'>
                            <label> Adicionar Imagem </label>
                            <div className='add-file'>
                                
                                { uri &&
                                    <div className='picture-show'>
                                        <img src={uri} title={caption} alt={caption} className="modal-image-show"/>

                                        <div className='add-another-picture'>
                                            <div className='button-another-picture'>
                                                <i className='icon-upload-image'/>
                                                <span> Mudar imagem </span>
                                            </div>
                                            <FileUploader handleFileChange={img => drawScaledImage(img).then(onSaveFile)} setCurrentUri={setCurrentUri}> </ FileUploader>
                                        </div>
                                    </div>
                                }

                                { !uri &&
                                    <div className='picture-hide'>
                                        <div className='input-file-text'>
                                            <i className='icon-upload-image'/>
                                            <span> Upload do arquivo </span>
                                        </div>

                                        <FileUploader handleFileChange={img => drawScaledImage(img).then(onSaveFile)} setCurrentUri={setCurrentUri}> </ FileUploader>
                                    </div>
                                }

                            </div>
                        </div>

                        <div className='box-alert-modal'> 
                            <div className='icon-alert'>
                                <svg viewBox="0 0 44 50">
                                    <g>
                                        <path className="st0" d="M39.3,37.1c-3.8-3.8-5.7-8.3-5.7-13.3v-7.4c0-5.6-3.7-9.3-7-10.8c-0.9-0.4-1.9-0.8-2.9-1V4.1
                                            c0-2.3-1.8-4.1-4.1-4.1s-4.1,1.8-4.1,4.1v0.5c-0.9,0.2-1.7,0.5-2.6,0.9c-3.6,1.7-7.2,5.6-7.2,10.8v7.4c0,5.2-1.8,9.7-5.5,13.3
                                            C0,37.3-0.1,37.6,0,37.9c0.1,0.3,0.3,0.5,0.6,0.6l7.8,1.3c1.4,0.2,2.8,0.5,4,0.6c1.4,2.8,4.2,4.6,7.3,4.6c3.1,0,5.9-1.8,7.3-4.6
                                            c1.3-0.2,2.6-0.4,4-0.6l7.8-1.3c0.3-0.1,0.5-0.3,0.6-0.6C39.6,37.6,39.5,37.3,39.3,37.1z M17.2,4.1c0-1.4,1.1-2.5,2.5-2.5
                                            s2.5,1.1,2.5,2.5v0.1c-1.6-0.2-3.3-0.2-4.9,0V4.1z M19.8,43.4c-2.1,0-4-1-5.3-2.7c1.8,0.2,3.5,0.3,5.2,0.3c1.7,0,3.4-0.1,5.3-0.3
                                            C23.8,42.3,21.9,43.4,19.8,43.4z M30.8,38.2c-1.6,0.3-3,0.5-4.4,0.7c0,0,0,0,0,0c-0.6,0.1-1.2,0.1-1.8,0.2c0,0,0,0,0,0
                                            c-0.5,0.1-1,0.1-1.5,0.1c-0.1,0-0.2,0-0.3,0c-0.4,0-0.9,0.1-1.3,0.1c-0.1,0-0.2,0-0.3,0c-1,0-2,0-3,0c-0.1,0-0.2,0-0.3,0
                                            c-0.4,0-0.9,0-1.3-0.1c-0.1,0-0.2,0-0.3,0c-0.5,0-1-0.1-1.5-0.1c0,0,0,0-0.1,0c-0.6-0.1-1.1-0.1-1.7-0.2c0,0,0,0,0,0
                                            c-1.4-0.2-2.8-0.4-4.4-0.7l-6.3-1.1c3.3-3.7,4.9-8.2,4.9-13.4v-7.4c0-4.4,3.2-7.9,6.3-9.3c0.9-0.4,1.9-0.7,2.9-1c0,0,0,0,0,0
                                            c2-0.5,4.2-0.5,6.2-0.1c0,0,0,0,0,0c1.1,0.2,2.1,0.6,3.1,1c3,1.4,6.1,4.8,6,9.3v7.4c0,4.9,1.8,9.5,5.2,13.4L30.8,38.2z"/>
                                        <path className="st0" d="M24.9,9.3c-3.2-1.4-7.1-1.5-10.2,0c-1.8,0.8-4.8,3.3-4.9,7.1c0,0.5,0.4,0.8,0.8,0.8c0,0,0,0,0,0
                                            c0.4,0,0.8-0.4,0.8-0.8c0-3,2.5-4.9,3.9-5.6c2.7-1.3,6.1-1.3,8.8,0c0.4,0.2,0.9,0,1.1-0.4C25.5,9.9,25.3,9.5,24.9,9.3z"/>
                                    </g>
                                </svg>
                            </div>
                            <p>A galeria só comporta um total de 24 fotos</p>
                        </div>

                        <div className='modal-footer'>
                            <Button size='small' text='Cancelar' type='warning' click={ close }/>
                            <Button size='small' text='Salvar' type='warning' click={ onSave }/>
                        </div>
                    </div>
                </div>
            </Modal>

            <div className='picture-placeholder' title='Adicionar imagem' onClick={ open } >
                <i className='icon-add-image'/>
                <span> Adicionar nova imagem </span>
            </div>

        </div>
    );
};


const MAX_IMG_SIZE = {width: 1280, height: 720};

async function drawScaledImage(img, fileName = 'picture') {
    const canvas = await canvasWithImage(img, {maxImgSize: MAX_IMG_SIZE});
    return canvasToArrayBuffer(canvas, fileName);
}

class GalleryPicture extends React.Component {

    constructor() {
        super();

        this.state = {
            pictureBeingEdited: null,
            confirmDelete: false,
        };

        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }

    handleCloseModal() {
        this.setState({pictureBeingEdited: null, confirmDelete: false});
    }

    handleSave(obj) {
        const newObj = {...this.state.pictureBeingEdited, ...obj};
        this.props.onEditCaption(newObj);
        this.handleCloseModal();
    }

    handleDelete(picture) {
        this.props.onRemove(picture);
        this.handleCloseModal();
    }

    render() {
        const {picture, edit, onClick} = this.props;
        const {pictureBeingEdited, confirmDelete} = this.state;

        return (
            <div className='img-container-wrapper' >
                {edit &&
                <div className='gallery-img-toolbar'>
                    <a onClick={() => this.setState({pictureBeingEdited: picture})}> {/* eslint-disable-line */}
                        <i className='fa fa-pencil' title='Editar' style={{color: 'white'}}/></a>
                    <a onClick={() => this.setState({confirmDelete: true})}> {/* eslint-disable-line */}
                        <i className='fa fa-trash' title='Remover' style={{color: 'white'}}/>
                    </a>
                </div>}
                <div
                    className='img-container' key={picture.url}
                    onClick={() => onClick(picture)}>
                    <div className='img-gallery'>
                        <img src={picture.url} alt={picture.caption}/>
                    </div>
                    <span className="img-caption">{picture.caption}</span>
                </div>
                {!!pictureBeingEdited &&
                <EditCaptionModal
                    user = { this.props.user }
                    onSaveFile={ this.handleInclusion }
                    onCancel={ this.handleCloseModal }
                    onSave={ this.handleSave }
                    picture={ pictureBeingEdited }
                />}
                {confirmDelete &&
                <ConfirmModal
                    user = { this.props.user }
                    onCancel={this.handleCloseModal}
                    onConfirm={() => this.handleDelete(picture)}
                    text='Confirma a exclusão da imagem?'
                />}
            </div>
        );
    }
}

class EditCaptionModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            caption: '',
            uri: this.props.picture.url,
            currentUri: this.props.picture.url,
            picture: this.props.picture
        };

        this.setCurrentUri = this.setCurrentUri.bind(this);
        this.savePicture = this.savePicture.bind(this);
        this.handleInclusion = this.handleInclusion.bind(this);
        this.showBlob = this.showBlob.bind(this);

    }

    savePicture() {
        const { uri, caption } = this.state;
        this.props.addPicture(uri, this.props.gallery, caption);
        this.setState({showModal: !this.state.showModal, currentUri: '', caption: ''});
    }   

    setCurrentUri(str) {

        this.setState({uri: str});
    }

    handleInclusion(dataURI) {
        this.setState({uri: dataURI});
    }
    
    showBlob(obj) {
        this.setState({picture: {...this.state.picture, url: obj}});
    }

    render() {
        const { onCancel, onSave, user} = this.props;
        const { theme } = user;

        const {picture, uri} = this.state;
    
        return (
            <Modal show={true} onClose={onCancel}>
                <div className={`home-container ${theme}`}>
                    <div className='modal-content modal-edit-gallery'>
                        <div className='box-title-modal'>
                            <i className='icon-gallery'/>
                            <h4>Galeria</h4>
                        </div>

                        <div className='item-modal'>
                            <label> Editar Título da Imagem:</label>
                            <textarea
                                className='img-caption-edit'
                                rows='3'
                                defaultValue={picture.caption}
                                onChange={({target}) => this.setState({picture:{ ...this.state.picture, caption: target.value }})}/>
                            
                            <div className='picture-show'>
                                <label> Editar Imagem:</label>

                                <img src={uri} title={picture.caption} alt={picture.caption} className="modal-image-show"/>

                                <div className='add-another-picture'>
                                    <div className='button-another-picture'>
                                        <i className='icon-upload-image'/>
                                        <span> Mudar imagem </span>
                                    </div>

                                    <FileUploader handleFileChange={img => drawScaledImage(img).then(this.showBlob)} setCurrentUri={this.setCurrentUri}> </ FileUploader>
                                </div>
                            </div>
                            
                        </div>

                        <div className='modal-footer'>
                            <Button size='small' text='Cancelar' type='warning' click={ onCancel }/>
                            <Button size='small' text='Salvar' type='warning' click={ ()=> onSave(picture) }/>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

export class ZoomedPictureModal extends React.Component {

    render() {
        const {picture, gallery, ...rest} = this.props;
        const settings = {
            selectedItem: gallery.indexOf(picture),
            dynamicHeight: true,
        };
        const contentStyle = {
            display: 'flex',
            'alignItems': 'center',
            'alignContent': 'center',
        };

        if (!picture) {
            return null;
        }

        return (
            <Modal show={true} {...rest} contentStyle={contentStyle}>
                <button className='modal-exit-btn' onClick={rest.onClose}>x</button>
                <div className='carousel-modal-wrapper modal-zoom-image home-container'>
                    <div className='carousel-modal-container'>
                        <Carousel {...settings}>
                            {gallery.map(picture => (
                                <div key={picture.url} className='all-container'>
                                    <div className='zoomed-img'>
                                        <img src={picture.url} alt={picture.caption}/>
                                        { picture.caption &&
                                            <span className='img-caption'>{picture.caption}</span>
                                        }
                                    </div>
                                </div>
                            ))}
                        </Carousel>
                    </div>
                </div>
            </Modal>
        );
    }
}

const splitIntoChunks = ([...pictures], chunkSize = 2) => {
    const chunks = [];
    while (pictures.length) {
        chunks.push(pictures.splice(0, chunkSize));
    }
    return chunks;
    
};

const viewportWidth = () => Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

const mapStateToProps = ({ Coach }) => ({
    gallery: Coach.user.gallery,
    user: Coach.user
});
const mapDispatchToProps = dispatch => bindActionCreators(
    {
        deletePicture,
        editPictureCaption,
        addPicture,
        changeVisibility,
        changeCurrentSection
    },
    dispatch);

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Gallery);
