import React from 'react';
import {Button, Modal} from '../../../commons';

import './confirm-modal.css';

export default class ConfirmModal extends React.Component {

    render() {
        const {
            onConfirm,
            onCancel,
            text = 'Confirma essa ação?',
            user
        } = this.props;
        return (
            <Modal show={true} onClose={onCancel}>
                <div className={`home-container ${user.theme}`}>
                    <div className='modal-content modal-confirmation'>
                        <div className='modal-content-all'>
                            <div className='question-modal-confirmation'>
                                <div className='icon-attention'> 
                                    <svg viewBox="0 0 60 56.1">
                                        <g>
                                            <g>
                                                <path className="st0" d="M59.2,43.4L34.6,2.6C33.6,1,31.9,0,30,0c-1.9,0-3.6,1-4.6,2.6L0.8,43.4c-1,1.7-1,3.7-0.1,5.4
                                                    c1,1.7,2.8,2.7,4.7,2.7h49.2c1.9,0,3.7-1,4.7-2.7C60.3,47.2,60.2,45.1,59.2,43.4z M55.9,46.9c-0.3,0.5-0.8,0.8-1.3,0.8H5.4
                                                    c-0.5,0-1-0.3-1.3-0.8c-0.3-0.5-0.3-1,0-1.5L28.7,4.6C29,4.2,29.5,3.9,30,3.9c0.5,0,1,0.3,1.3,0.7l24.6,40.8
                                                    C56.2,45.9,56.2,46.5,55.9,46.9z"/>
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path className="st0" d="M30,16.1c-1.5,0-2.6,0.8-2.6,2.2c0,4.3,0.5,10.5,0.5,14.8c0,1.1,1,1.6,2.1,1.6c0.9,0,2.1-0.5,2.1-1.6
                                                    c0-4.3,0.5-10.5,0.5-14.8C32.6,16.9,31.4,16.1,30,16.1z"/>
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path className="st0" d="M30.1,37.2c-1.6,0-2.8,1.3-2.8,2.8c0,1.5,1.2,2.8,2.8,2.8c1.5,0,2.8-1.3,2.8-2.8
                                                    C32.8,38.5,31.5,37.2,30.1,37.2z"/>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <p> Atenção </p>
                            </div>

                            <h4>{text}</h4>
                            
                            <div className='modal-footer'>
                                <Button size='small' text='Sim' type='warning' click={() => onConfirm()}/>
                                <Button size='small' text='Não' type='warning' click={onCancel}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}
