import React from 'react';
import {toast} from 'react-toastify';

import ToastBody from '../components/ToastBody';

function notification(type, message, onClose = () => { }, autoClose = true) {
    return toast[type](
        <ToastBody type={type} message={message} />, {
            position: 'top-right',
            autoClose,
            closeButton: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            onClose,
        }
    );
}

export default notification;
