import React from 'react';
import TitleSection from '../../../../../../components/TitleSection/TitleSection';
import IconSvg from '../../../../../../utils/svg';
import SlickCarousel from '../../../../../../components/SlickCarousel/slickCarousel';
import TestimonialCard from './testimonial-card/testimonial-card';

import './testimonial.css';
import { useTranslation } from 'react-i18next';

function Testimonial({ testimonies, onClick, themeColor }) {
    const {t} = useTranslation();

    if (!testimonies || !testimonies.length) {
        return null;
    }
    const settings = {
        dots: false,
        infinite: false,
        arrows: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <IconSvg name='icon-arrow' width='36px' color={themeColor} classDiv='arrowPrev' />,
        nextArrow: <IconSvg name='icon-arrow' width='36px' color={themeColor} classDiv='arrowNext' />,
    };


    return (
        <section
            onClick={() => onClick('testimonies')}
            id="testimonies"
            className="template--5-testimonial">
                
            <article className='template--5-testimonial-container'>
                <TitleSection text1={t('TESTIMONY')} colorIcon={themeColor} iconView={true}/>
                <IconSvg name="icon-circle-quotation-marks" width={60} height={60} color={themeColor} />

                <SlickCarousel className="generic--slider" settings={settings}>
                    {testimonies.map((testimonial, i) => (
                        <div className="generic--slider-item" key={i}>
                            <TestimonialCard
                                {...testimonial}
                            />
                        </div>
                    ))}
                </SlickCarousel>
            </article>

        </section>
    );
}

export default Testimonial;