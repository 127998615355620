import React from 'react';
import {useTranslation} from 'react-i18next';

import IconSvg from '../../../../utils/svg';
import backgroundTransformationAreas from '../../../../img/bg-transformation-areas.min.webp';

import './transformation-areas-section.css';

function TransformationAreasSection() {
    const {t} = useTranslation();

    return (
        <section className='transformation-areas-section' id='transformation_areas'>
            <PillarMainText t={t} />
            <PillarList items={items} t={t} />
            <img
                src={backgroundTransformationAreas}
                className='bg-transformation-areas'
                alt='Imagem de fundo das Áreas de transformação'
            />
        </section>
    );
}

function PillarMainText({t}) {
    const [title, highlightedWord] = t('TRANSFORMATION_TITLE').split('*');

    return (
        <p className='tranformation-areas-title'>
            {title}
            <span> {highlightedWord}</span>
        </p>
    );
}

function PillarList({items, t}) {
    const pillarItems = items.map(pillarMapper);

    function pillarMapper(item) {
        return <PillarItem item={item} t={t} key={item.name}/>;
    }

    return (
        <ul className='pillar-list'>
            {pillarItems}
        </ul>
    );
}

function PillarItem({item, t}) {
    return (
        <li className='pillar-item'>
            <IconSvg name={item.icon} width={40} height={40} color2='#fff' />
            <span>{t(item.name)}</span>
        </li>
    );
}

const items = [
    {
        name: 'EMOTIONAL',
        icon: 'icon-heart',
    },
    {
        name: 'BUSINESS',
        icon: 'icon-pseudo-dollar-sign',
    },
    {
        name: 'FAMILIAR',
        icon: 'icon-users',
    },
];

export default TransformationAreasSection;
