import React, { Component } from 'react';

import './helpScreen.css';

class HelpScreen extends Component {
    render() {
        return (
            <main className='main-content'>

                <header className='header-content'>
                    <div className='home-container help-main'>
                        <h1>
                            <svg viewBox='0 0 29.536 29.536'>
                                <g>
                                    <path d='M14.768,0C6.611,0,0,6.609,0,14.768c0,8.155,6.611,14.767,14.768,14.767s14.768-6.612,14.768-14.767   C29.535,6.609,22.924,0,14.768,0z M14.768,27.126c-6.828,0-12.361-5.532-12.361-12.359c0-6.828,5.533-12.362,12.361-12.362   c6.826,0,12.359,5.535,12.359,12.362C27.127,21.594,21.594,27.126,14.768,27.126z' fill='#FFFFFF'></path>
                                    <path d='M14.385,19.337c-1.338,0-2.289,0.951-2.289,2.34c0,1.336,0.926,2.339,2.289,2.339c1.414,0,2.314-1.003,2.314-2.339   C16.672,20.288,15.771,19.337,14.385,19.337z' fill='#FFFFFF'></path>
                                    <path d='M14.742,6.092c-1.824,0-3.34,0.513-4.293,1.053l0.875,2.804c0.668-0.462,1.697-0.772,2.545-0.772   c1.285,0.027,1.879,0.644,1.879,1.543c0,0.85-0.67,1.697-1.494,2.701c-1.156,1.364-1.594,2.701-1.516,4.012l0.025,0.669h3.42   v-0.463c-0.025-1.158,0.387-2.162,1.311-3.215c0.979-1.08,2.211-2.366,2.211-4.321C19.705,7.968,18.139,6.092,14.742,6.092z' fill='#FFFFFF'></path>
                                </g>
                            </svg>
                            Central de Ajuda
                        </h1>
                    </div>
                </header>

                <section className='content-all'>
                    <section className='video-content'>
                        <div className='home-container'>
                            <div className='video-all'>
                                <h1 className='video-content-item'>Vídeo Tutorial<em>Criando o site pessoal do Coach</em></h1>
                                <iframe title='video manual' src='https://player.vimeo.com/video/265468706?title=0&amp;byline=0&amp;portrait=0' width='800' height='450' frameBorder='0' webkitallowfullscreen='' mozallowfullscreen='' allowFullScreen=''></iframe>
                            </div>
                        </div>
                    </section>

                    <section className='pdf-content'>
                        <div className='home-container'>
                            <div className='pdf-all'>
                                <div className='title-pdf'>
                                    <h1>Manual </h1>
                                    <a className='btn-download' target='_blank' href='https://cdn.scisapp.com/downloads/docs/manual_oseucoach.pdf' rel='noopener noreferrer' download='manual-oseucoach'>
                                        <svg viewBox='0 0 512 512'>
                                            <g>
                                                <path d='M373.333,234.667c-76.48,0-138.667,62.187-138.667,138.667S296.853,512,373.333,512S512,449.813,512,373.333     S449.813,234.667,373.333,234.667z M373.333,490.667C308.48,490.667,256,438.187,256,373.333S308.48,256,373.333,256     s117.333,52.48,117.333,117.333C490.56,438.08,438.08,490.56,373.333,490.667z' fill='#FFFFFF'></path>
                                                <path d='M412.8,377.173l-28.8,28.8v-90.56c0-5.333-3.84-10.133-9.067-10.88c-6.613-0.96-12.267,4.16-12.267,10.56v90.667     l-28.587-28.587c-4.267-4.16-11.093-4.16-15.253,0c-4.16,4.267-4.16,11.093,0,15.253l46.933,47.04     c4.16,4.16,10.987,4.267,15.253,0l46.933-47.04c4.16-4.267,4.053-11.093-0.213-15.253     C423.573,373.12,416.96,373.12,412.8,377.173z' fill='#FFFFFF'></path>
                                                <path d='M181.333,384H97.067c4.373-5.653,7.573-12.16,9.28-19.093c0.213-0.853,0.32-1.707,0.32-2.56V128h320v63.68     c0,5.333,3.84,10.133,9.067,10.88C442.347,203.52,448,198.4,448,192v-74.667c0-5.867-4.8-10.667-10.667-10.667h-32V64     c0-5.867-4.8-10.667-10.667-10.667H168.533l-30.72-48.427C135.893,1.813,132.48,0,128.853,0H10.667C4.8,0,0,4.8,0,10.667v351.68     c0,0.853,0.107,1.707,0.32,2.56c5.76,23.147,25.92,38.827,50.453,39.893c0.853,0.32,1.707,0.427,2.56,0.533h127.68     c5.333,0,10.133-3.84,10.88-9.067C192.853,389.653,187.733,384,181.333,384z M85.333,117.333V360.96     c-4.8,16.747-20.16,22.72-32,22.72c-11.84,0-27.2-5.973-32-22.72V21.333H122.88L153.6,69.76c1.92,3.093,5.333,4.907,9.067,4.907     H384v32H96C90.133,106.667,85.333,111.467,85.333,117.333z' fill='#FFFFFF'></path>
                                            </g>
                                        </svg>
                                        Download do Manual
                                    </a>
                                </div>
                                <iframe title='pdf manual' style={{width:'850px', height:'400px'}} src='//e.issuu.com/embed.html#33224340/60300825' frameBorder='0' allowFullScreen></iframe>
                            </div>
                        </div>
                    </section>

                </section>

                <footer className='footer-content'>
                    <div className='home-container'><a href='https://oseucoach.com/Home' target='_blank' rel='noopener noreferrer'><b>oseu</b>coach</a></div>
                </footer>

            </main>
        );
    }
}

export default HelpScreen;