import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import Axios from 'axios';

import './videos.css';
import Play from '../../../../img/play-icon.png';
import {Button, Modal, Carousel, DisableBlock, Placeholder} from '../../../commons';
import ConfirmModal from '../confirm-modal/confirm-modal';
import {addVideo, deleteVideo, editVideo} from './videos.action';
import { changeVisibility, changeCurrentSection } from '../../coach.actions';
import { sortArray } from '../../../../utils/common-functions';
import { trackEvent } from '../../../../utils/analytics';
import { withTranslation } from 'react-i18next';

class Videos extends Component {

    constructor(props) {
        super(props);

        this.state = {
            video: null,
            vw: viewportWidth(),
            error: false
        };

        this.handleAddVideo = this.handleAddVideo.bind(this);
        this.handleDeleteVideo = this.handleDeleteVideo.bind(this);
        this.handleEditing = this.handleEditing.bind(this);
        this.listenResize = this.listenResize.bind(this);
        this.clickInVideo = this.clickInVideo.bind(this);
    }


    async handleAddVideo(url, caption = '') {
        const match = matchingHost(url);
        if (!match) {
            this.setState({error: true});
        } else {
            const id = HostsUrls[match].id(url);
            try {
                const video = {
                    host: match,
                    id,
                    thumbnail: await HostsUrls[match].thumbnail(id),
                    caption,
                };
                this.props.addVideo(video, this.props.videos);
                return true;
            } catch (e) {
                console.debug(e);
            }
        }
        return false;
    }

    handleDeleteVideo(video) {
        this.props.deleteVideo(video, this.props.videos);
    }

    handleEditing(video) {
        this.props.editVideo(video, this.props.videos);
    }

    listenResize() {
        this.setState({vw: viewportWidth()});
    }

    componentDidMount() {
        window.addEventListener('resize', this.listenResize, true);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.listenResize, true);
    }

    clickInVideo(video) {
        trackEvent('user interaction', 'click in video', 'choosing a video');
        this.setState({video});
    }

    render() {
        const {videos, edit, videosQtd, user, changeCurrentSection, t} = this.props;
        const {video, vw, error} = this.state;

        if (!videos || !videos.length) {
            return null;
        }

        let children = sortArray(videos, 'key', true);
   

        children = children.map(( video, i ) => (
            <EmbeddedVideo
                edit={edit}
                video={video}
                onClick={ this.clickInVideo }
                onRemove={this.handleDeleteVideo}
                onEdit={this.handleEditing}
                key={i}
                user = {user}
            />
        ));

        if (children.length < 16 && edit) {
            const placeholder = <AddVideoPlaceholder onSave={this.handleAddVideo} key='placeholder' isError={error} user={user}/>;
            children = [placeholder, ...children];
        }

        const chunks = splitIntoChunks(children, vw > 768 ? videosQtd : 1);
        const chunksAll = splitIntoChunks(children, vw <= 1 ? videosQtd : 1);
        
        if(!this.props.user.enableVideos && !this.props.edit) {
            return null;
        } else {
            return (
                <section 
                    id="videos"
                    onClick={() => changeCurrentSection('videos')}
                    className='videos-container'>
                    <div className='home-container'>

                        <div className='title-wrapper'>
                            <h1 className='title'>{t('VIDEOS')}</h1>
                        </div>

                        <DisableBlock id={'enableVideos'} show={ this.props.edit } click={ this.props.changeVisibility } enable={ this.props.user.enableVideos }/>
                            
                        <div className='carrousel-videos-wrapper'>
                            { ((children.length >= 1 && !this.props.edit) || (children.length >= 2 && this.props.edit))  &&
                                    <div className='carrousel-videos-all'>

                                        {((children.length <= 3 && !this.props.edit) || (children.length <= 4 && this.props.edit)) && 
                                            <div className='all-slider-container'>
                                                <Carousel> 
                                                    { chunksAll.map((chunk, i) => ( 
                                                        <div className='flex-carousel-container' key={i}> 
                                                            {chunk} 
                                                        </div> 
                                                    ))}
                                                </Carousel>
                                            </div>
                                        }
                                        
                                        {((children.length >= 4 && !this.props.edit) || (children.length >= 5 && this.props.edit)) &&
                                            <Carousel> 
                                                { chunks.map((chunk, i) => ( 
                                                    <div className='flex-carousel-container' key={i}> 
                                                        {chunk} 
                                                    </div> 
                                                ))}
                                            </Carousel>
                                        }

                                    </div>
                            }

                            { (children.length === 0 && !this.props.edit) &&
                                    <Placeholder text='Não há videos adicionados.' />
                            }

                            { (children.length === 1 && this.props.edit) &&
                                    <div className='no--content'>
                                        <AddVideoPlaceholder user={ user } onSave={this.handleAddVideo} key='placeholder' isError={error}/>
                                    </div>
                            }

                            <ZoomedVideoModal
                                video={video}
                                onClose={() => this.setState({video: null})}
                            />

                        </div>
                    </div>
                </section>
            );
        }
    }
}

class AddVideoPlaceholder extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            addingVideo: false,
        };

        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }

    handleCloseModal() {
        this.setState({addingVideo: false});
    }

    async handleSave(...params) {
        const close = await this.props.onSave(...params);
        if (close) {
            this.handleCloseModal();
        }
    }

    render() {
        const {addingVideo} = this.state;
        const {isError, user} = this.props;

       
        return (
            <React.Fragment>
                <div className='add-video video-wrapper' onClick={() => this.setState({addingVideo: true})} title='Adicionar vídeo' >
                    
                    <i className='icon-upload-video'/>
                    <span> Adicionar novo vídeo </span>

                </div>
                {addingVideo &&
                <AddVideoModal
                    onCancel={this.handleCloseModal}
                    onSave={this.handleSave}
                    error={isError}
                    user = {user}
                />}
            </React.Fragment>
        );
    }
}

class EmbeddedVideo extends React.Component {

    constructor() {
        super();

        this.state = {
            videoBeingEdited: null,
            confirmDelete: false,
        };

        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }

    handleCloseModal() {
        this.setState({videoBeingEdited: null, confirmDelete: false});
    }

    handleSave(caption) {
        this.props.onEdit({...this.state.videoBeingEdited, caption});
        this.handleCloseModal();
    }

    handleDelete(video) {
        this.props.onRemove(video);
        this.handleCloseModal();
    }

    render() {
        const {videoBeingEdited, confirmDelete} = this.state;
        const {
            video,
            edit = false,
            onClick,
            user
        } = this.props;

        return (
            <div className='video-wrapper'>
                {edit &&
                    <div className='videos-img-toolbar'>

                        <a onClick={() => this.setState({videoBeingEdited: video})}> {/* eslint-disable-line */}
                            <i className='fa fa-pencil' title='Editar' style={{color: 'white'}}/>
                        </a>

                        <a onClick={() => this.setState({confirmDelete: true})}> {/* eslint-disable-line */}
                            <i className='fa fa-trash' title='Remover'/>
                        </a>

                    </div>
                }

                <div className='video-and-caption-container'>

                    <div className='video-container-all'>
                        <div className='video-container' key={video.id} onClick={() => onClick(video)}>
                            <img src={video.thumbnail} alt={video.caption} className='video-thumbnail'/>

                            <span className='play-button-icon'>
                                <img className='play-img' alt='' src={ Play } />
                            </span>
                        </div>


                        <span className="video-caption" title={video.caption}>{video.caption}</span>
                    </div>

                </div>
                {!!videoBeingEdited &&
                <EditCaptionModal
                    caption={videoBeingEdited.caption}
                    onCancel={this.handleCloseModal}
                    onSave={this.handleSave}
                    user={user}
                />}
                {confirmDelete &&
                <ConfirmModal
                    onCancel={this.handleCloseModal}
                    onConfirm={() => this.handleDelete(video)}
                    user={user}
                    text='Confirma a exclusão do vídeo?'
                />}
            </div>
        );
    }
}

class EditCaptionModal extends React.Component {

    constructor() {
        super();

        this.state = {
            caption: '',
        };
    }

    render() {
        const {caption = '', onCancel, onSave, user} = this.props;

        return (
            <Modal show={true} onClose={onCancel}>
                <div className={`home-container ${user.theme}`}>
                    <div className='modal-content modal-edit-video'>
                        
                        <div className='box-title-modal'>
                            <i className='icon-video'/>
                            <h4>Vídeo</h4>
                        </div>

                        <div className='item-modal'>
                            <label> Editar Título do Vídeo:</label>
                            <textarea
                                className='img-caption-edit'
                                rows='3'
                                defaultValue={caption}
                                onChange={({target}) => this.setState({caption: target.value})}/>
                        </div>

                        <div className='modal-footer'>
                            <Button size='small' text='Cancelar' type='warning' click={onCancel}/>
                            <Button size='small' text='Salvar' type='warning' click={() => onSave(this.state.caption)}/>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

export class ZoomedVideoModal extends React.Component {

    render() {
        const {video, ...rest} = this.props;
        const contentStyle = {
            display: 'flex',
            'alignItems': 'center',
            'alignContent': 'center',
        };

        if (!video) {
            return null;
        }
        return (
            <Modal show={true} {...rest} contentStyle={contentStyle}>
                <button className='modal-exit-btn' onClick={rest.onClose}>x</button>
                
                <div className='zoomed-video'>
                    {HostsUrls[video.host] ? 
                        <iframe
                            title={video.caption}
                            frameBorder="0"
                            src={HostsUrls[video.host].url(video.id)}
                            webkitallowfullscreen={'true'}
                            mozllowfullscreen={'true'}
                            allowFullScreen={true}>
                        Vídeo embarcado: {video.caption}
                        </iframe>
                        :
                        <h2 style={{color: '#fff'}}>Problemas ao carregar o Video!</h2>
                    }
                </div>
            </Modal>
        );
    }
}

class AddVideoModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            url: '',
            caption: props.caption || '',
            error: false
        };

        this.handleUrlUpdate = this.handleUrlUpdate.bind(this);
    }

    static isValid(url) {
        return !url || matchingHost(url);
    }

    static getCaption(url) {
        const match = matchingHost(url);
        return HostsUrls[match].title(HostsUrls[match].id(url));
    }

    async handleUrlUpdate({target}) {
        const {value: url} = target;
        this.setState({url});
        if (AddVideoModal.isValid(url)) {

            this.setState({error: false});

            try {
                const caption = await AddVideoModal.getCaption(url);
                if (caption) {
                    this.setState({caption});
                }
            } catch (e) {
                console.debug(e);
            }
        } else {
            this.setState({error: true});
        }
    }

    render() {
        const {onCancel, onSave, user} = this.props;
        const {url, caption, error} = this.state;

        return (
            <Modal show={true} onClose={onCancel}>
                <div className={`home-container ${user.theme}`}>
                    <div className='modal-content modal-add-video'>

                        <div className='box-title-modal'>
                            <i className='icon-video'/>
                            <h4>Vídeo</h4>
                        </div>

                        <div className={ `item-modal ${!AddVideoModal.isValid(url) ? 'error' : ''}`}>
                            <label>Link do vídeo:</label>
                            <input
                                className='wide-input'
                                defaultValue={url}
                                onChange={this.handleUrlUpdate}/>

                            { error &&
                                <span className='url-invalid'>
                                    <i className='icon-url-invalid'/>
                                    Url inválida 
                                </span>
                            }

                        </div>
                        <div className="item-modal">
                            <label>Título:</label>
                            <textarea
                                className='wide-input'
                                rows='3'
                                value={caption}
                                onChange={({target}) => this.setState({caption: target.value})}/>

                        </div>

                        <div className='box-alert-modal'> 
                            <div className='icon-alert'>
                                <svg viewBox="0 0 44 50">
                                    <g>
                                        <path className="st0" d="M39.3,37.1c-3.8-3.8-5.7-8.3-5.7-13.3v-7.4c0-5.6-3.7-9.3-7-10.8c-0.9-0.4-1.9-0.8-2.9-1V4.1
                                            c0-2.3-1.8-4.1-4.1-4.1s-4.1,1.8-4.1,4.1v0.5c-0.9,0.2-1.7,0.5-2.6,0.9c-3.6,1.7-7.2,5.6-7.2,10.8v7.4c0,5.2-1.8,9.7-5.5,13.3
                                            C0,37.3-0.1,37.6,0,37.9c0.1,0.3,0.3,0.5,0.6,0.6l7.8,1.3c1.4,0.2,2.8,0.5,4,0.6c1.4,2.8,4.2,4.6,7.3,4.6c3.1,0,5.9-1.8,7.3-4.6
                                            c1.3-0.2,2.6-0.4,4-0.6l7.8-1.3c0.3-0.1,0.5-0.3,0.6-0.6C39.6,37.6,39.5,37.3,39.3,37.1z M17.2,4.1c0-1.4,1.1-2.5,2.5-2.5
                                            s2.5,1.1,2.5,2.5v0.1c-1.6-0.2-3.3-0.2-4.9,0V4.1z M19.8,43.4c-2.1,0-4-1-5.3-2.7c1.8,0.2,3.5,0.3,5.2,0.3c1.7,0,3.4-0.1,5.3-0.3
                                            C23.8,42.3,21.9,43.4,19.8,43.4z M30.8,38.2c-1.6,0.3-3,0.5-4.4,0.7c0,0,0,0,0,0c-0.6,0.1-1.2,0.1-1.8,0.2c0,0,0,0,0,0
                                            c-0.5,0.1-1,0.1-1.5,0.1c-0.1,0-0.2,0-0.3,0c-0.4,0-0.9,0.1-1.3,0.1c-0.1,0-0.2,0-0.3,0c-1,0-2,0-3,0c-0.1,0-0.2,0-0.3,0
                                            c-0.4,0-0.9,0-1.3-0.1c-0.1,0-0.2,0-0.3,0c-0.5,0-1-0.1-1.5-0.1c0,0,0,0-0.1,0c-0.6-0.1-1.1-0.1-1.7-0.2c0,0,0,0,0,0
                                            c-1.4-0.2-2.8-0.4-4.4-0.7l-6.3-1.1c3.3-3.7,4.9-8.2,4.9-13.4v-7.4c0-4.4,3.2-7.9,6.3-9.3c0.9-0.4,1.9-0.7,2.9-1c0,0,0,0,0,0
                                            c2-0.5,4.2-0.5,6.2-0.1c0,0,0,0,0,0c1.1,0.2,2.1,0.6,3.1,1c3,1.4,6.1,4.8,6,9.3v7.4c0,4.9,1.8,9.5,5.2,13.4L30.8,38.2z"/>
                                        <path className="st0" d="M24.9,9.3c-3.2-1.4-7.1-1.5-10.2,0c-1.8,0.8-4.8,3.3-4.9,7.1c0,0.5,0.4,0.8,0.8,0.8c0,0,0,0,0,0
                                            c0.4,0,0.8-0.4,0.8-0.8c0-3,2.5-4.9,3.9-5.6c2.7-1.3,6.1-1.3,8.8,0c0.4,0.2,0.9,0,1.1-0.4C25.5,9.9,25.3,9.5,24.9,9.3z"/>
                                    </g>
                                </svg>
                            </div>
                            <p>Apenas vídeos do YouTube ou Vímeo. </p>
                            <p>Só comportamos um total de 16 vídeos. </p>
                        </div>

                        <div className='modal-footer'>
                            <Button size='small' text='Cancelar' type='warning' click={onCancel}/>
                            <Button disabled={error}  size='small' text='Salvar' type='warning' click={() => onSave(url, caption)}/>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

const EmbeddedVideoHosts = {
    YouTube: 'YouTube',
    Vimeo: 'Vimeo',
};

const matchingHost = (url = '') => Object.keys(HostsUrls).find(host => HostsUrls[host].test(url));

const HostsUrls = {
    [EmbeddedVideoHosts.YouTube]: {
        url: (id) => `https://www.youtube.com/embed/${id}?autoplay=1&showinfo=0`,
        test: (url) => /(?:youtube(?:-nocookie)?\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([\w\d_-]{11})/gi.test(url),
        id: (url) => /(?:youtube(?:-nocookie)?\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([\w\d_-]{11})/gi.exec(url)[1],
        thumbnail: (id) => Axios.get(`https://noembed.com/embed?url=https://www.youtube.com/watch?v=${id}`).then(response => response.data.thumbnail_url),
        title: (id) => Axios
            .get(`https://noembed.com/embed?url=https://www.youtube.com/watch?v=${id}`)
            .then(response => response.data.title),
    },
    [EmbeddedVideoHosts.Vimeo]: {
        url: (id) => `https://player.vimeo.com/video/${id}?portrait=0&byline=0&autoplay=1`,
        test: (url) => /vimeo\./.test(url),
        id: (url) => /vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|)(\d+)(?:|\/\?)/.exec(url)[2],
        thumbnail: (id) => Axios
            .get(`https://vimeo.com/api/v2/video/${id}.json`)
            .then(response => response.data[0].thumbnail_large),
        title: (id) => Axios
            .get(`https://vimeo.com/api/v2/video/${id}.json`)
            .then(response => response.data[0].title),
    },
};

const splitIntoChunks = ([...pictures], chunkSize = 2) => {
    const chunks = [];
    while (pictures.length) {
        chunks.push(pictures.splice(0, chunkSize));
    }
    return chunks;
};

const viewportWidth = () => Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

const mapStateToProps = ({Coach}) => ({
    videos: Coach.user.videos,
    edit: Coach.isEdit,
    user: Coach.user
});
const mapDispatchToProps = dispatch => bindActionCreators({
    addVideo,
    deleteVideo,
    editVideo,
    changeCurrentSection,
    changeVisibility
}, dispatch);

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Videos);
