import React from 'react';

import RatingStars from '../../../ratingStarts';
import IconSvg from '../../../../utils/svg';
import { normalizeName } from '../../../../utils/common-functions';
import { Categories } from '../../../../utils/constants';
import { useTranslation } from 'react-i18next';

const style = { justifyContent: 'flex-end' };

const CoachInfo = ({ coach }) => {
    const { t } = useTranslation();
    const { firstName, secondName, photoUrl, rating, username, expertises } = coach;

    const categories =
        expertises && expertises.length > 0
            ? Categories.filter((e) => expertises.split(',').includes(e.name))
            : [];

    return (
        <div className='container-coach'>
            <IconSvg
                name='icon-ok'
                width={25}
                classDiv='modal-coach-icon-ok'
                styleDiv={style}
            />
            <img src={photoUrl} alt='coach' />
            <span>
                {normalizeName(firstName)} {normalizeName(secondName)}
            </span>
            {categories.length > 0 && (
                <div className='container-flag'>
                    {categories.map((e) => e.minifyName).join(' & ')}
                </div>
            )}
            <RatingStars color='#fff' height={20} width={20} rating={rating} />

            <button>
                <a target='_blank' href={`/${username}`} rel='noreferrer'>
                    {t('VISIT_THE_WEBSITE')}
                </a>
            </button>
        </div>
    );
};

export default CoachInfo;
