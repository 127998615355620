import React from 'react';
import { useTranslation } from 'react-i18next';
import Input from '../../../../../components/Input';
import IconSvg from '../../../../../utils/svg';
import Select from 'react-select';

import './graduation.css';

const currentYear = new Date().getFullYear();
const yearRange = 100;
const optionsYear = new Array(yearRange).fill(currentYear).map((item, index) => ({label: item - index,value: item - index}));


const colourStyles = {
    control: (styles, props) => ({
        ...styles,
        backgroundColor: 'transparent',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: props.selectProps.required ? '#ff0000' : '#FE7800',
        borderRadius: '5px'
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                    ? '#fff'
                    : isFocused
                        ? '#fff'
                        : undefined,
            color: isDisabled
                ? '#ccc'
                : 'black',
            cursor: isDisabled ? 'not-allowed' : 'pointer',
            padding: 15,
            fontFamily: 'Montserrat',
            fontWeight:'600',
            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled
                    ? isSelected
                        ? '#fff'
                        : '#fff'
                    : undefined,
            },
            ':hover': {
                ...styles[':hover'],
                color: '#fff',
                backgroundColor: '#FE7800'
            },
        };
    },
    input: (styles) => ({ ...styles }),
    placeholder: (styles) => ({
        ...styles,
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontSize: '0.8REM',
    }),
    singleValue: (styles) => ({
        ...styles,
        fontFamily: 'Montserrat',
        fontSize: '14px',
        color: '#FFFFFF',
    }),
    clearIndicator: (styles) => ({
        ...styles,
        color: '#FE7800',
    }),
    indicatorSeparator: (styles) => ({
        ...styles,
        background: '#FE7800',
    }),
    dropdownIndicator: (styles) => ({
        ...styles,
        color: '#FE7800',
    }),
    multiValue: (styles) => {
        return {
            ...styles,
            backgroundColor: '#FE7800',
        };
    },
    multiValueLabel: (styles) => ({
        ...styles,
        color: '#FFFFFF',
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '0.8REM',
        lineHeight: '20px',
    }),
    multiValueRemove: (styles) => ({
        ...styles,
        color: '#000',
        ':hover': {
            ...styles[':hover'],
            backgroundColor: '#fff',
            color: '#000',
        },
    }),
};

function GraduationData({data, setData, errors, handleAdd, optionsActing}) {
    const {t} = useTranslation();

    function handleChange(value, key, idx) {
        if (key === 'acting') {
            let item = {...data};
            item = {...item, [key]: value};
            setData(item);
        }
        if (key === 'year') {
            let currentData = {
                ...data,
                'formations': [...data.formations],
            };
            currentData.formations[idx][key] = value;
            setData(currentData);
        }
    }
    
    const handleRemove = (index) => {
        const removeAtt = data.formations.filter((ele, idx) => idx !== index);

        setData((e) => ({
            ...e,
            'formations': removeAtt,
        }));
    };

    return (
        <div className='graduation-container'>
            <div className='title'>{t('FORMATION')}:</div>
            <div className='line-1-formation'>
                <div className='form-select'>
                    <label className='select-acting-area'>{t('OCCUPATION_AREA')} *</label>
                    <Select
                        id='acting-select'
                        className='acting-area-select'
                        placeholder={t('ADD_OCCUPATION_AREA')}
                        options={optionsActing}
                        value={data.acting}
                        onChange={(values) => handleChange(values, 'acting')}
                        isMulti
                        styles={colourStyles}
                        required={errors && errors.acting}
                        isOptionDisabled={() => data.acting && data.acting.length === 3}
                        noOptionsMessage={() => t('NO_OPTIONS')}
                    />
                    <span className='error'>{errors && errors.acting ? `*${t('FIELD_NULL')}` : null}</span>
                </div>
            </div>
            {
                data.formations.map((formation, index) => (
                    <Graduations
                        key={index}
                        idx={index}
                        formation={formation}
                        data={data}
                        setData={setData}
                        handleChange={handleChange}
                        handleRemove={() => handleRemove(index)}
                    />
                )) 
            }
            <div className='btn-add-div'>
                <button
                    onClick={() => handleAdd()}
                    className={`${data.formations.length === 10 ? 'btn-add-disabled': 'btn-add'}`}
                    disabled={data.formations.length === 10 ? true : false}    
                >
                    + {t('ADD_NEW_FORMATION')}
                </button>
            </div>
        </div>
    );
}

const Graduations = ({
    formation,
    idx,
    data,
    setData,
    handleChange,
    handleRemove,
}) => {
    
    const {t} = useTranslation();
    const changeValue = (key, value) => {
        let currentData = {
            ...data,
            'formations': [
                ...data.formations]
        };

        currentData.formations[idx][key] = value;
        setData(currentData);
    };

    return (
        <div className='line-2-formation'>
            <div className='line-2-formation-inputs'>
                <div className='inputs-form-items'>
                    <div className='form-item'>
                        <Input
                            label={t('COURSE')}
                            value={formation.course}
                            type='text'
                            onChange={({target}) => changeValue('course', target.value)}
                            placeholder={t('COURSE')}
                            className={'input-contact'}
                        />
                    </div>
                    <div className='form-item'>
                        <Input
                            label={`${t('SCHOOL')}/${t('UNIVERSITY')}`}
                            value={formation.school}
                            type='text'
                            onChange={({target}) => changeValue('school', target.value)}
                            placeholder={t('PLACE_OF_YOUR_FORMATION')}
                            className={'input-contact'}
                        />
                    </div>
                </div>
                <div className={'form-select'}>
                    <label className='select-acting-area'>{t('YEAR')}</label>
                    <Select
                        id='acting-select'
                        className='year-area-select'
                        placeholder={t('YEAR')}
                        options={optionsYear}
                        value={formation.year}
                        onChange={(values) => handleChange(values, 'year', idx)}
                        styles={colourStyles}
                        noOptionsMessage={() => t('NO_OPTIONS')}
                    />
                </div>
            </div>
            <div className='div-btn-remove'>
                <button
                    onClick={handleRemove}
                    style={{cursor: `${data.formations.length === 1 ? '' : 'pointer'}` }}
                    disabled={data.formations.length === 1 ? true : false}
                >
                    <IconSvg
                        name='trash'
                        width={30}
                        height={30}
                        color={data.formations.length === 1 ? 'gray' : '#FE7800'}
                    />
                </button>
            </div>
        </div>
    );
};

export default GraduationData;