import React from 'react';

import Footer from '../home/components/footer';
import PostList from './components/postList';

import './blog.scss';

function Blog({match}) {
    const {category} = match.params;

    return (
        <main className='blog-page'>
            <PostList category={category} />
            <Footer />
        </main>
    );
}

export default Blog;
