import React, { useState, useMemo } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Loading from 'react-loading';
import {useTranslation} from 'react-i18next';

import Header from '../customize-page/components/header';
import PersonalData from './components/forms/personal-data/personal-data';
import GraduationData from './components/forms/graduation-data/graduation-data';
import AnswersData from './components/forms/answers-data/answers-data';
import DescriptionData from './components/forms/description-data/description-data';
import IconSvg from '../../utils/svg';
import { history } from '../../utils/store';
import { validEmail } from '../../utils/common-functions';
import {asyncFinishForm} from './step-forms.actions';
import {asyncGenerateDescription} from './step-forms.actions';

import './steps-forms.css';

const newFormation = {
    course: '',
    school: '',
    year: '',
};

const initialData = {
    acting: [],
    formations: [{
        course: '',
        school: '',
        year: '',
    }],
    name: '',
    email: '',
    phone: '',
    answer1: '',
    answer2: '',
    answer3: '',
    description: '',
};

const optionsActing = [
    {label: 'Life', value: 'Life'},
    {label: 'Família', value: 'Família'},
    {label: 'Carreira', value: 'Carreira'},
    {label: 'Saúde', value:'Saúde'},
    {label: 'Finanças', value:'Finanças'},
    {label: 'Business', value:'Business'},
    {label: 'Esportes', value:'Esportes'}
];

function initFormData(coachData, initialData) {
    const {fullName: name, email, tel: phone, expertises} = coachData;
    const parsedExpertises = expertises.map(item => optionsActing.find(i => i.value === item)).filter(i => i);

    return {...initialData, name, email, phone, acting: parsedExpertises};
}

function StepsForms() {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const coachData = useSelector(({Coach}) => Coach.user);
    const isFormLoading = useSelector(({StepForms}) => StepForms.isLoading);
    const isDescriptionLoading = useSelector(({StepForms}) => StepForms.descriptionLoading.isLoading);
    const isLoading = isFormLoading || isDescriptionLoading;

    const [step, setStep] = useState(0);
    const [errors, setErrors] = useState();
    const [data, setData] = useState(initFormData(coachData, initialData));

    const valuesObjects = useMemo(() => {
        return Object.values(data);
    }, [data]);

    const handleAdd = () => {
        setData((e) => ({
            ...e,
            'formations': [...e.formations, {...newFormation}],
        }));
        setErrors();
    };

    const verticalSteps = [
        <PersonalData
            data={data}
            setData={setData}
            errors={errors}
            invalidEmail={!validEmail(data.email)}
        />,
        <GraduationData
            data={data}
            optionsActing={optionsActing}
            setData={setData}
            errors={errors}
            handleAdd={handleAdd}
        />,
        <AnswersData
            data={data}
            setData={setData}
            errors={errors}
        />,
        <DescriptionData
            data={data}
            setData={setData}
            errors={errors}
        />,
    ];

    const buttonLabel = step < verticalSteps.length - 1 ? t('ADVANCE') : t('FINISH');

    const handleForm = (index) => {
        const skip = true;
        if (index < step) {
            setStep(index);
        } else if (index === step) {
            return null;
        } else {
            handleNextForm(step, skip);
        }
    };

    const handleNextForm = (stepCurrent, skip) => {
        const nextStep = stepCurrent + 1;
        if (stepCurrent === 0) {
            checkStep1(valuesObjects, nextStep);
        }
        if (stepCurrent === 1) {
            checkStep2(valuesObjects, nextStep);
        }
        if (stepCurrent === 2) {
            checkStep3(valuesObjects, nextStep, skip);
        }
        if (stepCurrent === 3) {
            checkStep4(valuesObjects, skip);
        }
    };

    const checkStep1 = (valuesObjects, nextStep) => {
        const erros = valuesObjects.map((e) => !e ? true : false).splice(2,2);
        setErrors(erros);

        if (erros && !erros.includes(true) && validEmail(data.email)) {
            setStep(nextStep);
            setErrors();
        }
    };

    const checkStep2 = (valuesObjects, nextStep) => {
        let error = {
            'acting': !valuesObjects[0] || valuesObjects[0].length === 0 ? true : false,
        };
        setErrors(error);

        if (error && error.acting === false) {
            setStep(nextStep);
            setErrors();
        }
    };

    const checkStep3 = (valuesObjects, nextStep, skip) => {
        const erros = valuesObjects.map((e) => !e ? true : false).slice(0, 8);
        erros.splice(4, 1);
        setErrors(erros);

        if (erros && !erros.includes(true)) {
            if (!skip) {
                dispatch(asyncGenerateDescription(data));                
            } 
            setStep(nextStep);
            setErrors();
        }
    };

    const checkStep4 = (valuesObjects, skip) => {
        const descriptionError = valuesObjects[valuesObjects.length - 1].trim() === '';
        const formErrors = [descriptionError];
        setErrors(formErrors);

        if (!formErrors.includes(true)) {
            if (!skip) {
                dispatch(asyncFinishForm(data));
            }
            setErrors();
        }
    };

    const handleBack = () => {
        const previous = step - 1;
        if (previous === -1 ) {
            history.push('/customize');
            setErrors();
        } else {
            setErrors();
            setStep(previous);
        }
    };

    return (
        <div className='container-forms'>
            <Header stepForms={true} handleBack={handleBack} className='header-forms'/>
            <main className='main-forms'>
                <div className='central-form'>
                    <div className='step-form'>
                        {
                            verticalSteps.map((ele, idx) => 
                                <button
                                    className='circle-btn'
                                    style={{background: `${step >= idx ? '#FE7800' : ''}`}}
                                    onClick={() => handleForm(idx)}
                                    key={idx}
                                >
                                    0{idx + 1}
                                </button>
                            )
                        }
                    </div>
                    <div className='forms'>
                        <button
                            onClick={handleBack}
                            type='button'
                            className='btn-back-form'
                        >
                            <IconSvg
                                name="arrow-left"
                                width={45}
                                height={37}
                                classDiv="btn-back"
                            />
                        </button>
                        {
                            verticalSteps.map((ele, idx) => {
                                if (idx === step) {
                                    return ele;
                                }
                                return true;
                            })
                        }
                        <div className='btn-final-div'>
                            <button
                                disabled={isLoading}
                                onClick={() => handleNextForm(step)}
                                type='button'
                                className={`${step === verticalSteps.length - 1 ? 'btn-final btn-continue-form' : 'btn-continue-form'}`}
                            >
                                {isLoading
                                    ? <Loading type='spin' delay={0} width={26} height={26} />
                                    : buttonLabel
                                }
                                
                            </button>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default StepsForms;
