import {actions} from '../../utils/constants';

const INITIAL_STATE = {
    descriptions: [],
    isLoading: false,
    currentDescriptionIndex: 0,
    descriptionLoading: {
        isLoading: false,
        type: '',
    },
};

export default(state= INITIAL_STATE, {type, payload}) => {
    switch (type) {
    case actions.INIT_FORM_LOADING:
        return {...state, isLoading: true};
    case actions.END_FORM_LOADING:
        return {...state, isLoading: false};
    case actions.SET_COACH_DESCRIPTIONS:
        return {...state, descriptions: payload};
    case actions.SET_DESCRIPTION_LOADING:
        return {...state, descriptionLoading: payload};
    case actions.SET_CURRENT_DESCRIPTION_INDEX:
        return {...state, currentDescriptionIndex: payload};
    default:
        return {...state};
    }
};
