import { startCase } from 'lodash';
import React from 'react';
import { returnStrWithLineBreak } from '../../../../../../../utils/common-functions';

import './biography-info.css';

function BiographyInfo({
    fullName,
    aboutMe,
    expertises
}) {
    return (
        <article className="biography-info">
            <div className='biography-box-1'>
                <h4>{fullName}</h4>
                <p>{[...returnStrWithLineBreak(aboutMe, '\n')]}</p>
            </div>

            <div className='biography-box-2'>
                <h5>Área de atuação</h5>
                <p> {expertises.map((item, index) => (`${index ? ' - ' : ''} ${startCase(item)}`))}</p>
            </div>
        </article>
    );
}

export default BiographyInfo;