import React, {memo} from 'react';

import './input.css';

function InputWrapper({id, label, type, optional = true, errorMessage,className, error, ...props}) {
    return (
        <div className={`input-wrapper-container ${className || ''}`}>
            {label && <label htmlFor={id}>{label} {!optional && <span>*</span>}</label>}
            {
                type === 'area' ? (
                    <textarea id={id} {...props} />
                ) : (
                    <input id={id} type={type} {...props} />
                )
            }
            {
                error && <span className='error'>{errorMessage}</span>
            }
        </div>
    );
}

export default memo(InputWrapper);