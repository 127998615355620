import React, { useState } from 'react';

import IconSvg from '../../../../../../utils/svg';
import VideoCard from './video-card/video-card';
import TitleSection from '../../../../../../components/TitleSection/TitleSection';
import SlickCarousel from '../../../../../../components/SlickCarousel/slickCarousel';
import { ZoomedVideoModal } from '../../../../components/videos/videos';

import './videos.css';
import { useTranslation } from 'react-i18next';

function Videos({ videos, onClick, themeColor }) {
    const {t} = useTranslation();

    if (!videos || !videos.length) {
        return null;
    }

    const [zoomVideo, setZoomVideo] = useState(null);

    const settings = {
        dots: false,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <IconSvg name='icon-arrow' width='45px' color={themeColor} classDiv='arrowPrev' />,
        nextArrow: <IconSvg name='icon-arrow' width='45px' color={themeColor} classDiv='arrowNext' />,
    };

    return (
        <section
            id="videos"
            onClick={() => onClick('videos')}
            className="template--7--videos">
            <div className='template--7--videos-container'>
                <TitleSection text1={t('VIDEOS')} colorIcon={themeColor} iconView={true} iconName='icon-text-effect' iconWidth='39px' />

                <SlickCarousel className="generic--slider" settings={settings}>
                    {videos.map((video, i) => (
                        <div className="generic--slider-item" key={i}>
                            <VideoCard
                                key={i}
                                onClick={() => setZoomVideo(video)}
                                {...video} />
                        </div>
                    ))}
                </SlickCarousel>

                <ZoomedVideoModal
                    video={zoomVideo}
                    onClose={() => setZoomVideo(null)}
                />
            </div>

        </section>
    );
}

export default Videos;