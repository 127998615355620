import React from 'react';
import IconSvg from '../../../../../../../utils/svg';

import './video-card.css';

function VideoCard({
    caption,
    thumbnail,
    onClick,
    match
}) {
    return (
        <div className="template--6-video-card" onClick={onClick} >

            <div className='template--6-video-image'>
                <img src={thumbnail} alt='title' />

                <div className="player-hover">
                    <IconSvg name="icon-player-videos" classDiv="icon-play-float" width={50} color="#fff" />
                </div>
            </div>

            <div className="video-infos">
                <span className="video-youtube"> {match} </span>

                <p className="video-subtitle">
                    {caption}
                </p>

                <button className="video-btn" type="button">
                    <IconSvg name="icon-player-videos" classDiv="icon-play-float" width={27} color="#fff" />
                    Assistir Vídeo
                </button>
            </div>
        </div>
    )
};

export default VideoCard;