import React from 'react';
import Icon from '../../utils/svg';

import './TitleSection.css';

const TitleSection = ({
    text1,
    text2, 
    iconView=false,
    colorIcon,
    style, 
    text2Style,
    iconName='icon-waves',
    iconWidth='50',
    iconHeight='20'
}) => (

    <div className="title-section-content">
        <h3 style={style}> 
            {text1}

            {text2 &&
                <span style={text2Style}> {text2}</span>
            } 
        </h3 >

        {iconView &&
            <Icon name={iconName} width={iconWidth} height={iconHeight} color={colorIcon} />
        }
    </div >
);

export default TitleSection;