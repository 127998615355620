import React from 'react';
import { validateSocialMediaUrl } from '../../../../../utils/common-functions';
import IconSvg from '../../../../../utils/svg';
import './social-medias.css';

function SocialMedias({
    facebook,
    linkedin,
    instagram,
    twitter,
    whatsapp,
    youtube,
    sizeIcons = 30,
    direction = "row",
    color = "#fff" }) {
    return (
        <ul
            style={{ flexDirection: direction }}
            className="list-of-social-medias">
            <SocialItem iconName="icon-facebook" type='facebook' url={facebook} color={color} size={sizeIcons} />
            <SocialItem iconName="icon-linkedin" type='linkedin' url={linkedin} color={color} size={sizeIcons} />
            <SocialItem iconName="icon-twitter" type='twitter' url={twitter} color={color} size={sizeIcons} />
            <SocialItem iconName="icon-youtube" type='youtube' url={youtube} color={color} size={sizeIcons} />
            <SocialItem iconName="icon-instagram" type='instagram' url={instagram} color={color} size={sizeIcons} />
            <SocialItem iconName="icon-whatsapp" type='whatsapp' url={whatsapp} color={color} size={sizeIcons} />
        </ul>
    );
}

const SocialItem = ({ type, url, color, iconName, size }) => url ? (
    <li>
        <a href={`https://${validateSocialMediaUrl(type, url)}`} target="_blank" rel="noreferrer">
            <IconSvg name={iconName} width={size} height={size} color={color} />
        </a>
    </li>
) : null;


export default SocialMedias;