import { actions, regexEmail, basepath, APIroutes } from '../../../../utils/constants';
import { all, takeEvery, put, select, call, takeLatest, delay } from 'redux-saga/effects';
import { getName, getCity, getEmail, getMessage, getSubject, getTel } from './selector';
import {  trackEvent } from '../../../../utils/analytics';
import Axios from 'axios';

export function* checkEmailValidate( action ) {
    if (action.payload !== '') {
        yield put({ type: actions.EMAIL_INVALID, payload: !(regexEmail).test(action.payload)});
        if(!(regexEmail).test(action.payload)) {
            yield put({ type: actions.LOCK_BUTTON, payload: true});
        }
    } else {
        yield put({ type: actions.EMAIL_INVALID, payload: false});
    }
}

export function* changeValue(action) {
    if ( Object.keys(action.payload) === 'tel') {
        action.payload.value = String(action.payload.tel).replace(/[()_-]/g, '');
    }
    yield put({ type: actions.CHANGE_VALUE, payload: action.payload});
    yield call(checkFormValues);
}

export function* watchChangeValue() {
    yield takeEvery(actions.ASYNC_CHANGE_VALUE, changeValue);
}

export function* checkFormValues() {
    const name = yield select(getName), city = yield select(getCity),
        email = yield select(getEmail), tel = yield select(getTel), 
        subject = yield select(getSubject), message = yield select(getMessage);

    if ( name && city && (regexEmail).test(email) && tel && subject && message ) {
        yield put({ type: actions.LOCK_BUTTON, payload: false });  
    } else {
        yield put({ type: actions.LOCK_BUTTON, payload: true});
    }
}

export function* sendForm() {
    yield put({ type: actions.LOCK_BUTTON, payload: true});
    const name = yield select(getName), city = yield select(getCity),
        email = yield select(getEmail), tel = yield select(getTel), 
        subject = yield select(getSubject), message = yield select(getMessage),
        username = yield select( state => state.Coach.username);

    trackEvent('contact', 'send form to coach', 'sending message');
    try {
        let url = `${basepath}${APIroutes.contact}`.replace('{username}', username);
        yield call(Axios.put, url, {
            name,
            email,
            city,
            phone: tel,
            subject,
            message
        });
        yield put({ type: actions.CLEAR_FORM });
        yield put({type: actions.SEND_FORM, payload: true});
        yield delay(5000);
        yield put({type: actions.SEND_FORM, payload: false});
        yield put({ type: actions.LOCK_BUTTON, payload: true});
    } catch (error) {
        yield put({type: actions.ERROR_SEND_FORM, payload: true});
        yield delay(5000);
        yield put({type: actions.ERROR_SEND_FORM, payload: false});
        yield put({ type: actions.LOCK_BUTTON, payload: true});
    }
}

export function* watchSendForm() { yield takeLatest(actions.ASYNC_CLEAR_FORM, sendForm); }

export function* watchCheckEmail() { yield takeLatest(actions.CHECK_EMAIL, checkEmailValidate); }

export default function* contactSaga() {
    yield all([
        watchChangeValue(),
        watchSendForm(),
        checkFormValues(),
        watchCheckEmail()
    ]);
}