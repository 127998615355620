import React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { asyncSendEmail } from '../../../../screens/coaches-list/coaches-list.actions';
import { validEmail } from '../../../../utils/common-functions';
import Input from '../../../Input';
import PhoneInput from 'react-phone-input-2';
import { phoneCountries } from '../../../../utils/constants';

import './sendYourMessage.css';

const SendYourMessage = ({
    coach,
    data,
    setData,
    setOpen,
    setLoading,
    setError}) => {

    const { t } = useTranslation();
    const dispatch = useDispatch(); 

    const invalidEmail = !validEmail(data.email);
    const hasError =
        !data.name ||
        !data.email ||
        !data.subject ||
        !data.message ||
        invalidEmail;

    const changeValue = useCallback(
        (key) => {
            return useCallback(({ target }) => {
                setData((e) => ({ ...e, [key]: target.value }));
            }, []);
        },
        [data]
    );

    const changePhone = (value) => {
        setData((data) => ({ ...data, phone: value.valueOf() }));
    };

    const onConfirm = () => {
        setLoading(true);
        setOpen(true);
        dispatch(
            asyncSendEmail({
                id: coach.id,
                ...data,
                callback: (error = false) => {
                    setError(error);
                    setLoading(false);
                },
            })
        );
    };

    return (
        <>
            <div>
                <div className="form-item">
                    <Input
                        optional={false}
                        label={t('NAME')}
                        value={data.name}
                        type="text"
                        onChange={changeValue('name')}
                        placeholder={t('FULL_NAME')}
                        className="input-contanct"
                    />
                </div>
                <div className="form-item">
                    <Input
                        optional={false}
                        label="Email"
                        value={data.email}
                        type="text"
                        onChange={changeValue('email')}
                        placeholder={t('YOUR_EMAIL')}
                        className="input-contanct"
                        error={!data.email ? false : invalidEmail}
                        errorMessage={`*${t('INVALID_MAIL')}`}
                    />
                </div>
                <div className="phone-input">
                    <label>{t('TELEPHONE')}</label>
                    <PhoneInput
                        country={phoneCountries.defaultCountry}
                        onChange={changePhone}
                        value={data.phone}
                        autoFormat={true}
                        countryCodeEditable={false}
                        preferredCountries={phoneCountries.preferredCountries}
                    />
                </div>
                <div className="form-item">
                    <Input
                        optional={false}
                        label={t('SUBJECT')}
                        value={data.subject}
                        type="text"
                        onChange={changeValue('subject')}
                        placeholder={t('SUBJECT')}
                        className="input-contanct double-item"
                    />
                    <Input
                        label={t('CITY')}
                        value={data.city}
                        type="text"
                        onChange={changeValue('city')}
                        placeholder={t('YOUR_CITY')}
                        className="input-contanct double-item"
                    />
                </div>
                <div className="form-item">
                    <Input
                        optional={false}
                        label={t('BRIEF_DESCRIPTION')}
                        value={data.message}
                        type="area"
                        onChange={changeValue('message')}
                        placeholder={t('MESSAGE')}
                        className="input-contanct"
                    />
                </div>
            </div>
            <div className="button-contact">
                <button
                    onClick={onConfirm}
                    className={hasError ? 'disable' : ''}
                    disabled={hasError}
                >
                    {t('SEND')}
                </button>
            </div>
        </>
    );
};

export default SendYourMessage;