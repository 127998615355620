import { actions } from '../../utils/constants';

const INITIAL_STATE = {
    user: {
        firstName: 'Nome',
        secondName: 'Sobrenome',
        tel: '85 999999999',
        email: 'seuemail@exemplo.com.br',
        photoUrl: 'http://www.saparch.com/public/widget/1_widget_john-doe.png',
        backgroundImg: {
            desktop: 'https://ak1.picdn.net/shutterstock/videos/26264891/thumb/9.jpg',
            mobile: 'https://ak1.picdn.net/shutterstock/videos/26264891/thumb/9.jpg'
        },
        coachUrl: '',
        aboutMe: '',
        facebook: '',
        twitter: '',
        instagram: '',
        youtube: '',
        linkedin: '',
        mainPhrase: '',
        coachingHours: 0,
        enableCoachingHours: true,
        febracisCourses: 0,
        enableFebracisCourses: true,
        lecturesHours: 0,
        enableLecturesHours: true,
        resumeFeed: '',
        enableBiography: true,
        enableResume: true,
        enableResumeFooter: true,
        enableGallery: true,
        enableVideos: true,
        enableTestimonies: true,
        theme: 'template-1',
        themeColor: '#ecc22e',
        expertises: [],
        city: '',
        state: '',
        country: '',
    },
    canEdit: false,
    menuOpen: false,
    loadingEdit: false,
    isLoading: true,
    showConfig: false,
    showChangeBackground: false,
    viewMode: false,
    backgroundImgs: [],
    backImgsLoading: false,
    expertisesArray: [],
    bannerImg:'',
    disableSave: false,
    searchingUsername: false,
    refreshToken: '',
    token: '',
    currentSection: 'general',
    username: '',
    disableSaveConfig: false,
    countryList: [],
    country: {
        name: '',
        states: [],
        cities: []
    },
    stateDisabled: false,
    fabToogle: false,
    coachDetails: {},
    selectedTab: 'experimental_session',
    loadingSessionRequest: true,
    countryListAndCities: [],
    isMobile: false,
};

export default (state = INITIAL_STATE, action) => {

    switch (action.type) {

    case actions.ADD_BANNER_IMAGE:
        return { ...state, bannerImg: action.payload };

    case actions.FAB_TOOGLE:
        return { ...state, fabToogle: !state.fabToogle };

    case actions.CHANGE_LOADING_EDIT:
        return { ...state, loadingEdit: action.payload };

    case actions.SET_SEARCHING_USERNAME:
        return { ...state, searchingUsername: action.payload };

    case actions.TOOGLE_CONFIG:
        return { ...state, showConfig: !state.showConfig };

    case actions.DISABLE_CHECKBOX:
        return { ...state, stateDisabled: action.payload };

    case actions.SET_COUNTRY_LIST:
        return { ...state, countryList: action.payload };

    case actions.CHANGE_CURRENT_SECTION_EDIT:
        return { ...state, currentSection: action.payload };

    case actions.SET_COUNTRY:
        return { ...state, country: action.payload };

    case actions.TOOGLE_CONFIG_SAVE:
        return { ...state, disableSaveConfig: action.payload };

    case actions.BACK_IMGS_LOADING:
        return { ...state, backImgsLoading: true };

    case actions.BACK_IMGS_LOADED:
        return { ...state, backImgsLoading: false };

    case actions.SET_EXPERTISES_ARRAY:
        return { ...state, expertisesArray: action.payload };

    case actions.SET_USER_EXPERTISE:
        return { ...state, user: { ...state.user, expertises: action.payload } };

    case actions.SET_BACK_IMGS:
        return { ...state, backgroundImgs: action.payload };

    case actions.TOOGLE_BACKGROUND:
        return { ...state, showChangeBackground: !state.showChangeBackground };

    case actions.CLOSE_MODAL:
        return { ...state, [action.payload]: false };

    case actions.SET_BACKGROUND_IMG:
        return { ...state, user: { ...state.user, backgroundImg: action.payload } };

    case actions.SET_CAN_EDIT:
        return { ...state, canEdit: action.payload };

    case actions.MENU_OPEN:
        return { ...state, menuOpen: action.payload };

    case actions.EDIT_USER:
        return { ...state, user: { ...state.user, [action.payload.id]: action.payload.value } };

    case actions.AVATAR_UPDATED:
        return { ...state, user: { ...state.user, photoUrl: action.payload } };

    case actions.SET_USER:
        return { ...state, user: { ...state.user, ...action.payload } };

    case actions.LOAD_COACH:
        return { ...state, isLoading: true };

    case actions.LOAD_COACH_FINISHED:
        return { ...state, isLoading: false };

    case actions.CHANGE_VISIBILITY:
        return { ...state, user: { ...state.user, [action.payload]: !state.user[action.payload] } };

    case actions.SET_PUBLISH_PROFILE:
        return { ...state, user: { ...state.user, published: action.payload } };

    case actions.CHANGE_SAVE_DISABLE:
        return { ...state, disableSave: action.payload };

    case actions.SAVE_REFRESH_TOKEN:
        return { ...state, refreshToken: actions.payload };

    case actions.SAVE_TOKENS:
        return { ...state, [action.payload.token]: action.payload.value };

    case actions.SAVE_USERNAME:
        return { ...state, username: action.payload };

    case actions.SELECT_TEMPLATE:
        return { ...state, user: { ...state.user, theme: action.payload } };

    case actions.SELECT_TEMPLATE_COLOR:
        return { ...state, user: { ...state.user, themeColor: action.payload } };
        
    case actions.SET_COACH_DETAILS:
        return {...state, coachDetails: action.payload};

    case actions.SET_LOADING_REQUEST:
        return {...state, loadingSessionRequest: action.payload};

    case actions.SET_COUNTRY_LIST_AND_CITIES:
        return { ...state, countryListAndCities: action.payload };

    case actions.SET_SELECTED_TAB:
        return {...state, selectedTab: action.payload};

    case actions.SET_IS_MOBILE: 
        return {...state, isMobile: action.payload};

    default:
        return state;
    }
};
