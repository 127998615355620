import ReactDOM from 'react-dom';
import React from 'react';

import Button from '../button/button';

export default class FileUploader extends React.Component {

    handleFile(e) {
        const { readAs = ReadAsTypes.DataURL } = this.props;
        const file = e.target.files[0];

        if (!file) {
            return;
        }

        const reader = new FileReader();
        reader.onload = (img) => {
            ReactDOM.findDOMNode(this.upload).value = '';
            if (this.props.setCurrentUri){
                this.props.setCurrentUri(img.target.result);
            }; 
            this.props.handleFileChange(img.target.result);
        };
        if (readAs === ReadAsTypes.ArrayBuffer) {
            reader.readAsArrayBuffer(file);
        } else {
            reader.readAsDataURL(file);
        }


    }

    render() {
        const { children, style } = this.props;

        return (
            <React.Fragment >
                <input
                    id="myInput"
                    type="file" accept='image/*'
                    ref={(ref) => this.upload = ref}
                    onChange={this.handleFile.bind(this)} />
                {children
                    ? <div style={style} onClick={() => this.upload.click()}>{children}</div>
                    : <Button size='small' text={this.props.text} type='warning' click={() => this.upload.click()} />
                }
            </React.Fragment>
        );
    }
}

export const ReadAsTypes = {
    ArrayBuffer: 'ArrayBuffer',
    DataURL: 'DataURL',
};
