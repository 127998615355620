import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import IconSvg from '../../../../utils/svg';
import {asyncGetStatistics, initialCoaches} from '../../../home/home.actions';
import {formatNumber} from '../../../../utils/common-functions';
import CoachesList from '../../../../components/CoachesList';

import './featured-coaches-section.css';
import { useTranslation } from 'react-i18next';

function FeaturedCoachesSection() {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {stats, bestCoaches} = useSelector(({Home}) => Home);

    useEffect(() => {
        dispatch(asyncGetStatistics());
        dispatch(initialCoaches());
    }, []);

    return (
        <section className='featured-coaches-section' id='featured_coaches'>
            <Title t={t} />
            <FeaturedCoachesCarousel featuredCoaches={bestCoaches} />
            <Statistics statsInfo={stats} t={t} />
        </section>
    );
}

function Title({t}) {
    const [title, highlightedWord] = t('FEATURED_COACHES_TITLE').split('*');
    return (
        <header className='featured-coaches-header'>
            <IconSvg name='icon-user2' classDiv='icon-user' />
            <p className='featured-coaches-title'>
                {title}
                <span>{highlightedWord}</span>
            </p>
        </header>
    );
}

function FeaturedCoachesCarousel({featuredCoaches}) {
    const coachesShowns = featuredCoaches.slice(0, 3);
    const slidesLength = coachesShowns.length;
    const responsiveConfig = [
        {
            breakpoint: 1920,
            settings: {
                slidesToShow: featuredCoaches.length > 6 ? 6 : slidesLength,
            }
        },
        {
            breakpoint: 1700,
            settings: {
                slidesToShow: featuredCoaches.length > 5 ? 5 : slidesLength,
            }
        },
        {
            breakpoint: 1360,
            settings: {
                slidesToShow: featuredCoaches.length > 4 ? 4 : slidesLength,
            }
        },
        {
            breakpoint: 1100,
            settings: {
                slidesToShow: featuredCoaches.length > 3 ? 3 : slidesLength,
            }
        },
        {
            breakpoint: 750,
            settings: {
                slidesToShow: featuredCoaches.length > 2 ? 2 : slidesLength,
            }
        },
        {
            breakpoint: 510,
            settings: {
                slidesToShow: 1,
                centerMode: false,
            }
        }
    ];

    const carouselSettings = {
        dots: true,
        infinite: false,
        arrows: false,
        speed: 500,
        slidesToShow: slidesLength > 7 ? 7 : slidesLength,
        slidesToScroll: 1,
        responsive: responsiveConfig,
    };

    return (
        <CoachesList
            isCarousel
            coaches={featuredCoaches}
            classNameCarousel='featured-coaches-carousel'
            settings={carouselSettings}
        />
    );
}

function Statistics({statsInfo, t}) {
    const stats = [{
        text: 'COACHING_HOURS',
        value: formatNumber(statsInfo.hours)
    },
    {
        text: 'SESSIONS_HELD',
        value: formatNumber(statsInfo.sessions)
    },
    {
        text: 'TRANSFORMED_LIVES',
        value: formatNumber(statsInfo.coachees)
    }];

    const statsItems = stats.map(statsItem => {
        return <StatsItem item={statsItem} key={statsItem.text} t={t} />;
    });

    return (
        <ul className='stats-list'>
            {statsItems}
        </ul>
    );
}

function StatsItem({item, t}) {
    return (
        <li className='stats-item' key={item.text}>
            <p className='stats-value'>{item.value}</p>
            <p className='stats-text'>{t(item.text)}</p>
        </li>
    );
}

export default FeaturedCoachesSection;
