import {actions} from '../../utils/constants';

export function asyncFinishForm(payload) {
    return {
        type: actions.ASYNC_FINISH_FORM,
        payload,
    };
}

export function asyncGenerateDescription(payload) {
    return {
        type: actions.ASYNC_GENERATE_DESCRIPTION,
        payload,
    };
}

export function initFormLoading() {
    return {
        type: actions.INIT_FORM_LOADING,
    };
}

export function endFormLoading() {
    return {
        type: actions.END_FORM_LOADING,
    };
}

export function setCoachDescriptions(payload) {
    return {
        type: actions.SET_COACH_DESCRIPTIONS,
        payload,
    };
}

export function setDescriptionLoading(payload) {
    return {
        type: actions.SET_DESCRIPTION_LOADING,
        payload,
    };
}

export function asyncProofreadText(payload) {
    return {
        type: actions.ASYNC_PROOFREAD_TEXT,
        payload,
    };
}

export function asyncRegenerateText() {
    return {
        type: actions.ASYNC_REGENERATE_TEXT,
    };
}

export function setCurrentDescriptionIndex(payload) {
    return {
        type: actions.SET_CURRENT_DESCRIPTION_INDEX,
        payload,
    };
}
