import React from 'react';
import './biography-info.css';
import { returnStrWithLineBreak } from '../../../../../../../utils/common-functions';

function BiographyInfo({ aboutMe }) {
    return (
        <div className="biography-info">
            <p>
                {returnStrWithLineBreak(aboutMe)}
            </p>
        </div>
    );
}

export default BiographyInfo;