import React from 'react';
import { useTranslation } from 'react-i18next';
import { goesTo } from '../../../../../../../utils/common-functions';
import IconSvg from '../../../../../../../utils/svg';
import SocialMedias from '../../../../components/social-medias/social-medias';
import { SESSION_ITEM_NAV } from '../../../../../../../utils/constants';

import './nav-bar-mobile.css';

function navBarMobile({
    itemsNav,
    tel,
    onClose,
    facebook,
    linkedin,
    instagram,
    twitter,
    whatsapp,
    youtube,
    themeColor,
    hasSessionExperimetal
}) {

    const {t} = useTranslation();

    function onSelectSection(url) {
        goesTo(url);
        onClose();
    }

    function handleExperimentalSession() {
        return itemsNav.map((e) => {
            if (hasSessionExperimetal && e.name === 'Contato') {
                return (
                    <li key={SESSION_ITEM_NAV.url} onClick={() => onSelectSection(SESSION_ITEM_NAV.url)}>
                        {t(SESSION_ITEM_NAV.key)}
                    </li>
                );
            } else {
                return(
                    <li key={e.url} onClick={() => onSelectSection(e.url)}>
                        {t(e.key)}
                    </li>
                );
            }
        });
    }

    return (
        <section className={'header--menu-mobile'}>
            <div className='header--menu-mobile-container'>
                <div className='close-btn' onClick={() => onClose()}></div>
                <ul className='header--menu-list'>
                    {handleExperimentalSession()}
                </ul>

                <h2 className="phone">
                    <IconSvg name="icon-whatsapp" width={20} height={20} color={themeColor} />
                    {tel}
                </h2>

                <SocialMedias
                    facebook={facebook}
                    linkedin={linkedin}
                    instagram={instagram}
                    twitter={twitter}
                    youtube={youtube}
                    whatsapp={whatsapp}
                    direction="row"
                    color="#262D52"
                />

            </div>

        </section>
    );
}

export default navBarMobile;