import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import './header-biografia.css';

import { Button, DisableBlock, InputCustom } from '../../../../../commons/index';
import { changeVisibility, selectAsyncExpertise, changeCurrentSection } from '../../../../coach.actions';
import { editProfile, updateAvatar } from '../../header.action';
import HeaderAvatar from '../header-avatar/header-avatar';
import { getViewportWidth, removeConstatsFromExpertises, returnStrWithLineBreak } from '../../../../../../utils/common-functions';
import { withTranslation } from 'react-i18next';
import { startCase } from 'lodash';

class HeaderBiography extends Component {
    constructor(props) {
        super(props);
        this.createCheckboxes = this.createCheckboxes.bind(this);
        this.showEditVersion = this.showEditVersion.bind(this);
    }

    createCheckboxes(array = []) {
        return array.map((e, i) => {
            return (
                <div className='checkbox-input' key={i}>
                    <input type='checkbox' id={e.name} onChange={this.props.selectAsyncExpertise} checked={e.selected} value={e.name} />
                    <label htmlFor={e.name}>{e.name}</label>
                </div>
            );
        });
    }

    goesTo(id) {
        window.location.href = `#${id}`;
    }

    showEditVersion(show) {
        if (show) {
            return (
                <div className='areas-expertise-container'>
                    <small style={{ color: '#F17127' }}>Escolher no máximo 3 áreas de atuação</small>
                    <div className='checkbox-input-group'>
                        {
                            this.createCheckboxes(this.props.expertiseAreas)
                        }
                    </div>
                </div>
            );
        } else {
            return (
                <div className='expertises-list'>
                    {(removeConstatsFromExpertises(this.props.user.expertises)  !== null && removeConstatsFromExpertises(this.props.user.expertises).length > 0) && <h4> Área de atuação </h4>}
                    <ul>
                        {removeConstatsFromExpertises(this.props.user.expertises) !== null && removeConstatsFromExpertises(this.props.user.expertises).map((e, i) => {
                            return <li key={i}>{startCase(e)}</li>;
                        })}
                    </ul>
                </div>
            );
        }
    }

    render() {

        const { photoUrl, aboutMe, firstName, secondName, email, tel, enableBiography, city, state, country, backgroundImg } = this.props.user;
        const vw = getViewportWidth();
        const { activeBiography = true, changeCurrentSection, t } = this.props;

        if (!enableBiography && !this.props.isEdit) {
            return null;
        } else {
            return (
                activeBiography === true &&
                <section className='header-biography'
                    id="biography"
                    onClick={() => changeCurrentSection('biography')} >
                    {/* { theme === 'template-02' &&  */}
                    <div className='header-biography-bg' style={{ background: `url(${backgroundImg !== null ? vw > 768 ? backgroundImg.desktop : backgroundImg.mobile : 'https://cdn.scisapp.com/downloads/images/1521851004245676fbc9cffd08e15.jpeg'})` }}></div>
                    {/* } */}

                    <div className='container'>
                        <div className={`home-container ${this.props.isEdit ? 'edit' : ''}`}>

                            {this.props.isEdit &&
                                <div className='title-wrapper'>
                                    <div className='title-top-bar'></div>
                                    <div className='title'>Biografia</div>
                                </div>
                            }

                            <DisableBlock id={'enableBiography'} show={this.props.isEdit} click={this.props.changeVisibility} enable={enableBiography} />

                            <div className="header-biography-content">
                                <HeaderAvatar
                                    photo={photoUrl}
                                    edit={this.props.isEdit}
                                    onUpdate={this.props.updateAvatar}
                                />
                                <div className='info'>
                                    {!this.props.isEdit && <div className='title'>{`${(firstName && firstName != null) ? firstName : ''} ${(secondName && secondName != null) ? secondName : ''}`}</div>}
                                    {this.props.isEdit &&
                                        <div className='edit-input'>
                                            <input maxLength='25' type='text' onChange={this.props.editProfile} id='firstName' value={firstName} />
                                            <input maxLength='25' type='text' onChange={this.props.editProfile} id='secondName' value={secondName} />
                                        </div>
                                    }
                                    {this.props.isEdit && (firstName === '' || secondName === '') && <div className='error-message'>O nome e sobrenome não devem estar vazios</div>}
                                    {!this.props.isEdit && <div className='tooltip info-content'>
                                        {(aboutMe && aboutMe !== null) && <p>{[...returnStrWithLineBreak(aboutMe, '\n')]}</p>}
                                        {(!aboutMe || aboutMe === null) && <p></p>}
                                    </div>}
                                    {this.props.isEdit &&
                                        <div className='info-content-input'>
                                            <textarea id='aboutMe' maxLength='475' placeholder='Escreva um pouco sobre você' value={(aboutMe && aboutMe != null) ? aboutMe : ''} onChange={this.props.editProfile} />
                                            <span className='character-count' style={{ color: 'rgb(80, 81, 82)' }}>{`${475 - ((aboutMe && aboutMe != null) ? aboutMe.length : ('').length)}  caracteres restantes`}</span>
                                        </div>
                                    }

                                    {
                                        this.showEditVersion(this.props.isEdit)
                                    }
                                    <div className='info-contact'>
                                        <div className='contacts'>
                                            {!this.props.isEdit &&
                                                <div className='item-email'>
                                                    <i className='fa fa-envelope-o' />
                                                    {!this.props.isEdit && <div title={email}>{(email && email != null) ? email : ''}</div>}
                                                    {this.props.isEdit &&
                                                        <InputCustom type='email' placeholder='meuemail@exemplo.com' classname='toedit-input' id='email' value={email} Read />
                                                    }
                                                </div>
                                            }

                                            <div className='item-contacts'>
                                                {(tel && !this.props.isEdit) && <i className='fa fa-phone' />}
                                                {!this.props.isEdit && <div>{(tel && tel != null) ? tel : ''}</div>}
                                                {this.props.isEdit &&
                                                    <i className='fa fa-phone' />
                                                }
                                                {this.props.isEdit &&
                                                    <input maxLength='20' placeholder='9999-9999' className='toedit-input' id={'tel'} onChange={this.props.editProfile} value={tel} />
                                                }
                                            </div>
                                            {(!this.props.isEdit && (city || state || country) != null) &&
                                                <div className='adress item-contacts'>
                                                    <i className='fa fa-map-marker' />
                                                    <div>{`${(city && city != null) ? city : ''}${((state && city) && (state != null && city != null)) ? '/' : ''} ${(state && state != null) ? state : ''}${(((state && state != null) || (city && city != null)) && (country && country != null)) ? ' - ' : ''}${(country && country != null) ? country : ''}`}</div>
                                                </div>
                                            }
                                        </div>
                                        <div className='button-container'>
                                            <Button size='small' disabled={this.props.isEdit} text={`${this.props.user.enableSiteExperimentalSession ? t('REQUEST_YOUR_EXPERIMENTAL_SESSION') : t('CONTACT_ME')}`} type='warning' click={() => this.goesTo(`${this.props.user.enableSiteExperimentalSession ? 'session_request' : 'contact'}`)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            );
        }
    }
}

const mapStateToProps = state => ({
    user: state.Coach.user,
    isEdit: state.Coach.isEdit,
    canEdit: state.Coach.canEdit,
    expertiseAreas: state.Coach.expertisesArray
});

const mapDispatchToProps = dispatch => bindActionCreators({ editProfile, changeCurrentSection, updateAvatar, changeVisibility, selectAsyncExpertise }, dispatch);
export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(HeaderBiography);
