import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { validEmail } from '../../../../utils/common-functions';
import Input from '../../../Input';
import SelectWrapper from '../../../Select';

import './sessionRequest.css';
import { asyncGetCoachDetails, asyncSendSessionRequest } from '../../../../screens/coach/coach.actions';
import {
    weekDays,
    sessionTypes,
    turnTypes,
    phoneCountries,
} from '../../../../utils/constants';

const initialData = {
    name: '',
    email: '',
    phone: '+55',
    day: '',
    turn: '',
    sessionType: '',
};

const SessionRequest = ({
    coach,
    setOpen,
    setError,
    setLoading
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [data, setData] = useState(initialData);

    const coachSessionType = useSelector(({ Coach }) => Coach.coachDetails.sessionType);
    const availableHours = useSelector(({ Coach }) => Coach.coachDetails.schedules_hours) || [];

    const availableDays = Array.from(new Set(availableHours.map((e) => e.weekday))) || [];
    const invalidEmail = !validEmail(data.email);
    const hasError =
        !data.name ||
        !data.email ||
        !data.day ||
        !data.turn ||
        !data.sessionType ||
        invalidEmail;

    useEffect(() => {
        dispatch(asyncGetCoachDetails(coach.id));
    }, []);

    const changeValue = useCallback((key) => {
        return useCallback(({ target }) => {
            setData((e) => ({ ...e, [key]: target.value }));
        }, []);
    }, [data]);

    const changeDayValue = (option) => {
        if (option.value !== data.day.value) {
            setData((e) => ({
                ...e,
                day: option,
                turn: '',
            }));
        }
    };

    const changeTurnValue = (option) => {
        setData((e) => ({ ...e, turn: option }));
    };

    const changePhone = (value) => {
        setData((data) => ({ ...data, phone: value.valueOf() }));
    };

    const changeSessionTypeValue = (option) => {
        setData((e) => ({ ...e, sessionType: option }));
    };

    const onConfirm = () => {
        setLoading(true);
        setData(initialData);
        setOpen(true);
        dispatch(asyncSendSessionRequest({
            ...data,
            coachId: coach.id,
            callback: (error = false) => {
                setError(error);
                setLoading(false);
            }
        }));
    };

    const turnsAvailable = availableHours
        .filter(
            (item) => item.weekday === data.day.value
        )
        .map((e) => ({
            label: t(`${e.turn}`),
            value: e.turn,
        }));

    const turnsOrder = turnTypes().map(e => turnsAvailable
        .find(ele => ele.value === e.value))
        .filter(ele => ele !== undefined);

    return (
        <React.Fragment>
            <div className="container-request-session">

                <div className="container-form-contact">
                    <div className="form-item">
                        <Input
                            optional={false}
                            label={t('NAME')}
                            value={data.name}
                            type="text"
                            onChange={changeValue('name')}
                            placeholder={t('FULL_NAME')}
                            className="input-contanct"
                        />
                    </div>
                    <div className="form-item">
                        <Input
                            optional={false}
                            label="Email"
                            value={data.email}
                            type="text"
                            onChange={changeValue('email')}
                            placeholder={t('YOUR_EMAIL')}
                            className="input-contanct"
                            error={!data.email ? false : invalidEmail}
                            errorMessage={`*${t('INVALID_MAIL')}`}
                        />
                    </div>
                    <div className="phone-input-session">
                        <label>{t('TELEPHONE')}</label>
                        <PhoneInput
                            country={phoneCountries.defaultCountry}
                            onChange={changePhone}
                            value={data.phone}
                            autoFormat={true}
                            countryCodeEditable={false}
                            preferredCountries={phoneCountries.preferredCountries}
                        />
                    </div>
                    <div className="selectors-group">
                        <SelectWrapper
                            className="experimental-req-day"
                            label={t('CHOOSE_A DAY')}
                            isClearable={false}
                            value={data.day}
                            options={weekDays().filter((item) =>
                                availableDays.includes(item.value)
                            )}
                            onChange={changeDayValue}
                            placeholder={t('CHOOSE_A DAY')}
                        />
                        <SelectWrapper
                            className="experimental-req-period"
                            label={t('CHOOSE_A TURN')}
                            isClearable={false}
                            value={data.turn}
                            options={turnsOrder}
                            placeholder={t('CHOOSE_A TURN')}
                            onChange={changeTurnValue}
                        />
                        <SelectWrapper
                            className="experimental-req-session"
                            label={t('CHOOSE_SESSION_TYPE')}
                            isClearable={false}
                            value={data.sessionType}
                            options={sessionTypes().filter((item) => {
                                if (coachSessionType === 'BOTH') {
                                    return sessionTypes();
                                }
                                return item.value === coachSessionType;
                            })}
                            placeholder={t('CHOOSE_SESSION_TYPE')}
                            onChange={changeSessionTypeValue}
                        />
                    </div>
                </div>

                <div className="button-contact">
                    <button
                        onClick={onConfirm}
                        className={hasError ? 'disable' : ''}
                        disabled={hasError}
                    >
                        {t('REQUEST')}
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default SessionRequest;
