export const changeValue = event => ({
    type: 'changeAsyncValue',
    payload: {
        id: event.target.id,
        value: event.target.value,
        keyCode: event.target.keyCode
    }
});

export const sendToServer = (code) => ({
    type: 'sendToServer',
    payload: code
});

export const keyPressInput = event => ({
    type: 'keyPressInput', payload: event.charCode
});

