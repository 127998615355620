const languages = {
    'pt': 'Portuguese',
    'en': 'English',
    'es': 'Spanish',
};

export const coachBioSystemContent = `\
Your job is to write a short biography based on the json information delimited by <>, \
that user will provide.
The biography should be intended to give a presentation of the coaching professional \
for users who visit your personal website.
In case it is not possible to write the biography based on the past information, \
or the information its not related with coaching carrer, \
inform the coach that it was not possible to create the bio and ask him to fill in \
the form again with coaching career information.
The biography must contain 3 paragraphs: presentation, experience and goals.
The biography must be a maximum of 120 words.
The biography must be in the first person.
Every time a user send a message requesting a new version of the biography \
you should reevaluate the json info and try create a new version different of the others generated.
User can request another number of paragraphs, words or structure and you should comply.
The response should only have the text ready to be replaced.
`;

export const generateBioPromptMaker = (coachInfo, lang) => `\
JSON information: <${JSON.stringify(coachInfo)}. \
The biography must be returned in ${languages[lang]}.
`;

export const proofreadTextPrompMaker = (text, lang) => `\
Proofread and correct the following text delimited by <>.
The text must be returned in ${languages[lang]}.
text: <${text}>.
`;

export const regenerateBioPrompts = [
    'Provide another version with 2 paragraphs, with a max of 100 words.',
    'Provide another version with 3 paragraphs: presentation, goals and experience, with a max of 120 words.'
];

export const withLangPromptMaker = (lang) => `The response must be returned in ${languages[lang]}.`;
