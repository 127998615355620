import { call, all, takeEvery } from 'redux-saga/effects';
import { axiosPost } from '../../utils/common-functions';
import { actions, APIroutes, basepathV2 } from '../../utils/constants';

function* sendEmail({payload}) {
    const {callback, id, ...rest} = payload;
    try {
        const url = `${basepathV2}${APIroutes.sendEmail.replace(':id', id)}`;
        const { status } = yield call(axiosPost, url, rest);
        if (callback) {
            callback(status >= 200 ? false : true);
        }
    } catch(err) {
        if (callback) {
            callback(true);
        }
    }
}

export default function* mySaga() {
    yield all([
        yield takeEvery(actions.NEW_SEND_EMAIL, sendEmail)
    ]);
}