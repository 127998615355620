import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { asyncGetBlogPosts } from '../../home.actions';
import { Link } from 'react-router-dom';
import Carousel from '../../../../components/SlickCarousel/slickCarousel';
import PostCard from './components/postCard';

import './published-articles.scss';

function PublishedArticlesSection() {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {blogPosts} = useSelector(({Home}) => Home);

    useEffect(() => {
        dispatch(asyncGetBlogPosts());
    }, []);

    const responsive = [
        {
            breakpoint: 1320,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                dots: true,
            },
        },
        {
            breakpoint: 960,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                dots: true,
            }
        },
        {
            breakpoint: 630,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: false,
                dots: true,
            }
        }
    ];

    var settings = {
        dots: true,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToScroll: 0,
        slidesToShow: 4,
        responsive: responsive,
    };

    const hasArticlesToShow = blogPosts && blogPosts.length > 0;

    if (hasArticlesToShow) {
        return (
            <section className='published-articles-container'>
                <div className='published-articles-title'>
                    <h1>{t('ARTICLES_PUBLISHED')+ ' '}<span>{t('ON_OUR_BLOG')}</span></h1>
                </div>

                <div className='published-articles-cards'>
                    <Carousel settings={settings}>
                        {blogPosts.map(article => <PostCard post={article} key={article.id} />)}
                    </Carousel> 
                </div>
        
                <div className='published-articles-see-more'>
                    <Link to='/blog'>
                        <span>{t('SEE-MORE_POSTS')}</span>
                    </Link>
                </div>
            </section>
        );
    } else {
        return null;
    }
}

export default PublishedArticlesSection;