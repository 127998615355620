export default {
    CHANGE_VALUE: 'CHANGE_VALUE',
    ASYNC_CHANGE_VALUE: 'ASYNC_CHANGE_VALUE',
    CLEAR_FORM: 'CLEAR_FORM',
    ASYNC_CLEAR_FORM: 'ASYNC_CLEAR_FORM',
    LOCK_BUTTON: 'LOCK_BUTTON',
    EDIT_ACTION: 'EDIT_ACTION',
    ASYNC_EDIT_ACTION: 'ASYNC_EDIT_ACTION',
    MENU_OPEN: 'MENU_OPEN',
    GET_USER: 'GET_USER',
    SET_USER: 'SET_USER',
    EDIT_USER: 'EDIT_USER',
    UPDATE_AVATAR: 'UPDATE_AVATAR',
    AVATAR_UPDATED: 'AVATAR_UPDATED',
    ASYNC_ADD_GALLERY_PICTURE: 'ASYNC_ADD_GALLERY_PICTURE',
    ADD_GALLERY_PICTURE: 'ADD_GALLERY_PICTURE',
    ADD_BANNER_IMAGE: 'ADD_BANNER_IMAGE',
    ASYNC_EDIT_GALLERY_PICTURE: 'ASYNC_EDIT_GALLERY_PICTURE',
    EDIT_GALLERY_PICTURE: 'EDIT_GALLERY_PICTURE',
    ASYNC_DELETE_GALLERY_PICTURE: 'ASYNC_DELETE_GALLERY_PICTURE',
    DELETE_GALLERY_PICTURE: 'DELETE_GALLERY_PICTURE',
    ASYNC_ADD_VIDEO: 'ASYNC_ADD_VIDEO',
    ADD_VIDEO: 'ADD_VIDEO',
    ASYNC_EDIT_VIDEO: 'ASYNC_EDIT_VIDEO',
    EDIT_VIDEO: 'EDIT_VIDEO',
    ASYNC_DELETE_VIDEO: 'ASYNC_DELETE_VIDEO',
    DELETE_VIDEO: 'DELETE_VIDEO',
    ASYNC_ADD_TESTIMONY: 'ASYNC_ADD_TESTIMONY',
    ADD_TESTIMONY: 'ADD_TESTIMONY',
    ASYNC_DELETE_TESTIMONY: 'ASYNC_DELETE_TESTIMONY',
    DELETE_TESTIMONY: 'DELETE_TESTIMONY',
    ASYNC_EDIT_TESTIMONY: 'ASYNC_EDIT_TESTIMONY',
    EDIT_TESTIMONY: 'EDIT_TESTIMONY',
    ASYNC_EDIT_USER: 'ASYNC_EDIT_USER',
    FIELDS_SAVED: 'fieldsToSave',
    UPDATE_AVATAR_FULFILLED: 'UPDATE_AVATAR_FULFILLED',
    LOAD_COACH: 'LOAD_COACH',
    LOAD_COACH_FINISHED: 'LOAD_COACH_FINISHED',
    INIT_USER: 'INIT_USER',
    INIT_USER_WITHOUT_TOKEN: 'INIT_USER_WITHOUT_TOKEN',
    SET_CAN_EDIT: 'SET_CAN_EDIT',
    ASYNC_CHANGE_VISIBILITY: 'ASYNC_CHANGE_VISIBILITY',
    CHANGE_VISIBILITY: 'CHANGE_VISIBILITY',
    TOOGLE_CONFIG: 'TOOGLE_CONFIG',
    TOOGLE_BACKGROUND: 'TOOGLE_BACKGROUND',
    SET_BACKGROUND_IMG: 'SET_BACKGROUND_IMG',
    SET_PUBLISH_PROFILE: 'SET_PUBLISH_PROFILE',
    ASYNC_SET_PUBLISH_PROFILE: 'ASYNC_SET_PUBLISH_PROFILE',
    ASYNC_SAVE_CONFIG: 'ASYNC_SAVE_CONFIG',
    CANCEL_SAVE: 'CANCEL_SAVE',
    CLOSE_MODAL: 'CLOSE_MODAL',
    VISUALIZATION: 'VISUALIZATION',
    TOOGLE_FAB: 'TOOGLE_FAB',
    BACKGROUND_IMG_LOAD: 'BACKGROUND_IMG_LOAD',
    SET_BACK_IMGS: 'SET_BACK_IMGS',
    BACK_IMGS_LOADING: 'BACK_IMGS_LOADING',
    BACK_IMGS_LOADED: 'BACK_IMGS_LOADED',
    LOAD_EXPERTISE: 'LOAD_EXPERTISE',
    SET_EXPERTISES_ARRAY: 'SET_EXPERTISES_ARRAY',
    ASYNC_SELECT_EXPERTISE: 'ASYNC_SELECT_EXPERTISE',
    SET_USER_EXPERTISE: 'SET_USER_EXPERTISE',
    ASYNC_CHECK_USERNAME: 'ASYNC_CHECK_USERNAME',
    CHANGE_SAVE_DISABLE: 'CHANGE_SAVE_DISABLE',
    SAVE_REFRESH_TOKEN: 'SAVE_REFRESH_TOKEN',
    SAVE_USERNAME: 'SAVE_USERNAME',
    TOOGLE_CONFIG_SAVE: 'TOOGLE_CONFIG_SAVE',
    EMAIL_INVALID: 'EMAIL_INVALID',
    CHECK_EMAIL: 'CHECK_EMAIL',
    LOAD_COUNTRY: 'LOAD_COUNTRY',
    SELECT_COUNTRY: 'SELECT_COUNTRY',
    SET_COUNTRY_LIST: 'SET_COUNTRY_LIST',
    SET_COUNTRY_LIST_AND_CITIES: 'SET_COUNTRY_LIST_AND_CITIES',
    SET_COUNTRY: 'SET_COUNTRY',
    DISABLE_CHECKBOX: 'DISABLE_CHECKBOX',
    SELECT_STATE: 'SELECT_STATE',
    SELECT_CITY: 'SELECT_CITY',
    DO_SEARCH: 'DO_SEARCH',
    ASYNC_DO_SEARCH: 'ASYNC_DO_SEARCH',
    EXIT_SEARCH: 'EXIT_SEARCH',
    SET_LOADING: 'SET_LOADING',
    SET_SEARCH_RESULT: 'SET_SEARCH_RESULT',
    CHANGE_SEARCH_INPUT: 'CHANGE_SEARCH_INPUT',
    ASYNC_CHANGE_SEARCH_INPUT: 'ASYNC_CHANGE_SEARCH_INPUT',
    SET_SEARCH_OPTIONS: 'SET_SEARCH_OPTIONS',
    SET_OPTIONS: 'SET_OPTIONS',
    ERROR_SEND_FORM: 'ERROR_SEND_FORM',
    SEND_FORM: 'SEND_FORM',
    CHECK_LAST_USERNAME: 'CHECK_LAST_USERNAME',
    SET_SEARCHING_USERNAME: 'SET_SEARCHING_USERNAME',
    SAVE_TOKENS: 'SAVE_TOKENS',
    ASYNC_INITIAL_COACHES: 'ASYNC_INITIAL_COACHES',
    ADD_INITIAL_COACHES: 'ADD_INITIAL_COACHES',
    SET_TOTAL_PROFILES: 'SET_TOTAL_PROFILES',
    SET_NEXT_PAGE: 'SET_NEXT_PAGE',
    SET_TOTAL_PAGES: 'SET_TOTAL_PAGES',
    ASYNC_NEXT_PAGE_SEARCH: 'ASYNC_NEXT_PAGE_SEARCH',
    ASYNC_PREVIOUS_PAGE_SEARCH: 'ASYNC_PREVIOUS_PAGE_SEARCH',
    SET_ARRAY_PAGES: 'SET_ARRAY_PAGES',
    SET_DISABLE_NEXT: 'SET_DISABLE_NEXT',
    SET_DISABLE_PREVIOUS: 'SET_DISABLE_PREVIOUS',
    SELECT_ASYNC_TEMPLATE: 'SELECT_ASYNC_TEMPLATE',
    SELECT_TEMPLATE_COLOR: 'SELECT_TEMPLATE_COLOR',
    FAB_TOOGLE: 'FAB_TOOGLE',
    SELECT_TEMPLATE: 'SELECT_TEMPLATE',
    RESET_ARRAY_PAGES: 'RESET_ARRAY_PAGES',
    TOOGLE_STATE: 'TOOGLE_STATE',
    CHANGE_CURRENT_SECTION_EDIT: 'CHANGE_CURRENT_SECTION_EDIT',
    CHANGE_LOADING_EDIT: 'CHANGE_LOADING_EDIT',
    SET_RESULTS_QUERY: 'SET_RESULTS_QUERY',
    SET_STATS_INFO: 'SET_STATS_INFO',
    ASYNC_GET_STATISTICS: 'ASYNC_GET_STATISTICS',
    NEW_SEND_EMAIL: 'NEW_SEND_EMAIL',
    ASYNC_HANDLE_QUERY_PARAMS: 'ASYNC_HANDLE_QUERY_PARAMS',
    ASYNC_FETCH_ALL_OPTIONS_LANGUAGES: 'ASYNC_FETCH_ALL_OPTIONS_LANGUAGES',
    SET_ALL_OPTIONS_BY_LANG: 'SET_ALL_OPTIONS_BY_LANG',
    SET_ALL_OPTIONS_MIXED: 'SET_ALL_OPTIONS_MIXED',
    ASYNC_GET_COACH_DETAILS: 'ASYNC_GET_COACH_DETAILS',
    SET_COACH_DETAILS: 'SET_COACH_DETAILS',
    ASYNC_SEND_SESSION_REQUEST: 'ASYNC_SEND_SESSION_REQUEST', 
    ASYNC_CONFIRM_SESSION_REQUEST: 'ASYNC_CONFIRM_SESSION_REQUEST',
    SET_LOADING_REQUEST: 'SET_LOADING_REQUEST',
    ASYNC_SET_COOKIE_CONSENT: 'ASYNC_SET_COOKIE_CONSENT',
    ASYNC_HANDLE_COACH_ACCESS: 'ASYNC_HANDLE_COACH_ACCESS',
    ASYNC_FINISH_FORM: 'ASYNC_FINISH_FORM',
    INIT_FORM_LOADING: 'INIT_FORM_LOADING',
    END_FORM_LOADING: 'END_FORM_LOADING',
    ASYNC_GENERATE_DESCRIPTION: 'ASYNC_GENERATE_DESCRIPTION',
    SET_COACH_DESCRIPTIONS: 'SET_COACH_DESCRIPTIONS',
    SET_DESCRIPTION_LOADING: 'SET_DESCRIPTION_LOADING',
    ASYNC_PROOFREAD_TEXT: 'ASYNC_PROOFREAD_TEXT',
    ASYNC_REGENERATE_TEXT: 'ASYNC_REGENERATE_TEXT',
    SET_CURRENT_DESCRIPTION_INDEX: 'SET_CURRENT_DESCRIPTION_INDEX',
    INIT_DESCRIPTION_LOADING: 'INIT_DESCRIPTION_LOADING',
    END_DESCRIPTION_LOADING: 'END_DESCRIPTION_LOADING',
    META_TAGS_DECORATOR: 'META_TAGS_DECORATOR',
    SET_SELECTED_TAB: 'SET_SELECTED_TAB',
    SET_OTHER_OPTIONS_FILTER: 'SET_OTHER_OPTIONS_FILTER',
    SET_IS_MOBILE: 'SET_IS_MOBILE',
    ASYNC_GET_BLOG_POSTS: 'ASYNC_GET_BLOG_POSTS',
    ASYNC_SET_BLOG_POSTS: 'ASYNC_SET_BLOG_POSTS',
    ASYNC_GET_POSTS: 'ASYNC_GET_POSTS',
    SET_POSTS: 'SET_POSTS',
    SET_IS_POSTS_LOADING: 'SET_IS_POSTS_LOADING',
    ASYNC_BLOG_SEARCH: 'ASYNC_BLOG_SEARCH',
    SET_BLOG_SEARCH: 'SET_BLOG_SEARCH',
    ASYNC_GET_POSTS_PAGE_CONTENT: 'ASYNC_GET_POSTS_PAGE_CONTENT',
    SET_POSTS_PAGE_CONTENT: 'SET_POSTS_PAGE_CONTENT',
    SET_POST_SUGGESTIONS: 'SET_POST_SUGGESTIONS',
    SET_POST_CATEGORIES: 'SET_POST_CATEGORIES',
};