import {actions} from '../../../../utils/constants';

export const addVideo = (video, videos = []) => ({
    type: actions.ASYNC_ADD_VIDEO,
    payload: {
        video,
        videos,
    },
});

export const editVideo = (video, videos = []) => ({
    type: actions.ASYNC_EDIT_VIDEO,
    payload: {
        video,
        videos,
    },
});

export const deleteVideo = (video, videos = []) => ({
    type: actions.ASYNC_DELETE_VIDEO,
    payload: {
        video,
        videos,
    },
});
