import {actions} from '../../../../utils/constants';

export const addTestimony = (testimony, testimonies = []) => ({
    type: actions.ASYNC_ADD_TESTIMONY,
    payload: {
        testimony,
        testimonies,
    },
});

export const deleteTestimony = (testimony, testimonies = []) => ({
    type: actions.ASYNC_DELETE_TESTIMONY,
    payload: {
        testimony,
        testimonies,
    },
});

export const editTestimony = (testimony, testimonies = []) => ({
    type: actions.ASYNC_EDIT_TESTIMONY,
    payload: {
        testimony,
        testimonies,
    },
});
