import React from 'react';
import TitleSection from '../../../../../../components/TitleSection/TitleSection';
import BiographyInfo from './biography-info/biography-info';
import BiographyContact from './biography-contact/biography-contact';

import './biography.css';
import { useTranslation } from 'react-i18next';
import { removeConstatsFromExpertises } from '../../../../../../utils/common-functions';

function Biography({
    aboutMe,
    expertises,
    tel,
    email,
    location,
    photoUrl,
    onClick,
    themeColor
}) {
    const {t} = useTranslation();
    const expertisesList = removeConstatsFromExpertises(expertises);

    return (
        <section
            className="template--6--biography"
            onClick={() => onClick('biography')}
            id="biography">
            <TitleSection
                text1={t('WHO_I_AM')}
                text2={t('BIOGRAPHY')}
                colorIcon={themeColor}
                icon={false} />
            <article className="biography-content">
                <img
                    alt="coach"
                    src={photoUrl} />

                <BiographyInfo
                    aboutMe={aboutMe}
                />

                <BiographyContact
                    expertises={expertisesList}
                    tel={tel}
                    email={email}
                    location={location}
                    themeColor={themeColor}
                />
            </article>
        </section>
    );
}

export default Biography;