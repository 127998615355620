import React from 'react';
import { history } from '../../../../utils/store';
import IconSvg from '../../../../utils/svg';
import Search from '../search';

import './search-container.css';



const ContainerSearch = () => {
    function returnToHome() {
        history.push('/home');
    }

    return (
        <section className='coaches-list-container-search' >
            <div className='container-image' >
                <IconSvg
                    name='logo-oseu-coach'
                    classDiv='logo-oseu-coach'
                    width={170}
                    height={55}
                    onClick={returnToHome}
                />
            </div>
            <div className='search' >
                <Search />
            </div>
        </section>
    );
};

export default ContainerSearch;