import React from 'react';
import {useTranslation} from 'react-i18next';

import IconSvg from '../../../../utils/svg';
import contactUsImg from '../../../../img/contact-us.webp';

import './contact-us-section.css';

const contactUsLink = 'https://febra.me/NovoContatoSCIS';

function ContactUsSection() {
    const {t} = useTranslation();

    return (
        <section className='contact-us-section' id='contact_us'>
            <div className='contact-us-container'>
                <img src={contactUsImg} alt='Imagem fale conosco' className='contact-us-img' />
                <MainContent t={t} />
            </div>
        </section>
    );
}

function MainContent({t}) {
    return (
        <div className='contact-us-main-content'>
            <p className='contact-us-title'>{t('CONTACT_TITLE')}</p>
            <p className='contact-us-text'>
                {t('CONTACT_TEXT')}
            </p>
            <ContactUsButton href={contactUsLink} t={t} />
        </div>
    );
}

function ContactUsButton({href, t}) {
    return (
        <button className='btn-contact-us'>
            <a
                className='contact-anchor'
                href={href}
                rel='noopener noreferrer'
                target='_blank'
            >
                <IconSvg name='icon-whatsapp2' classDiv='contact-whatsapp-icon'/>
                <span>{t('CONTACT_US')}</span>
            </a>
        </button>
    );
}

export default ContactUsSection;
