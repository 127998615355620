import React, { useEffect, useMemo, useState } from 'react';
import {useTranslation} from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import InputWrapper from '../../../../components/Input';
import SelectWrapper from '../../../../components/Select';
import {paramsToQueryString} from '../../../../utils/common-functions';
import {history} from '../../../../utils/store';
import IconSvg from '../../../../utils/svg';
import {fetchAllOptionsLanguages, goToSearch, setSearchInput} from '../../../home/home.actions';

import './search.css';
import { categoryOptions } from '../../../../utils/constants';

function sanitizeParams(params) {
    const hasExpertises = params.expertises && params.expertises.length > 0;
    const hasCategories = params.categories && params.categories.length > 0;

    let searchParams = params;

    if (hasExpertises) {
        const params = searchParams.expertises.map(expertise => expertise.name);
        searchParams = {...searchParams, expertises: params};
    }

    if (hasCategories) {
        const params = searchParams.categories.map(option => option.name);
        searchParams = {...searchParams, categories: params};
    }

    return searchParams;
}

function updateQueryParams(params) {
    const queryString = paramsToQueryString(params);
    const pathname = history.location.pathname;
    const hashpath = history.location.hash;
    const fullPath = `${pathname}${hashpath}`;

    history.push(`${fullPath}?${queryString}`);
}

const Search = ({isDouble = false, isResponsive = false}) => {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const {options, search} = useSelector(({Home}) => ({
        options: Home.searchingOptions || [],
        search: Home.search,
    }));

    useEffect(() => {
        dispatch(fetchAllOptionsLanguages(true));
    }, []);

    function handleChange(value, id, isSelectChange = false) {
        if (id === 'expertises' && !value) {
            value = [];
        }

        dispatch(setSearchInput({id, value, doSearch: isSelectChange ? handleSearch : undefined}));
    }

    function handleKeyUp(event) {
        if (event.key === 'Enter') {
            handleSearch(search);
        }
    }

    function handleSearch(search) {
        handleScrollToView('new-header');
        dispatch(goToSearch());
        updateQueryParams(sanitizeParams(search));
    }

    function onToggle() {
        setOpen(e => !e);
    }

    const handleScrollToView = (id) => {
        const el = document.getElementById(id);
        el.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
        });
    };

    const {country, state, city} = useMemo(() => {
        return {
            country: options.countries.find(e => e.name === search.country) || null,
            state: options.states.find(e => e.name === search.state) || null,
            city: options.cities.find(e => e.name === search.city) || null
        };
    }, [search, options]);

    return (
        <div className={`coaches-list-search ${isDouble ? 'double' : 'one'} ${isResponsive ? 'responsive' : ''}`} >
            <div className='title' >
                {
                    isResponsive 
                        ? (
                            <div className='search-coach' >
                                <InputWrapper
                                    value={search.name}
                                    type='text'
                                    name='coach-name'
                                    onKeyUp={handleKeyUp}
                                    onChange={(event) => handleChange(event.target.value, 'name')}
                                    placeholder={t('NEW_SEARCH')}
                                    className='input-coach-name-responsive'
                                />
                                <IconSvg onClick={() => handleSearch(search)} name='icon-search-select' width={20} />
                            </div>
                        )
                        : <h1>{t('SEARCH')}</h1>
                }

                {
                    isResponsive && (
                        <div className='responsive-arrow' >
                            <IconSvg classDiv={open ? 'invert' : 'no-invert'} onClick={onToggle} name='icon-arrow' width={20} />
                        </div>
                    )
                }
            </div>
            <div className={`container-all-form ${open ? 'open' : 'close'}`} >
                <SelectWrapper
                    id='country-select'
                    label={t('COUNTRY')}
                    className='search-item'
                    placeholder={t('SELECT_COUNTRY')}
                    value={country}
                    options={options.countries}
                    onChange={(value) => handleChange((value && value.name) || null, 'country', true)}
                />
                <SelectWrapper
                    id='state-select'
                    label={t('STATE')}
                    className='search-item'
                    placeholder={t('SELECT_STATE')}
                    options={options.states}
                    value={state}
                    isDisabled={options.states && options.states.length < 1}
                    onChange={(value) => handleChange((value && value.name) || null, 'state', true)}
                />
                <SelectWrapper
                    id='city-select'
                    label={t('CITY')}
                    className='search-item'
                    placeholder={t('SELECT_CITY')}
                    isDisabled={options.cities && options.cities.length < 1}
                    options={options.cities}
                    value={city}
                    onChange={(value) => handleChange((value && value.name) || null, 'city', true)}
                />
                <InputWrapper
                    label={t('COACH_NAME')}
                    value={search.name}
                    type='text'
                    name='coach-name'
                    id='coach-name-input'
                    onKeyUp={handleKeyUp}
                    onChange={(event) => handleChange(event.target.value, 'name')}
                    placeholder={t('NAME')}
                    className='input-coach-name search-item'
                />
                <SelectWrapper
                    id='expertise-select'
                    label={t('WHAT_AREA_TRANSFORM')}
                    placeholder={t('SELECT_AREA')}
                    options={options.expertises.map(e => ({...e, value: e.id}))}
                    value={search.expertises}
                    onChange={(values) => handleChange(values, 'expertises', true)}
                    isMulti
                    info={t('MORE_THAN_ONE_OPTION')}
                />
                <SelectWrapper
                    id='coach-category-select'
                    label={t('OTHER_OPTIONS')}
                    placeholder={t('SELECT')}
                    options={categoryOptions()}
                    className='item-no-margin'
                    onChange={(values) => handleChange(values, 'categories', true)}
                    value={search.categories}
                    info={t('MORE_THAN_ONE_OPTION')}
                    isMulti
                />

                <button className='button-search-coach' onClick={() => handleSearch(search)} >
                    {t('SEARCH_MY_COACH')}
                </button>
            </div>
        </div>
    );
};

export default Search;