import React from 'react';
import {Link} from 'react-router-dom';
import format from 'date-fns/format';

import {locales} from '../../../../utils/constants';
import LazyImage from '../../../../components/lazyImage';

import './postCard.scss';

function PostCard({post, className = ''}) {
    return (
        <Link to={`/blog/post/${post.meta.slug}`}>
            <article className={`blog-post-card ${className}`.trim()}>
                <section className='thumbnail'>
                    <LazyImage
                        src={post.thumbnail}
                        alt='Thumbnail da postagem'
                        placeholderClassName='lazy-img-placeholder'
                    />
                </section>

                <CardInfo post={post} />
            </article>
        </Link>
    );
}

function CardInfo({post}) {
    const publishedDate = new Date(post.last_published_at);
    const formatedDate = format(publishedDate, 'PPP', {locale: locales['pt']});

    return (
        <section className='card-info'>
            <header>
                <div className='author-area'>
                    <span className='author'>{post.author}</span>
                </div>
                <h3>{post.title}</h3>
            </header>

            <p>{post.description}</p>

            <footer>
                <PostCategories categories={post.categories} />
                <span className='divider'>-</span>
                <span className='published-date'>{formatedDate}</span>
            </footer>
        </section>
    );
}

function PostCategories({categories}) {
    return (
        <div className='categories-area'>
            {categories.map(category => {
                return <span className='category' key={category}>{category}</span>;
            })}
        </div>
    );
}

export default PostCard;
