import { startCase } from 'lodash';
import React, { Fragment } from 'react';
import './biography-contact.css';

function BiographyContact({
    expertises,
}) {
    return (
        <Fragment>
            <div className="biography-contact--7">
                <div className="expertises">
                    <h4>Área de atuação </h4>
                    <p> {expertises.map((item, index) => (`${index ? index === expertises.length - 1 ? ' e' : ',' : ''} ${startCase(item)}`))} </p>
                </div>
            </div>
        </Fragment>
    );
}

export default BiographyContact;