import React from 'react';

import './info-card.css';

import Svg from '../../../../../../img/iconsSvg.svg';

const url = Svg;

const Icon = (props) => (
    <svg viewBox='0 0 16 16' className={`icon icon-${props.icone}`}>
        <use xlinkHref={`${url}#icon-${props.icone}`} />
    </svg>
);

const InfoCard = ({ icon, title, qtd, info, isEdit, onchange, id, canEdit, iconUrl, isVisible }) => (
    <div className={`info-card ${ (!isEdit && (qtd === 0 || qtd === null || qtd === '0' )) || !isVisible ? 'notShow': ''}`}>
        <div className='icon-wrapper'>
            <Icon icone={`${iconUrl}`} />
        </div>
        <div className='info-wrapper'>
            <div className='info-title'>
                { canEdit && isEdit ? 
                    <input className='info-edit' min='0' type='number' value={ qtd } id={ id } onChange={ (event)=> {
                        event.stopPropagation();
                        event.preventDefault();
                        onchange(event);
                    } }  />
                    : qtd }
                {title}
            </div>
            <div className='content'>{info}</div>
        </div>
    </div>
);

export default InfoCard;
