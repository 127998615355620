import React, { useRef } from 'react';
import './menu-header.css';

import { goesTo } from '../../../../../utils/common-functions';
import { useTranslation } from 'react-i18next';
import { SESSION_ITEM_NAV } from '../../../../../utils/constants';

const useRenderList = (array, user, checkboxRef) => {
    const {t} = useTranslation();

    const handleSelectMenuItem = (href) => {
        checkboxRef.current.click();
        goesTo(href);
    }

    return array.map((e,i) => {
        if (e.name === 'Contato') {
            const hasSessionExperimental = user.enableSiteExperimentalSession;
            const href = `${hasSessionExperimental ? SESSION_ITEM_NAV.url ||'#' : e.url||'#'}`;

            return (
                <li key={i} >
                    <a
                        href={href}
                        onClick={() => handleSelectMenuItem(href)}
                    >
                        {`${hasSessionExperimental ? t(SESSION_ITEM_NAV.key) : t(e.key)}`}
                    </a>
                </li>
            );
        } else {
            return (
                <li key={i} >
                    <a href={e.url||'#'} onClick={() => handleSelectMenuItem(e.url || '#')}>
                        {t(e.key)}
                    </a>
                </li>
            );
        }
    }
    );
};

const MenuHeader = (props) => {
    const { user, socialLine, elements, isEdit, menuOpen } = props;
    const checkboxRef = useRef(null);
    const renderList = useRenderList(elements, user, checkboxRef);

    return (
        <label className='wrapper'>
            <input
                type='checkbox'
                disabled={isEdit}
                onChange={props.toogleMenu}
                checked={menuOpen}
                ref={checkboxRef}
            />
            <span className='menu' style={{height: user.theme === 'template-1' ? '30px' : '50px'}}>
                <span className='hamburger'></span>
            </span>
            { socialLine && menuOpen && <ul>
                {renderList}
                <li className='social-li'>
                    { user.facebook && <a className="items" href={user.facebook|| '#'}><i className='fa fa-facebook'/></a> }
                    { user.instagram && <a className="items" href={user.instagram|| '#'}><i className='fa fa-instagram'/></a> }
                    { user.youtube && <a className="items" href={user.youtube|| '#'}><i className='fa fa-youtube'/></a> }
                    { user.twitter && <a className="items" href={user.twitter|| '#'}><i className='fa fa-twitter'/></a> }
                    { user.linkedin && <a className="items" href={user.linkedin || '#'}><i className='fa fa-linkedin'/></a> }
                    { user.whatsapp && <a className="items" href={user.whatsapp || '#'}><i className='fa fa-whatsapp'/></a> }
                </li>
            </ul>}
        </label>
    );};

export default MenuHeader;