import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { toogleMenu } from './header.action';
import './header.css';
import { Main, Biography } from './components/index';

class Header extends Component {

    render() {
        const { activeBiography } = this.props;
        return (
            <header className='header' id='home'>
                <Main type={this.props.type} showPhone={this.props.showPhone} toogleMenu={this.props.toogleMenu} menuOpen={this.props.menuOpen}
                    user={{ ...this.props.user, menuOpen: this.props.menuOpen }}
                />

                <Biography activeBiography={activeBiography} />
            </header>
        );
    }
}

const mapStateToProps = state => ({
    menuOpen: state.Coach.menuOpen,
    user: state.Coach.user
});

const mapDispatchToProps = dispatch => bindActionCreators({ toogleMenu }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Header);