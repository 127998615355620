import React, {memo, useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Loader } from '../../screens/commons';
import { normalizeName } from '../../utils/common-functions';
import { Categories } from '../../utils/constants';
import IconSvg from '../../utils/svg';
import ModalCoach from '../ModalCoach';
import RatingStars from '../ratingStarts';
import Carousel from '../SlickCarousel/slickCarousel';


import './coaches-list.css';

const CoachesList = memo(({coaches, scrollableTarget, fetchData, hasMore = true, isCarousel = false, settings, classNameCarousel = ''}) => {
    const [selectedCoach, setSelectedCoach] = useState(null);

    const onClose = () => {
        setSelectedCoach(null);
    };

    const onOpen = useCallback((coach) => {
        setSelectedCoach(coach);
    }, []);

    const coachItems = useMemo(() => {
        return coaches.map((coach) => {
            return <CoachCard key={coach.id} {...coach} onClick={onOpen} />;
        });
    }, [coaches, onOpen]);

    if (isCarousel) {
        return (
            <React.Fragment>
                <ModalCoach show={selectedCoach && selectedCoach.id} {...selectedCoach} onClose={onClose} />
                <Carousel settings={settings} className={classNameCarousel}>
                    {coachItems}
                </Carousel>
            </React.Fragment>
        );
    } else {
        return (
            <InfiniteScroll
                dataLength={coaches.length}
                next={fetchData}
                hasMore={hasMore}
                className='infinite-scroll-coaches-list'
                loader={<Loader />}
                scrollableTarget={scrollableTarget}
            >
                <ModalCoach show={selectedCoach && selectedCoach.id} {...selectedCoach} onClose={onClose} />
                {coachItems}
            </InfiniteScroll>
        );
    }

});

export const CoachCard = memo((props) => {
    const {firstName, secondName, photoUrl, rating, expertises, enableSiteExperimentalSession, onClick} = props;
    const categories = expertises && expertises.length > 0 ? Categories.filter(e => expertises.split(',').includes(e.flag)) : [];
    const {t} = useTranslation();
    
    const handlerClick = () => {
        onClick(props);
    };

    return (
        <div
            className='coach-item'
            onClick={handlerClick}
            style={{backgroundImage: `url("${photoUrl}")`}}
        >
            <div className='wrapper-filter' />
            <div className='coach-info' >
                <span className='text-info'>{normalizeName(firstName)}</span>
                <span className='text-info'>{normalizeName(secondName)}</span>
                <RatingStars height={20} width={20} rating={rating} enableSiteExperimentalSession={enableSiteExperimentalSession}/>
                {
                    categories.length > 0 && <div className={`${categories.length >= 2 ? 'container-flag' : 'container-single-flag'}`} >
                        {
                            categories.map(flag => {
                                return <Flag title={flag.minifyName} icon={flag.iconName}/>;
                            })
                        }
                    </div>
                }
                <div className='know-more'>{t('KNOW_MORE')}</div>
            </div>
        </div>
    );
});

const Flag = memo(({title, icon}) => {
    return (
        <div className='flag' >
            <IconSvg classDiv='icon-flag-div' color='#FE7800' width={17} height={17} name={icon}/>
            <span>{title}</span>
        </div>
    );
});

export default CoachesList;