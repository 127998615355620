import { actions } from '../../utils/constants';

export const editAction = () => ({ type: actions.ASYNC_EDIT_ACTION });

export const getUser = () => ({ type: actions.GET_USER });

export const initUser = (name, code, refreshToken) => ({ type: actions.INIT_USER, payload: { name, code, refreshToken } });

export const changeVisibility = (event) => ({ type: actions.ASYNC_CHANGE_VISIBILITY, payload: event.target.id });

export const toogleConfig = () => ({ type: actions.TOOGLE_CONFIG });

export const toogleBackground = () => ({ type: actions.TOOGLE_BACKGROUND });

export const setBackgroundImg = url => ({ type: actions.SET_BACKGROUND_IMG, payload: url });

export const setPublishProfile = event => ({ type: actions.ASYNC_SET_PUBLISH_PROFILE, payload: event.target.checked });

export const saveConfig = event => ({ type: actions.ASYNC_SAVE_CONFIG });

export const cancelSaveConfig = () => ({ type: actions.CANCEL_SAVE });

export const closeConfigModal = () => ({ type: actions.CLOSE_MODAL, payload: 'showConfig' });

export const closeChooseBackgroundModal = () => ({ type: actions.CLOSE_MODAL, payload: 'showChangeBackground' });

export const ViewModify = () => ({ type: actions.VISUALIZATION });

export const toogleFab = () => ({ type: actions.TOOGLE_FAB });

export const imagesBackgroundLoad = () => ({ type: actions.BACKGROUND_IMG_LOAD });

export const loadExpertiseAreas = () => ({ type: actions.LOAD_EXPERTISE });

export const metaDecorator = () => ({ type: actions.META_TAGS_DECORATOR });

export const selectAsyncExpertise = event => ({
    type: actions.ASYNC_SELECT_EXPERTISE,
    payload: event.target.value,
});

export const addBannerImage = (picture) => ({
    type: actions.ADD_BANNER_IMAGE,
    payload: {picture}
});

export const asyncVerifyIfUsernameIsAvailable = event => ({
    type: actions.ASYNC_CHECK_USERNAME, payload: event.target.value
});

export const loadCountries = () => ({
    type: actions.LOAD_COUNTRY
});

export const selectedCountry = (event) => ({
    type: actions.SELECT_COUNTRY,
    payload: event.target.value
});

export const changeUser = (id, value) => ({
    type: actions.EDIT_USER,
    payload: { id, value }
});

export const changeCurrentSection = (section_id) => ({
    type: actions.CHANGE_CURRENT_SECTION_EDIT,
    payload: section_id
});

export const setCountryState = event => ({
    type: actions.SELECT_STATE,
    payload: event.target.value
});

export const selectCity = event => ({
    type: actions.SELECT_CITY,
    payload: event.target.value
});

export const selectTemplate = event => ({
    type: actions.SELECT_ASYNC_TEMPLATE,
    payload: event.target.id
});

export const selectTemplateColor = color => ({
    type: actions.SELECT_TEMPLATE_COLOR,
    payload: color
});

export const asyncGetCoachDetails = (payload) => ({
    type: actions.ASYNC_GET_COACH_DETAILS,
    payload,
});

export const setCoachDetails = (payload) => ({
    type: actions.SET_COACH_DETAILS,
    payload,
});

export const asyncSendSessionRequest = (payload) => ({
    type: actions.ASYNC_SEND_SESSION_REQUEST,
    payload,
});

export const confirmSessionRequest = (payload) => ({
    type: actions.ASYNC_CONFIRM_SESSION_REQUEST,
    payload,
});

export const asyncHandleCoachAccess = (payload) => ({
    type: actions.ASYNC_HANDLE_COACH_ACCESS,
    payload,
});

export const setSelectedTab = (selectedTab) => ({
    type: actions.SET_SELECTED_TAB,
    payload: selectedTab,
});

export const setIsMobile = (payload) => ({
    type: actions.SET_IS_MOBILE,
    payload
});