import React from 'react';
import NavBar from './nav-bar/nav-bar';
import SocialMedias from '../../../components/social-medias/social-medias';
import EffectMessage from './effect-message/effect-message';
import { useWidth } from '../../../template.hooks';

import './banner.css';

function Banner({
    enableBiography,
    enableResume,
    enableVideos,
    enableTestimonies,
    enableGallery,
    facebook,
    linkedin,
    instagram,
    twitter,
    whatsapp,
    youtube,
    gallery,
    testimonies,
    videos,
    backgroundImg,
    mainPhrase,
    mainPhraseAuthor,
    tel,
    firstName,
    onClick
}) {

    const [width] = useWidth();

    return (
        <section
            className="template--5--banner"
            onClick={() => onClick('home')}
            id="home">
            <div
                className="background-image-blur"
                style={{ backgroundImage: `url(${window.innerWidth > 500 ? backgroundImg.desktop : backgroundImg.desktop})` }}
            />

            <div className="content">
                <NavBar
                    enableBiography={enableBiography}
                    enableResume={enableResume}
                    enableVideos={enableVideos}
                    enableTestimonies={enableTestimonies}
                    enableGallery={enableGallery}
                    firstName={firstName}
                    tel={tel}
                    facebook={facebook}
                    linkedin={linkedin}
                    instagram={instagram}
                    twitter={twitter}
                    whatsapp={whatsapp}
                    youtube={youtube}
                    gallery={gallery}
                    testimonies={testimonies}
                    videos={videos}
                />

                <EffectMessage
                    mainPhrase={mainPhrase}
                    mainPhraseAuthor={mainPhraseAuthor} />

                {width >= 990 &&
                    <SocialMedias
                        facebook={facebook}
                        linkedin={linkedin}
                        instagram={instagram}
                        twitter={twitter}
                        whatsapp={whatsapp}
                        youtube={youtube}
                        direction="column" />
                }
            </div>
        </section>
    );
}

export default Banner;