import React from 'react';
import {format} from 'date-fns';

import {locales} from '../../../../utils/constants';

import './post-info.scss';

function PostInfo({author, categories, lastPublishedAt}) {
    return(
        <div className='post-info-section'>
            <span className='blog-post-author'>{author}</span>
            <span className='post-category'>{categories[0]}</span>
            <span> - </span>
            <span className='post-date'> {format(new Date(lastPublishedAt), 'PPP', {locale: locales['pt']})}</span>
        </div>
    );
}

export default PostInfo;
