import { useState, useEffect } from 'react';
import { basepath, APIroutes } from "../../../utils/constants";
import { getViewportWidth } from '../../../utils/common-functions';
import Axios from 'axios';

export function sendContact(username) {
    const [res, setRes] = useState({
        pedding: false,
        error: '',
        success: false,
        complete: false
    });

    function handleChangeRes(newValues) {
        setRes({ ...res, ...newValues })
    };

    async function makeContactRequest(form) {
        handleChangeRes({ pending: true });
        let url = `${basepath}${APIroutes.contact}`.replace('{username}', username);
        try {
            await Axios.put(url, form);
            handleChangeRes({
                pending: false,
                error: '',
                success: true,
                complete: true
            });
        } catch (err) {
            handleChangeRes({
                pending: false,
                success: false,
                error: err.message,
                complete: true
            });
        };
    };

    return [res, makeContactRequest];
}

export function useWidth() {

    const [width, setWidth] = useState(getViewportWidth());

    function listenResize() {
        setWidth(getViewportWidth());
    }

    useEffect(() => {
        window.addEventListener('resize', listenResize);

        return () => {
            window.removeEventListener('resize', listenResize);
        };
    }, []);

    return [width];
}
