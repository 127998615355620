import React from 'react';
import Select, {components} from 'react-select';
import {useTranslation} from 'react-i18next';

import './select.css';

const NoOptionsMessage = props => {
    const {t} = useTranslation();

    return (
        <components.NoOptionsMessage {...props}>
            <span>{t('NO_OPTIONS_SELECT')}</span> 
        </components.NoOptionsMessage>
    );
};

function Input(props) {
    const {autoComplete} = props.selectProps;
    const inputProps = {...props, autoComplete};

    return <components.Input {...inputProps} />;
}

const colourStyles = {
    control: (styles, {isDisabled}) => ({
        ...styles,
        backgroundColor: isDisabled
            ? '#FFFFFF7D' : 'white',
        border: 'none',
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                    ? '#fff'
                    : isFocused
                        ? '#fff'
                        : undefined,
            color: isDisabled
                ? '#ccc'
                : 'black',
            cursor: isDisabled ? 'not-allowed' : 'pointer',
            padding: 15,
            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled
                    ? isSelected
                        ? '#fff'
                        : '#fff'
                    : undefined,
            },
            ':hover': {
                ...styles[':hover'],
                color: '#fff',
                backgroundColor: '#000'
            },
        };
    },
    input: (styles) => ({ ...styles }),
    placeholder: (styles) => ({ ...styles }),
    singleValue: (styles) => ({ ...styles }),
    multiValue: (styles) => {
        return {
            ...styles,
            backgroundColor: '#15161B',
        };
    },
    multiValueLabel: (styles) => ({
        ...styles,
        color: '#FFFFFF',
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '0.8REM',
        lineHeight: '20px',
    }),
    multiValueRemove: (styles) => ({
        ...styles,
        color: '#FE7800',
        ':hover': {
            ...styles[':hover'],
            backgroundColor: '#15161B'
        },
    }),
};
function SelectWrapper({id, label, isClearable = true, className, info, style = {}, inputProps, ...props}) {

    return (
        <div className={`select-wrapper-container ${className || ''}`}>
            <label htmlFor={id} className={`${props.isDisabled ? 'disabled' : ''}`}>{label}</label>
            <Select
                inputId={id}
                isClearable={isClearable}
                autoComplete='disabled'
                components={{NoOptionsMessage, Input}}
                {...props}
                styles={{...colourStyles, ...style}}
            />
            {
                info 
                && <div className='container-helper' >
                    <span>{info}</span>
                </div>
            }
        </div>
    );
}

export default SelectWrapper;