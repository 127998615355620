import React, { useCallback, useEffect } from 'react';
import Loading from 'react-loading';
import {useDispatch, useSelector} from 'react-redux';
import { useTranslation } from 'react-i18next';

import Input from '../../../../../components/Input';
import {asyncProofreadText, asyncRegenerateText, setCurrentDescriptionIndex} from '../../../step-forms.actions';
import IconSvg from '../../../../../utils/svg';

import './description.css';

function DescriptionData({data, setData, errors}) {
    const {t} = useTranslation();
    const {isLoading, type: loadingType} = useSelector(({StepForms}) => StepForms.descriptionLoading);
    const loadingText = t(loadingType);

    return (
        <div className='description-container'>
            {isLoading
                ? <GenerateLoading text={loadingText} />
                : <Description data={data} setData={setData} errors={errors} />
            }
        </div>
    );
}

function Description({data, setData, errors}) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [text1, text2] = t('YOUR_DESCRIPTION_SUBTITLE').split('*');
    const descriptions = useSelector(({StepForms}) => StepForms.descriptions);
    const currentDescriptionIndex = useSelector(({StepForms}) => StepForms.currentDescriptionIndex);
    const descriptionError = errors && errors.includes(true);

    const changeValue = useCallback((key) => {
        return useCallback(({ target }) => {
            setData((e) => ({ ...e, [key]: target.value }));
        }, []);
    }, [data]);

    const handleRegenerateText = () => {
        dispatch(asyncRegenerateText());
    };

    const handleProofreadText = () => {
        dispatch(asyncProofreadText(data.description));
    };

    useEffect(() => {
        setData((e) => ({...e, description: descriptions[currentDescriptionIndex]}));
    }, [currentDescriptionIndex]);

    return (
        <div className='description-data'>
            <title className='description-title'>
                <h1>{t('YOUR_DESCRIPTION')}</h1>
                <h5>{text1}</h5>
                <h5>{text2}</h5>
            </title>

            <div className='your-description'>
                <div className='form-description'>
                    <Input
                        id='description-textarea'
                        optional={false}
                        label={t('CLICK_TO_EDIT')}
                        value={data.description}
                        type='area'
                        onChange={changeValue('description')}
                        placeholder={`${t('ADD_YOUR_ANSWER_HERE')}...`}
                        className={`input-description ${descriptionError ? 'error' : ''}`}
                        error={descriptionError}
                        errorMessage={`*${t('FIELD_NULL')}`}
                    />
                </div>

                <VersionControl
                    descriptions={descriptions}
                    currentDescriptionIndex={currentDescriptionIndex}
                    setCurrentDescriptionIndex={setCurrentDescriptionIndex}
                />

                <div className='btn-text-reviews'>
                    <button
                        onClick={handleProofreadText}
                        type='button'
                        className='btn-review-text'
                    >
                        {t('REVIEW_TEXT')}
                    </button>
                    <button
                        disabled={descriptions.length > 2}
                        onClick={handleRegenerateText}
                        type='button'
                        className='btn-rewrite-text'
                    >
                        {t('REWRITE_TEXT')}
                    </button>
                </div>
            </div>
        </div>
    );
}

function GenerateLoading({text}) {
    return (
        <div className='generate-loading'>
            <h1>{text}</h1>
            <Loading type='bubbles' delay={0} />
        </div>
    );
}

function VersionControl({descriptions, currentDescriptionIndex, setCurrentDescriptionIndex}) {
    const dispatch = useDispatch();
    const currentVersion = currentDescriptionIndex + 1;
    const totalVersions = descriptions.length;
    const isFirstVersion = currentDescriptionIndex === 0;
    const isLastVersion = currentDescriptionIndex >= (descriptions.length - 1);

    const goToPreviousVersion = () => {
        dispatch(setCurrentDescriptionIndex(currentDescriptionIndex - 1));
    };

    const goToNextVersion = () => {
        dispatch(setCurrentDescriptionIndex(currentDescriptionIndex + 1));
    };

    return (
        <div className='version-control'>
            <button
                className='description-arrow'
                onClick={goToPreviousVersion}
                disabled={isFirstVersion}
            >
                <IconSvg
                    name='chevron'
                    height='26'
                    width='26'
                    color='white'
                    classDiv='chevron-icon'
                />
            </button>
            <span className='version-number'>
                {currentVersion} / {totalVersions}
            </span>
            <button
                className='description-arrow'
                onClick={goToNextVersion}
                disabled={isLastVersion}
            >
                <IconSvg
                    name='chevron'
                    height='24'
                    width='24'
                    color='white'
                    classDiv='chevron-icon right'
                />
            </button>
        </div>
    );
}

export default DescriptionData;
