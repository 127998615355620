import React, {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {history} from '../../../../utils/store';
import SelectWrapper from '../../../../components/Select';
import InputWrapper from '../../../../components/Input';
import {fetchAllOptionsLanguages, goToSearch, setSearchInput} from '../../../home/home.actions';

import { useTranslation } from 'react-i18next';
import { categoryOptions } from '../../../../utils/constants';
import './search-section.css';


const selectStyle = {
    placeholder: (styles) => ({...styles, fontSize: 'clamp(12px, 1vw, 16px)'}),
};

function SearchSection() {
    const {t} = useTranslation();

    return (
        <section className='search-section-container' id='search-coach'>
            <p className='search-section-title'>{t('FIND_YOUR_COACH')}:</p>
            <SearchForm t={t} />
        </section>
    );
}

function SearchForm({t}) {
    const dispatch = useDispatch();
    const {options, search} = useSelector(({Home}) => ({
        options: Home.searchingOptions || [],
        search: Home.search,
    }));

    useEffect(() => {
        dispatch(fetchAllOptionsLanguages());
    }, []);

    function handleChange(value, id) {
        if (id === 'expertises' && !value) {
            value = [];
        }

        dispatch(setSearchInput({id, value}));
    }

    function handleKeyUp(event) {
        if (event.key === 'Enter') {
            handleSearch();
        }
    }

    function handleSearch() {
        dispatch(goToSearch());
        history.push('/coaches_list');
        const headerElement = document.getElementById('new-header');
        headerElement.scrollIntoView();
    }

    const {country, state, city} = useMemo(() => {
        return {
            country: options.countries.find(e => e.name === search.country) || null,
            state: options.states.find(e => e.name === search.state) || null,
            city: options.cities.find(e => e.name === search.city) || null,
        };
    }, [search, options]);

    return (
        <div className='search-form'>
            <div className='first-row'>
                <p className='search-title-mobile'>{t('SEARCH')}</p>
                <SelectWrapper
                    id='country-select'
                    className='search-item'
                    label={t('COUNTRY')}
                    placeholder={t('SELECT_COUNTRY')}
                    value={country}
                    options={options.countries}
                    onChange={(value) => handleChange((value && value.name) || null, 'country')}
                    style={selectStyle}
                />
                <SelectWrapper
                    id='state-select'
                    className='search-item'
                    label={t('STATE')}
                    placeholder={t('SELECT_STATE')}
                    options={options.states}
                    value={state}
                    isDisabled={options.states && options.states.length < 1}
                    onChange={(value) => handleChange((value && value.name) || null, 'state')}
                    style={selectStyle}
                />
                <SelectWrapper
                    id='city-select'
                    className='search-item'
                    label={t('CITY')}
                    placeholder={t('SELECT_CITY')}
                    options={options.cities}
                    value={city}
                    isDisabled={options.cities && options.cities.length < 1}
                    onChange={(value) => handleChange((value && value.name) || null, 'city')}
                    style={selectStyle}
                />
                <InputWrapper
                    label={t('COACH_NAME')}
                    value={search.name}
                    type='text'
                    name='coach-name'
                    id='coach-name-input'
                    onKeyUp={handleKeyUp}
                    onChange={(event) => handleChange(event.target.value, 'name')}
                    placeholder={t('NAME')}
                    className='input-coach-name search-item'
                />
            </div>
            <div className='second-row'>
                <SelectWrapper
                    id='expertise-select'
                    className='search-item'
                    label={t('WHAT_AREA_TRANSFORM')}
                    placeholder={t('SELECT_AREA')}
                    options={options.expertises.map(e => ({...e, value: e.id}))}
                    value={search.expertises}
                    onChange={(values) => handleChange(values, 'expertises')}
                    isMulti
                    info={t('MORE_THAN_ONE_OPTION')}
                    style={selectStyle}
                />
                <SelectWrapper
                    id='coach-category-select'
                    className='search-item'
                    label={t('OTHER_OPTIONS')}
                    placeholder={t('SELECT')}
                    options={categoryOptions()}
                    onChange={(values) => handleChange(values, 'categories')}
                    value={search.categories}
                    style={selectStyle}
                    info={t('MORE_THAN_ONE_OPTION')}
                    isMulti
                />
                <div className='search-actions'>
                    <button type='button' className='btn-search' onClick={handleSearch}>
                        {t('SEARCH_MY_COACH')}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default SearchSection;
