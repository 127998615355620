import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';

import config from '../../utils/config';

import en from './en.json';
import es from './es.json';
import ptBR from './pt.json';

i18n
    .use(LanguageDetector)
    .use(initReactI18next).init({
        resources: {
            'pt': {
                translations: {...ptBR},
            },
            'en': {
                translations: {...en},
            },
            'es': {
                translations: {...es},
            },
        },
        lng: 'pt',
        fallbackLng: 'pt',
        debug: false,
        ns: ['translations'],
        defaultNS: 'translations',

        keySeparator: false,
        detection: {
            order: ['navigator'],
        },
    });

document.documentElement.lang = i18n.language;

const savedLang = localStorage.getItem(config.currentLang);
if (savedLang) {
    i18n.changeLanguage(savedLang);
}

i18n.on('languageChanged', (lng) => {
    document.documentElement.setAttribute('lang', lng);
});

export default i18n;
