import React from 'react';
import { returnStrWithLineBreak } from '../../../../../../../utils/common-functions';
import './curriculum-info.css';

function CurriculumInfo({
    resumeFeed
}) {
    return (
        <div className="curriculum-info">
            <p>{returnStrWithLineBreak(resumeFeed)}</p>
        </div>
    );
}

export default CurriculumInfo;