import {actions} from '../../../../utils/constants';

export default (state = gallery, action) => {
    switch (action.type) {

    case actions.SET_USER:
        return action.payload.gallery || [];

    case actions.DELETE_GALLERY_PICTURE:
        return state
            .filter(picture => picture.key !== action.payload.key );

    case actions.ADD_GALLERY_PICTURE:
        return  [...state, action.payload];

    case actions.EDIT_GALLERY_PICTURE:
        return state
            .map(picture => picture.key === action.payload.key ? action.payload : picture);

    default:
        return state;
    }
};

const gallery = [];

// const gallery = [
//     {
//         url: 'http://s3.amazonaws.com/ibc-portal/wp-content/uploads/2016/03/20133308/Palestra_ibc.jpg',
//         caption: 'Curso Fator de Enriquecimento',
//     },
//     {
//         url: 'http://pubblicitaprodutora.com.br/pozzobon/wp-content/uploads/2015/11/Palestra2.jpg',
//         caption: 'Palestra o Poder da Ação',
//     },
//     {
//         url: 'http://www.fundacaoiade.org.br/wp-content/uploads/2015/11/content_palestra-kalinka.jpg',
//         caption: 'Formação internacional em método CIS (com participação do Paulo Vieira)',
//     },
//     {
//         url: 'https://cdn1.mundodastribos.com/728464-Como-montar-palestras-3.jpg',
//         caption: 'Caption 06',
//     },
// ];
