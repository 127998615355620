import React from 'react';
import { useTranslation } from 'react-i18next';
import IconSvg from '../../../../../../utils/svg';
import './coach-numbers.css';

function CoachNumbers({
    onClick,
    coachingHours,
    enableCoachingHours,
    febracisCourses,
    enableFebracisCourses,
    lecturesHours,
    enableLecturesHours,
}) {

    const {t} = useTranslation();

    return (
        <section
            onClick={() => onClick('resume')}
            className="template--5--coach-numbers">
            <ul className='template--5-numbers-content'>
                { enableFebracisCourses && 
                    <CoachNumberItem
                        iconName={'icon-graduated'}
                        label={t('FEBRACIS_TRAINING_COURSES')}
                        value={febracisCourses}
                    />
                }

                { enableCoachingHours && 
                    <CoachNumberItem
                        iconName={'icon-clock'}
                        label={t('COACHING_HOURS_SCIS')}
                        value={coachingHours}
                    />
                }

                { enableLecturesHours && 
                    <CoachNumberItem
                        iconName={'icon-speaker'}
                        label={t('HOURS_OF_COURSE_AND_LECTURES')}
                        value={lecturesHours}
                    />
                }
            </ul>
        </section>
    );
}

const CoachNumberItem = ({ value, iconName, label }) => value ? (
    <li className="item-info" >
        <IconSvg
            classDiv="icon-item"
            name={iconName}
            width={55}
            height={60}
            color={'#696969'} />

        <div className="coach-infos">
            <p className="number-info">{value}</p>
            <p className="title-info">{label}</p>
        </div>
    </li>
): null;

export default CoachNumbers;