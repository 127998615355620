import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { 
    editAction,
    initUser,
    toogleConfig,
    toogleBackground,
    closeChooseBackgroundModal,
    closeConfigModal,
    ViewModify,
    toogleFab,
    loadExpertiseAreas,
    metaDecorator,
} from './coach.actions';
import { Loader } from '../commons/index';

import CurrentTemplates from './components/current-templates/current-templates';

import './css/main.css';
import './coach.css';


class Coach extends Component {
    constructor(props) {
        super(props);
        this.historyReplaceUrl = this.historyReplaceUrl.bind(this);
        this.checkUserpermisssions = this.checkUserpermisssions.bind(this);
    }

    componentDidMount() {
        this.checkUserpermisssions();
        this.props.metaDecorator();
    }

    checkUserpermisssions() {
        const { name, refreshToken } = this.props.match.params;
        const query = new URLSearchParams(this.props.location.search);
        const code = query.get('refresh_token');

        if (refreshToken && this.props.refreshToken) {
            this.props.initUser(name, null, this.props.refreshToken);
        } else if (code) {
            this.props.initUser(name, code);
        } else {
            this.props.initUser(name);
            window.history.replaceState('', '', `/${name}`);
        }
    }

    historyReplaceUrl() {
        if (this.props.canEdit) {
            this.props.history.replace(`/${this.props.username}/edit`);
        }
        else {
            this.props.history.replace(`/${this.props.username}`);
        }
    }

    render() {
        return (
            <main className='page-coach'>
                {(this.props.isLoading || this.props.loadingEdit) && (
                    <Loader
                        width={'100vw'}
                        height={'100vh'}
                        loadHeight={100}
                        loadWidth={100}
                        color={'orange'} />
                )}
                {(!this.props.isLoading && !this.props.loadingEdit) &&
                    <CurrentTemplates changeUrl={this.historyReplaceUrl} />
                }
            </main>
        );
    }
}

const mapStateToProps = state => ({
    canEdit: state.Coach.canEdit,
    isLoading: state.Coach.isLoading,
    showConfig: state.Coach.showConfig,
    showBackground: state.Coach.showChangeBackground,
    viewMode: state.Coach.viewMode,
    user: state.Coach.user,
    disableSave: state.Coach.disableSave,
    username: state.Coach.username,
    loadingEdit: state.Coach.loadingEdit,
    refreshToken: state.Coach.refreshToken,
    countryListAndCities: state.Coach.countryListAndCities,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    editAction,
    initUser,
    toogleConfig,
    toogleBackground,
    closeChooseBackgroundModal,
    closeConfigModal,
    ViewModify,
    toogleFab,
    loadExpertiseAreas,
    metaDecorator
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Coach);