import React from 'react';
import { useTranslation } from 'react-i18next';
import TitleSection from '../../../../../../components/TitleSection/TitleSection';
import CurriculumInfo from './curriculum-info/curriculum-info';

import './curriculum.css';

function Curriculum({
    resumeFeed,
    onClick
}) {

    const {t} = useTranslation();

    return (
        <section
            onClick={() => onClick('resume')}
            className="template--6--curriculum"
            id="resume">
            <TitleSection text1={t('MY')} text2={t('CURRICULUM')} colorIcon="#ee4266" icon={false} />
            <div className="curriculum-content">
                <CurriculumInfo
                    resumeFeed={resumeFeed}
                />
            </div>
        </section>
    );
}

export default Curriculum;