import React, { useCallback } from 'react';

import Modal from '../../screens/commons/modal/modal';
import SendMessage from './components/SendMessage/SendMessage';
import CoachInfo from './components/CoachInfo/CoachInfo';

const ModalCoach = ({ onClose, show, ...coach }) => {

    const close = useCallback(() => {
        onClose();
    }, []);

    return (
        <Modal
            show={show}
            onClose={close}
            contentClassName="modal-coach"
            dialogClassName="main-container"
        >
            <CoachInfo coach={coach} />
            <SendMessage onClose={close} coach={coach} />
        </Modal>
    );
};

export default ModalCoach;
