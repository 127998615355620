const INITIAL_STATE = {
    username: '',
    password: '',
    client_id: 'coach_profile',
    result: null,
    fieldsError: false,
    userExpired: false,
    isRequesting: false
};

export default (state= INITIAL_STATE, action) => {
    switch(action.type) {
    case 'changeValue':
        return { ...state, [action.payload.id]: action.payload.value };

    case 'resetForm':
        return { ...state, username: null, password: null, client_id: null };

    case 'setAddress':
        return { ...state, result: action.payload };
    
    case  'error_campo_invalido':
        return { ...state, fieldsError: action.payload };
    
    case 'error_user_expired':
        return { ...state, userExpired: action.payload };

    case 'change_is_requesting':
        return { ...state, isRequesting: action.payload };

    default:
        return state;
    }
};