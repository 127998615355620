import React from 'react';
import {useTranslation} from 'react-i18next';

import IconSvg from '../../../../utils/svg';

import './benefits-section.css';

function BenefitsSection() {
    const {t} = useTranslation();

    const [titleA, highlightedWord, titleB] = t('BENEFIT_TITLE').split('*');
    return (
        <section className='benefits-section' id='benefits'>
            <p className='benefits-title'>
                {titleA}
                <span>{highlightedWord}</span>
                {titleB}
            </p>
            <BenefitsList benefitsList={benefitsItems} t={t} />
        </section>
    );
}

function BenefitsList({benefitsList, t}) {
    const benefitsItems = benefitsList.map(benefit => {
        return <BenefitItem benefit={benefit} key={benefit.label} t={t} />;
    });

    return (
        <ul className='benefits-list'>
            {benefitsItems}
        </ul>
    );
}

function BenefitItem({benefit, t}) {
    return (
        <div className='benefit-item'>
            <IconSvg name={benefit.icon} classDiv='benefit-icon' width={90} height={90} />
            <p className='benefit-text'>{t(benefit.label)}</p>
        </div>
    );
}

const benefitsItems = [
    {
        label: 'THOUSANDS_OF_REGISTERED_COACHES',
        icon: 'icon-users',
    },
    {
        label: 'ONLINE_AND_FACE_TO_FACE_SERVICE',
        icon: 'icon-multiplataform',
    },
    {
        label: 'FLEXIBLE_HOURS',
        icon: 'icon-clock2',
    },
];

export default BenefitsSection;
