import React from 'react';
import IconSvg from '../../../../../../../utils/svg';

import './photo-card.css';

function PhotoCard({ url, onClick, caption }) {
    return (
        <div
            onClick={onClick}
            className="template--5-photo-card"
            style={{ backgroundImage: `url(${url})` }}
        >

            <div className="background-effect" />
            <p className="photo-subtitle">{caption}</p>
            <IconSvg name="icon-circle-zoom" classDiv="photo-icon-zoom" width={25} height={25} color="#fff" />

        </div>
    )
};

export default PhotoCard;