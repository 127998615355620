import React from 'react';
import { useTranslation } from 'react-i18next';

import './initial-card.css';

function InitialCard({ url }) {
    const {t} = useTranslation();

    return (
        <div className="template--6-initial-card" style={{ backgroundImage: `url(${url})` }}>
            <p>{t('MY_FEM')}</p>
            <h4>{t('GALLERY')}</h4>
        </div>
    );
}

export default InitialCard;