import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';


import { connect } from 'react-redux';
import { compose } from 'redux';
import { goesTo } from '../../../../utils/common-functions';

import SocialLine from '../../../commons/social-line/social-line';

import './footer.css';

class FooterComponent extends Component {
    
    render() {
        const { firstName, secondName, tel } = this.props.user;
        const { t } = this.props;

        return (
            <footer className='footer-wrapper'>
                <div className='home-container'>
                    <div className='section-up'>
                        <div className='coach-name'>
                            <div>{ firstName }</div>
                            <div>{ secondName }</div>
                        </div>
                       
                        { tel &&
                        <div className='coach-tel'>
                            <a href={`tel:${tel}`} style={{display: 'flex',flexDirection: 'row', alignItems: 'center'}}>
                                <i className='fa fa-whatsapp' />
                                <div className="coach-tel-number">{ tel }</div>
                            </a>
                        </div>
                        }

                        <SocialLine  user={ this.props.user } />

                        <div className='anchor-top'>
                            <a href='#home' onClick={ ()=> goesTo('#home') } alt="Voltar ao início" title={t('BACK_TO_TOP')}>
                                <i className="fa fa-angle-up" />
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

const mapStateToProps = ( state ) => ({  user: state.Coach.user });

export default compose(withTranslation(), connect(mapStateToProps))(FooterComponent);