import { actions } from '../../../../utils/constants';

const INITIAL_STATE = {
    name: '',
    city: '',
    email: '',
    tel: '',
    subject: '',
    message: '',
    sendSucess: false,
    sendError: false,
    lockButton: true,
    emailInvalid: false
};

export default (state=INITIAL_STATE, action) => {
    switch (action.type) {
    case actions.CHANGE_VALUE:
        return {...state, ...action.payload};
    case actions.CLEAR_FORM:
        return { ...state, ...INITIAL_STATE };
    case actions.LOCK_BUTTON:
        return {...state, lockButton: action.payload };

    case actions.EMAIL_INVALID:
        return { ...state, emailInvalid: action.payload };

    case actions.ERROR_SEND_FORM: 
        return {...state, sendError: action.payload};

    case actions.SEND_FORM:
        return {...state, sendSucess: action.payload};
        
        
    default:
        return state;
    }
};