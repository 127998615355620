import React, { useState, useEffect } from 'react';
import TextareaAutosize from 'react-autosize-textarea/lib';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';

import { regexEmail } from '../../../../../utils/constants';
import { CheckIfHasEmpty } from '../../../../../utils/common-functions';
import { sendContact } from '../../template.hooks';

import './styles.css';

const initialValue = {
    name: '',
    email: '',
    tel: '+55',
    subject: '',
    message: '',
    city: '',
};

const defaultCountry = 'br';
const preferredCountries = ['br', 'us', 'pt', 'ao', 'jp'];

function ContactForm({ username }) {
    const [formValue, setFormValue] = useState(initialValue);
    const [formInvalid, setFormInvalid] = useState('');

    const [{ pending, success, error, complete }, makeContactRequest] = sendContact(username);

    const { t } = useTranslation();

    useEffect(() => {
        if (complete && success) {
            setFormValue(initialValue);
        }
    }, [complete]);

    function handleValidateEmail() {
        const isValid = regexEmail.test(formValue.email);
        if (isValid) {
            setFormInvalid('');
        } else {
            setFormInvalid('E-mail inválido');
        }
    }

    function changePhone(value) {
        setFormValue((data) => ({ ...data, tel: value.valueOf() }));
    }

    function handleChangeForm(e, name) {
        setFormValue({ ...formValue, [name]: e.target.value });
    }

    function handleSubmit(e) {
        e.preventDefault();
        makeContactRequest(formValue);
        setFormValue(initialValue);
    }

    return (
        <form onSubmit={handleSubmit} className="template-contact-form">
            <div className="contact-form">
                <div className="form-group">
                    <InputForm
                        name={'name'}
                        placeholder={t('NAME')}
                        onChange={handleChangeForm}
                        value={formValue.name}
                    />
                    <div className="form-group--2">
                        <InputForm
                            name={'city'}
                            placeholder={t('CITY')}
                            onChange={handleChangeForm}
                            value={formValue.city}
                        />
                        <InputForm
                            name={'email'}
                            placeholder={t('EMAIL')}
                            inputError={formInvalid}
                            onBlur={handleValidateEmail}
                            onChange={handleChangeForm}
                            value={formValue.email}
                        />
                    </div>
                    <PhoneInput
                        country={defaultCountry}
                        onChange={changePhone}
                        value={formValue.tel}
                        autoFormat={true}
                        countryCodeEditable={false}
                        preferredCountries={preferredCountries}
                        className="contact-phone-input"
                    />
                </div>
                <div className="form-group">
                    <InputForm
                        name={'subject'}
                        placeholder={t('SUBJECT')}
                        onChange={handleChangeForm}
                        value={formValue.subject}
                    />
                    <div className="text-counter">
                        <InputForm
                            istextarea
                            areaClassName="input-area"
                            name={'message'}
                            maxLength={1000}
                            placeholder={t('MESSAGE')}
                            onChange={handleChangeForm}
                            value={formValue.message}
                        />
                        <span>
                            {1000 - formValue.message.length} {t('CHARACTERS_REMAINING')}
                        </span>
                    </div>
                </div>
            </div>

            {error && (
                <DisplayMessage type="error" message={t('ERROR_SENDING_MESSAGE')} />
            )}
            {success && (
                <DisplayMessage
                    type="success"
                    message={t('MESSAGE_SENT_SUCCESSFULLY')}
                />
            )}

            <button
                className="contact-button"
                disabled={pending || formInvalid || CheckIfHasEmpty(formValue)}
                type="submit"
            >
                {pending ? t('SENDING') : t('SEND')}
            </button>
        </form>
    );
}

const InputForm = ({
    name,
    type,
    label,
    onChange,
    inputError,
    istextarea,
    placeholder,
    maxLength = 100,
    onBlur = () => {},
}) => (
    <div className={`input-control ${inputError && 'input-control-error'}`}>
        {label && <label>{label}</label>}
        {istextarea ? (
            <TextareaAutosize
                name={name}
                maxRows={3}
                maxLength={maxLength}
                placeholder={placeholder}
                onChange={(e) => onChange(e, name)}
            />
        ) : (
            <input
                type={type}
                onBlur={onBlur}
                name={name}
                placeholder={placeholder}
                onChange={(e) => onChange(e, name)}
                maxLength={100}
            />
        )}
    </div>
);

export default ContactForm;

function DisplayMessage({ type, message }) {
    return (
        <div className={`dialog-message message-${type}`}>
            <p>{message}</p>
        </div>
    );
}
