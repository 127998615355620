import React from 'react';
import TitleSection from '../../../../../../components/TitleSection/TitleSection';
import BiographyInfo from './biography-info/biography-info';
import BiographyContact from './biography-contact/biography-contact';

import './biography.css';
import { useTranslation } from 'react-i18next';
import { removeConstatsFromExpertises } from '../../../../../../utils/common-functions';

function Biography({
    fullName,
    aboutMe,
    expertises,
    tel,
    email,
    location,
    photoUrl,
    onClick,
    themeColor
}) {
    const {t} = useTranslation();
    const expertisesList = removeConstatsFromExpertises(expertises);

    return (
        <section
            className="template--5--biography"
            onClick={() => onClick('biography')}
            id="biography">
                
            <TitleSection text1={t('BIOGRAPHY')} iconView={true} colorIcon={themeColor} />
            <div className="biography-content">
                <div className='biography-image'>
                    <img
                        alt="coach"
                        src={photoUrl}
                    />

                    <div className='bg-pontilhado'></div>
                </div>

                <BiographyInfo
                    fullName={fullName}
                    aboutMe={aboutMe}
                    expertises={expertisesList}
                />

                <BiographyContact
                    tel={tel}
                    email={email}
                    location={location}
                />
            </div>
        </section>
    );
}

export default Biography;