import React from 'react';
import Loading from '../../../img/loading.gif';

import './loader.css';


const Loader = ({ style, className, color, width = '100%', height = '100%', loadWidth, loadHeight, text, type }) => (
    <div
        className={`loader-wrapper ${className ? className : ''}`}
        style={{ ...style, width, height }}>
        <img src={Loading} alt='Loading'/> 
        {text && <span style={{ color: color || 'black', fontSize: '1.5em' }}>{text}</span>}
    </div>
);

export default Loader;