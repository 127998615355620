import React from 'react';

import './testimonial-card.css';

function TestimonialCard({
    text,
    occupation,
    author
}) {
    return (
        <div className="template--7-testimonial-card">
            <p className="testimonial-text">
                {text}
            </p>

            <p className="author">
                {author}
            </p>

            <p className="author-work">
                {occupation}
            </p>
        </div>
    );
};

export default TestimonialCard;