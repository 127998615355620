import React from 'react';

import Footer from '../../screens/home/components/footer';
import Header from '../../components/newHeader';
import {Link} from 'react-router-dom';

import './404.css';

const page404 = () => (
    <div className='page-404'>
        <Header className='new-home-header'/>

        <main className='page-404-main'>
            <h1 className='title-404'>404</h1>

            <h4 className='text-404'>Ooops, a página não foi encontrada.</h4>

            <button className='btn-back-to-home'>
                <Link to='/home'>
                    Voltar para a página inicial
                </Link>
            </button>
        </main>


        <Footer />
    </div>
);

export default page404;