import React from 'react';
import './biography-info.css';

function BiographyInfo({ aboutMe }) {
    return (
        <div className="biography-info">
            <p>{aboutMe}</p>
        </div>
    );
}
export default BiographyInfo;