import React, { useState } from 'react';

import VideoCard from './video-card/video-card';
import TitleSection from '../../../../../../components/TitleSection/TitleSection';
import SlickCarousel from '../../../../../../components/SlickCarousel/slickCarousel';
import { ZoomedVideoModal } from '../../../../components/videos/videos';

import './videos.css';
import { useTranslation } from 'react-i18next';

function Videos({ videos, onClick }) {

    const {t} = useTranslation();

    if (!videos || !videos.length) {
        return null;
    }

    const [zoomVideo, setZoomVideo] = useState(null);

    const settings = {
        dots: true,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: videos < 2 ? videos : 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ]
    };


    return (
        <section
            onClick={() => onClick('videos')}
            id="videos"
            className="template--6--videos">
            <div className='template--6--videos-container'>
                <TitleSection text1={t('MY_PLURAL')} text2={t('VIDEOS')} colorIcon="#ecc22e" />

                <SlickCarousel className="generic--slider" settings={settings}>
                    {videos.map((video, i) => (
                        <div className="generic--slider-item" key={i}>
                            <VideoCard key={i} {...video}
                                onClick={() => setZoomVideo(video)} />
                        </div>
                    ))}
                </SlickCarousel>

                <ZoomedVideoModal
                    video={zoomVideo}
                    onClose={() => setZoomVideo(null)}
                />

            </div>

        </section>
    );
}

export default Videos;