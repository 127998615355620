import React from 'react';
import ContentLoader from 'react-content-loader';

import './loading-card.css';

export default function LoadingCards() {
    return (
        <div className='container-loading' >
            {Array(7).fill({}).map((_, idx) => <div key={idx} className='loading-card-item'><LoadingCard /></div>)}
        </div>
    );
}

const LoadingCard = () => (
    <ContentLoader
        speed={2}
        backgroundColor='#e6e6e6'
        foregroundColor='#d8d8d8'
        width='280px'
        height='420px'
    >
        <rect x='0' y='0' rx='5' ry='5' width='100%' height='100%' />
    </ContentLoader>
);