import React from 'react';
import TitleSection from '../../../../../../components/TitleSection/TitleSection';
import BiographyInfo from './biography-info/biography-info';

import './biography.css';
import BiographyContact from './biography-contact/biography-contact';
import { useTranslation } from 'react-i18next';
import { removeConstatsFromExpertises } from '../../../../../../utils/common-functions';

function Biography({
    onClick,
    aboutMe,
    expertises,
    photoUrl,
    themeColor
}) {
    const {t} = useTranslation();
    const expertisesList = removeConstatsFromExpertises(expertises);

    return (
        <section
            onClick={() => onClick('biography')}
            className="template--7--biography"
            id="biography">

            <article className="biography-content">
                <img alt="coach" src={photoUrl} />

                <div className='biography-content-divisor'>
                    <TitleSection text1={t('BIOGRAPHY')} colorIcon={themeColor} iconView={true} iconName='icon-text-effect' iconWidth='39px' />

                    <BiographyInfo
                        aboutMe={aboutMe}
                    />

                    <BiographyContact
                        expertises={expertisesList}
                    />
                </div>
            </article>

        </section>
    );
}

export default Biography;