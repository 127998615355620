import React, { useState, Fragment, useEffect } from 'react';
import { ITEMS_NAV, SESSION_ITEM_NAV } from '../../../../../../../utils/constants';
import { goesTo } from '../../../../../../../utils/common-functions';
import { useWidth } from '../../../../template.hooks';
import SocialMedias from '../../../../components/social-medias/social-medias';
import NavBarMobile from '../nav-bar-mobile/nav-bar-mobile';

import './nav-bar.css';
import SelectLanguage from '../../../../../../../components/SelectLanguage';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { initSearchingOptions } from '../../../../../../home/home.actions';
import { useTranslation } from 'react-i18next';

function navBar({
    enableBiography,
    enableResume,
    enableVideos,
    enableTestimonies,
    enableGallery,
    gallery,
    testimonies,
    videos,
    firstName,
    tel,
    facebook,
    linkedin,
    instagram,
    twitter,
    whatsapp,
    youtube,
}) {

    const [width] = useWidth();
    const [addClass, setAddClass] = useState(false);
    const [itemsNav, setItemsNav] = useState(ITEMS_NAV);
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const coachDetails = useSelector(
        ({Coach}) => Coach.user,
        shallowEqual
    );

    useEffect(() => {
        const newitems = ITEMS_NAV.filter(({ url }) => {
            switch (url) {
            case '#biography':
                return enableBiography;
            case '#resume':
                return enableResume;
            case '#videos':
                return enableVideos && videos && videos.length;
            case '#testimonies':
                return enableTestimonies && testimonies && testimonies.length;
            case '#gallery':
                return enableGallery && gallery && gallery.length;
            default:
                return true;
            }
        });
        setItemsNav(newitems);
    }, [enableBiography, enableResume, enableVideos, enableTestimonies, enableGallery]);

    useEffect(() => {
        if (coachDetails && coachDetails.enableSiteExperimentalSession) {
            const list = itemsNav.map((item) => {
                if (item.key === 'CONTACT') {
                    return SESSION_ITEM_NAV;
                }
                return item;
            });

            setItemsNav(list);
        }
    }, [coachDetails]);

    function toggle() {
        setAddClass(!addClass);
    }

    let boxClass = ['template--6--navbar'];
    if (addClass) {
        boxClass.push(' menu-active');
    }

    function handleLanguageChange(option) {
        dispatch(initSearchingOptions(option.value));
    }

    return (
        <nav className={`${boxClass.join('')}`}>
            <h2 className="name">{firstName}</h2>

            {width <= 990 ?
                <Fragment>
                    <div className='left-header'>
                        <div className='select-language-hamburguer-template-6'>
                            <SelectLanguage
                                onChange={handleLanguageChange}
                                className={'template-6'}
                            />
                        </div>
                        <div className="header--menu-burguer" onClick={toggle}>
                            <span className="line" style={{visibility: `${addClass ? 'hidden': 'visible'}`}}></span>
                            <span className="line" style={{visibility: `${addClass ? 'hidden': 'visible'}`}}></span>
                            <span className="line" style={{visibility: `${addClass ? 'hidden': 'visible'}`}}></span>
                        </div>
                    </div>
                    {addClass &&
                        <NavBarMobile
                            itemsNav={itemsNav}
                            onClose={() => setAddClass(false)}
                            firstName={firstName}
                            tel={tel}
                            facebook={facebook}
                            linkedin={linkedin}
                            instagram={instagram}
                            twitter={twitter}
                            whatsapp={whatsapp}
                            youtube={youtube}
                        />
                    }
                </Fragment>

                :

                <Fragment>
                    <ul className="items--nav">
                        {itemsNav.map(({ key, url }) => (
                            <li key={url} onClick={() => goesTo(url)}>
                                <p>{t(key).toUpperCase()}</p>
                            </li>
                        ))}
                        <li className='select-language' style={{minWidth:'125px'}}>
                            <SelectLanguage
                                onChange={handleLanguageChange}
                                className={'template-6'}
                            />
                        </li>
                    </ul>

                    <SocialMedias
                        facebook={facebook}
                        linkedin={linkedin}
                        instagram={instagram}
                        twitter={twitter}
                        whatsapp={whatsapp}
                        youtube={youtube}
                        sizeIcons={25}
                        direction="row"
                        color="#4e4e4e" />
                </Fragment>

            }

        </nav>
    );
}

export default navBar;