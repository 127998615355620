import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Input from '../../../../../components/Input';

import './answer.css';

function AnswersData({data, setData, errors}) {
    const {t} = useTranslation();

    const changeValue = useCallback((key) => {
        return useCallback(({ target }) => {
            let item = {...data};
            item = {...item, [key]: target.value};
            setData(item);
        }, [data]);
    }, [data]);

    return (
        <div className="answer-container">
            <title className='answer-title'>
                <h1>{t('NEW_DESCRIPTION_TO_YOU')}</h1>
                <h5>{t('ANSWER_THE_QUESTIONS')}</h5>
            </title>
            <div className='answers-group'>
                <div className="form-item">
                    <Input
                        optional={false}
                        label={t('QUESTION1')}
                        value={data.answer1}
                        type="area"
                        onChange={changeValue('answer1')}
                        placeholder={`${t('ADD_YOUR_ANSWER_HERE')}...`}
                        className={`input-contat ${errors && errors[4] ? 'error' : ''}`}
                        error={errors && errors[4]}
                        errorMessage={`*${t('FIELD_NULL')}`}
                    />
                </div>
                <div className="form-item">
                    <Input
                        optional={false}
                        label={t('QUESTION2')}
                        value={data.answer2}
                        type="area"
                        onChange={changeValue('answer2')}
                        placeholder={`${t('ADD_YOUR_ANSWER_HERE')}...`}
                        className={`input-contat ${errors && errors[5] ? 'error' : ''}`}
                        error={errors && errors[5]}
                        errorMessage={`*${t('FIELD_NULL')}`}
                    />
                </div>
                <div className="form-item">
                    <Input
                        optional={false}
                        label={t('QUESTION3')}
                        value={data.answer3}
                        type="area"
                        onChange={changeValue('answer3')}
                        placeholder={`${t('ADD_YOUR_ANSWER_HERE')}...`}
                        className={`input-contat ${errors && errors[6] ? 'error' : ''}`}
                        error={errors && errors[6]}
                        errorMessage={`*${t('FIELD_NULL')}`}
                    />
                </div>
            </div>
        </div>
    );
}
export default AnswersData;