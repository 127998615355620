import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import { Button, Input } from '../../../commons/index';
import { changeValue, sendContactMessage, checkEmailValidate } from './contact.action';

import './contact.css';

class ContactComponent extends Component {
    render() {
        const {t} = this.props;
        return (
            <section className='contact-wrapper'>

                <div className='effect-contact'></div>
                <div className='effect-contact-2'></div>

                <div className='contact-content'>
                    <div className="home-container">
                        <div className='content'>
                            <h2>{t('ENTER_IN')} {t('CONTACT')}</h2>
                            <form className='contact-form'>
                                <div className='one-input'>
                                    <Input icon={'fa-id-badge'} max={'50'} id='name' onchange={this.props.changeValue}
                                        placeholder={t('NAME')} value={this.props.name}
                                    />
                                </div>

                                <div className='two-input'>
                                    <Input icon='fa-map-marker' max={'25'} id='city' onchange={this.props.changeValue}
                                        placeholder={t('CITY')} value={this.props.city}
                                    />

                                    <Input onblur={this.props.checkEmailValidate} icon='fa-envelope-o' type='email' id='email' onchange={this.props.changeValue}
                                        placeholder={t('EMAIL')} value={this.props.email}
                                    />
                                </div>
                                {this.props.emailInvalid && <div className='error-message-email'> <i className="icon-exclamation"></i>E-mail inválido</div>}

                                <div className='two-input'>
                                    <Input icon='fa-phone' max={'30'} type='tel' id='tel' onchange={this.props.changeValue}
                                        placeholder={t('TELEPHONE')} value={this.props.tel}
                                    />

                                    <Input icon='fa-list-alt' max={'50'} id='subject' onchange={this.props.changeValue}
                                        placeholder={t('SUBJECT')} value={this.props.subject} />
                                </div>

                                <div className='one-input last'>
                                    <div className='input-wrapper msg'>
                                        <textarea maxLength='1000' id='message' placeholder={t('YOUR_MESSAGE')}
                                            value={this.props.message} onChange={this.props.changeValue}
                                        />
                                    </div>
                                    <span className='character-count' style={{ color: 'white' }}>{`${1000 - this.props.message.replace(/\n/g, '').length}  ${t('CHARACTERS_REMAINING')}`}</span>
                                </div>

                                {this.props.sendSucess &&
                                    <div className='message-sendSucess'>
                                        <div className="success-msg">
                                            <i className="icon-correct"></i>
                                            {t('MESSAGE_SENT')}
                                        </div>
                                        {t('THANKS_FOR_CONTACT_US')}

                                    </div>
                                }

                                {this.props.sendError &&
                                    <div className='message-sendError'>
                                        <div className="error-msg">
                                            <i className="icon-error"></i>
                                            {t('ERROR')}
                                        </div>
                                        {t('MESSAGE_NOT_SENT')}
                                    </div>
                                }

                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button disabled={this.props.lockButton} size={'small'} type={'outline'} text={t('SEND')} click={this.props.sendContactMessage} />
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = state => ({
    name: state.Contact.name,
    city: state.Contact.city,
    email: state.Contact.email,
    tel: state.Contact.tel,
    subject: state.Contact.subject,
    message: state.Contact.message,
    lockButton: state.Contact.lockButton,
    emailInvalid: state.Contact.emailInvalid,
    sendSucess: state.Contact.sendSucess,
    sendError: state.Contact.sendError
});

const mapDispatchToProps = dispatch => bindActionCreators({ changeValue, sendContactMessage, checkEmailValidate }, dispatch);

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(ContactComponent);