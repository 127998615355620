import React from 'react';

import './input.css';

const Input = (props) => (
    <div className='input-wrapper'>
        {props.icon && <i className={`fa ${props.icon}`} />}
        <input
            onBlur={props.onblur}
            value={props.value || ''}
            type={props.type || 'text'}
            id={props.id}
            placeholder={props.placeholder}
            onChange={props.onchange}
            maxLength={props.max}
        />
    </div>
);

export default Input;