import { createStore, applyMiddleware } from 'redux';
import promiseMiddleware from 'redux-promise-middleware';

import createSagaMiddleware from 'redux-saga';

import { createBrowserHistory } from 'history';

import RootReducer from './rootreducer';
import RootSaga from './rootSaga';
import { routerMiddleware } from 'connected-react-router';

const sagaMiddleware = createSagaMiddleware();

const history = createBrowserHistory();

const historyMiddleware = routerMiddleware(history);

const devTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

const Store = applyMiddleware(promiseMiddleware(), sagaMiddleware, historyMiddleware)(createStore)(RootReducer(history), devTools);

sagaMiddleware.run(RootSaga);

export default Store;

export { history };