import React, { Component } from 'react';
import './image-card.css';

class ImageCard extends Component {
    
    render() {
        const { imgUrl, onclick } = this.props;
        return (
            <div className='main-container-card' style={{ background: `url(${imgUrl})`}} onClick={ onclick }>
                <div className='transparent-wrapper'>
                    <div className='sign-container'>
                        <i className='fa fa-check-circle ok-sign' />
                    </div>
                </div>
            </div>
        );
    }
}

export default ImageCard;