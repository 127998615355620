import React from 'react';
import IconSvg from '../../../../../../../utils/svg';

import './video-card.css';

function VideoCard({
    caption,
    thumbnail,
    onClick
}) {
    return (
        <div className="template--7-video-card">

            <div className='template--7-video-image' onClick={onClick}>
                <img src={thumbnail} alt='title' />

                <div className="player-hover">
                    <IconSvg name="icon-player-videos" classDiv="icon-play-float-video" width={50} color="#fff" />
                </div>
            </div>

            <div className="video-infos" onClick={onClick}>

                <p className="video-subtitle">{caption}</p>

                <button className="video-btn" type="button" >
                    <IconSvg name="icon-player-videos" classDiv="icon-play-float" width={27} color="#fff" />
                    Assistir Vídeo
                </button>
            </div>
        </div>
    )
};

export default VideoCard;