import { actions } from '../../../../utils/constants';

export const changeValue = event => ({
    type: actions.ASYNC_CHANGE_VALUE,
    payload: { [event.target.id]: event.target.value }
});

export const sendContactMessage = (event) => {
    event.preventDefault();
    return { type: actions.ASYNC_CLEAR_FORM }; 
};

export const checkEmailValidate = event => ({
    type: actions.CHECK_EMAIL,
    payload: event.target.value
});