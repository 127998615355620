import React from 'react';
import { returnStrWithLineBreak } from '../../../../../../../utils/common-functions';
import './effect-message.css';

function EffectMessage({ mainPhraseAuthor, mainPhrase }) {
    return (
        <div className="template--6--effect-message">
            <span className="subtitle">
                {returnStrWithLineBreak(mainPhrase)}
            </span>
            <p className="autor"><span>
                {mainPhraseAuthor}
            </span></p>
        </div>
    );
}

export default EffectMessage;
