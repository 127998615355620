import React from 'react';

import SearchContainer from './components/search-container';
import ContainerMain from './components/container-main';

import './coaches-list.css';

const CoachesList = () => {
    return (
        <div className='container-coaches-list' >
            <SearchContainer />
            <ContainerMain />
        </div>
    );
};

export default CoachesList;