import React from 'react';

import './button.css';

const button = (props) => (
    <button disabled={props.disabled} type={'button'} className={`button ${props.disabled ? 'disabled' : ''} ${props.size || ''} ${props.type || ''}`} onClick={props.click}>
        <div className='text'>{props.text}</div>
        {props.children}
    </button>
);

export default button;