import React, {memo} from 'react';
import { useTranslation } from 'react-i18next';
import IconSvg from '../../utils/svg';


import './ratingStars.css';

function RatingStars({rating, color = '#FE7800', showNumericRating = false, enableSiteExperimentalSession}) { 
    const {t} = useTranslation(); 
    const noneColor = '#0000';
    const ratingConvert =  rating ? rating.toFixed(1).replace('.', ',') : rating;

    return (
        <div className='group-infos'>
            <div className='background-container'>
                <div className='flags-infos'>
                    <IconSvg name='icon-star' borderColor={color} color={color} color2={noneColor} percent={100} width={17} height={17} classDiv='icon-flag' />
                    {rating === 5 || !rating ? '5,0' : ratingConvert}
                </div>
            </div>
            {enableSiteExperimentalSession &&
                    <div className='background-container'>
                        <div className='flags-infos'>
                            <IconSvg classDiv='icon-flag' name='icon-check' color='#FE7800' width={17} height={17} />
                            {t('EXPERIMENTAL_SESSION')}
                        </div>

                    </div>
            }
            <div className='background-session'>
                    
            </div>
        </div>
    );
}

export default memo(RatingStars);
