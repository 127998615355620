import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import { ResumeFooter } from './components/index';
import DisableBlock from '../../../commons/disable-block/disable-block';
import './resume.css';
import { editProfile } from '../header/header.action';
import { changeVisibility, changeCurrentSection } from '../../coach.actions';
import { returnStrWithLineBreak } from '../../../../utils/common-functions';
import { withTranslation } from 'react-i18next';

class Resume extends Component {

    render() {
        const { 
            coachingHours,
            febracisCourses,
            lecturesHours,
            resumeFeed,
            enableResume,
            enableResumeFooter,
            enableCoachingHours,
            enableFebracisCourses,
            enableLecturesHours,
            theme
        } = this.props.user;

        const { imageBackground, changeCurrentSection, t } = this.props;

        if (!enableResume && !this.props.isEdit) {
            return null;
        } else {
            return (
                <section
                    id="resume"
                    onClick={() => changeCurrentSection('resume')}
                    className='resume-container'
                    style={{ background: imageBackground ? `url(${imageBackground})` : 'inherit' }} >
                    <article className="resume-content" style={{marginLeft: `${theme === 'template-2' ? '20px' : ''}`}}>
                        <div className="home-container max-container">
                            <div
                                className='title-wrapper'
                                style={{display: `${theme === 'template-2' ? 'block' : ''}`}}    
                            >
                                <h1
                                    className='title'
                                    style={{textAlign: `${theme === 'template-2' ? 'initial' : ''}`}}>{t('CURRICULUM')}</h1>
                            </div>

                            <DisableBlock id={'enableResume'} show={this.props.isEdit} click={this.props.changeVisibility} enable={enableResume} />

                            <div className='courses-container'>
                                {!this.props.isEdit &&
                                    <article className='tooltip'>
                                        {resumeFeed && <p>{[...returnStrWithLineBreak(resumeFeed, '\n')]}</p>}
                                        {!resumeFeed && <p></p>}

                                    </article>
                                }
                                {this.props.isEdit && <textarea maxLength='1090' value={(resumeFeed && resumeFeed != null) ? resumeFeed : ''} id={'resumeFeed'} onChange={this.props.editProfile} className='feedEdit' />}
                                {this.props.isEdit && <span className='character-count' style={{ color: 'gray' }}>{`${1090 - (resumeFeed ? resumeFeed.length : ('').length)}  caracteres restantes`}</span>}
                            </div>
                        </div>
                    </article>
                    {
                        (enableResumeFooter || (!enableResumeFooter && this.props.isEdit)) &&
                        <ResumeFooter 
                            changeVisibility={this.props.changeVisibility}
                            enable={enableResumeFooter}
                            coaching={coachingHours}
                            coachingVisibility={enableCoachingHours}
                            lectures={lecturesHours}
                            lecturesVisibility={enableLecturesHours}
                            courses={febracisCourses}
                            coursesVisibility={enableFebracisCourses}
                            isEdit={this.props.isEdit}
                            onchange={this.props.editProfile}
                        />
                    }
                </section>
            );
        }
    }
}

const mapStateToProps = state => ({
    user: state.Coach.user,
    isEdit: state.Coach.isEdit,
    canEdit: state.Coach.canEdit,
});

const mapDispatchToProps = dispatch => bindActionCreators({ editProfile, changeCurrentSection, changeVisibility }, dispatch);

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Resume);