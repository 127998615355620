import React, { useMemo } from 'react';
import {useTranslation} from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import CoachesList from '../../../../components/CoachesList';
import LoadingCards from '../../../../components/LoadingCard';
import IconSvg from '../../../../utils/svg';
import {nextPageSearch} from '../../../home/home.actions';

import './container-list.css';

const ContainerList = () => {
    const {t} = useTranslation();
    const disptach = useDispatch();
    const {
        query: {
            country,
            name,
            state,
            expertises,
            categories,
            city,
        },
        pages,
        totalProfiles,
        loading,
    } = useSelector(({Home}) => ({
        query: Home.query,
        pages: Home.pages,
        totalProfiles: Home.totalProfiles,
        loading: Home.isSearching,
    }));

    const hasInfo = country || name || state || (expertises && expertises.length > 0) || (categories && categories.length > 0) || city;
    let infos = [country, state, city, name, ...expertises.map(e => e.label)].filter(e => !!e);
    infos = categories ? [...infos, ...categories.map(e => e.label)] : infos;

    const {coaches, hasMore} = useMemo(() => {
        const coaches = Object.values(pages).flatMap(e => e);
        return {
            coaches: coaches,
            hasMore: coaches.length < totalProfiles,
        };
    }, [Object.keys(pages)]);

    const fetchMore = () => {
        disptach(nextPageSearch());
    };

    return (
        <div className='container-list' id='container-list'>
            <div className='container-general' >
                <div className='container-results' >
                    <IconSvg name='icon-search' color='#FE7800' classDiv='div-search' width={25} height={25} />
                    <span className='result-label'>{totalProfiles} {totalProfiles === 1 ? t('COACH_FIND') : t('COACHES_FIND')} </span>
                </div>
                {
                    hasInfo && <span className='result-info' >
                        {infos.join(' - ')} 
                    </span>
                }
            </div>

            {(loading && coaches.length === 0) && <LoadingCards />}

            {(!loading && coaches.length === 0) && <p className='no-result'>{t('NO_RESULTS_FOUND')}</p>}

            <CoachesList fetchData={fetchMore} coaches={coaches} scrollableTarget='my-body' hasMore={hasMore}/>
        </div>
    );
};

export default ContainerList;