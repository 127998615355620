import React, { Component } from 'react';
import { CustomModal } from '../../../commons/index';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { editProfile } from '../header/header.action';
import { setPublishProfile,asyncVerifyIfUsernameIsAvailable, loadCountries, cancelSaveConfig } from '../../coach.actions';


import General from './components/general/general';
import MediaSocial from './components/media-social/media-social';

import './config-modal.css';

class ModalConfig extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabs:[
                { tab: 'general', show: true },
                { tab: 'media', show: false },
            ]
        };

        this.handleClick = this.handleClick.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }


    handleClick(key) {
        let { tabs } = this.state;

        tabs = tabs.map((element,i) => {
            if( element.tab === key ) {
                element.show = true;
            } else {
                element.show = false;
            }
            return element;
        });
        this.setState({ tabs });
    }

    closeModal() {
        this.props.cancelSaveConfig();
        this.props.close();
    }

    render() {
        const { show, close, toogleBackground, user, className } = this.props;
        const { tabs } = this.state;
        
        return (
            <CustomModal className={ className } show={ show } close={ this.closeModal } title={'configurações'} icon='fa-cog'>
                <div className="modal-configurations-content">
                    <nav className="menu-configurations">
                        <ul className="list-configurations">

                            <li className={`item-configurations general  ${ tabs[0].show ? 'tab-active':''}`} onClick={()=> this.handleClick('general')}> 
                                Geral 
                            </li>
                            
                            <li className={`item-configurations media-social ${ tabs[1].show ? 'tab-active':'' }`} onClick={()=> this.handleClick('media')}> 
                                Redes Sociais 
                            </li>
                            
                        </ul>
                    </nav>

                   
                    {tabs[0].show && 
                        <General saveFunction={ this.props.saveFunction } close={ close } toogleBackground={ toogleBackground }  imgSrc={ user.backgroundImg} /> 
                    }
                    
                    {tabs[1].show && 
                        <MediaSocial saveFunction={ this.props.saveFunction } close={ close }/> 
                    }
                   
                </div>
            </CustomModal>
        );
    }
}

const mapStateToProps = state => ({
    user: state.Coach.user,
    username: state.Coach.username
});

const mapDispatchToProps = dispatch => bindActionCreators({ 
    editProfile, 
    setPublishProfile,
    asyncVerifyIfUsernameIsAvailable,
    loadCountries,
    cancelSaveConfig
}, dispatch);

export default connect( mapStateToProps, mapDispatchToProps )( ModalConfig );
