import {actions} from '../../utils/constants';

const INITIAL_STATE = {
    isLoading: true,
    postsData: null,
    postsDataPageContent: null,
    search: '',
    postSuggestions: null,
    postCategories: null,
};

export default(state= INITIAL_STATE, {type, payload}) => {
    switch (type) {

    case actions.SET_POSTS:
        return {...state, postsData: payload};
    case actions.SET_IS_POSTS_LOADING:
        return {...state, isLoading: payload};
    case actions.SET_BLOG_SEARCH:
        return {...state, search: payload};
    case actions.SET_POSTS_PAGE_CONTENT:
        return {...state, postsDataPageContent: payload};
    case actions.SET_POST_SUGGESTIONS:
        return {...state, postSuggestions: payload};
    case actions.SET_POST_CATEGORIES: 
        return {...state, postCategories: payload};
    default:
        return {...state};
    }
};