import React from 'react';
import {Carousel} from 'react-responsive-carousel';

import './carousel.css';

export default class CustomCarousel extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            index: props.selectedItem || 0,
        };
    }

    render() {
        const {index} = this.state;
        const {children, ...rest} = this.props;

        return (
            <Carousel
                showThumbs={false}
                showStatus={false}
                infiniteLoop={true}
                useKeyboardArrows={false}
                emulateTouch={false}
                dynamicHeight={false}
                width={'100%'}
                onChange={(index) => this.setState({index})}
                showIndicators={children && children.length > 1}
                {...rest}
                selectedItem={Math.min(children.length - 1, index)}
            >
                {children}
            </Carousel>
        );
    }
}
