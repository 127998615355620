import React from 'react';

import './header-avatar.css';
import ImageEditor from '../image-editor/image-editor';
import userImgDefault from '../../../../../../img/avatar-default.png';

export default class HeaderAvatar extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isEditingImage: false,
        };

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleUpdateAvatar = this.handleUpdateAvatar.bind(this);
    }

    openModal() {
        if (!this.props.edit) {
            return;
        }
        this.setState(() => ({isEditingImage: true}));
    }

    closeModal() {
        this.setState(() => ({isEditingImage: false}));
    }

    handleUpdateAvatar(croppedImage) {
        this.props.onUpdate(croppedImage);
        this.closeModal();
    }

    render() {
        if (!this.props.edit) {
            return (
                <div className='avatar'>
                    <img
                        className='avatar-photo'
                        src={this.props.photo ? this.props.photo : userImgDefault }
                        alt='avatar'
                    />
                </div>
            );
        } else {
            return (
                <div className='avatar' onClick={this.openModal}>
                    <img
                        className='avatar-photo'
                        src={this.props.photo}
                        alt='avatar'
                    />

                    <span className='edit-avatar'>
                        <i className='fa fa-pencil' />
                        Editar Imagem
                    </span>

                    {this.state.isEditingImage &&
                    <ImageEditor onCancel={this.closeModal} onSave={this.handleUpdateAvatar} srcOrBlob={this.props.photo}/>}
                </div>
            );
        }
    }
}
