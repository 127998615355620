import React, {useState} from 'react';

import './lazy-image.scss';

export default function LazyImage({placeholderClassName, alt = '', className = '', isLoadedCallback, ...props}) {
    const [loadedImage, setLoadedImage] = useState(false);

    function handleLoadedImage() {
        setLoadedImage(true);

        if (isLoadedCallback) {
            isLoadedCallback();
        }
    }

    return (
        <div className='lazy-img-container'>
            {!loadedImage && <div className={`placeholder ${placeholderClassName}`}></div>}
            <img
                alt={alt}
                className={`${loadedImage ? 'loaded-img' : 'unloaded-img'} ${className}`}
                onLoad={handleLoadedImage}
                {...props}
            />
        </div>
    );
}
