import { actions } from '../../utils/constants';

const INITIAL_STATE = {
    bestCoaches: [],
    inSearching: false, 
    isSearching: false,
    searchResults: [],
    nextPage: 0,
    totalProfiles: 0,
    totalPages: 0,
    stats: {},
    pages: {},    
    search: {
        country:'',
        name:'',
        state: '',
        expertises: [],
        city: '',
        categories: [],
    },
    searchingOptions: {
        countries: [],
        states: [],
        expertises: [],
        cities: []
    },
    disableNext: false,
    disablePrevious: false,
    stateControl: false,
    query: {
        country:'',
        name:'',
        state: '',
        expertises: [],
        categories: [],
        city: '',
    },
    allOptionsByLang: {
        pt: {countries: [], expertises: []},
        en: {countries: [], expertises: []},
        es: {countries: [], expertises: []},
    },
    allOptionsMixed: {
        countries: [],
        expertises: [],
    },
    blogPosts: [],
};

export default( state= INITIAL_STATE, action ) => {
    switch (action.type) {

    case actions.SET_DISABLE_NEXT:
        return { ...state, disableNext: action.payload };

    case actions.SET_DISABLE_PREVIOUS:
        return { ...state, disablePrevious: action.payload };

    case actions.RESET_ARRAY_PAGES:
        return { ...state, pages: {} };

    case actions.SET_ARRAY_PAGES:
        return { ...state, pages: {...state.pages, [action.payload.idx]: action.payload.value}};
    
    case actions.SET_TOTAL_PROFILES:
        return { ...state, totalProfiles: action.payload };

    case actions.SET_NEXT_PAGE:
        return { ...state, nextPage: action.payload };

    case actions.SET_TOTAL_PAGES:
        return { ...state, totalPages: action.payload };
    
    case actions.DO_SEARCH:
        return { ...state, inSearching: true };
            
    case actions.EXIT_SEARCH:
        return { ...state, inSearching: false };

    case actions.SET_LOADING:
        return { ...state, isSearching: action.payload };

    case actions.SET_SEARCH_RESULT:
        return { ...state, searchResults: action.payload };

    case actions.SET_OPTIONS:
        return { ...state, searchingOptions: { ...state.searchingOptions, [action.payload.id]: action.payload.value}};

    case actions.CHANGE_SEARCH_INPUT:
        return { ...state, search: { ...state.search, [action.payload.id]: action.payload.value } };

    case actions.ADD_INITIAL_COACHES:
        return { ...state, bestCoaches: action.payload };

    case actions.TOOGLE_STATE:
        return { ...state, stateControl: action.payload };

    case actions.SET_RESULTS_QUERY:
        return { ...state, query: action.payload };
    
    case actions.SET_STATS_INFO:
        return {...state, stats: action.payload};

    case actions.SET_ALL_OPTIONS_BY_LANG:
        return {...state, allOptionsByLang: action.payload};

    case actions.SET_ALL_OPTIONS_MIXED:
        return {...state, allOptionsMixed: action.payload};

    case actions.ASYNC_SET_BLOG_POSTS:
        return { ...state, blogPosts: action.payload };

    default:
        return { ...state };
    }
};