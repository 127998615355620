import React from 'react';
import Header from './components/header';
import InfoSection from './components/info-section';
import StartSection from './components/start-section';

import './customize-page.css';

function CustomizePage() {
    
    return (
        <div className='customize-page'>
            <Header />
            <InfoSection />
            <StartSection />
        </div>
    );
}

export default CustomizePage;
