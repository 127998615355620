import React, { Component } from 'react';
import Slider from 'react-slick';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';

import './slickCarousel.css';

class Carousel extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {settings, children, className} = this.props;
       

        return (
            <section className={`carousel-main ${className && className}`}>
            
                <Slider {...settings}>
                    {children}    
                </Slider>
                    
            </section>
        );
    }
}

export default (Carousel);
