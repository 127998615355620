import React, {useEffect, useState} from 'react';
import ContentLoader from 'react-content-loader';
import parse, {attributesToProps} from 'html-react-parser';
import {useDispatch, useSelector} from 'react-redux';

import PostInfo from '../postInfo';
import SearchBar from '../searchBar';
import NewHeader from '../../../../components/newHeader';
import {asyncGetPostsPageContent, setSearch} from '../../blog.actions';
import Footer from '../../../home/components/footer';
import LazyImage from '../../../../components/lazyImage';
import PostSuggestions from '../postSuggestions';
import Categories from '../categories';

import './blog-post-content.scss';

const options = {
    replace: (domNode) => {
        if (domNode.attribs && domNode.name === 'img') {
            const props = attributesToProps(domNode.attribs);

            return (
                <ContentImgLoader {...props} />
            );
        }

        if (domNode.attribs && domNode.name === 'p' && domNode.children.length === 0) {
            return <></>;
        }
    }
};

function PostContent({match}) {
    const isLoading = useSelector(({Blog}) => Blog.isLoading);
    const postData = useSelector(({Blog}) => Blog.postsDataPageContent);
    const postSuggestions = useSelector(({Blog}) => Blog.postSuggestions);
    const dispath = useDispatch();
    const {slug} = match.params;

    const handleClearSearch = () => {
        dispath(setSearch(''));
    };

    useEffect(() => {
        dispath(asyncGetPostsPageContent(slug));
        document.querySelector('.blog-post-content-page').scrollIntoView({behavior: 'smooth'});
    }, [slug]);

    return (
        <div className='blog-post-content-page'>
            <NewHeader
                showSelectLang={false}
                className='blog-header'
                onLogoClick={handleClearSearch}
            />

            <SearchBar />

            <Categories />
            
            <div className='blog-post-content'>

                <PostBody 
                    isLoading={isLoading} 
                    post={postData}
                />

                {!isLoading && postData && <PostSuggestions posts={postSuggestions} />}
            </div>
            <Footer />
        </div>
    );
}

function PostBody({isLoading, post}) {
    if (isLoading || !post) {
        return <LoadingContent className='skelton-loading' />;
    } else {
        return (
            <div className='blog-post-content-body'>
                <div className='blog-post-title'>
                    <h1>{post.title}</h1>
                </div>
                <p className='blog-post-description'>{post.description}</p>

                <PostInfo author={post.author} categories={post.categories} lastPublishedAt={post.last_published_at} />

                {parse(post.body, options)}

                <div className='bottom-line'>
                    <PostInfo author={post.author} categories={post.categories} lastPublishedAt={post.last_published_at} />
                    <hr />
                </div>
            </div>
        );
    }
}

function ContentImgLoader(props) {
    const [isLoaded, setIsLoaded] = useState(false);

    const handleIsLoaded = () => {
        setIsLoaded(true);
    };

    return (
        <div className='img-container' style={{height: isLoaded ? '100%' : '300px'}}>
            <LazyImage
                placeholderClassName='lazy-placeholder'
                isLoadedCallback={handleIsLoaded}
                {...props}
            />
        </div>
    );
}

function DesktopLoader() {
    return (
        <>
            <rect x='2%' y='1%' rx='8px' ry='8px' width='70%' height='60px' />
            <rect x='2%' y='10%' rx='8px' ry='8px' width='95%' height='100px' />
            <rect x='2%' y='25%' rx='8px' ry='8px' width='95%' height='40px' />
            <rect x='2%' y='32%' rx='8px' ry='8px' width='95%' height='500px' />
        </>
    );
}

function LoadingContent(props) {
    return (
        <ContentLoader
            style={{
                padding: '20px',
            }}
            speed={2}
            width='100%'
            height='800px'
            backgroundColor='#e6e6e6'
            foregroundColor='#d8d8d8'
            className='post-list-content-loader'
            {...props}
        >
            <DesktopLoader />
        </ContentLoader>
    );
}

export default PostContent;
