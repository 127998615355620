import React, { Component } from 'react';

import { Button } from '../../../../../commons/index';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { editProfile } from '../../../header/header.action';
import { setPublishProfile, saveConfig, cancelSaveConfig, 
    selectAsyncExpertise, asyncVerifyIfUsernameIsAvailable,
    selectedCountry, setCountryState, selectCity, loadCountries, imagesBackgroundLoad
} from '../../../../coach.actions';


class General extends Component {
    constructor(props) {
        super(props);
        this.saveConfigs = this.saveConfigs.bind(this);
        this.cancelSaveConfigs = this.cancelSaveConfigs.bind(this);
    }



    saveConfigs() {
        this.props.saveConfig();
        this.props.saveFunction();
        this.props.close();
    }

    cancelSaveConfigs() {
        this.props.cancelSaveConfig();
        this.props.close();
    }
    
    componentDidMount() {
        this.props.loadCountries();
        this.props.imagesBackgroundLoad();
    }

    setCountryItems(array=[]) {
        return array.map((country, i ) => {
            return <option key={i} value={ country.name }>{ country.name }</option>;
        });
    }

    setStateItems(array=[{ name: this.props.user.state }]) {
        return array.map((state, i ) => {
            return <option key={i} value={ state.name }>{ `${state.name}${state.abbrev ? `- ${state.abbrev}`:'' }` }</option>;
        });
    }

    setCityItems(array=[{ name: this.props.user.city }]) {
        return array.map((city, i ) => {
            return <option key={i} value={ city.name }>{ `${city.name}` }</option>;
        });
    }

    render() { 

        const { imgSrc, toogleBackground, user, country, countriesList } = this.props;

        return (  
            <section className="configurations-general">
                <section className="configurations-general-content">
                    <div className='personal-info-container'>
                        <div className='sector-divider'>Dados Site Pessoal</div>
                        <div className='input-group no-edit'>
                            <label htmlFor='url'>Endereço site pessoal</label>
                            <input readOnly value={`${window.location.origin}/${this.props.username}`} type='text' id='username' />
                        </div>

                        <div className='input-group'>
                            <label htmlFor='username'>Link pessoal</label>
                            <input type='text' value={ this.props.username } onBlur={ this.props.asyncVerifyIfUsernameIsAvailable } onChange={ this.props.editProfile } id='username' />
                            { (this.props.searchingUsername && this.props.disableSaveButon) && <div style={{ color: 'green', fontSize: '0.6em'}}>comparando link <i style={{ fontSize:'.6em'}} className='fa fa-spinner fa-spin' /></div>}
                            { (!this.props.searchingUsername && this.props.disableSaveButon) && <div className='error-message'>url indisponivel, tente outra por favor.</div>}
                        </div>
                    </div>

                    <div className='location-info-container'>
                        <div className='sector-divider'>Localização</div>
                        <div className='input-group select'>
                            <label htmlFor='country'>país</label>
                            <select className='custom-select' value={ user.country } onChange={ this.props.selectedCountry}>
                                <option value="-1">Escolha o País</option>
                                { this.setCountryItems( countriesList ) }
                            </select>
                        </div>

                        <div className='input-group select'>
                            <label htmlFor='state'>Estado</label>
                            <select value={ user.state || '' } disabled={ this.props.disableCheck } className='custom-select' onChange={ this.props.setCountryState }>
                                <option value="-1">Escolha o Estado</option>
                                { this.setStateItems(country.states) }
                            </select>
                        </div>

                        <div className='input-group select'>
                            <label htmlFor='city'>Cidade</label>
                            <select className='custom-select' value={ user.city || '' } onChange={ this.props.selectCity }>
                                <option value="-1">Escolha a Cidade</option>
                                { country.cities.length !== 0 && this.setCityItems(country.cities)}
                                { (country.cities.length === 0 || !country.cities) && <option value={ user.city }>{user.city}</option>}
                            </select>
                        </div>
                    </div>

                    <div className='input-group switch'>
                        <label htmlFor='publishProfile'>Publicar Perfil </label>
                        <input type="checkbox" checked={ user.published } onChange={ this.props.setPublishProfile } className='inputSwitch' id="publishProfile" />
                        <label className='switchLabel' htmlFor="publishProfile">Toggle</label>
                    </div>
                    
                    <div className='input-group changeGround'>
                        <label htmlFor='imgBack'> Imagem de fundo <br/> do cabeçalho </label>
                        <div id='imgBack' onClick={ toogleBackground } className='selectImgBack'>
                            <img src={ imgSrc.mobile } alt='Background imagem'/>
                            <span className='edit-changeGround'>
                                <i className='fa fa-pencil' />
                            </span>
                        </div>
                    </div>
                </section>

                <div className="configurations-buttons">
                    <Button type='outline warning' text='Cancelar' click={ this.cancelSaveConfigs }  />
                    <Button type='warning' text='Salvar' disabled={ this.props.disableSaveButon } click={ this.saveConfigs } />
                </div>

            </section> 
        );
    }
}

const mapStateToProps = state => ({
    user: state.Coach.user,
    expertiseAreas: state.Coach.expertisesArray,
    username: state.Coach.username,
    disableSaveButon: state.Coach.disableSaveConfig,
    countriesList: state.Coach.countryList,
    country: state.Coach.country,
    disableCheck: state.Coach.stateDisabled,
    searchingUsername: state.Coach.searchingUsername
});

const mapDispatchToProps = dispatch => bindActionCreators({ 
    editProfile, 
    setPublishProfile, 
    saveConfig, 
    cancelSaveConfig, 
    selectAsyncExpertise,
    asyncVerifyIfUsernameIsAvailable,
    selectedCountry,
    setCountryState,
    selectCity,
    loadCountries,
    imagesBackgroundLoad
}, dispatch);

export default connect( mapStateToProps, mapDispatchToProps )( General );